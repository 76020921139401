import React, { useContext, useEffect, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import _, { set } from 'lodash'
import { runInAction } from 'mobx'
import Select, { Props, ControlProps, components as reactSelectComponents } from 'react-select'

// evertel
import { useService } from '@evertel/di'
import { CustomAsyncPaginate, CustomOptionProps } from '@evertel/web/ui'
import { RepliesViewController } from '@evertel/message'
import { RoomContext } from '../RoomContext'
import { ReplyTile } from '@evertel/web/message'
import { DisplayRoomMessage } from '@evertel/stores'
import { APIDataMediaToNormalizedMedia, NormalizedMedia } from '@evertel/media'
import { FetchUserService } from '@evertel/blue-user'

interface RoomRepliesSearchSelectProps extends Omit<Props, 'maxMenuHeight'> {
    selected?: DisplayRoomMessage[],
    isDisabled?: boolean,
    placeholder?: string,
    maxMenuHeight?: number|string,
    className?: string,
    onEndReachedThreshold?: number,
    onSelect?: (reply: DisplayRoomMessage) => void
}

const RoomRepliesSearchSelect: React.FC<RoomRepliesSearchSelectProps> = observer(({
    selected = [],
    isDisabled,
    placeholder = 'Search replies...',
    noOptionsMessage = 'No replies found',
    onSelect,
    maxMenuHeight,
    className,
    onEndReachedThreshold = 80,
    ...otherProps
}) => {

    const [loadingNotification, setLoadingNotification] = useState(false)

    const { roomController } = useContext(RoomContext)
    const fetchUserService = useService(FetchUserService, [])
    const repliesViewController = useService(RepliesViewController, [roomController.roomId])

    useEffect(() => {
        if (roomController.roomId) {
            repliesViewController.init(roomController.roomId)
        }
    }, [roomController.roomId])

    const retrieveRepliesUsers = useCallback(() => {
        const ownerIds = repliesViewController.replies.map(m => m.ownerId)
        const forwardedOwnerIds = repliesViewController.replies
            .filter(m => m.type === 'forwarded' && _.get(m, 'meta.forwardedMessage.ownerId'))
            .map(m => m.meta.forwardedMessage.ownerId)

        fetchUserService.fetchSpecificUsersFromRoom(roomController.roomId, ownerIds)
        fetchUserService.fetchUsersForForwardedOwnerIds(roomController.room.departmentId, forwardedOwnerIds)
    }, [repliesViewController, roomController.roomId, fetchUserService])

    useEffect(() => {
        if (repliesViewController.replies.length) {
            retrieveRepliesUsers()
        }
    }, [repliesViewController.replies, retrieveRepliesUsers])

    const search = useCallback(async (
        searchString: string,
        loadedOptions: DisplayRoomMessage[],
        { page }: { page: number }
    ) => {
        if (repliesViewController.searchTerm !== searchString) {
            repliesViewController.setSearchTerm(searchString)
        }

        repliesViewController.setPage(page)

        try {
            await repliesViewController.search()
        } catch (error) {
            console.error('ERROR: RoomRepliesSearchSelect search():', error)
        }

        const hasMore = (repliesViewController.repliesLatestFetch.length >= repliesViewController.limit)

        return {
            options: repliesViewController.repliesLatestFetch || [],
            hasMore,
            additional: {
                page: page + 1
            }
        }
    }, [roomController.roomId])

    const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number): boolean => {
        if (repliesViewController.isLoading) return false
        const maxPixelThreshold = 300
        const percentThreshold = 100 - onEndReachedThreshold

        const pxFromBottom = scrollHeight - clientHeight - scrollTop
        const percentThresholdFromBottomInPx = ((percentThreshold / 100) * (scrollHeight - clientHeight))
    
        const thresholdToUse = Math.min(percentThresholdFromBottomInPx, maxPixelThreshold)

        return pxFromBottom <= thresholdToUse
    }

    const onSelectOption = (reply: DisplayRoomMessage) => {
        if (onSelect) onSelect(reply)
    }

    const toggleNotify = async (message: DisplayRoomMessage) => {
        try {
            if (!message.id) return
            setLoadingNotification(true)
            const notify = message.meta?.repliesSubscription?.notify as boolean
            await roomController.updateRepliesNotification(!notify, message.id)
            runInAction(() => {
                set(message, 'meta.repliesSubscription.notify', !notify)
            })
        } catch (e) {
            /** nothing */
        } finally {
            setLoadingNotification(false)
        }
    }

    return (
        <CustomAsyncPaginate
            name="room-replies"
            keyName={roomController.roomId}
            searchOnly={true}
            loadOptions={search}
            debounceTimeout={300}
            additional={{
                page: 1
            }}
            defaultOptions
            shouldLoadMore={shouldLoadMore}
            value={selected}
            maxMenuHeight={maxMenuHeight}
            isDisabled={isDisabled}
            openMenuOnClick
            onChange={(message: DisplayRoomMessage, test) => {
                // this will trigger when clicking or using arrows from select options
                // instead disabled the visual indication of this and disabled
            }}
            noOptionsMessage={() => noOptionsMessage as string}
            placeholder={placeholder}
            className={classNames('no-bg space-below reply-tile-select', className)}
            components={{ Input: () => null, Option: ReplyOption }}
            customProps={{
                onSelect: onSelectOption,
                toggleNotify,
                loadingNotification,
                room: roomController.room
            }}
            {...otherProps}
        />
    )
})

interface SelectCustomProps {
    data: DisplayRoomMessage,
    onSelect: (reply: DisplayRoomMessage) => void,
    toggleNotify: (message: DisplayRoomMessage) => void,
    loadingNotification: boolean
    room: any
}

const ReplyOption = observer((props: CustomOptionProps<DisplayRoomMessage, SelectCustomProps>) => {
    const { data, selectProps } = props
    const { onSelect, toggleNotify, loadingNotification, room } = selectProps.customProps
    let normalizedResults = []
    

    if (data.media) {
        normalizedResults = data.media.map(m => APIDataMediaToNormalizedMedia(m))
    } 

    return (
        <reactSelectComponents.Option {...props}>
            <ReplyTile
                message={data}
                media={normalizedResults}
                onSelect={onSelect}
                onToggleNotify={(message) => toggleNotify(message)}
                loadingNotification={loadingNotification}
            />
        </reactSelectComponents.Option>
    )
})

export { RoomRepliesSearchSelect }