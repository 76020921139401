import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { APIDataRoomMessage } from '@evertel/types'
import { debounce } from 'lodash'
import { SessionState } from '@evertel/session'
import { UnreadCountsState } from '@evertel/blue-user'

// Assuming MESSAGE_DEFAULT_INCLUDE is imported from the appropriate location
import { MESSAGE_DEFAULT_INCLUDE } from './MessageWallController'

import debugModule from 'debug'
const debug = debugModule('app:RepliesViewController')

class RepliesViewController {
    replies: APIDataRoomMessage[] = []
    repliesLatestFetch: APIDataRoomMessage[] = []
    roomId = 0
    searchTerm = ''
    page = 1
    limit = 10
    repliesCount = 0
    isLoading = false

    constructor(
        private api: Api,
        private session: SessionState,
        private unreadState: UnreadCountsState
    ) {
        makeAutoObservable(this)
    }

    init(roomId: number) {
        this.roomId = roomId
        this.resetState()
    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value
    }

    setPage(page: number) {
        this.page = page
    }

    debouncedSearch = debounce(async () => this.search(), 300)

    search = async () => {
        if (!this.roomId) return

        runInAction(() => {
            this.isLoading = true
        })
        
        let count = this.repliesCount
        let results: APIDataRoomMessage[] = []

        if (!this.repliesCount) {
            count = await this.fetchRepliesCount()
        }

        this.repliesLatestFetch = []

        if (count > this.replies?.length) {
            results = await this.api.Routes.Room.getMessages(this.roomId, this.filter)
        }

        const processedResults = results.map(message => {
            message.meta = message.meta || {}

            if (message.repliesSubscriptions && message.repliesSubscriptions.length > 0) {
                const subscription = message.repliesSubscriptions.find(sub => sub.notify !== undefined)
                if (subscription) {
                    message.meta.repliesSubscription = { notify: subscription.notify }
                } else {
                    // No subscription found, treat it as a true subscription
                    message.meta.repliesSubscription = { notify: true }
                }
            } else {
                // No repliesSubscriptions array, treat it as a true subscription
                message.meta.repliesSubscription = { notify: true }
            }
            if (message.isRetracted) {
                message.type = 'retracted'
                message.ownerId = 1
            }

            return message
        })

        runInAction(() => {
            this.repliesLatestFetch = processedResults
            this.replies = [...this.replies, ...processedResults]
            this.isLoading = false
        })  
    }

    fetchNextReplies() {
        this.page = this.page + 1
        this.search()
    }

    async fetchRepliesCount() {
        const res = await this.api.Routes.Room.getMessagesCount(this.roomId, this.filter.where)

        runInAction(() => {
            this.repliesCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    resetSearchMeta() {
        this.page = 1
        this.replies = []
        this.repliesCount = 0
    }

    resetState() {
        this.resetSearchMeta()
        this.searchTerm = ''
    }

    async markAllRepliesAsRead(roomId: number) {
        this.unreadState.markAllRepliesAsRead(roomId)
    }

    get filter() {
        const f: any = {
            where: {
                hasReplies: true
            },
            order: 'updatedDate DESC',
            limit: this.limit,
            skip: (this.page - 1) * this.limit,
            include: [
                ...MESSAGE_DEFAULT_INCLUDE,
                {
                    relation: 'repliesSubscriptions',
                    scope: {
                        where: {
                            blueUserId: this.session.currentUserId
                        },
                        fields: ['id', 'notify']
                    }
                }
            ]
        }

        if (this.searchTerm !== '') {
            f.where.searchTerm = this.searchTerm
        }

        return f
    }
}

decorate(injectable(), RepliesViewController)
decorate(inject(Api), RepliesViewController, 0)
decorate(inject(SessionState), RepliesViewController, 1)
decorate(inject(UnreadCountsState), RepliesViewController, 2)

export { RepliesViewController }