import React, { MouseEventHandler, forwardRef, memo, useEffect, useRef, useState } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy'
import { useCurrentMediaPlaying, useForkedRef } from '@evertel/hooks'

export interface VideoProps extends ReactPlayerProps {
    url: string,
    fileName?: string,
    controls?: boolean,
    width?: number | string,
    height?: number | string,
    maxWidth?: number,
    maxHeight?: number,
    fallbackWidth?: number,
    rounded?: boolean,
    showThumbnail?: boolean
    onClick?: MouseEventHandler<HTMLImageElement>
    light?: boolean,
    fit?: 'contain' | 'cover' | 'fill' | 'scale' | 'none' | 'scale-down' | 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset',
}

const Video = memo(forwardRef<ReactPlayer, VideoProps>(({
    url,
    fileName = '',
    controls = true,
    width,
    height,
    maxWidth,
    maxHeight,
    fallbackWidth = '70%',
    light,
    rounded,
    showThumbnail = false,
    onClick,
    ...otherProps
}, ref) => {

    const { currentMediaPlaying, setCurrentMediaPlaying } = useCurrentMediaPlaying()
    const player = useRef(null)
    const forkedRef = useForkedRef(ref, player)

    const [isLoading, setIsLoading] = useState(true)
    const [playing, setPlay] = useState(false)
    const [thumbnailUrl, setThumbnailUrl] = useState('')

    const DEFAULT_DIMENSION = 300
    const DEFAULT_THUMBNAIL_TIME = 2
    
    useEffect(() => {
        if (!currentMediaPlaying || currentMediaPlaying === url || !playing) {
            return
        }
        setPlay(!playing)

    }, [currentMediaPlaying])

    useEffect(() => {
        if (showThumbnail && player.current) {
            generateThumbnail()
        }
    }, [showThumbnail, player.current])

    const generateThumbnail = () => {
        const video = player.current.getInternalPlayer()

        if (!video) return null

        // const video = document.createElement('video')
        const canvas = document.createElement('canvas')

        //video.crossOrigin = 'use-credentials'
        video.src = url
        video.currentTime = DEFAULT_THUMBNAIL_TIME

        video.addEventListener('seeked', () => {
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
            const thumbnailDataUrl = canvas.toDataURL('image/jpeg')
            setThumbnailUrl(thumbnailDataUrl)
        })

        video.addEventListener('loadedmetadata', () => {
            if (video.readyState >= 2) {
                video.currentTime = DEFAULT_THUMBNAIL_TIME
            }
        })
    }

    const onReady = () => {
        setIsLoading(false)
    }

    const onPlay = () => {
        // notify context to stop other players
        if (!playing) setCurrentMediaPlaying(url)

        setPlay(!playing)
    }


    if (!width && !height) {
        width = fallbackWidth //usually 70%, 100% on forwarded
        height = undefined
    }

    if (showThumbnail && thumbnailUrl) {
        return (
            <div 
                data-tooltip={fileName || 'No file name'}
                data-tooltip-pos="top">
                <img
                    src={thumbnailUrl}
                    alt="Video Thumbnail"
                    style={{
                        width: width,
                        height: height,
                        maxWidth: maxWidth,
                        maxHeight: maxHeight,
                        // objectFit: otherProps?.fit,
                        borderRadius: rounded ? '0.4rem' : 0
                    }}
                    onClick={onClick}
                />
            </div>
        )
    }

    return (
        // @ts-ignore
        <ReactPlayer
            ref={forkedRef}
            url={url}
            playing={playing}
            controls={controls && !isLoading}
            onReady={onReady}
            onPlay={onPlay}
            {...(light) && { onClickPreview: onPlay }}
            width={'100%'}
            height={'100%'}
            config={{
                file: {
                    attributes: {
                        style: {
                            backgroundColor: 'black',
                            width: width,
                            height: height,
                            maxWidth: maxWidth,
                            maxHeight: maxHeight,
                            objectFit: otherProps?.fit,
                            borderRadius: (rounded) ? '0.4rem' : 0
                        },
                        crossOrigin: 'use-credentials'
                    }
                }
            }}
            {...otherProps}
        />
    )
}
))

export { Video }
