import React from 'react'

import { NormalizedMedia } from '@evertel/media'
import { DisplayRoomMessage } from '@evertel/stores'
import { ReplyTileBodyForwarded } from './ReplyTileBodyForwarded'
import { ReplyTileBodyUser } from './ReplyTileBodyUser'

export interface ReplyTileBodyProps {
    message: DisplayRoomMessage,
    media?: NormalizedMedia[]
}

const ReplyTileBody: React.FC<ReplyTileBodyProps> = ({
    message,
    media
}) => {
    const { type } = message

    switch (type) {
        case 'forwarded':
            return <ReplyTileBodyForwarded message={message} media={media} />
        case 'user':
        case 'user_audio':
        default:
            return <ReplyTileBodyUser message={message} media={media} />
    }
}

export default ReplyTileBody