
import { APIDataMedia } from '@evertel/types'
import { makeAutoObservable, runInAction } from 'mobx'
import { debounce, uniqBy } from 'lodash'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { NormalizedMedia } from '../NormalizedMediaType'
import { APIDataMediaToNormalizedMedia } from '../APIDataMediaToNormalizedMedia'

class RoomMediaSearchController {
    media: NormalizedMedia[] = []
    mediaLatestFetch: NormalizedMedia[] = []
    roomId = 0
    searchTerm = ''
    mimeType = ''
    page = 1
    limit = 10
    mediaCount = 0
    isInfiniteScroll = true
    isLoading = false

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init(roomId: number, isInfiniteScroll = true) {
        this.roomId = roomId
        this.isInfiniteScroll = isInfiniteScroll
        this.resetState()
    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value
    }

    setMimetype(value: string) {
        this.resetSearchMeta()
        this.mimeType = value
    }

    setPage(page: number) {
        this.page = page
    }

    debouncedSearch = async () => {
        return debounce( async () => this.search(), 300)
    }

    search = async () => {
        if (!this.roomId) return

        runInAction(() => {
            this.isLoading = true
        })
        
        let count = this.mediaCount
        let results: APIDataMedia[] = []

        // grab the total count of media in this room
        if (!this.mediaCount) {
            count = await this.fetchMediaCount()
        }

        runInAction(() => {
            this.mediaLatestFetch = []
        })

        // stop from fetching if we already got them all
        if (count > this.media?.length) {
            results = await this.api.Routes.Room.getMedia(this.roomId, this.filter)
        }

        const normalizedResults = results.map(m => APIDataMediaToNormalizedMedia(m))
        const apiDataResults = (this.isInfiniteScroll) ? uniqBy([...this.media, ...normalizedResults], 'id') : normalizedResults

        runInAction(() => {
            this.mediaLatestFetch = normalizedResults
            this.media = apiDataResults
            this.isLoading = false
        })  
    }

    fetchNextMedia() {
        this.page = this.page + 1
        this.search()
    }

    async fetchMediaCount() {
        const res = await this.api.Routes.Room.getMediaCount(this.roomId, this.filter.where)

        runInAction(() => {
            this.mediaCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    resetSearchMeta() {
        this.page = 1
        this.media = []
        this.mediaCount = 0
    }

    resetState() {
        this.resetSearchMeta()
        this.searchTerm = ''
        this.mimeType = ''
    }

    get filter() {
        const f: any = {
            where: {},
            order: 'updatedDate DESC',
            limit: this.limit,
            skip: (this.page - 1) * this.limit
        }

        if (this.searchTerm != '') {
            f.where['fileName'] = { like: `%${this.searchTerm}%` }
        }

        return f
    }

    get images() {
        return this.media?.filter(m => m.contentType === 'image')
    }
}

decorate(injectable(), RoomMediaSearchController)
decorate(inject(Api), RoomMediaSearchController, 0)

export { RoomMediaSearchController }
