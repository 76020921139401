import React, { memo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
//evertel
import { Card, CardBody, ContentPlaceholder, Col, Row, Text, Icon } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { RoomForwardedMessageController } from '@evertel/room'
import { ProfilePic, useUserDetail } from '@evertel/web/user'
import { MessageBody } from './MessageBody'
import { MessageHeader } from './MessageHeader'
import { parsedContent } from '@evertel/utils'
import { MessageBodyProps } from '../../../types'
import { DisplayRoomMessage } from '@evertel/stores'

const ForwardedMessageTile: React.FC<MessageBodyProps> = observer(({
    message,
    media,
    variant = 'list-item'
}) => {

    const roomForwardedMessageController = useService(RoomForwardedMessageController, [message])

    useState(() => {
        roomForwardedMessageController.init(message)
    })

    const { openUserDetail } = useUserDetail()
    const forwardedPost = roomForwardedMessageController.message as DisplayRoomMessage

    //this isn't going to update when room is fetched because the controller dep doesn't change
    const room = roomForwardedMessageController.room
    const user = roomForwardedMessageController.user || {}
    const formattedDate = roomForwardedMessageController.formattedPublished

    const colProps = variant === 'search' ? {} : { xl: 7, md: 10, sm: 12 }

    return (
        <Col {...colProps}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {message.isUrgent && (
                    <span className="urgent-icon" >
                        <Icon
                            name='triangle-exclamation'
                            color='white'
                        />
                    </span>
                )}

                <div
                    dangerouslySetInnerHTML={{ __html: parsedContent(message.text) }}
                    className={`post-content ${message.isUrgent ? 'urgent' : ''}`}
                    style={{ display: 'inline-block' }}
                />
            </div>
            <Card className={classNames('mt-2 forwarded-message-tile')}>
                <CardBody>
                    <Row
                        className={classNames(
                            'message',
                            'post-' + message.id
                        )}
                    >
                        <Col
                            valign="top"
                            className="left-col mr-3">
                            <ProfilePic
                                userId={user?.id}
                                firstName={user?.firstName}
                                lastName={user?.lastName}
                                imageUrl={user?.publicImage}
                                isGuest={false}
                                onClick={openUserDetail}
                            />
                        </Col>

                        <Col valign="top">
                            <MessageHeader
                                message={forwardedPost}
                                user={user}
                                isGuest={false}
                                onClickUser={openUserDetail}
                            />
                            <MessageBody
                                message={forwardedPost}
                                highlightString={''}
                                media={media}
                            />
                        </Col>
                    </Row>

                    <Row>
                        {room?.name &&
                            <Text bold className="mt-4" color='muted'>{`Posted in #${room?.name} | ${formattedDate}`}</Text>
                        }
                        {!room?.name &&
                            <ContentPlaceholder
                                color="gray200"
                                height="26"
                                className="mt-4"
                            ><Text className="ml-2 mt-4" color='muted'>Posted in #</Text></ContentPlaceholder>
                        }
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
})

export const MemoizedForwardedMessageTile = memo(ForwardedMessageTile)
