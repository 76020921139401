import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
// evertel
import { Modal, ModalBody, ModalHeader, Row, Col, Button, Text, useUI, Icon } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { UserController } from '@evertel/blue-user'
import { useWindowDimensions } from '@evertel/web/hooks'
import { DepartmentsAccessController } from '@evertel/departments-access'
import { DepartmentController } from '@evertel/department'
import { CreateThreadController } from '@evertel/thread'
import { DrawerController } from '@evertel/message'
import { getNonGuestPrimaryDepartmentsAccess } from '@evertel/utils'

interface UserDetailModalProps {
    visible: boolean,
    userId: number,
    onClose: () => void,
    onCloseDone?: () => void
}

const UserDetailModal: React.FC<UserDetailModalProps> = observer(({
    visible,
    userId,
    onClose,
    onCloseDone
}) => {

    const navigate = useNavigate()
    const { addToast } = useUI()
    const { height: windowHeight } = useWindowDimensions()
    const session = useService(SessionState, [])
    const userController = useService(UserController, [])
    const departmentsAccessController = useService(DepartmentsAccessController, [])
    const departmentControllerPrimary = useService(DepartmentController, [])
    const departmentControllerSelected = useService(DepartmentController, [])
    const createThreadController = useService(CreateThreadController, [])
    const drawerController = useService(DrawerController, [])

    const [isLoading, setIsLoading] = useState(false)
    const [isStartingThread, setIsStartingThread] = useState(false)

    useEffect(() => {
        (async () => {
            if (!userId) return

            setIsLoading(true)

            await Promise.all([
                userController.init(userId),
                departmentsAccessController.init(userId)
            ])

            const primaryDepartment = getNonGuestPrimaryDepartmentsAccess(departmentsAccessController.departmentsAccess)
            const selectedDepartment = departmentsAccessController.selectedDepartmentsAccess

            if (primaryDepartment) {
                await departmentControllerPrimary.init(primaryDepartment.departmentId)
            }
            if (selectedDepartment && selectedDepartment.departmentId !== primaryDepartment?.departmentId) {
                await departmentControllerSelected.init(selectedDepartment.departmentId)
            }

            // Handle bot case
            if (userController.user?.isBot && session.selectedDepartmentId) {
                await departmentControllerSelected.init(session.selectedDepartmentId)
            }

            setIsLoading(false)
        })()
    }, [userId, userController, departmentsAccessController, departmentControllerPrimary, departmentControllerSelected, session.selectedDepartmentId])

    const startThread = async () => {
        if (!userId) return

        // start the thread
        try {
            setIsStartingThread(true)
            const thread = await createThreadController.createThread(userId)
            await drawerController.fetchActiveThreads()
            setIsStartingThread(false)

            onClose()

            // navigate to the new thread
            navigate(`/thread/${thread.id}`)

        } catch (error: any) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
    }

    const editProfile = () => {
        onClose()
        navigate(`/account/profile/${userId}`)
    }

    const user = userController.user || {}
    const isMe = (user?.id === session.currentUserId)

    const renderDepartmentInfo = () => {
        if (user?.isBot) {
            return (
                <>
                    <Text tag="h3" color="muted" bold>
                        {departmentControllerSelected.department?.name || 'Unknown Department'}
                    </Text>
                    <Text tag="h5" color="muted">Evertel Bot</Text>
                </>
            )
        }

        const primaryDepartment = getNonGuestPrimaryDepartmentsAccess(departmentsAccessController.departmentsAccess)
        const selectedDepartment = departmentsAccessController.selectedDepartmentsAccess

        if (!primaryDepartment && !selectedDepartment) {
            return (
                <Text tag="h3" color="muted" bold>Guest</Text>
            )
        }

        return (
            <Row valign='bottom' className="border-bottom flex-wrap">
                {primaryDepartment && (
                    <Col className='p-1'>
                        {selectedDepartment && primaryDepartment.departmentId !== selectedDepartment.departmentId && (
                            <Text tag="small" color="muted">Primary</Text>
                        )}
                        <Text tag="h3" color="muted" bold>{departmentControllerPrimary.department?.name}</Text>
                        <Text tag="h5" color="muted">{primaryDepartment.positionString || 'No position set'}</Text>
                    </Col>
                )}
                {selectedDepartment && primaryDepartment?.departmentId !== selectedDepartment.departmentId && (
                    <Col className='p-1'>
                        <Text tag="h3" color="muted" bold>{departmentControllerSelected.department?.name}</Text>
                        <Text tag="h5" color="muted">{selectedDepartment.positionString || 'No position set'}</Text>
                    </Col>
                )}
            </Row>
        )
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            onCloseDone={onCloseDone}>
            <ModalHeader />
            <ModalBody className="p-0 pt-3">
                {/* IMAGE */}
                <Row className="bg-primary">
                    {(user?.publicImage || user?.publicMedia) ? (
                        <img
                            src={(user.publicMedia) ? user.publicMedia.url + '?ver=500sqr' : user.publicImage}
                            alt={''}
                            width="100%"
                            style={{
                                maxHeight: windowHeight * 0.5,
                                objectFit: 'cover'
                            }}
                        />
                    ) : (
                        <img
                            src="/assets/img/avatars/user_photo_default.jpg"
                            alt={''}
                            width="100%"
                            style={{
                                maxHeight: windowHeight * 0.35,
                                objectFit: 'cover'
                            }}
                        />
                    )}
                </Row>
                {/* NAME */}
                <Row className="p-3">
                    <Col className="pt-3">
                        <Row>
                            <Text tag="h1">
                                {user?.firstName + ' ' + user?.lastName}
                            </Text>
                            {(user?.isAdmin) &&
                                <Icon
                                    name="star"
                                    color="warning"
                                    type="solid"
                                    className="ml-1"
                                />
                            }
                        </Row>
                        <span>
                            {renderDepartmentInfo()}
                        </span>
                    </Col>
                </Row>
                {/* BUTTONS */}
                <Row className="p-3 mb-3">
                    {!isMe && (
                        <>
                            <Button
                                onClick={startThread}
                                loading={isStartingThread}
                                className="mr-2">
                                DM {user?.firstName}
                            </Button>
                            {user?.phoneNumber && (
                                <Button
                                    outline
                                    className="mr-2"
                                    onClick={() => window.open(`tel:${user.phoneNumber}`)}
                                    data-tooltip={user?.phoneNumber}
                                    data-tooltip-pos="top">
                                    Call {user?.firstName}
                                </Button>
                            )}
                        </>
                    )}
                    {isMe && (
                        <Button
                            outline
                            onClick={editProfile}>
                            Edit Profile
                        </Button>
                    )}
                </Row>
            </ModalBody>
        </Modal>
    )
})

export { UserDetailModal }