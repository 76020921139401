import React, { memo, useContext } from 'react'
import classNames from 'classnames'
// evertel
import { Col, Icon, PhotoGalleryContext, Row } from '@evertel/web/ui'
import { Media } from '@evertel/web/ui'
import { parsedContent, scaleMedia } from '@evertel/utils'
import { useWindowDimensions } from '@evertel/web/hooks'
import { MessageBodyProps } from '../../../types'
import MessageBodyAction from './MessageBodyAction'

const MessageBody: React.FC<MessageBodyProps> = memo((
    data
) => {

    const { message, media, highlightString } = data

    const photoGallery = useContext(PhotoGalleryContext)

    const { width: windowWidth, height: windowHeight } = useWindowDimensions()
    const maxMediaWidth = windowWidth * 0.5
    const maxMediaHeight = windowHeight * 0.3

    const onClickMedia = (mediaId: number) => {
        photoGallery.setMedia(media, mediaId)
        photoGallery.setVisible(true)
    }

    if (!message?.text && !media?.length) return null


    //if a video doesn't have dimensions in a forwarded message the width must be 100%

    return (
        <Col>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {message.isUrgent && (
                    <span className="urgent-icon" >
                        <Icon
                            name='triangle-exclamation'
                            color='white'
                        />
                    </span>
                )}

                <div
                    dangerouslySetInnerHTML={{ __html: parsedContent(message.text, highlightString) }}
                    className={classNames('post-content', {'urgent': message.isUrgent} )}
                    style={{ display: 'inline-block' }}
                />
            </div>
            {(!!media?.length) && (
                <>
                    {[
                        //defaults to file if not a media type:
                        (contentType) => !['audio', 'image', 'video'].includes(contentType),
                        'audio',
                        ['image', 'video']
                    ].map((contentTypeFilter) => (
                        <Row
                            className="d-flex flex-wrap" 
                            key={
                                typeof contentTypeFilter === 'function'
                                    ? 'file'
                                    : Array.isArray(contentTypeFilter)
                                        ? contentTypeFilter.join('-')
                                        : contentTypeFilter
                            }
                        >
                            {media
                                .filter((m) =>
                                    typeof contentTypeFilter === 'function'
                                        ? contentTypeFilter(m.contentType)
                                        : Array.isArray(contentTypeFilter)
                                            ? contentTypeFilter.includes(m.contentType)
                                            : m.contentType === contentTypeFilter
                                )
                                .map((m) => {
                                    const scaledDimensions =
                                    ['image', 'video'].includes(m.contentType)
                                        ? scaleMedia({
                                            maxWidth: maxMediaWidth,
                                            maxHeight: maxMediaHeight,
                                            naturalHeight: m.height,
                                            naturalWidth: m.width
                                        })
                                        : undefined
                                    return (
                                        <Media
                                            key={m.id}
                                            media={m}
                                            width={scaledDimensions?.width}
                                            height={scaledDimensions?.height}
                                            rounded
                                            className="mt-2 mr-2"
                                            fileProps={{
                                                width: undefined,
                                                maxWidth: maxMediaWidth
                                            }}
                                            videoProps={{
                                                maxWidth: 'max(calc(100vw - 200px), 100%)',
                                                maxHeight: 'calc(100vh - 250px)',
                                                ...(message.meta.isForwarded ? { fallbackWidth: '100%' } : {})
                                            }}
                                            imageProps={{
                                                onClick: () => onClickMedia(m.id),
                                                // TODO: make work with fix-fluid for dynamic image resizing in rooms
                                                flexibility: 'fixed'
                                            }}
                                        />
                                    )
                                })}
                        </Row>
                    ))}
                </>
            )}

            {(!!message.meta?.cta?.length) &&
                <MessageBodyAction message={message} />
            }
        </Col>
    )
})

export { MessageBody }
