import React, { createContext, useState, useContext } from 'react'
import { DocumentModal } from './DocumentModal'
import { useNavigate } from 'react-router-dom'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { DocumentShareModal } from '../..'

export interface DocumentModalContext {
    openDocumentModal: (documentId: number) => void,
    closeDocumentModal: () => void
}
export interface DocumentModalProps {
    children: JSX.Element[]
}

export const DocumentModalContext = createContext<DocumentModalContext>({
    openDocumentModal: (documentId: number) => {
        //
    },
    closeDocumentModal: () => {
        //
    }
})

export const DocumentModalContextProvider: React.FC<DocumentModalProps> = ({
    children
}) => {

    const [visible, setVisible] = useState(false)
    const [documentId, setDocumentId] = useState(0)
    const [isShareModalVisible, setIsShareModalVisible] = useState(false)
    const navigate = useNavigate()
    const session = useService(SessionState, [])

    const openDocumentModal = (documentId: number) => {
        setDocumentId(documentId)
        setVisible(true)
    }

    const closeDocumentModal = () => {
        setVisible(false)
    }

    const onEdit = () => {
        navigate(`/everdocs/${documentId}`)
        closeDocumentModal()
    }

    return (
        <DocumentModalContext.Provider value={{ openDocumentModal, closeDocumentModal }}>
            {children}
            <DocumentModal
                visible={visible}
                documentId={documentId}
                onEdit={onEdit}
                onShare={() => setIsShareModalVisible(true)}
                onClose={() => setVisible(false)}
            />
            <DocumentShareModal
                isVisible={isShareModalVisible}
                backdrop={false}
                onClose={() => setIsShareModalVisible(false)}
                documentId={documentId}
                departmentId={session.selectedDepartmentId}
                currentUserId={session.currentUserId}
                //documentName={selectedDoc?.document?.data?.title}
            />
        </DocumentModalContext.Provider>
    )
}

export const DocumentModalConsumer = DocumentModalContext.Consumer

export const useDocumentModal = () => {
    return useContext(DocumentModalContext)
}
