import React from 'react'
import { forwardRef, HTMLAttributes, MouseEventHandler } from 'react'
import classNames from 'classnames'

interface Props extends React.HTMLProps<HTMLDivElement> {
    className?: string,
    children: any
}

const CardHeaderActions = forwardRef<HTMLDivElement, Props>(({
    className,
    children,
    ...otherProps
}, ref) => {

    const _className = classNames('card-header-actions', className)

    return (
        <div
            ref={ref}    
            className={_className}
            {...otherProps}>
            {children}
        </div>
    )
})

export { CardHeaderActions }
