
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'
// evertel
import { useService } from '@evertel/di'
import { DrawerController } from '@evertel/message'
import { MultilinePlaceholder, useUI } from '@evertel/web/ui'
import { SessionState } from '@evertel/session'
import { DeviceState } from '@evertel/device'
import { CurrentUserController, UnreadCountsState, UserSettingsController } from '@evertel/blue-user'
import { useJoinRoom } from '@evertel/web/room'
import { useCreateThread } from '@evertel/web/thread'
import { DrawerNavDropdownLink, DrawerNavItemLink, DrawerNavRoomLink, DrawerNavThreadLink } from '../elements'


const MessagesDrawer: React.FC = observer(() => {


    const session = useService(SessionState, [])
    const drawerController = useService(DrawerController, [session.selectedDepartmentId])
    const userSettingsController = useService(UserSettingsController, [])
    const currentUserController = useService(CurrentUserController, [session.currentUserId])
    const unreadState = useService(UnreadCountsState, [])
    const deviceState = useService(DeviceState, [])
    

    const navigate = useNavigate()
    const location = useLocation()
    const { openJoinRoom } = useJoinRoom()
    const { addToast } = useUI()
    const { openCreateThread } = useCreateThread()

    const [isLoading, setIsLoading] = useState(false)

    useState(() => {
        setIsLoading(true)
        Promise.all([
            drawerController.fetchActiveRooms(),
            drawerController.fetchActiveThreads(),
            unreadState.fetchUnreadCounts()
            
        ]).then(() => setIsLoading(false))
    })
    
    useEffect(() => {
        userSettingsController.init(session.currentUserId)
        drawerController.startPoll()
        return function messagesDrawerCleanup() {
            drawerController.stopPoll()
        }
    }, [])

    const closeThread = async (threadId: number, e: Event) => {
        e.stopPropagation()
        try {
            await drawerController.closeThread(threadId)

            session.deleteThreadFromNavigationHistory(threadId)

            const match = matchPath(
                { path: '/thread/:id' },
                location.pathname
            )

            if (match && match.params.id && (parseInt(match.params.id) === threadId)) {
                navigate('/landing')
            }

        } catch (error: any) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
    }

    if (isLoading) {
        return (
            <MultilinePlaceholder sections={4} />
        )
    }
    
    return (
        <>
            {/* interagency rooms ----------------------------------------------------------------------------- */}
            <DrawerNavDropdownLink
                label="Interagency Rooms"
                isOpen={deviceState.drawerDropdownsState?.interagency === 'open'}
                onClick={(isOpen) => deviceState.updateDrawerDropdownsState('interagency', isOpen)}
                unreadCount={drawerController.interAgencyRoomsUnreadCountTotal?.count}
                hasUrgentUnreads={drawerController.interAgencyRoomsUnreadCountTotal?.urgent}>

                {drawerController.interAgencyRooms?.map(room => {
                    const unread = unreadState.getRoomUnread(room.id as number) as any
                    const settings = userSettingsController.roomNotificationSettings[room.id as number]?.roomMessage

                    return (
                        <DrawerNavRoomLink
                            key={room.id}
                            to={`/room/${room.id}`}
                            departmentId={room.departmentId}
                            label={room.name as string}
                            icon={(!(room.options as any)?.departmentMembersCanJoinAsMember) ? 'lock' : (room.isSearchable) ? 'bolt' : 'hashtag'}
                            unreadCount={unread?.count || 0}
                            hasUrgentUnreads={unread?.urgent}
                            selected={new RegExp(`^/room/${room.id}(/replies/[^/]+)?$`).test(location.pathname)}
                            muted={settings && !settings?.enabled}
                        />
                    )
                })}
            </DrawerNavDropdownLink>

            {/* agency-wide rooms ----------------------------------------------------------------------------- */}
            <DrawerNavDropdownLink
                label="Agency-Wide Rooms"
                isOpen={deviceState.drawerDropdownsState?.agencywide === 'open'}
                onClick={(isOpen) => deviceState.updateDrawerDropdownsState('agencywide', isOpen)}
                unreadCount={drawerController.agencyWideRoomsUnreadCountTotal?.count}
                hasUrgentUnreads={drawerController.agencyWideRoomsUnreadCountTotal?.urgent}>

                {drawerController.agencyWideRooms?.map(room => {
                    if (room.isArchived) return null
                    const unread = unreadState.getRoomUnread(room.id as number) as any
                    const settings = userSettingsController.roomNotificationSettings[room.id as number]?.roomMessage

                    return (
                        <DrawerNavRoomLink
                            key={room.id}
                            to={`/room/${room.id}`}
                            label={room.name as string}
                            icon="hashtag"
                            unreadCount={unread?.count || 0}
                            hasUrgentUnreads={unread?.urgent}
                            selected={new RegExp(`^/room/${room.id}(/replies/[^/]+)?$`).test(location.pathname)}
                            muted={settings && !settings?.enabled}
                        />
                    )
                })}
            </DrawerNavDropdownLink>

            {/* team rooms ------------------------------------------------------------------------------------ */}
            <DrawerNavDropdownLink
                label="Team Rooms"
                isOpen={deviceState.drawerDropdownsState?.team === 'open'}
                onClick={(isOpen) => deviceState.updateDrawerDropdownsState('team', isOpen)}
                unreadCount={drawerController.teamRoomsUnreadCountTotal?.count}
                hasUrgentUnreads={drawerController.teamRoomsUnreadCountTotal?.urgent}>

                {drawerController.teamRooms?.map(room => {
                    const unread = unreadState.getRoomUnread(room.id as number) as any
                    const settings = userSettingsController.roomNotificationSettings[room.id as number]?.roomMessage

                    return (
                        <DrawerNavRoomLink
                            key={room.id}
                            to={`/room/${room.id}`}
                            label={room.name as string}
                            icon={(!(room.options as any)?.departmentMembersCanJoinAsMember) ? 'lock' : (room.isSearchable) ? 'bolt' : 'hashtag'}
                            unreadCount={unread?.count || 0}
                            hasUrgentUnreads={unread?.urgent}
                            selected={new RegExp(`^/room/${room.id}(/replies/[^/]+)?$`).test(location.pathname)}
                            muted={settings && !settings?.enabled}
                        />
                    )
                })}
                { !currentUserController.hasGuestRoleInSelectedDepartment && 
                    <DrawerNavItemLink
                        label="Join Room"
                        icon={{name: 'circle-plus', type: 'solid'}}
                        onClick={openJoinRoom}
                    />
                }
            </DrawerNavDropdownLink>

            {/* DMs ---------------------------------------------------------------------------------------- */}
            <DrawerNavDropdownLink
                label="Direct Messages"
                isOpen={deviceState.drawerDropdownsState?.threads === 'open'}
                onClick={(isOpen) => deviceState.updateDrawerDropdownsState('threads', isOpen)}
                unreadCount={drawerController.threadsUnreadCountTotal?.count}
                hasUrgentUnreads={drawerController.threadsUnreadCountTotal?.urgent}>

                {drawerController.sortedThreads?.map((thread) => {
                    const unreads = unreadState.getThreadUnread(thread.id as number) as any

                    return (
                        <DrawerNavThreadLink
                            key={thread.id}
                            to={`/thread/${thread.id}`}
                            thread={thread}
                            unreadCount={unreads?.count || 0}
                            hasUrgentUnreads={unreads?.urgent}
                            selected={location.pathname === `/thread/${thread.id}`}
                            onCloseThread={(e: Event) => closeThread(thread.id as number, e)}
                        />
                    )
                })}
                <DrawerNavItemLink
                    label="Add Conversation"
                    icon={{name: 'circle-plus', type: 'solid'}}
                    onClick={openCreateThread}
                />
            </DrawerNavDropdownLink>
        </>
    )
})

export { MessagesDrawer }

