
/** Generated code, do not modify !!!*/
/** From: test undefined undefined*/
/** V2 */

import { Agent, APIDataAny, APIDataBlueUserHistory, APIDataMedia, APIDataDevice, APIDataObject, APIDataCount, APIDataAlert, APIDataRead, APIDataInvite, APIDataRoomMessage, APIDataExtendedAccessToken, APIDataDepartmentUserThrough, APIDataUserCRM, APIDataReaction, APIDataMediaMultiPartUpload, APIDataBlueUser, APIDataRoom, APIDataThread, APIDataDepartment, APIDataUserProfile, APIDataRoomJoinRequest, APIDataDepartmentHistory, APIDataAudit, APIDataEmoji, APIDataThreadMessage, APIDataDepartmentCRM, APIDataDepartmentLinkedThrough, APIDataVerifiedEmailDomain, APIDataDocumentSchema, APIDataDocumentSchemaCategory, APIDataBulletinSubject, APIDataBulletinVehicle, APIDataBulletin, APIDataDepartmentAssignment, APIDataDocument, APIDataWebContent, APIDataBulletinHistory, APIDataRoomHistory, APIDataRoomJoinRequestRule, APIDataReactionUserThrough, APIDataReport, APIDataEventLog, APIDataDepartmentCRMHistory, APIDataUserCRMHistory, APIDataBotCampaignHistory, APIDataBotCampaign, APIDataDocumentSchemaHistory, APIDataDocumentSchemaRevision, APIDataDocumentHistory, APIDataDocumentACL, APIDataDocumentACLUserThrough, APIDataDocumentRevision, APIDataDocumentSearchData, APIDataMediaMultiPartUploadPart} from '@evertel/types'
type file = Blob

export class BlueUserRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /BlueUsers/:id/history/:fk (BlueUser.prototype.__findById__history)
    * Find a related item by id for history.
    */
    getHistoryById = async (blueUserId: number, fk: number) => this.getAgent().get<APIDataBlueUserHistory>(`/BlueUsers/${blueUserId}/history/${fk}`)

    /**
    * GET /BlueUsers/:id/publicMedia (BlueUser.prototype.__get__publicMedia)
    * Fetches belongsTo relation publicMedia.
    */
    getPublicMedia = async (blueUserId: number, refresh?: boolean) => this.getAgent().get<APIDataMedia>(`/BlueUsers/${blueUserId}/publicMedia`, { params: { refresh }})

    /**
    * GET /BlueUsers/:id/devices/:fk (BlueUser.prototype.__findById__devices)
    * Find a related item by id for devices.
    */
    getDevicesById = async (blueUserId: number, fk: number) => this.getAgent().get<APIDataDevice>(`/BlueUsers/${blueUserId}/devices/${fk}`)

    /**
    * GET /BlueUsers/:id/history (BlueUser.prototype.__get__history)
    * Queries history of BlueUser.
    */
    getHistory = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUserHistory[]>(`/BlueUsers/${blueUserId}/history`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/history/count (BlueUser.prototype.__count__history)
    * Counts history of BlueUser.
    */
    getHistoryCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/history/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/media (BlueUser.prototype.__get__media)
    * Queries media of BlueUser.
    */
    getMedia = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMedia[]>(`/BlueUsers/${blueUserId}/media`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/media/count (BlueUser.prototype.__count__media)
    * Counts media of BlueUser.
    */
    getMediaCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/media/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/alerts (BlueUser.prototype.__get__alerts)
    * Queries alerts of BlueUser.
    */
    getAlerts = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataAlert[]>(`/BlueUsers/${blueUserId}/alerts`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/alerts/count (BlueUser.prototype.__count__alerts)
    * Counts alerts of BlueUser.
    */
    getAlertsCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/alerts/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/reads (BlueUser.prototype.__get__reads)
    * Queries reads of BlueUser.
    */
    getReads = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRead[]>(`/BlueUsers/${blueUserId}/reads`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/reads/count (BlueUser.prototype.__count__reads)
    * Counts reads of BlueUser.
    */
    getReadsCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/reads/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/invitesSent (BlueUser.prototype.__get__invitesSent)
    * Queries invitesSent of BlueUser.
    */
    getInvitesSent = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataInvite[]>(`/BlueUsers/${blueUserId}/invitesSent`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/invitesSent/count (BlueUser.prototype.__count__invitesSent)
    * Counts invitesSent of BlueUser.
    */
    getInvitesSentCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/invitesSent/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/invitesReceived (BlueUser.prototype.__get__invitesReceived)
    * Queries invitesReceived of BlueUser.
    */
    getInvitesReceived = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataInvite[]>(`/BlueUsers/${blueUserId}/invitesReceived`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/invitesReceived/count (BlueUser.prototype.__count__invitesReceived)
    * Counts invitesReceived of BlueUser.
    */
    getInvitesReceivedCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/invitesReceived/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/roomMessages (BlueUser.prototype.__get__roomMessages)
    * Queries roomMessages of BlueUser.
    */
    getRoomMessages = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoomMessage[]>(`/BlueUsers/${blueUserId}/roomMessages`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/roomMessages/count (BlueUser.prototype.__count__roomMessages)
    * Counts roomMessages of BlueUser.
    */
    getRoomMessagesCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/roomMessages/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/accessTokens (BlueUser.prototype.__get__accessTokens)
    * Queries accessTokens of BlueUser.
    */
    getAccessTokens = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataExtendedAccessToken[]>(`/BlueUsers/${blueUserId}/accessTokens`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/accessTokens/count (BlueUser.prototype.__count__accessTokens)
    * Counts accessTokens of BlueUser.
    */
    getAccessTokensCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/accessTokens/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/devices (BlueUser.prototype.__get__devices)
    * Queries devices of BlueUser.
    */
    getDevices = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDevice[]>(`/BlueUsers/${blueUserId}/devices`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/devices/count (BlueUser.prototype.__count__devices)
    * Counts devices of BlueUser.
    */
    getDevicesCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/devices/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/departmentsAccess (BlueUser.prototype.__get__departmentsAccess)
    * Queries departmentsAccess of BlueUser.
    */
    getDepartmentsAccess = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentUserThrough[]>(`/BlueUsers/${blueUserId}/departmentsAccess`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/departmentsAccess/count (BlueUser.prototype.__count__departmentsAccess)
    * Counts departmentsAccess of BlueUser.
    */
    getDepartmentsAccessCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/departmentsAccess/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/identifyingDocuments (BlueUser.prototype.__get__identifyingDocuments)
    * Queries identifyingDocuments of BlueUser.
    */
    getIdentifyingDocuments = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMedia[]>(`/BlueUsers/${blueUserId}/identifyingDocuments`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/CRMData (BlueUser.prototype.__get__CRMData)
    * Queries CRMData of BlueUser.
    */
    getCrmData = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataUserCRM[]>(`/BlueUsers/${blueUserId}/CRMData`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/CRMData/count (BlueUser.prototype.__count__CRMData)
    * Counts CRMData of BlueUser.
    */
    getCrmDataCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/CRMData/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/consumedInvites (BlueUser.prototype.__get__consumedInvites)
    * Queries consumedInvites of BlueUser.
    */
    getConsumedInvites = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataInvite[]>(`/BlueUsers/${blueUserId}/consumedInvites`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/consumedInvites/count (BlueUser.prototype.__count__consumedInvites)
    * Counts consumedInvites of BlueUser.
    */
    getConsumedInvitesCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/consumedInvites/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/reactions (BlueUser.prototype.__get__reactions)
    * Queries reactions of BlueUser.
    */
    getReactions = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataReaction[]>(`/BlueUsers/${blueUserId}/reactions`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/reactions/count (BlueUser.prototype.__count__reactions)
    * Counts reactions of BlueUser.
    */
    getReactionsCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/reactions/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/roomJoinRequests/count (BlueUser.prototype.__count__roomJoinRequests)
    * Counts roomJoinRequests of BlueUser.
    */
    getRoomJoinRequestsCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/roomJoinRequests/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/mediaMultiPartUploads (BlueUser.prototype.__get__mediaMultiPartUploads)
    * Queries mediaMultiPartUploads of BlueUser.
    */
    getMediaMultiPartUploads = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMediaMultiPartUpload[]>(`/BlueUsers/${blueUserId}/mediaMultiPartUploads`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/mediaMultiPartUploads/count (BlueUser.prototype.__count__mediaMultiPartUploads)
    * Counts mediaMultiPartUploads of BlueUser.
    */
    getMediaMultiPartUploadsCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/mediaMultiPartUploads/count`, { params: { where }})

    /**
    * POST /BlueUsers (BlueUser.create)
    * Create a new instance of the model and persist it into the data source.
    */
    post = async (data?: APIDataObject) => this.getAgent().post<APIDataBlueUser>(`/BlueUsers`, data)

    /**
    * GET /BlueUsers/:id (BlueUser.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser>(`/BlueUsers/${blueUserId}`, { params: { filter }})

    /**
    * GET /BlueUsers (BlueUser.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/BlueUsers`, { params: { filter }})

    /**
    * GET /BlueUsers/count (BlueUser.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/count`, { params: { where }})

    /**
    * PUT /BlueUsers/:id (BlueUser.prototype.update)
    * Update BlueUser
    */
    putById = async (blueUserId: number, user: {  firstName: string,  lastName: string,  phoneNumber: string,  meta: APIDataObject,  isSearchable: boolean }) => this.getAgent().put<APIDataBlueUser>(`/BlueUsers/${blueUserId}`, user)

    /**
    * GET /BlueUsers/:id/rooms/count (BlueUser.prototype.countRooms)
    * Count rooms this user is manager or member of
    */
    getRoomsCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/rooms/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/rooms (BlueUser.prototype.getRooms)
    * Get rooms user is manager of or member of
    */
    getRooms = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/BlueUsers/${blueUserId}/rooms`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/email (BlueUser.getUserEmail)
    * Find a users email
    */
    getEmail = async (blueUserId: number) => this.getAgent().get<string>(`/BlueUsers/${blueUserId}/email`)

    /**
    * GET /BlueUsers/testAccess (BlueUser.testAccess)
    * Test access token
    */
    getTestAccess = async () => this.getAgent().get<boolean>(`/BlueUsers/testAccess`)

    /**
    * POST /BlueUsers/:id/suggestedContacts (BlueUser.prototype.suggestedContacts)
    * Does something with inviting users ?
    */
    postSuggestedContacts = async (blueUserId: number, match: {  emails: string[] }) => this.getAgent().post<APIDataBlueUser[]>(`/BlueUsers/${blueUserId}/suggestedContacts`, match)

    /**
    * GET /BlueUsers/:id/threads (BlueUser.getThreads)
    * Get a list of Threads.
    */
    getThreads = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataThread[]>(`/BlueUsers/${blueUserId}/threads`, { params: { filter }})

    /**
    * POST /BlueUsers/:id/pushTokens (BlueUser.prototype.createPushToken)
    * Create a pushToken for this user.
    */
    postPushTokens = async (blueUserId: number, pushToken: APIDataObject) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/pushTokens`, pushToken)

    /**
    * POST /BlueUsers/login/auth0 (BlueUser.loginAuth0)
    * Login a user with Auth0 access token
    */
    postLoginAuth0 = async (credentials: {  auth0Token: string,  deviceToken: string }) => this.getAgent().post<APIDataObject>(`/BlueUsers/login/auth0`, credentials)

    /**
    * GET /BlueUsers/:id/threadMessages/countUnread (BlueUser.prototype.countUnreadThreadMessages)
    * Count unread messages.
    */
    getThreadMessagesCountUnread = async (blueUserId: number) => this.getAgent().get<{  threadId: number,  count: number,  urgent: number, messageIds: number[] }[]>(`/BlueUsers/${blueUserId}/threadMessages/countUnread`)

    /**
    * GET /BlueUsers/:id/roomMessages/countUnread (BlueUser.prototype.countUnreadRoomMessages)
    * Count unread messages.
    */
    getRoomMessagesCountUnread = async (blueUserId: number) => this.getAgent().get<{  roomId: number,  departmentId: number,  count: number,  urgent: number, messageIds: number[],  repliesCount: number,  unreadRepliesMap: {[key: string | number]:{ repliesToId: number, unreadCount: number, urgentCount: number }} }[]>(`/BlueUsers/${blueUserId}/roomMessages/countUnread`)

    /**
    * GET /BlueUsers/:id/bulletins/viewable/countUnread (BlueUser.prototype.countUnreadViewableBulletins)
    * (Deprecated)
    * @deprecated
    */
    getBulletinsViewableCountUnread = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataObject[]>(`/BlueUsers/${blueUserId}/bulletins/viewable/countUnread`, { params: { where }})

    /**
    * POST /BlueUsers/logout (BlueUser.logoutUser)
    * Logout a user with access token.
    */
    postLogout = async () => this.getAgent().post<never>(`/BlueUsers/logout`, undefined)

    /**
    * PUT /BlueUsers/:id/devices/:fk/settings (BlueUser.prototype.updateDeviceSettings)
    * Update device settings.
    */
    putDevicesSettings = async (blueUserId: number, fk: number, settings: APIDataObject) => this.getAgent().put<APIDataDevice>(`/BlueUsers/${blueUserId}/devices/${fk}/settings`, settings)

    /**
    * PUT /BlueUsers/:id/devices/:fk/info (BlueUser.prototype.updateDeviceInfo)
    * Update device info.
    */
    putDevicesInfo = async (blueUserId: number, fk: number, info: string) => this.getAgent().put<APIDataDevice>(`/BlueUsers/${blueUserId}/devices/${fk}/info`, undefined, { params: { info }})

    /**
    * PUT /BlueUsers/:id/auth0/authenticator (BlueUser.prototype.updateAuthenticator)
    * Update BlueUser mfa authenticator
    */
    putAuth0Authenticator = async (blueUserId: number, params: {  authenticator: string }) => this.getAgent().put<APIDataBlueUser>(`/BlueUsers/${blueUserId}/auth0/authenticator`, params)

    /**
    * GET /BlueUsers/:id/threads/count (BlueUser.countThreads)
    * Count instances of Threads matched by where from the data source.
    */
    getThreadsCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/threads/count`, { params: { where }})

    /**
    * POST /BlueUsers/:id/logoutOtherDevices (BlueUser.prototype.logoutOtherDevices)
    * Logout other devices
    */
    postLogoutOtherDevices = async (blueUserId: number) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/logoutOtherDevices`, undefined)

    /**
    * POST /BlueUsers/:id/profileImage (BlueUser.prototype.publicMediaUpload)
    * (Deprecated)
    * @deprecated
    */
    postProfileImage = async (blueUserId: number) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/profileImage`, undefined)

    /**
    * POST /BlueUsers/:id/publicImage (BlueUser.prototype.publicMediaUpload)
    * (Deprecated)
    * @deprecated
    */
    postPublicImage = async (blueUserId: number, file?: FormData) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/publicImage`, undefined, { params: { file }})

    /**
    * POST /BlueUsers/:id/publicMedia/upload (BlueUser.prototype.publicMediaUpload)
    * Upload profile image to user.
    */
    postPublicMediaUpload = async (blueUserId: number, file?: FormData) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/publicMedia/upload`, undefined, { params: { file }})

    /**
    * DEL /BlueUsers/:id/profileImage (BlueUser.prototype.deletePublicMedia)
    * (Deprecated)
    * @deprecated
    */
    delProfileImage = async (blueUserId: number) => this.getAgent().del<APIDataBlueUser>(`/BlueUsers/${blueUserId}/profileImage`)

    /**
    * DEL /BlueUsers/:id/publicImage (BlueUser.prototype.deletePublicMedia)
    * (Deprecated)
    * @deprecated
    */
    delPublicImage = async (blueUserId: number) => this.getAgent().del<APIDataBlueUser>(`/BlueUsers/${blueUserId}/publicImage`)

    /**
    * DEL /BlueUsers/:id/publicMedia (BlueUser.prototype.deletePublicMedia)
    * Delete profile image to user.
    */
    delPublicMedia = async (blueUserId: number) => this.getAgent().del<APIDataBlueUser>(`/BlueUsers/${blueUserId}/publicMedia`)

    /**
    * GET /BlueUsers/:id/publicMedia/download (BlueUser.prototype.publicMediaDownload)
    * (Deprecated)
    * @deprecated
    */
    getPublicMediaDownload = async (blueUserId: number, ver?: string) => this.getAgent().get<FormData>(`/BlueUsers/${blueUserId}/publicMedia/download`, { params: { ver }})

    /**
    * GET /BlueUsers/:id/media/public/download/:fileKey (BlueUser.prototype.downloadPublicMedia)
    * Download an image for Profile.
    */
    getMediaPublicDownloadById = async (blueUserId: number, fileKey?: string, ver?: string) => this.getAgent().get<FormData>(`/BlueUsers/${blueUserId}/media/public/download/${fileKey}`, { params: { ver }})

    /**
    * DEL /BlueUsers/:id/identifyingDocuments/:fk (BlueUser.prototype.deleteIdMedia)
    * Delete an image or video for a ThreadMessage.
    */
    delIdentifyingDocumentsById = async (blueUserId: number, fk?: number) => this.getAgent().del<APIDataObject>(`/BlueUsers/${blueUserId}/identifyingDocuments/${fk}`)

    /**
    * GET /BlueUsers/:id/identifyingDocuments/download/:fk (BlueUser.getIdMediaDownload)
    * Download an image or video for Thread.
    */
    getIdentifyingDocumentsDownloadById = async (blueUserId: number, fk?: string) => this.getAgent().get<FormData>(`/BlueUsers/${blueUserId}/identifyingDocuments/download/${fk}`)

    /**
    * POST /BlueUsers/:id/identifyingDocuments/upload (BlueUser.prototype.postIdMediaUpload)
    * Upload an image or video for a BlueUser.identifyingDocuments
    */
    postIdentifyingDocumentsUpload = async (blueUserId: number) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/identifyingDocuments/upload`, undefined)

    /**
    * POST /BlueUsers/:id/department/request (BlueUser.prototype.sendDepartmentRequest)
    * Request a department to be created.
    */
    postDepartmentRequest = async (blueUserId: number, departmentName: string) => this.getAgent().post<APIDataBlueUser>(`/BlueUsers/${blueUserId}/department/request`, undefined, { params: { departmentName }})

    /**
    * PUT /BlueUsers/:id/email (BlueUser.prototype.updateEmail)
    * Update BlueUser Email
    */
    putEmail = async (blueUserId: number, body?: {  email: string,  password: string }) => this.getAgent().put<APIDataBlueUser>(`/BlueUsers/${blueUserId}/email`, body)

    /**
    * PUT /BlueUsers/:id/auth0/email (BlueUser.prototype.updateEmail)
    * Update BlueUser Email
    */
    putAuth0Email = async (blueUserId: number, body?: {  email: string,  password: string }) => this.getAgent().put<APIDataBlueUser>(`/BlueUsers/${blueUserId}/auth0/email`, body)

    /**
    * GET /BlueUsers/:id/botThread (BlueUser.prototype.getBotThread)
    * Get users bot thread
    */
    getBotThread = async (blueUserId: number) => this.getAgent().get<string>(`/BlueUsers/${blueUserId}/botThread`)

    /**
    * GET /BlueUsers/:id/settings (BlueUser.prototype.getSettings)
    * Get user settings
    */
    getSettings = async (blueUserId: number) => this.getAgent().get<APIDataObject>(`/BlueUsers/${blueUserId}/settings`)

    /**
    * PUT /BlueUsers/:id/settings (BlueUser.prototype.putSettings)
    * Update user settings
    */
    putSettings = async (blueUserId: number, settings: APIDataObject) => this.getAgent().put<APIDataObject>(`/BlueUsers/${blueUserId}/settings`, settings)

    /**
    * GET /BlueUsers/reports/activity (BlueUser.getActivityReport)
    * undefined
    */
    getReportsActivity = async (duration?: number) => this.getAgent().get<APIDataAny[]>(`/BlueUsers/reports/activity`, { params: { duration }})

    /**
    * GET /BlueUsers/reports/activity/count (BlueUser.getActivityReportCount)
    * undefined
    */
    getReportsActivityCount = async (duration?: number) => this.getAgent().get<APIDataCount>(`/BlueUsers/reports/activity/count`, { params: { duration }})

    /**
    * PUT /BlueUsers/:id/department (BlueUser.prototype.updateDepartmentDeprecated)
    * (Deprecated)
    * @deprecated
    */
    putDepartment = async (blueUserId: number, department: APIDataDepartment) => this.getAgent().put<APIDataBlueUser>(`/BlueUsers/${blueUserId}/department`, department)

    /**
    * POST /BlueUsers/:id/requestBolo (BlueUser.prototype.requestBoloDeprecated)
    * (Deprecated)
    * @deprecated
    */
    postRequestBolo = async (blueUserId: number) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/requestBolo`, undefined)

    /**
    * GET /BlueUsers/:id/department (BlueUser.prototype.getDepartmentDeprecated)
    * (Deprecated)
    * @deprecated
    */
    getDepartment = async (blueUserId: number) => this.getAgent().get<APIDataObject>(`/BlueUsers/${blueUserId}/department`)

    /**
    * POST /BlueUsers/:id/verifyDept/:code (BlueUser.prototype.verifyDeptDeprecated)
    * (Deprecated)
    * @deprecated
    */
    postVerifyDeptById = async (blueUserId: number, code: string) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/verifyDept/${code}`, undefined)

    /**
    * GET /BlueUsers/:id/badge (BlueUser.prototype.badgeDeprecated)
    * (Deprecated)
    * @deprecated
    */
    getBadge = async (blueUserId: number) => this.getAgent().get<number>(`/BlueUsers/${blueUserId}/badge`)

    /**
    * POST /BlueUsers/:id/profile (BlueUser.prototype.createProfileDeprecated)
    * (Deprecated)
    * @deprecated
    */
    postProfile = async (blueUserId: number, profile: APIDataObject) => this.getAgent().post<APIDataUserProfile>(`/BlueUsers/${blueUserId}/profile`, profile)

    /**
    * PUT /BlueUsers/:id/profile (BlueUser.prototype.updateProfileDeprecated)
    * (Deprecated)
    * @deprecated
    */
    putProfile = async (blueUserId: number, data: APIDataObject) => this.getAgent().put<APIDataUserProfile>(`/BlueUsers/${blueUserId}/profile`, data)

    /**
    * POST /BlueUsers/:id/createInvite (BlueUser.prototype.createInviteDeprecated)
    * (Deprecated)
    * @deprecated
    */
    postCreateInvite = async (blueUserId: number) => this.getAgent().post<APIDataBlueUser>(`/BlueUsers/${blueUserId}/createInvite`, undefined)

    /**
    * POST /BlueUsers/:id/sendInvite (BlueUser.prototype.sendInviteDeprecated)
    * (Deprecated)
    * @deprecated
    */
    postSendInvite = async (blueUserId: number, toUser: {  id: number,  email: string[],  phone: string[],  emailText: string,  phoneText: string,  token: string }) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/sendInvite`, toUser)

    /**
    * DEL /BlueUsers/:id/invites/:fk (BlueUser.prototype.deleteInvite)
    * Delete an invite.
    */
    delInvitesById = async (blueUserId: number, fk?: number) => this.getAgent().del<APIDataObject>(`/BlueUsers/${blueUserId}/invites/${fk}`)

    /**
    * POST /BlueUsers/:id/sendInvites (BlueUser.prototype.postSendInvites)
    * Send invite tokens, creates thread with user.
    */
    postSendInvites = async (blueUserId: number, body: { to: string[],  message: string, addUserCRM: APIDataUserCRM[] }) => this.getAgent().post<APIDataInvite>(`/BlueUsers/${blueUserId}/sendInvites`, body)

    /**
    * GET /BlueUsers/:id/invites (BlueUser.prototype.getInvites)
    * Search Invites Sent and Received
    */
    getInvites = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataInvite[]>(`/BlueUsers/${blueUserId}/invites`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/invites/count (BlueUser.prototype.getInvitesCount)
    * Count Invites Sent and Received
    */
    getInvitesCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/invites/count`, { params: { where }})

    /**
    * POST /BlueUsers/:id/password/change (BlueUser.prototype.changePassword)
    * Reset Password for logged in user
    */
    postPasswordChange = async (blueUserId: number, body?: {  oldPassword: string,  newPassword: string,  confirmation: string }) => this.getAgent().post<APIDataObject>(`/BlueUsers/${blueUserId}/password/change`, body)

    /**
    * POST /BlueUsers/password/reset (BlueUser.resetPasswordStatic)
    * Reset Password with token
    */
    postPasswordReset = async (body: {  password: string,  confirmation: string }, token?: string) => this.getAgent().post<APIDataObject>(`/BlueUsers/password/reset`, body, { params: { token }})

    /**
    * POST /BlueUsers/reset (BlueUser.resetPasswordInitStatic)
    * (Deprecated)
    * @deprecated
    */
    postReset = async (body: {  email: string }) => this.getAgent().post<never>(`/BlueUsers/reset`, body)

    /**
    * POST /BlueUsers/password/reset/request (BlueUser.resetPasswordInitStatic)
    * Request password reset email.
    */
    postPasswordResetRequest = async (body: {  email: string }) => this.getAgent().post<never>(`/BlueUsers/password/reset/request`, body)

    /**
    * POST /BlueUsers/auth0/enrollInit (BlueUser.enrollInit)
    * Request enroll mfa email
    */
    postAuth0EnrollInit = async (body: {  email: string }) => this.getAgent().post<never>(`/BlueUsers/auth0/enrollInit`, body)

    /**
    * POST /BlueUsers/auth0/enrollMfa (BlueUser.enrollMfa)
    * Enroll in mfa with token
    */
    postAuth0EnrollMfa = async (body: {  phoneNumber: string,  password: string }, token?: string) => this.getAgent().post<APIDataObject>(`/BlueUsers/auth0/enrollMfa`, body, { params: { token }})

    /**
    * POST /BlueUsers/:id/CRMData (BlueUser.prototype.createCRMData)
    * Creates a new instance in UserCRM of this model.
    */
    postCrmData = async (blueUserId: number, data?: APIDataObject) => this.getAgent().post<APIDataUserCRM>(`/BlueUsers/${blueUserId}/CRMData`, data)

    /**
    * PUT /BlueUsers/:id/CRMData/:fk (BlueUser.prototype.updateByIdCRMData)
    * Update a related item by id for CRMData.
    */
    putCrmDataById = async (blueUserId: number, fk: number, data?: APIDataObject) => this.getAgent().put<APIDataUserCRM>(`/BlueUsers/${blueUserId}/CRMData/${fk}`, data)

    /**
    * DEL /BlueUsers/:id/CRMData/:fk (BlueUser.prototype.destroyByIdCRMData)
    * Delete a related item by id for CRMData.
    */
    delCrmDataById = async (blueUserId: number, fk: number) => this.getAgent().del<APIDataUserCRM>(`/BlueUsers/${blueUserId}/CRMData/${fk}`)

    /**
    * GET /BlueUsers/search (BlueUser.searchAdmin)
    * (Deprecated)
    * @deprecated
    */
    getSearch = async (filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/BlueUsers/search`, { params: { filter }})

    /**
    * GET /BlueUsers/search/count (BlueUser.searchAdminCount)
    * (Deprecated)
    * @deprecated
    */
    getSearchCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/search/count`, { params: { where }})

    /**
    * GET /BlueUsers/search/admin (BlueUser.searchAdmin)
    * Search Users
    */
    getSearchAdmin = async (filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/BlueUsers/search/admin`, { params: { filter }})

    /**
    * GET /BlueUsers/search/admin/count (BlueUser.searchAdminCount)
    * Count Search Users
    */
    getSearchAdminCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/search/admin/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/search/contacts (BlueUser.prototype.searchContacts)
    * Search Users
    */
    getSearchContacts = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/BlueUsers/${blueUserId}/search/contacts`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/search/contacts/count (BlueUser.prototype.searchContactsCount)
    * Count Search Users
    */
    getSearchContactsCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/search/contacts/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/search/public (BlueUser.prototype.searchPublic)
    * Criteria to filter model instances, can use 'where.searchTerm' flag.
    */
    getSearchPublic = async (blueUserId: number, departmentIds: number[], filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/BlueUsers/${blueUserId}/search/public`, { params: { departmentIds, filter }})

    /**
    * GET /BlueUsers/:id/search/public/count (BlueUser.prototype.searchPublicCount)
    * Count Search Users
    */
    getSearchPublicCount = async (blueUserId: number, departmentIds: number[], where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/search/public/count`, { params: { departmentIds, where }})

    /**
    * GET /BlueUsers/:id/roomJoinRequests (BlueUser.prototype.getRoomJoinRequests)
    * Queries roomJoinRequests of BlueUser
    */
    getRoomJoinRequests = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoomJoinRequest[]>(`/BlueUsers/${blueUserId}/roomJoinRequests`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/managedRooms/count (BlueUser.prototype.countManagedRooms)
    * (Deprecated) use /rooms/managed/count)
    * @deprecated
    */
    getManagedRoomsCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/managedRooms/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/rooms/managed/count (BlueUser.prototype.countManagedRooms)
    * Count rooms this user is manager of
    */
    getRoomsManagedCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/rooms/managed/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/managedRooms (BlueUser.prototype.getManagedRooms)
    * (Deprecated) use /rooms/managed
    * @deprecated
    */
    getManagedRooms = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/BlueUsers/${blueUserId}/managedRooms`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/rooms/managed (BlueUser.prototype.getManagedRooms)
    * Get rooms user is manager of
    */
    getRoomsManaged = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/BlueUsers/${blueUserId}/rooms/managed`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/rooms/managed/userCounts (BlueUser.prototype.getManagedRoomsUserCounts)
    * Get user counts for rooms user is manager of
    */
    getRoomsManagedUserCounts = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<{  id: string,  count: number }[]>(`/BlueUsers/${blueUserId}/rooms/managed/userCounts`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/rooms/member/count (BlueUser.prototype.countMemberRooms)
    * Count rooms this user is member of
    */
    getRoomsMemberCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/rooms/member/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/rooms/member (BlueUser.prototype.getMemberRooms)
    * Get rooms user is member
    */
    getRoomsMember = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/BlueUsers/${blueUserId}/rooms/member`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/rooms/forward (BlueUser.prototype.getForwardRooms)
    * Get rooms user is allowed to forward messages
    */
    getRoomsForward = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/BlueUsers/${blueUserId}/rooms/forward`, { params: { filter }})

    /**
    * GET /BlueUsers/:id/rooms/forward/count (BlueUser.prototype.countForwardRooms)
    * Count rooms this user is allowed to forward messages
    */
    getRoomsForwardCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/rooms/forward/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/rooms/bulletins/shareable/count (BlueUser.prototype.countBulletinShareableRooms)
    * Count rooms this user is manager or member of
    */
    getRoomsBulletinsShareableCount = async (blueUserId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BlueUsers/${blueUserId}/rooms/bulletins/shareable/count`, { params: { where }})

    /**
    * GET /BlueUsers/:id/rooms/bulletins/shareable (BlueUser.prototype.getBulletinShareableRooms)
    * Get rooms user can share bulletins to.
    */
    getRoomsBulletinsShareable = async (blueUserId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/BlueUsers/${blueUserId}/rooms/bulletins/shareable`, { params: { filter }})

    /**
    * POST /BlueUsers/:id/media/identifying/documents/upload/start (BlueUser.prototype.startIdMultiPartUpload)
    * Upload an image or video for a BlueUser.identifyingDocuments
    */
    postMediaIdentifyingDocumentsUploadStart = async (blueUserId: number, params: {  fileName: string,  mimeType: string,  meta: APIDataObject }) => this.getAgent().post<APIDataMediaMultiPartUpload>(`/BlueUsers/${blueUserId}/media/identifying/documents/upload/start`, params)

    /**
    * POST /BlueUsers/:id/media/public/upload/start (BlueUser.prototype.startPublicMediaMultiPartUpload)
    * Upload profile image to user.
    */
    postMediaPublicUploadStart = async (blueUserId: number, params: {  fileName: string,  mimeType: string,  meta: APIDataObject }) => this.getAgent().post<APIDataMediaMultiPartUpload>(`/BlueUsers/${blueUserId}/media/public/upload/start`, params)

}
export class DepartmentRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /Departments/:id/history/:fk (Department.prototype.__findById__history)
    * Find a related item by id for history.
    */
    getHistoryById = async (departmentId: number, fk: number) => this.getAgent().get<APIDataDepartmentHistory>(`/Departments/${departmentId}/history/${fk}`)

    /**
    * GET /Departments/:id/audits/:fk (Department.prototype.__findById__audits)
    * Find a related item by id for audits.
    */
    getAuditsById = async (departmentId: number, fk: number) => this.getAgent().get<APIDataAudit>(`/Departments/${departmentId}/audits/${fk}`)

    /**
    * GET /Departments/:id/emojis/:fk (Department.prototype.__findById__emojis)
    * Find a related item by id for emojis.
    */
    getEmojisById = async (departmentId: number, fk: number) => this.getAgent().get<APIDataEmoji>(`/Departments/${departmentId}/emojis/${fk}`)

    /**
    * GET /Departments/:id/history (Department.prototype.__get__history)
    * Queries history of Department.
    */
    getHistory = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentHistory[]>(`/Departments/${departmentId}/history`, { params: { filter }})

    /**
    * GET /Departments/:id/history/count (Department.prototype.__count__history)
    * Counts history of Department.
    */
    getHistoryCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/history/count`, { params: { where }})

    /**
    * GET /Departments/:id/invites (Department.prototype.__get__invites)
    * Queries invites of Department.
    */
    getInvites = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataInvite[]>(`/Departments/${departmentId}/invites`, { params: { filter }})

    /**
    * GET /Departments/:id/invites/count (Department.prototype.__count__invites)
    * Counts invites of Department.
    */
    getInvitesCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/invites/count`, { params: { where }})

    /**
    * GET /Departments/:id/rooms (Department.prototype.__get__rooms)
    * Queries rooms of Department.
    */
    getRooms = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/Departments/${departmentId}/rooms`, { params: { filter }})

    /**
    * GET /Departments/:id/rooms/count (Department.prototype.__count__rooms)
    * Counts rooms of Department.
    */
    getRoomsCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/rooms/count`, { params: { where }})

    /**
    * GET /Departments/:id/usersAccess (Department.prototype.__get__usersAccess)
    * Queries usersAccess of Department.
    */
    getUsersAccess = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentUserThrough[]>(`/Departments/${departmentId}/usersAccess`, { params: { filter }})

    /**
    * GET /Departments/:id/usersAccess/count (Department.prototype.__count__usersAccess)
    * Counts usersAccess of Department.
    */
    getUsersAccessCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/usersAccess/count`, { params: { where }})

    /**
    * GET /Departments/:id/threadMessages (Department.prototype.__get__threadMessages)
    * Queries threadMessages of Department.
    */
    getThreadMessages = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataThreadMessage[]>(`/Departments/${departmentId}/threadMessages`, { params: { filter }})

    /**
    * GET /Departments/:id/threadMessages/count (Department.prototype.__count__threadMessages)
    * Counts threadMessages of Department.
    */
    getThreadMessagesCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/threadMessages/count`, { params: { where }})

    /**
    * GET /Departments/:id/audits (Department.prototype.__get__audits)
    * Queries audits of Department.
    */
    getAudits = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataAudit[]>(`/Departments/${departmentId}/audits`, { params: { filter }})

    /**
    * GET /Departments/:id/audits/count (Department.prototype.__count__audits)
    * Counts audits of Department.
    */
    getAuditsCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/audits/count`, { params: { where }})

    /**
    * GET /Departments/:id/CRMData (Department.prototype.__get__CRMData)
    * Queries CRMData of Department.
    */
    getCrmData = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentCRM[]>(`/Departments/${departmentId}/CRMData`, { params: { filter }})

    /**
    * GET /Departments/:id/CRMData/count (Department.prototype.__count__CRMData)
    * Counts CRMData of Department.
    */
    getCrmDataCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/CRMData/count`, { params: { where }})

    /**
    * GET /Departments/:id/parentDepartments (Department.prototype.__get__parentDepartments)
    * Queries parentDepartments of Department.
    */
    getParentDepartments = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartment[]>(`/Departments/${departmentId}/parentDepartments`, { params: { filter }})

    /**
    * GET /Departments/:id/childDepartments (Department.prototype.__get__childDepartments)
    * Queries childDepartments of Department.
    */
    getChildDepartments = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartment[]>(`/Departments/${departmentId}/childDepartments`, { params: { filter }})

    /**
    * GET /Departments/:id/parentDepartmentsThrough (Department.prototype.__get__parentDepartmentsThrough)
    * Queries parentDepartmentsThrough of Department.
    */
    getParentDepartmentsThrough = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentLinkedThrough[]>(`/Departments/${departmentId}/parentDepartmentsThrough`, { params: { filter }})

    /**
    * GET /Departments/:id/domains (Department.prototype.__get__domains)
    * Queries domains of Department.
    */
    getDomains = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataVerifiedEmailDomain[]>(`/Departments/${departmentId}/domains`, { params: { filter }})

    /**
    * GET /Departments/:id/domains/count (Department.prototype.__count__domains)
    * Counts domains of Department.
    */
    getDomainsCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/domains/count`, { params: { where }})

    /**
    * GET /Departments/:id/documentSchemas (Department.prototype.__get__documentSchemas)
    * Queries documentSchemas of Department.
    */
    getDocumentSchemas = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchema[]>(`/Departments/${departmentId}/documentSchemas`, { params: { filter }})

    /**
    * POST /Departments/:id/documentSchemas (Department.prototype.__create__documentSchemas)
    * Creates a new instance in documentSchemas of this model.
    */
    postDocumentSchemas = async (departmentId: number, data?: APIDataObject) => this.getAgent().post<APIDataDocumentSchema>(`/Departments/${departmentId}/documentSchemas`, data)

    /**
    * GET /Departments/:id/documentSchemas/count (Department.prototype.__count__documentSchemas)
    * Counts documentSchemas of Department.
    */
    getDocumentSchemasCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/documentSchemas/count`, { params: { where }})

    /**
    * GET /Departments/:id/documentSchemaCategories (Department.prototype.__get__documentSchemaCategories)
    * Queries documentSchemaCategories of Department.
    */
    getDocumentSchemaCategories = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchemaCategory[]>(`/Departments/${departmentId}/documentSchemaCategories`, { params: { filter }})

    /**
    * GET /Departments/:id/documentSchemaCategories/count (Department.prototype.__count__documentSchemaCategories)
    * Counts documentSchemaCategories of Department.
    */
    getDocumentSchemaCategoriesCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/documentSchemaCategories/count`, { params: { where }})

    /**
    * POST /Departments (Department.create)
    * Create a new instance of the model and persist it into the data source.
    */
    post = async (data?: APIDataObject) => this.getAgent().post<APIDataDepartment>(`/Departments`, data)

    /**
    * GET /Departments/:id (Department.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartment>(`/Departments/${departmentId}`, { params: { filter }})

    /**
    * GET /Departments (Department.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataDepartment[]>(`/Departments`, { params: { filter }})

    /**
    * GET /Departments/count (Department.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/count`, { params: { where }})

    /**
    * GET /Departments/:id/mediaDownload/:fileKey (Department.prototype.getMediaDownload)
    * Download an image or video for Department.
    */
    getMediaDownloadById = async (departmentId: number, fileKey?: string, ver?: string) => this.getAgent().get<FormData>(`/Departments/${departmentId}/mediaDownload/${fileKey}`, { params: { ver }})

    /**
    * POST /Departments/:id/requestAccess (Department.prototype.requestAccess)
    * Send email requesting verification to department
    */
    postRequestAccess = async (departmentId: number, email: APIDataObject) => this.getAgent().post<APIDataObject>(`/Departments/${departmentId}/requestAccess`, email)

    /**
    * GET /Departments/:id/verificationCode (Department.prototype.getVerificationCode)
    * (Deprecated)
    * @deprecated
    */
    getVerificationCode = async (departmentId: number) => this.getAgent().get<APIDataObject>(`/Departments/${departmentId}/verificationCode`)

    /**
    * GET /Departments/:id/users (Department.prototype.getUsers)
    * Get Dept Users.
    */
    getUsers = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/Departments/${departmentId}/users`, { params: { filter }})

    /**
    * GET /Departments/:id/user/:fk (Department.prototype.getUser)
    * Get Dept Users.
    */
    getUserById = async (departmentId: number, fk: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser>(`/Departments/${departmentId}/user/${fk}`, { params: { filter }})

    /**
    * GET /Departments/:id/users/count (Department.prototype.countUsers)
    * Count Search Users
    */
    getUsersCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/users/count`, { params: { where }})

    /**
    * PUT /Departments/:id/users/:userId (Department.prototype.addUserRemote)
    * Add User to Department
    */
    putUsersById = async (departmentId: number, userId: number) => this.getAgent().put<APIDataBlueUser>(`/Departments/${departmentId}/users/${userId}`, undefined)

    /**
    * POST /Departments/auth0/enterpriseUser/:userId (Department.addEnterpriseUser)
    * Add enterprise user to Department
    */
    postAuth0EnterpriseUserById = async (userId: number) => this.getAgent().post<APIDataObject>(`/Departments/auth0/enterpriseUser/${userId}`, undefined)

    /**
    * DEL /Departments/:id/users/:userId (Department.prototype.removeUser)
    * Remove user from room
    */
    delUsersById = async (departmentId: number, userId: number) => this.getAgent().del<APIDataBlueUser>(`/Departments/${departmentId}/users/${userId}`)

    /**
    * GET /Departments/:id/hasManagers (Department.prototype.hasManagers)
    * Get Dept Has Managers.
    */
    getHasManagers = async (departmentId: number) => this.getAgent().get<boolean>(`/Departments/${departmentId}/hasManagers`)

    /**
    * PUT /Departments/:id/users/:userId/verify (Department.prototype.updateUserVerifiedRemote)
    * Update user verification in Department
    */
    putUsersVerify = async (departmentId: number, userId: number, isVerified: boolean) => this.getAgent().put<APIDataBlueUser>(`/Departments/${departmentId}/users/${userId}/verify`, undefined, { params: { isVerified }})

    /**
    * GET /Departments/:id/verify/:code (Department.prototype.checkVerificationCode)
    * (Deprecated)
    * @deprecated
    */
    getVerifyById = async (departmentId: number, code: string) => this.getAgent().get<APIDataObject>(`/Departments/${departmentId}/verify/${code}`)

    /**
    * GET /Departments/:id/users/search (Department.prototype.searchUsers)
    * Search Users
    */
    getUsersSearch = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/Departments/${departmentId}/users/search`, { params: { filter }})

    /**
    * GET /Departments/:id/users/search/count (Department.prototype.searchUsersCount)
    * Count Search Users
    */
    getUsersSearchCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/users/search/count`, { params: { where }})

    /**
    * PUT /Departments/:id/verify/:code (Department.prototype.verifyUserInDept)
    * (Deprecated)
    * @deprecated
    */
    putVerifyById = async (departmentId: number, code: string) => this.getAgent().put<APIDataObject>(`/Departments/${departmentId}/verify/${code}`, undefined)

    /**
    * POST /Departments/:id/room (Department.prototype.createRoom)
    * Create Room for department
    */
    postRoom = async (departmentId: number, room: APIDataObject) => this.getAgent().post<APIDataRoom>(`/Departments/${departmentId}/room`, room)

    /**
    * POST /Departments/:id/bulletins (Department.prototype.createBulletin)
    * Create an intelligence bulletin.
    */
    postBulletins = async (departmentId: number, bulletin: {  title: string,  status: string,  summary: string,  reportNumber: string,  crimeType: string,  bulletinType: string,  otherInfo: string,  isUrgent: boolean,  isDraft: boolean,  isVisible: boolean,  isArchived: boolean,  caseAgentId: number, subjects: APIDataBulletinSubject[], vehicles: APIDataBulletinVehicle[] }) => this.getAgent().post<APIDataBulletin>(`/Departments/${departmentId}/bulletins`, bulletin)

    /**
    * PUT /Departments/auth0/enterpriseConnector (Department.enterpriseConnector)
    * Create an enterprise connection in the database
    */
    putAuth0EnterpriseConnector = async (params: {  departmentId: number,  connectorName: string,  meta: APIDataObject }) => this.getAgent().put<APIDataObject>(`/Departments/auth0/enterpriseConnector`, params)

    /**
    * GET /Departments/:id/bulletins/viewable (Department.prototype.getViewableBulletins)
    * Get a list of intelligence bulletins.
    */
    getBulletinsViewable = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBulletin[]>(`/Departments/${departmentId}/bulletins/viewable`, { params: { filter }})

    /**
    * GET /Departments/:id/bulletins/viewable/unread/count (Department.prototype.countUnreadViewableBulletin)
    * Count unread Bulletins.
    */
    getBulletinsViewableUnreadCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/bulletins/viewable/unread/count`, { params: { where }})

    /**
    * GET /Departments/:id/bulletins/viewable/count (Department.prototype.countViewableBulletins)
    * Count instances of the intelligence bulletins matched by where from the data source.
    */
    getBulletinsViewableCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/bulletins/viewable/count`, { params: { where }})

    /**
    * PUT /Departments/:id/users/:userId/roles (Department.prototype.addUsersRoleRemote)
    * Add role to user in Dept
    */
    putUsersRoles = async (departmentId: number, userId: number, role: string) => this.getAgent().put<APIDataBlueUser>(`/Departments/${departmentId}/users/${userId}/roles`, undefined, { params: { role }})

    /**
    * DEL /Departments/:id/users/:userId/roles (Department.prototype.removeUsersRole)
    * Remove role from user in Dept
    */
    delUsersRoles = async (departmentId: number, userId: number, role: string) => this.getAgent().del<APIDataBlueUser>(`/Departments/${departmentId}/users/${userId}/roles`, { params: { role }})

    /**
    * PUT /Departments/:id/users/:userId/details (Department.prototype.updateUserDetails)
    * (Deprecated) Update user details in dept
    * @deprecated
    */
    putUsersDetails = async (departmentId: number, userId: number, details: {  badgeNumber: string,  isPrimary: boolean,  positionId: number,  assignmentId: number }) => this.getAgent().put<APIDataDepartmentUserThrough>(`/Departments/${departmentId}/users/${userId}/details`, details)

    /**
    * PUT /Departments/:id/users/:userId/departmentDetails (Department.prototype.updateUserDepartmentDetails)
    * Update user details in dept
    */
    putUsersDepartmentDetails = async (departmentId: number, userId: number, details: {  badgeNumber: string,  isPrimary: boolean,  position: string,  assignment: string }) => this.getAgent().put<APIDataDepartmentUserThrough>(`/Departments/${departmentId}/users/${userId}/departmentDetails`, details)

    /**
    * PUT /Departments/:id/users/:userId/forbidden (Department.prototype.updateUserVerifyDisabled)
    * Update user verification in Department
    */
    putUsersForbidden = async (departmentId: number, userId: number, isVerifyDisabled: boolean) => this.getAgent().put<APIDataBlueUser>(`/Departments/${departmentId}/users/${userId}/forbidden`, undefined, { params: { isVerifyDisabled }})

    /**
    * POST /Departments/:id/publicMedia/upload (Department.prototype.postPublicMediaUpload)
    * Upload an image for publicMedia.
    */
    postPublicMediaUpload = async (departmentId: number) => this.getAgent().post<APIDataObject>(`/Departments/${departmentId}/publicMedia/upload`, undefined)

    /**
    * GET /Departments/:id/predefinedRoomConfigs (Department.prototype.getPredefinedRoomConfigs)
    * Get list of predefined room configs.
    */
    getPredefinedRoomConfigs = async (departmentId: number) => this.getAgent().get<APIDataObject[]>(`/Departments/${departmentId}/predefinedRoomConfigs`)

    /**
    * PUT /Departments/:id (Department.prototype.updateDepartment)
    * Update Department
    */
    putById = async (departmentId: number, department: APIDataObject) => this.getAgent().put<APIDataDepartment>(`/Departments/${departmentId}`, department)

    /**
    * GET /Departments/:id/availableCapacities (Department.prototype.getAvailableCapacities)
    * Get available department capacity
    */
    getAvailableCapacities = async (departmentId: number) => this.getAgent().get<APIDataObject>(`/Departments/${departmentId}/availableCapacities`)

    /**
    * GET /Departments/:id/adminNotes (Department.prototype.getAdminNotes)
    * Get admin notes for department
    */
    getAdminNotes = async (departmentId: number) => this.getAgent().get<APIDataObject>(`/Departments/${departmentId}/adminNotes`)

    /**
    * POST /Departments/:id/sendInvites (Department.prototype.postSendInvites)
    * Send invite tokens, adds user to department.
    */
    postSendInvites = async (departmentId: number, body: { to: string[],  message: string, roles: string[], addUserCRM: APIDataUserCRM[] }) => this.getAgent().post<APIDataInvite>(`/Departments/${departmentId}/sendInvites`, body)

    /**
    * POST /Departments/demo (Department.createDemoDepartment)
    * (Deprecated) Create a demo department
    * @deprecated
    */
    postDemo = async (options?: {  positionId: number,  assignmentId: number,  type: string, roles: string[] }) => this.getAgent().post<APIDataDepartment>(`/Departments/demo`, options)

    /**
    * DEL /Departments/:id/demo (Department.prototype.deleteDemoDepartment)
    * Delete a demo department
    */
    delDemo = async (departmentId: number) => this.getAgent().del<APIDataDepartment>(`/Departments/${departmentId}/demo`)

    /**
    * GET /Departments/assignments (Department.getAssignments)
    * Get list of Dept Assignments.
    */
    getAllAssignments = async (type?: string) => this.getAgent().get<APIDataDepartmentAssignment[]>(`/Departments/assignments`, { params: { type }})

    /**
    * GET /Departments/positions (Department.getPositions)
    * Get list of Positions.
    */
    getAllPositions = async (type?: string) => this.getAgent().get<APIDataAny[]>(`/Departments/positions`, { params: { type }})

    /**
    * GET /Departments/:id/assignments (Department.prototype.getDepartmentAssignments)
    * Get list of Dept Assignments.
    */
    getAssignments = async (departmentId: number) => this.getAgent().get<APIDataAny[]>(`/Departments/${departmentId}/assignments`)

    /**
    * GET /Departments/:id/positions (Department.prototype.getDepartmentPositions)
    * Get list of Dept Positions.
    */
    getPositions = async (departmentId: number) => this.getAgent().get<APIDataAny[]>(`/Departments/${departmentId}/positions`)

    /**
    * POST /Departments/:id/audits/preview (Department.prototype.postPreviewAudit)
    * undefined
    */
    postAuditsPreview = async (departmentId: number, body: { dateRangeStart: Date, dateRangeEnd: Date, userIds: number[], roomIds: number[], keywords: string[],  includeDirectMessages: boolean,  includeContextConversations: boolean }) => this.getAgent().post<APIDataObject>(`/Departments/${departmentId}/audits/preview`, body)

    /**
    * POST /Departments/:id/audits/run (Department.prototype.postRunAudit)
    * undefined
    */
    postAuditsRun = async (departmentId: number, body: { description: string, password: string, dateRangeStart: Date, dateRangeEnd: Date, userIds: number[], roomIds: number[], keywords: string[],  includeDirectMessages: boolean,  includeContextConversations: boolean,  timeZone: string,  type: string }) => this.getAgent().post<APIDataObject>(`/Departments/${departmentId}/audits/run`, body)

    /**
    * GET /Departments/:id/audits/:fk/download (Department.prototype.getAuditDownload)
    * Download an audit for Department.
    */
    getAuditsDownload = async (departmentId: number, fk?: string) => this.getAgent().get<FormData>(`/Departments/${departmentId}/audits/${fk}/download`)

    /**
    * GET /Departments/:id/reports/roomMessages/countPerDay (Department.prototype.getRoomMessagesCountPerDay)
    * undefined
    */
    getReportsRoomMessagesCountPerDay = async (departmentId: number, startDate?: Date, endDate?: Date) => this.getAgent().get<APIDataAny[]>(`/Departments/${departmentId}/reports/roomMessages/countPerDay`, { params: { startDate, endDate }})

    /**
    * GET /Departments/:id/reports/directMessages/countPerDay (Department.prototype.getDirectMessagesCountPerDay)
    * undefined
    */
    getReportsDirectMessagesCountPerDay = async (departmentId: number, startDate?: Date, endDate?: Date) => this.getAgent().get<APIDataAny[]>(`/Departments/${departmentId}/reports/directMessages/countPerDay`, { params: { startDate, endDate }})

    /**
    * GET /Departments/:id/reports/roomMessages/count (Department.prototype.getRoomMessagesCount)
    * undefined
    */
    getReportsRoomMessagesCount = async (departmentId: number) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/reports/roomMessages/count`)

    /**
    * GET /Departments/:id/reports/directMessages/count (Department.prototype.getDirectMessagesCount)
    * undefined
    */
    getReportsDirectMessagesCount = async (departmentId: number) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/reports/directMessages/count`)

    /**
    * GET /Departments/:id/reports/usersWithMessageCounts (Department.prototype.getUsersWithMessageCounts)
    * undefined
    */
    getReportsUsersWithMessageCounts = async (departmentId: number, filter?: APIDataObject, startDate?: Date, endDate?: Date) => this.getAgent().get<APIDataObject>(`/Departments/${departmentId}/reports/usersWithMessageCounts`, { params: { filter, startDate, endDate }})

    /**
    * GET /Departments/:id/reports/usersWithMessageCounts/count (Department.prototype.countUsersWithMessageCounts)
    * undefined
    */
    getReportsUsersWithMessageCountsCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/reports/usersWithMessageCounts/count`, { params: { where }})

    /**
    * GET /Departments/:id/reports/allRoomsWithMessageCounts (Department.prototype.getAllRoomsWithMessageCounts)
    * undefined
    */
    getReportsAllRoomsWithMessageCounts = async (departmentId: number, filter?: APIDataObject, startDate?: Date, endDate?: Date) => this.getAgent().get<APIDataRoom[]>(`/Departments/${departmentId}/reports/allRoomsWithMessageCounts`, { params: { filter, startDate, endDate }})

    /**
    * GET /Departments/:id/reports/messageCountsByRole (Department.prototype.getMessageCountsByRole)
    * undefined
    */
    getReportsMessageCountsByRole = async (departmentId: number) => this.getAgent().get<APIDataObject>(`/Departments/${departmentId}/reports/messageCountsByRole`)

    /**
    * GET /Departments/reports/subscriptionsExpiration (Department.getSubscriptionsExpiration)
    * undefined
    */
    getReportsSubscriptionsExpiration = async (daysRange?: number) => this.getAgent().get<APIDataObject>(`/Departments/reports/subscriptionsExpiration`, { params: { daysRange }})

    /**
    * POST /Departments/:id/startTrial (Department.prototype.startTrialRemote)
    * undefined
    */
    postStartTrial = async (departmentId: number, days: number) => this.getAgent().post<APIDataDepartment>(`/Departments/${departmentId}/startTrial`, undefined, { params: { days }})

    /**
    * POST /Departments/:id/invites (Department.prototype.createInvite)
    * Create a department Invite.
    */
    postInvites = async (departmentId: number, expiresInDays?: number, setConsumerRole?: string, addUserCRM?: APIDataUserCRM[]) => this.getAgent().post<APIDataInvite>(`/Departments/${departmentId}/invites`, undefined, { params: { expiresInDays, setConsumerRole, addUserCRM }})

    /**
    * DEL /Departments/:id/invites/:fk (Department.prototype.deleteInvite)
    * Delete an Invite.
    */
    delInvitesById = async (departmentId: number, fk?: number) => this.getAgent().del<APIDataObject>(`/Departments/${departmentId}/invites/${fk}`)

    /**
    * POST /Departments/:id/CRMData (Department.prototype.createCRMData)
    * Creates a new instance in DepartmentCRM of this model.
    */
    postCrmData = async (departmentId: number, data?: APIDataObject) => this.getAgent().post<APIDataDepartmentCRM>(`/Departments/${departmentId}/CRMData`, data)

    /**
    * PUT /Departments/:id/CRMData/:fk (Department.prototype.updateByIdCRMData)
    * Update a related item by id for CRMData.
    */
    putCrmDataById = async (departmentId: number, fk: number, data?: APIDataObject) => this.getAgent().put<APIDataDepartmentCRM>(`/Departments/${departmentId}/CRMData/${fk}`, data)

    /**
    * DEL /Departments/:id/CRMData/:fk (Department.prototype.destroyByIdCRMData)
    * Delete a related item by id for CRMData.
    */
    delCrmDataById = async (departmentId: number, fk: number) => this.getAgent().del<never>(`/Departments/${departmentId}/CRMData/${fk}`)

    /**
    * POST /Departments/:id/emojis (Department.prototype.createEmojis)
    * Creates a new instance of Emoji in this Department.
    */
    postEmojis = async (departmentId: number, data?: APIDataObject) => this.getAgent().post<APIDataEmoji>(`/Departments/${departmentId}/emojis`, data)

    /**
    * GET /Departments/:id/emojis (Department.prototype.getEmojis)
    * Get department emojis.
    */
    getEmojis = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataEmoji[]>(`/Departments/${departmentId}/emojis`, { params: { filter }})

    /**
    * POST /Departments/:id/emojis/:fk/image/upload (Department.prototype.emojiUploadImage)
    * Upload image to Emoji.
    */
    postEmojisImageUpload = async (departmentId: number, fk: number, file?: FormData) => this.getAgent().post<APIDataEmoji>(`/Departments/${departmentId}/emojis/${fk}/image/upload`, undefined, { params: { file }})

    /**
    * PUT /Departments/:id/emojis/:fk (Department.prototype.updateEmoji)
    * Update a related item by id for Emoji.
    */
    putEmojisById = async (departmentId: number, fk: number, data?: APIDataObject) => this.getAgent().put<APIDataEmoji>(`/Departments/${departmentId}/emojis/${fk}`, data)

    /**
    * GET /Departments/:id/users/linked/search (Department.prototype.searchChildDepartmentUsers)
    * Search in child and parent department Users
    */
    getUsersLinkedSearch = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/Departments/${departmentId}/users/linked/search`, { params: { filter }})

    /**
    * GET /Departments/:id/users/linked/search/count (Department.prototype.searchChildDepartmentUsersCount)
    * Count Search in child and parent department Users
    */
    getUsersLinkedSearchCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/users/linked/search/count`, { params: { where }})

    /**
    * POST /Departments/:id/domains/:domain (Department.prototype.postDomain)
    * undefined
    */
    postDomainsById = async (departmentId: number, domain: string) => this.getAgent().post<APIDataVerifiedEmailDomain>(`/Departments/${departmentId}/domains/${domain}`, undefined)

    /**
    * DEL /Departments/:id/domains/:domain (Department.prototype.deleteDomain)
    * undefined
    */
    delDomainsById = async (departmentId: number, domain: string) => this.getAgent().del<APIDataVerifiedEmailDomain>(`/Departments/${departmentId}/domains/${domain}`)

    /**
    * GET /Departments/:id/documents/search (Department.prototype.searchDocuments)
    * (Deprecated)
    * @deprecated
    */
    getDocumentsSearch = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocument[]>(`/Departments/${departmentId}/documents/search`, { params: { filter }})

    /**
    * GET /Departments/:id/documents/search/count (Department.prototype.searchDocumentsCount)
    * (Deprecated)
    * @deprecated
    */
    getDocumentsSearchCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/documents/search/count`, { params: { where }})

    /**
    * POST /Departments/:id/documents (Department.prototype.createDocumentRemote)
    * Creates a new instance of Document in this Department.
    */
    postDocuments = async (departmentId: number, document?: APIDataDocument) => this.getAgent().post<APIDataDocument>(`/Departments/${departmentId}/documents`, document)

    /**
    * GET /Departments/:id/bulletins (Department.prototype.getBulletinsFromDocuments)
    * (Deprecated)
    * @deprecated
    */
    getBulletins = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocument[]>(`/Departments/${departmentId}/bulletins`, { params: { filter }})

    /**
    * GET /Departments/:id/bulletins/count (Department.prototype.countBulletinsFromDocuments)
    * (Deprecated)
    * @deprecated
    */
    getBulletinsCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/bulletins/count`, { params: { where }})

    /**
    * GET /Departments/:id/documents/count (Department.prototype.documentsAdminCount)
    * Returns the count of documents in a department
    */
    getDocumentsCount = async (departmentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Departments/${departmentId}/documents/count`, { params: { where }})

    /**
    * POST /Departments/:id/documentSchemaCategories (Department.prototype.createDocumentSchemaCategories)
    * Create a department DocumentSchemaCategory.
    */
    postDocumentSchemaCategories = async (departmentId: number, name: string) => this.getAgent().post<APIDataCount>(`/Departments/${departmentId}/documentSchemaCategories`, undefined, { params: { name }})

    /**
    * GET /Departments/:id/users/csv (Department.prototype.userListCSV)
    * Downloads department user list as CSV
    */
    getUsersCsv = async (departmentId: number) => this.getAgent().get<FormData>(`/Departments/${departmentId}/users/csv`)

    /**
    * GET /Departments/:id/rooms/userCounts (Department.prototype.getRoomUserCounts)
    * Get User Counts for Department rooms
    */
    getRoomsUserCounts = async (departmentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataObject[]>(`/Departments/${departmentId}/rooms/userCounts`, { params: { filter }})

    /**
    * POST /Departments/:id/media/public/upload/start (Department.prototype.startPublicMediaMultiPartUpload)
    * Upload an image for publicMedia.
    */
    postMediaPublicUploadStart = async (departmentId: number, params: {  fileName: string,  mimeType: string,  meta: APIDataObject }) => this.getAgent().post<APIDataMediaMultiPartUpload>(`/Departments/${departmentId}/media/public/upload/start`, params)

}
export class InviteRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * POST /Invites/consume/:token (Invite.consume)
    * Consume invite token
    */
    postConsumeById = async (token: string) => this.getAgent().post<APIDataThread>(`/Invites/consume/${token}`, undefined)

}
export class PushRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /Pushes/queue/counts (Push.getJobCounts)
    * undefined
    */
    getQueueCounts = async () => this.getAgent().get<APIDataObject>(`/Pushes/queue/counts`)

    /**
    * GET /Pushes/queue/clean (Push.cleanJobs)
    * undefined
    */
    getQueueClean = async (grace?: number, status?: string, limit?: number) => this.getAgent().get<APIDataObject>(`/Pushes/queue/clean`, { params: { grace, status, limit }})

    /**
    * GET /Pushes/userBadgeCount (Push.userBadgeCount)
    * (development) test userBadgeCount function.
    */
    getUserBadgeCount = async (userId: number) => this.getAgent().get<APIDataCount>(`/Pushes/userBadgeCount`, { params: { userId }})

    /**
    * POST /Pushes/send (Push.send)
    * (development) Send push to BlueUser devices.
    */
    postSend = async (userIds: number[], body: APIDataObject) => this.getAgent().post<APIDataObject>(`/Pushes/send`, body, { params: { userIds }})

}
export class WebContentRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /WebContents/:id (WebContent.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (webContentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataWebContent>(`/WebContents/${webContentId}`, { params: { filter }})

    /**
    * POST /WebContents/logError (WebContent.postError)
    * Log Web Error.
    */
    postLogError = async (error?: APIDataObject) => this.getAgent().post<APIDataObject>(`/WebContents/logError`, error)

}
export class BulletinRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /Bulletins/:id/history/:fk (Bulletin.prototype.__findById__history)
    * Find a related item by id for history.
    */
    getHistoryById = async (bulletinId: number, fk: number) => this.getAgent().get<APIDataBulletinHistory>(`/Bulletins/${bulletinId}/history/${fk}`)

    /**
    * PUT /Bulletins/:id/subjects/:fk (Bulletin.prototype.__updateById__subjects)
    * Update a related item by id for subjects.
    */
    putSubjectsById = async (bulletinId: number, fk: number, data?: APIDataObject) => this.getAgent().put<APIDataBulletinSubject>(`/Bulletins/${bulletinId}/subjects/${fk}`, data)

    /**
    * PUT /Bulletins/:id/vehicles/:fk (Bulletin.prototype.__updateById__vehicles)
    * Update a related item by id for vehicles.
    */
    putVehiclesById = async (bulletinId: number, fk: number, data?: APIDataObject) => this.getAgent().put<APIDataBulletinVehicle>(`/Bulletins/${bulletinId}/vehicles/${fk}`, data)

    /**
    * GET /Bulletins/:id/reads (Bulletin.prototype.__get__reads)
    * Queries reads of Bulletin.
    */
    getReads = async (bulletinId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRead[]>(`/Bulletins/${bulletinId}/reads`, { params: { filter }})

    /**
    * GET /Bulletins/:id/reads/count (Bulletin.prototype.__count__reads)
    * Counts reads of Bulletin.
    */
    getReadsCount = async (bulletinId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Bulletins/${bulletinId}/reads/count`, { params: { where }})

    /**
    * GET /Bulletins/:id/history (Bulletin.prototype.__get__history)
    * Queries history of Bulletin.
    */
    getHistory = async (bulletinId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBulletinHistory[]>(`/Bulletins/${bulletinId}/history`, { params: { filter }})

    /**
    * GET /Bulletins/:id/history/count (Bulletin.prototype.__count__history)
    * Counts history of Bulletin.
    */
    getHistoryCount = async (bulletinId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Bulletins/${bulletinId}/history/count`, { params: { where }})

    /**
    * GET /Bulletins/:id/subjects (Bulletin.prototype.__get__subjects)
    * Queries subjects of Bulletin.
    */
    getSubjects = async (bulletinId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBulletinSubject[]>(`/Bulletins/${bulletinId}/subjects`, { params: { filter }})

    /**
    * GET /Bulletins/:id/subjects/count (Bulletin.prototype.__count__subjects)
    * Counts subjects of Bulletin.
    */
    getSubjectsCount = async (bulletinId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Bulletins/${bulletinId}/subjects/count`, { params: { where }})

    /**
    * GET /Bulletins/:id/vehicles (Bulletin.prototype.__get__vehicles)
    * Queries vehicles of Bulletin.
    */
    getVehicles = async (bulletinId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBulletinVehicle[]>(`/Bulletins/${bulletinId}/vehicles`, { params: { filter }})

    /**
    * GET /Bulletins/:id/vehicles/count (Bulletin.prototype.__count__vehicles)
    * Counts vehicles of Bulletin.
    */
    getVehiclesCount = async (bulletinId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Bulletins/${bulletinId}/vehicles/count`, { params: { where }})

    /**
    * GET /Bulletins/:id/rooms (Bulletin.prototype.__get__rooms)
    * Queries rooms of Bulletin.
    */
    getRooms = async (bulletinId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/Bulletins/${bulletinId}/rooms`, { params: { filter }})

    /**
    * GET /Bulletins/:id/rooms/count (Bulletin.prototype.__count__rooms)
    * Counts rooms of Bulletin.
    */
    getRoomsCount = async (bulletinId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Bulletins/${bulletinId}/rooms/count`, { params: { where }})

    /**
    * GET /Bulletins/:id/media (Bulletin.prototype.getMedia)
    * Get Media objects for this intelligence bulletin.
    */
    getMedia = async (bulletinId: number) => this.getAgent().get<APIDataMedia>(`/Bulletins/${bulletinId}/media`)

    /**
    * POST /Bulletins/:id/mediaUpload (Bulletin.prototype.postMediaUpload)
    * (Deprecated)
    * @deprecated
    */
    postMediaUpload = async (bulletinId: number, data: FormData, meta?: APIDataObject) => this.getAgent().post<APIDataMedia>(`/Bulletins/${bulletinId}/mediaUpload`, data, { params: { meta }, headers: {'Content-Type': 'multipart/form-data'}})

    /**
    * DEL /Bulletins/:id/media/:fk (Bulletin.prototype.deleteMedia)
    * (Deprecated)
    * @deprecated
    */
    delMediaById = async (bulletinId: number, fk?: number) => this.getAgent().del<APIDataObject>(`/Bulletins/${bulletinId}/media/${fk}`)

    /**
    * POST /Bulletins/:id/reads (Bulletin.prototype.postRead)
    * Read an intelligence bulletin.
    */
    postReads = async (bulletinId: number) => this.getAgent().post<APIDataObject>(`/Bulletins/${bulletinId}/reads`, undefined)

    /**
    * PUT /Bulletins/:id (Bulletin.prototype.updateBulletin)
    * (Deprecated).
    * @deprecated
    */
    putById = async (bulletinId: number, bulletin: APIDataObject) => this.getAgent().put<APIDataObject>(`/Bulletins/${bulletinId}`, bulletin)

    /**
    * GET /Bulletins/:id (Bulletin.findOneBulletin)
    * (Deprecated) returns Document!
    * @deprecated
    */
    getById = async (bulletinId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBulletin>(`/Bulletins/${bulletinId}`, { params: { filter }})

    /**
    * DEL /Bulletins/:id (Bulletin.prototype.destroyBulletin)
    * (Deprecated)
    * @deprecated
    */
    delById = async (bulletinId: number) => this.getAgent().del<APIDataObject>(`/Bulletins/${bulletinId}`)

    /**
    * DEL /Bulletins/:id/vehicle/:fk (Bulletin.prototype.destroyVehicle)
    * (Deprecated)
    * @deprecated
    */
    delVehicleById = async (bulletinId: number, fk: number) => this.getAgent().del<APIDataObject>(`/Bulletins/${bulletinId}/vehicle/${fk}`)

    /**
    * DEL /Bulletins/:id/vehicles/:fk (Bulletin.prototype.destroyVehicle)
    * (Deprecated)
    * @deprecated
    */
    delVehiclesById = async (bulletinId: number, fk: number) => this.getAgent().del<APIDataObject>(`/Bulletins/${bulletinId}/vehicles/${fk}`)

    /**
    * DEL /Bulletins/:id/subject/:fk (Bulletin.prototype.destroySubject)
    * (Deprecated)
    * @deprecated
    */
    delSubjectById = async (bulletinId: number, fk: number) => this.getAgent().del<APIDataObject>(`/Bulletins/${bulletinId}/subject/${fk}`)

    /**
    * DEL /Bulletins/:id/subjects/:fk (Bulletin.prototype.destroySubject)
    * (Deprecated)
    * @deprecated
    */
    delSubjectsById = async (bulletinId: number, fk: number) => this.getAgent().del<APIDataObject>(`/Bulletins/${bulletinId}/subjects/${fk}`)

}
export class RoomRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /Rooms/:id/media/:fk (Room.prototype.__findById__media)
    * Find a related item by id for media.
    */
    getMediaById = async (roomId: number, fk: number) => this.getAgent().get<APIDataMedia>(`/Rooms/${roomId}/media/${fk}`)

    /**
    * GET /Rooms/:id/history/:fk (Room.prototype.__findById__history)
    * Find a related item by id for history.
    */
    getHistoryById = async (roomId: number, fk: number) => this.getAgent().get<APIDataRoomHistory>(`/Rooms/${roomId}/history/${fk}`)

    /**
    * DEL /Rooms/:id/roomJoinRequestRules/:fk (Room.prototype.__destroyById__roomJoinRequestRules)
    * Delete a related item by id for roomJoinRequestRules.
    */
    delRoomJoinRequestRulesById = async (roomId: number, fk: number) => this.getAgent().del<never>(`/Rooms/${roomId}/roomJoinRequestRules/${fk}`)

    /**
    * PUT /Rooms/:id/roomJoinRequestRules/:fk (Room.prototype.__updateById__roomJoinRequestRules)
    * Update a related item by id for roomJoinRequestRules.
    */
    putRoomJoinRequestRulesById = async (roomId: number, fk: number, data?: APIDataObject) => this.getAgent().put<APIDataRoomJoinRequestRule>(`/Rooms/${roomId}/roomJoinRequestRules/${fk}`, data)

    /**
    * GET /Rooms/:id/invites (Room.prototype.__get__invites)
    * Queries invites of Room.
    */
    getInvites = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataInvite[]>(`/Rooms/${roomId}/invites`, { params: { filter }})

    /**
    * GET /Rooms/:id/history (Room.prototype.__get__history)
    * Queries history of Room.
    */
    getHistory = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoomHistory[]>(`/Rooms/${roomId}/history`, { params: { filter }})

    /**
    * GET /Rooms/:id/history/count (Room.prototype.__count__history)
    * Counts history of Room.
    */
    getHistoryCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/history/count`, { params: { where }})

    /**
    * GET /Rooms/:id/members (Room.prototype.__get__members)
    * Queries members of Room.
    */
    getMembers = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/Rooms/${roomId}/members`, { params: { filter }})

    /**
    * GET /Rooms/:id/members/count (Room.prototype.__count__members)
    * Counts members of Room.
    */
    getMembersCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/members/count`, { params: { where }})

    /**
    * GET /Rooms/:id/managers (Room.prototype.__get__managers)
    * Queries managers of Room.
    */
    getManagers = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/Rooms/${roomId}/managers`, { params: { filter }})

    /**
    * GET /Rooms/:id/managers/count (Room.prototype.__count__managers)
    * Counts managers of Room.
    */
    getManagersCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/managers/count`, { params: { where }})

    /**
    * GET /Rooms/:id/roomJoinRequests (Room.prototype.__get__roomJoinRequests)
    * Queries roomJoinRequests of Room.
    */
    getRoomJoinRequests = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoomJoinRequest[]>(`/Rooms/${roomId}/roomJoinRequests`, { params: { filter }})

    /**
    * GET /Rooms/:id/roomJoinRequests/count (Room.prototype.__count__roomJoinRequests)
    * Counts roomJoinRequests of Room.
    */
    getRoomJoinRequestsCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/roomJoinRequests/count`, { params: { where }})

    /**
    * GET /Rooms/:id/roomJoinRequestRules (Room.prototype.__get__roomJoinRequestRules)
    * Queries roomJoinRequestRules of Room.
    */
    getRoomJoinRequestRules = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoomJoinRequestRule[]>(`/Rooms/${roomId}/roomJoinRequestRules`, { params: { filter }})

    /**
    * POST /Rooms/:id/roomJoinRequestRules (Room.prototype.__create__roomJoinRequestRules)
    * Creates a new instance in roomJoinRequestRules of this model.
    */
    postRoomJoinRequestRules = async (roomId: number, data?: APIDataObject) => this.getAgent().post<APIDataRoomJoinRequestRule>(`/Rooms/${roomId}/roomJoinRequestRules`, data)

    /**
    * GET /Rooms/:id/roomJoinRequestRules/count (Room.prototype.__count__roomJoinRequestRules)
    * Counts roomJoinRequestRules of Room.
    */
    getRoomJoinRequestRulesCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/roomJoinRequestRules/count`, { params: { where }})

    /**
    * GET /Rooms/:id (Room.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoom>(`/Rooms/${roomId}`, { params: { filter }})

    /**
    * PUT /Rooms/:id (Room.prototype.updateRoom)
    * Update Room
    */
    putById = async (roomId: number, room: APIDataObject) => this.getAgent().put<APIDataRoom>(`/Rooms/${roomId}`, room)

    /**
    * PUT /Rooms/:id/members/:userId (Room.prototype.addMemberRemote)
    * Add User to room
    */
    putMembersById = async (roomId: number, userId: number) => this.getAgent().put<APIDataObject>(`/Rooms/${roomId}/members/${userId}`, undefined)

    /**
    * DEL /Rooms/:id/members/:userId (Room.prototype.removeMemberRemote)
    * Remove user from room
    */
    delMembersById = async (roomId: number, userId: number) => this.getAgent().del<APIDataObject>(`/Rooms/${roomId}/members/${userId}`)

    /**
    * GET /Rooms/:id/availableCapacities (Room.prototype.getAvailableCapacities)
    * (Deprecated)
    * @deprecated
    */
    getAvailableCapacities = async (roomId: number) => this.getAgent().get<number>(`/Rooms/${roomId}/availableCapacities`)

    /**
    * PUT /Rooms/:id/bulletin/:bulletinId (Room.prototype.addBulletin)
    * (Deprecated)
    * @deprecated
    */
    putBulletinById = async (roomId: number, bulletinId: number) => this.getAgent().put<APIDataObject>(`/Rooms/${roomId}/bulletin/${bulletinId}`, undefined)

    /**
    * DEL /Rooms/:id/bulletin/:bulletinId (Room.prototype.removeBulletin)
    * Remove Bulletin from room
    */
    delBulletinById = async (roomId: number, bulletinId: number) => this.getAgent().del<APIDataObject>(`/Rooms/${roomId}/bulletin/${bulletinId}`)

    /**
    * PUT /Rooms/:id/managers/:userId (Room.prototype.addManagerRemote)
    * Add manager to room
    */
    putManagersById = async (roomId: number, userId: number) => this.getAgent().put<APIDataObject>(`/Rooms/${roomId}/managers/${userId}`, undefined)

    /**
    * DEL /Rooms/:id/managers/:userId (Room.prototype.removeManagerRemote)
    * Remove manager from room
    */
    delManagersById = async (roomId: number, userId: number) => this.getAgent().del<APIDataObject>(`/Rooms/${roomId}/managers/${userId}`)

    /**
    * POST /Rooms/:id/messages (Room.prototype.createMessage)
    * Create RoomMessage for Room
    */
    postMessages = async (roomId: number, message: APIDataRoomMessage) => this.getAgent().post<APIDataObject>(`/Rooms/${roomId}/messages`, message)

    /**
    * GET /Rooms/:id/messages/countUnread (Room.prototype.countUnreadMessages)
    * Count unread messages.
    */
    getMessagesCountUnread = async (roomId: number, where?: APIDataObject) => this.getAgent().get<{  count: number,  urgent: number,  repliesCount: number,  unreadRepliesMap: {[key: string | number]:{ repliesToId: number, unreadCount: number, urgentCount: number }} }[]>(`/Rooms/${roomId}/messages/countUnread`, { params: { where }})

    /**
    * PUT /Rooms/:id/screenShot (Room.prototype.screenShot)
    * Report screen shot
    */
    putScreenShot = async (roomId: number) => this.getAgent().put<APIDataObject>(`/Rooms/${roomId}/screenShot`, undefined)

    /**
    * POST /Rooms/:id/messages/reads (Room.prototype.createReads)
    * Create reads for all messages in room
    */
    postMessagesReads = async (roomId: number, where?: APIDataObject) => this.getAgent().post<APIDataCount>(`/Rooms/${roomId}/messages/reads`, undefined, { params: { where }})

    /**
    * GET /Rooms/:id/messages (Room.prototype.searchMessages)
    * Queries messages of Room.
    */
    getMessages = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoomMessage[]>(`/Rooms/${roomId}/messages`, { params: { filter }})

    /**
    * GET /Rooms/:id/messages/count (Room.prototype.searchMessagesCount)
    * Counts messages of Room.
    */
    getMessagesCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/messages/count`, { params: { where }})

    /**
    * POST /Rooms/:id/sendInvites (Room.prototype.postSendInvites)
    * Send invite tokens, adds user to room.
    */
    postSendInvites = async (roomId: number, body: { to: string[],  message: string, roles: string[],  addAsRoomManager: boolean, addUserCRM: APIDataUserCRM[] }) => this.getAgent().post<APIDataInvite>(`/Rooms/${roomId}/sendInvites`, body)

    /**
    * GET /Rooms/:id/media (Room.prototype.getMedia)
    * Get message media
    */
    getMedia = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMedia[]>(`/Rooms/${roomId}/media`, { params: { filter }})

    /**
    * GET /Rooms/:id/media/count (Room.prototype.countMedia)
    * Counts Room media.
    */
    getMediaCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/media/count`, { params: { where }})

    /**
    * POST /Rooms/:id/invites (Room.prototype.createInvite)
    * Create a room invite.
    */
    postInvites = async (roomId: number, expiresInDays?: number, setConsumerRole?: string, managerInvite?: boolean, addUserCRM?: APIDataUserCRM[]) => this.getAgent().post<APIDataCount>(`/Rooms/${roomId}/invites`, undefined, { params: { expiresInDays, setConsumerRole, managerInvite, addUserCRM }})

    /**
    * DEL /Rooms/:id/invites/:fk (Room.prototype.deleteInvite)
    * Delete an Invite.
    */
    delInvitesById = async (roomId: number, fk?: number) => this.getAgent().del<APIDataObject>(`/Rooms/${roomId}/invites/${fk}`)

    /**
    * GET /Rooms/:id/users (Room.prototype.findUsers)
    * Queries users of Room Messages and Members.
    */
    getUsers = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/Rooms/${roomId}/users`, { params: { filter }})

    /**
    * GET /Rooms/:id/users/count (Room.prototype.findUsersCount)
    * Counts users of Room Messages and Members.
    */
    getUsersCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/users/count`, { params: { where }})

    /**
    * GET /Rooms/:id/search/users (Room.prototype.searchUsers)
    * Queries users of Room.
    */
    getSearchUsers = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/Rooms/${roomId}/search/users`, { params: { filter }})

    /**
    * GET /Rooms/:id/search/users/count (Room.prototype.searchUsersCount)
    * Counts users of Room.
    */
    getSearchUsersCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/search/users/count`, { params: { where }})

    /**
    * GET /Rooms/search/public (Room.searchPublic)
    * Search Users in other departments
    */
    getSearchPublic = async (filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/Rooms/search/public`, { params: { filter }})

    /**
    * GET /Rooms/search/public/count (Room.searchPublicCount)
    * Count Search Users
    */
    getSearchPublicCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/search/public/count`, { params: { where }})

    /**
    * POST /Rooms/:id/roomJoinRequests (Room.prototype.roomJoinRequestCreate)
    * Request to join room.
    */
    postRoomJoinRequests = async (roomId: number, body?: APIDataObject) => this.getAgent().post<APIDataRoomJoinRequest>(`/Rooms/${roomId}/roomJoinRequests`, body)

    /**
    * POST /Rooms/:id/roomJoinRequests/:fk/accept (Room.prototype.roomJoinRequestAccept)
    * Accept room join request.
    */
    postRoomJoinRequestsAccept = async (roomId: number, fk?: number) => this.getAgent().post<APIDataRoomJoinRequest>(`/Rooms/${roomId}/roomJoinRequests/${fk}/accept`, undefined)

    /**
    * POST /Rooms/:id/roomJoinRequests/:fk/block (Room.prototype.roomJoinRequestBlock)
    * Block room join request.
    */
    postRoomJoinRequestsBlock = async (roomId: number, fk?: number) => this.getAgent().post<APIDataRoomJoinRequest>(`/Rooms/${roomId}/roomJoinRequests/${fk}/block`, undefined)

    /**
    * POST /Rooms/:id/roomJoinRequests/:fk/reject (Room.prototype.roomJoinRequestReject)
    * Reject room join request.
    */
    postRoomJoinRequestsReject = async (roomId: number, fk?: number) => this.getAgent().post<APIDataRoomJoinRequest>(`/Rooms/${roomId}/roomJoinRequests/${fk}/reject`, undefined)

    /**
    * DEL /Rooms/:id/roomJoinRequests/:fk (Room.prototype.roomJoinRequestDelete)
    * Cancel room join request.
    */
    delRoomJoinRequestsById = async (roomId: number, fk?: number) => this.getAgent().del<APIDataRoomJoinRequest>(`/Rooms/${roomId}/roomJoinRequests/${fk}`)

    /**
    * GET /Rooms/:id/documents/search (Room.prototype.searchDocuments)
    * Search Documents
    */
    getDocumentsSearch = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocument[]>(`/Rooms/${roomId}/documents/search`, { params: { filter }})

    /**
    * GET /Rooms/:id/documents/search/count (Room.prototype.searchDocumentsCount)
    * Count Search Documents
    */
    getDocumentsSearchCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/documents/search/count`, { params: { where }})

    /**
    * GET /Rooms/:id/bulletins (Room.prototype.getBulletinsFromDocuments)
    * (Deprecated)
    * @deprecated
    */
    getBulletins = async (roomId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocument[]>(`/Rooms/${roomId}/bulletins`, { params: { filter }})

    /**
    * GET /Rooms/:id/bulletins/count (Room.prototype.countBulletinsFromDocuments)
    * (Deprecated)
    * @deprecated
    */
    getBulletinsCount = async (roomId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Rooms/${roomId}/bulletins/count`, { params: { where }})

    /**
    * POST /Rooms/:id/clone (Room.prototype.cloneRoom)
    * Clone Room
    */
    postClone = async (roomId: number, config: APIDataObject) => this.getAgent().post<APIDataObject>(`/Rooms/${roomId}/clone`, config)

    /**
    * PUT /Rooms/:id/messages/:repliesToId/notify (Room.prototype.putRepliesSubscription)
    * Updates subscription for Reply message notifications
    */
    putMessagesNotify = async (roomId: number, repliesToId: number, body: {  notify: boolean }) => this.getAgent().put<APIDataObject>(`/Rooms/${roomId}/messages/${repliesToId}/notify`, body)

}
export class RoomMessageRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /RoomMessages/:id/replies (RoomMessage.prototype.__get__replies)
    * Queries replies of RoomMessage.
    */
    getReplies = async (roomMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoomMessage[]>(`/RoomMessages/${roomMessageId}/replies`, { params: { filter }})

    /**
    * GET /RoomMessages/:id/replies/count (RoomMessage.prototype.__count__replies)
    * Counts replies of RoomMessage.
    */
    getRepliesCount = async (roomMessageId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/RoomMessages/${roomMessageId}/replies/count`, { params: { where }})

    /**
    * GET /RoomMessages/:id/media (RoomMessage.prototype.__get__media)
    * Queries media of RoomMessage.
    */
    getMedia = async (roomMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMedia[]>(`/RoomMessages/${roomMessageId}/media`, { params: { filter }})

    /**
    * GET /RoomMessages/:id/media/count (RoomMessage.prototype.__count__media)
    * Counts media of RoomMessage.
    */
    getMediaCount = async (roomMessageId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/RoomMessages/${roomMessageId}/media/count`, { params: { where }})

    /**
    * GET /RoomMessages/:id/reactions (RoomMessage.prototype.__get__reactions)
    * Queries reactions of RoomMessage.
    */
    getReactions = async (roomMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataReaction[]>(`/RoomMessages/${roomMessageId}/reactions`, { params: { filter }})

    /**
    * GET /RoomMessages/:id/reactions/count (RoomMessage.prototype.__count__reactions)
    * Counts reactions of RoomMessage.
    */
    getReactionsCount = async (roomMessageId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/RoomMessages/${roomMessageId}/reactions/count`, { params: { where }})

    /**
    * GET /RoomMessages/:id/reactedByThrough (RoomMessage.prototype.__get__reactedByThrough)
    * Queries reactedByThrough of RoomMessage.
    */
    getReactedByThrough = async (roomMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataReactionUserThrough[]>(`/RoomMessages/${roomMessageId}/reactedByThrough`, { params: { filter }})

    /**
    * GET /RoomMessages/:id/reactedByThrough/count (RoomMessage.prototype.__count__reactedByThrough)
    * Counts reactedByThrough of RoomMessage.
    */
    getReactedByThroughCount = async (roomMessageId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/RoomMessages/${roomMessageId}/reactedByThrough/count`, { params: { where }})

    /**
    * POST /RoomMessages/:id/mediaUpload (RoomMessage.prototype.postMediaUpload)
    * Upload an image or video for a RoomMessage.
    */
    postMediaUpload = async (roomMessageId: number, data: FormData, meta?: APIDataObject) => this.getAgent().post<APIDataMedia>(`/RoomMessages/${roomMessageId}/mediaUpload`, data, { params: { meta }, headers: {'Content-Type': 'multipart/form-data'}})

    /**
    * POST /RoomMessages/:id/mediaUploadAndPublish (RoomMessage.prototype.postMediaUploadAndPublish)
    * Upload an image or video for a RoomMessage, and Publish the message.
    */
    postMediaUploadAndPublish = async (roomMessageId: number, data: FormData, meta?: APIDataObject) => this.getAgent().post<APIDataObject>(`/RoomMessages/${roomMessageId}/mediaUploadAndPublish`, data, { params: { meta }, headers: {'Content-Type': 'multipart/form-data'}})

    /**
    * POST /RoomMessages/:id/media/upload/start (RoomMessage.prototype.startMultiPartUpload)
    * Upload an image or video for a RoomMessage with Multi-part upload.
    */
    postMediaUploadStart = async (roomMessageId: number, params: {  fileName: string,  mimeType: string,  meta: APIDataObject }) => this.getAgent().post<APIDataMediaMultiPartUpload>(`/RoomMessages/${roomMessageId}/media/upload/start`, params)

    /**
    * DEL /RoomMessages/:id/media/:fk (RoomMessage.prototype.deleteMedia)
    * Delete an image or video for a RoomMessage.
    */
    delMediaById = async (roomMessageId: number, fk?: number) => this.getAgent().del<APIDataObject>(`/RoomMessages/${roomMessageId}/media/${fk}`)

    /**
    * POST /RoomMessages/:id/reads (RoomMessage.prototype.createRead)
    * Read a RoomMessage.
    */
    postReads = async (roomMessageId: number) => this.getAgent().post<APIDataObject>(`/RoomMessages/${roomMessageId}/reads`, undefined)

    /**
    * PUT /RoomMessages/:id/retract (RoomMessage.prototype.retract)
    * Retract RoomMessage
    */
    putRetract = async (roomMessageId: number) => this.getAgent().put<APIDataRoomMessage>(`/RoomMessages/${roomMessageId}/retract`, undefined)

    /**
    * POST /RoomMessages/:id/report (RoomMessage.prototype.report)
    * Report RoomMessage
    */
    postReport = async (roomMessageId: number, report?: APIDataReport) => this.getAgent().post<APIDataReport>(`/RoomMessages/${roomMessageId}/report`, report)

    /**
    * PUT /RoomMessages/:id/reactions (RoomMessage.prototype.createReaction)
    * Add a Reaction.
    */
    putReactions = async (roomMessageId: number, text: string) => this.getAgent().put<APIDataReaction>(`/RoomMessages/${roomMessageId}/reactions`, undefined, { params: { text }})

    /**
    * DEL /RoomMessages/:id/reactions (RoomMessage.prototype.deleteReaction)
    * Delete a Reaction.
    */
    delReactions = async (roomMessageId: number, text: string) => this.getAgent().del<APIDataReaction>(`/RoomMessages/${roomMessageId}/reactions`, { params: { text }})

    /**
    * GET /RoomMessages/:id/reads (RoomMessage.prototype.getReadsRemote)
    * Get Reads.
    */
    getReads = async (roomMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRead[]>(`/RoomMessages/${roomMessageId}/reads`, { params: { filter }})

    /**
    * GET /RoomMessages/:id/reads/count (RoomMessage.prototype.getReadsCountRemote)
    * Counts Reads.
    */
    getReadsCount = async (roomMessageId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/RoomMessages/${roomMessageId}/reads/count`, { params: { where }})

    /**
    * POST /RoomMessages/:id/forward (RoomMessage.prototype.forwardMessage)
    * Forwards a RoomMessage to a new room
    */
    postForward = async (roomMessageId: number, data: {  roomId: number,  messageText: string,  isUrgent: boolean }) => this.getAgent().post<APIDataRoomMessage>(`/RoomMessages/${roomMessageId}/forward`, data)

    /**
    * GET /RoomMessages/:id (RoomMessage.findOneRoomMessage)
    * Returns a single room message
    */
    getById = async (roomMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRoomMessage>(`/RoomMessages/${roomMessageId}`, { params: { filter }})

    /**
    * PUT /RoomMessages/:id/publish (RoomMessage.prototype.publish)
    * Publish Model
    */
    putPublish = async (roomMessageId: number) => this.getAgent().put<APIDataObject>(`/RoomMessages/${roomMessageId}/publish`, undefined)

}
export class ThreadRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /Threads/:id/media/:fk (Thread.prototype.__findById__media)
    * Find a related item by id for media.
    */
    getMediaById = async (threadId: number, fk: number) => this.getAgent().get<APIDataMedia>(`/Threads/${threadId}/media/${fk}`)

    /**
    * GET /Threads/:id/users (Thread.prototype.__get__users)
    * Queries users of Thread.
    */
    getUsers = async (threadId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/Threads/${threadId}/users`, { params: { filter }})

    /**
    * GET /Threads/:id/users/count (Thread.prototype.__count__users)
    * Counts users of Thread.
    */
    getUsersCount = async (threadId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Threads/${threadId}/users/count`, { params: { where }})

    /**
    * GET /Threads/:id (Thread.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (threadId: number, filter?: APIDataObject) => this.getAgent().get<APIDataThread>(`/Threads/${threadId}`, { params: { filter }})

    /**
    * GET /Threads/:id/mediaDownload/:fileKey (Thread.prototype.getMediaDownload)
    * Download an image or video for Thread.
    */
    getMediaDownloadById = async (threadId: number, fileKey?: string, ver?: string) => this.getAgent().get<FormData>(`/Threads/${threadId}/mediaDownload/${fileKey}`, { params: { ver }})

    /**
    * POST /Threads/:id/messages (Thread.prototype.createMessage)
    * Add ThreadMessage to Thread
    */
    postMessages = async (threadId: number, message: APIDataThreadMessage) => this.getAgent().post<APIDataThreadMessage>(`/Threads/${threadId}/messages`, message)

    /**
    * GET /Threads/:id/messages/countUnread (Thread.prototype.countUnreadMessages)
    * Count unread messages.
    */
    getMessagesCountUnread = async (threadId: number, where?: APIDataObject) => this.getAgent().get<APIDataObject>(`/Threads/${threadId}/messages/countUnread`, { params: { where }})

    /**
    * POST /Threads/start (Thread.startRemote)
    * Start a thread with Users
    */
    postStart = async (userIds: number[]) => this.getAgent().post<APIDataThread>(`/Threads/start`, userIds)

    /**
    * PUT /Threads/:id/screenShot (Thread.prototype.screenShot)
    * Report screen shot
    */
    putScreenShot = async (threadId: number) => this.getAgent().put<APIDataObject>(`/Threads/${threadId}/screenShot`, undefined)

    /**
    * POST /Threads/:id/messages/reads (Thread.prototype.createReads)
    * Create reads for all messages in room
    */
    postMessagesReads = async (threadId: number, where?: APIDataObject) => this.getAgent().post<APIDataCount>(`/Threads/${threadId}/messages/reads`, undefined, { params: { where }})

    /**
    * DEL /Threads/:id (Thread.prototype.deleteThread)
    * Hide a thread
    */
    delById = async (threadId: number) => this.getAgent().del<APIDataObject>(`/Threads/${threadId}`)

    /**
    * GET /Threads/:id/messages (Thread.prototype.searchMessages)
    * Queries messages of Thread.
    */
    getMessages = async (threadId: number, filter?: APIDataObject) => this.getAgent().get<APIDataThreadMessage[]>(`/Threads/${threadId}/messages`, { params: { filter }})

    /**
    * GET /Threads/:id/messages/count (Thread.prototype.searchMessagesCount)
    * Counts messages of Thread.
    */
    getMessagesCount = async (threadId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Threads/${threadId}/messages/count`, { params: { where }})

    /**
    * POST /Threads/request (Thread.requestThread)
    * Start a thread with public user
    */
    postRequest = async (body: {  userId: number,  message: string }) => this.getAgent().post<APIDataObject>(`/Threads/request`, body)

    /**
    * GET /Threads/:id/media (Thread.prototype.getMedia)
    * Get message media
    */
    getMedia = async (threadId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMedia[]>(`/Threads/${threadId}/media`, { params: { filter }})

    /**
    * GET /Threads/:id/media/count (Thread.prototype.countMedia)
    * Counts Thread media.
    */
    getMediaCount = async (threadId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Threads/${threadId}/media/count`, { params: { where }})

}
export class ThreadMessageRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /ThreadMessages/:id/reactions (ThreadMessage.prototype.__get__reactions)
    * Queries reactions of ThreadMessage.
    */
    getReactions = async (threadMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataReaction[]>(`/ThreadMessages/${threadMessageId}/reactions`, { params: { filter }})

    /**
    * GET /ThreadMessages/:id/reactions/count (ThreadMessage.prototype.__count__reactions)
    * Counts reactions of ThreadMessage.
    */
    getReactionsCount = async (threadMessageId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/ThreadMessages/${threadMessageId}/reactions/count`, { params: { where }})

    /**
    * GET /ThreadMessages/:id/media (ThreadMessage.prototype.__get__media)
    * Queries media of ThreadMessage.
    */
    getMedia = async (threadMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMedia[]>(`/ThreadMessages/${threadMessageId}/media`, { params: { filter }})

    /**
    * GET /ThreadMessages/:id/media/count (ThreadMessage.prototype.__count__media)
    * Counts media of ThreadMessage.
    */
    getMediaCount = async (threadMessageId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/ThreadMessages/${threadMessageId}/media/count`, { params: { where }})

    /**
    * GET /ThreadMessages/:id/reactedByThrough (ThreadMessage.prototype.__get__reactedByThrough)
    * Queries reactedByThrough of ThreadMessage.
    */
    getReactedByThrough = async (threadMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataReactionUserThrough[]>(`/ThreadMessages/${threadMessageId}/reactedByThrough`, { params: { filter }})

    /**
    * GET /ThreadMessages/:id/reactedByThrough/count (ThreadMessage.prototype.__count__reactedByThrough)
    * Counts reactedByThrough of ThreadMessage.
    */
    getReactedByThroughCount = async (threadMessageId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/ThreadMessages/${threadMessageId}/reactedByThrough/count`, { params: { where }})

    /**
    * GET /ThreadMessages/:id (ThreadMessage.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (threadMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataThreadMessage>(`/ThreadMessages/${threadMessageId}`, { params: { filter }})

    /**
    * PUT /ThreadMessages/:id (ThreadMessage.prototype.updateAttributes)
    * Patch attributes for a model instance and persist it into the data source.
    */
    putById = async (threadMessageId: number, data?: APIDataObject) => this.getAgent().put<APIDataThreadMessage>(`/ThreadMessages/${threadMessageId}`, data)

    /**
    * POST /ThreadMessages/:id/mediaUpload (ThreadMessage.prototype.postMediaUpload)
    * Upload an image or video for a ThreadMessage.
    */
    postMediaUpload = async (threadMessageId: number, data: FormData, meta?: APIDataObject) => this.getAgent().post<APIDataMedia>(`/ThreadMessages/${threadMessageId}/mediaUpload`, data, { params: { meta }, headers: {'Content-Type': 'multipart/form-data'}})

    /**
    * POST /ThreadMessages/:id/mediaUploadAndPublish (ThreadMessage.prototype.postMediaUploadAndPublish)
    * Upload an image or video for a ThreadMessage, and Publish the message.
    */
    postMediaUploadAndPublish = async (threadMessageId: number, data: FormData, meta?: APIDataObject) => this.getAgent().post<APIDataObject>(`/ThreadMessages/${threadMessageId}/mediaUploadAndPublish`, data, { params: { meta }, headers: {'Content-Type': 'multipart/form-data'}})

    /**
    * DEL /ThreadMessages/:id/media/:fk (ThreadMessage.prototype.deleteMedia)
    * Delete an image or video for a ThreadMessage.
    */
    delMediaById = async (threadMessageId: number, fk?: number) => this.getAgent().del<APIDataObject>(`/ThreadMessages/${threadMessageId}/media/${fk}`)

    /**
    * POST /ThreadMessages/:id/reads (ThreadMessage.prototype.createRead)
    * Read a ThreadMessage.
    */
    postReads = async (threadMessageId: number) => this.getAgent().post<APIDataObject>(`/ThreadMessages/${threadMessageId}/reads`, undefined)

    /**
    * PUT /ThreadMessages/:id/retract (ThreadMessage.prototype.retract)
    * Retract ThreadMessage
    */
    putRetract = async (threadMessageId: number) => this.getAgent().put<APIDataThreadMessage>(`/ThreadMessages/${threadMessageId}/retract`, undefined)

    /**
    * POST /ThreadMessages/:id/report (ThreadMessage.prototype.report)
    * Report ThreadMessage
    */
    postReport = async (threadMessageId: number, report?: APIDataObject) => this.getAgent().post<APIDataReport>(`/ThreadMessages/${threadMessageId}/report`, report)

    /**
    * PUT /ThreadMessages/:id/reactions (ThreadMessage.prototype.createReaction)
    * Add a Reaction.
    */
    putReactions = async (threadMessageId: number, text: string) => this.getAgent().put<APIDataReaction>(`/ThreadMessages/${threadMessageId}/reactions`, undefined, { params: { text }})

    /**
    * DEL /ThreadMessages/:id/reactions (ThreadMessage.prototype.deleteReaction)
    * Delete a Reaction.
    */
    delReactions = async (threadMessageId: number, text: string) => this.getAgent().del<APIDataReaction>(`/ThreadMessages/${threadMessageId}/reactions`, { params: { text }})

    /**
    * POST /ThreadMessages/:id/action (ThreadMessage.prototype.postAction)
    * Message action
    */
    postAction = async (threadMessageId: number, data: {  action: string,  value: APIDataObject }) => this.getAgent().post<APIDataObject>(`/ThreadMessages/${threadMessageId}/action`, data)

    /**
    * GET /ThreadMessages/:id/reads (ThreadMessage.prototype.getReadsRemote)
    * Get Reads.
    */
    getReads = async (threadMessageId: number, filter?: APIDataObject) => this.getAgent().get<APIDataRead[]>(`/ThreadMessages/${threadMessageId}/reads`, { params: { filter }})

    /**
    * GET /ThreadMessages/:id/reads/count (ThreadMessage.prototype.getReadsCountRemote)
    * Counts Reads.
    */
    getReadsCount = async (threadMessageId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/ThreadMessages/${threadMessageId}/reads/count`, { params: { where }})

    /**
    * POST /ThreadMessages/:id/media/upload/start (ThreadMessage.prototype.startMultiPartUpload)
    * Upload an image or video for a ThreadMessage with Multi-part upload.
    */
    postMediaUploadStart = async (threadMessageId: number, params: {  fileName: string,  mimeType: string,  meta: APIDataObject }) => this.getAgent().post<APIDataMediaMultiPartUpload>(`/ThreadMessages/${threadMessageId}/media/upload/start`, params)

    /**
    * PUT /ThreadMessages/:id/publish (ThreadMessage.prototype.publish)
    * Publish Model
    */
    putPublish = async (threadMessageId: number) => this.getAgent().put<APIDataObject>(`/ThreadMessages/${threadMessageId}/publish`, undefined)

}
export class BotRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /Bots/media (Bot.getMedia)
    * Get Media objects for the bot.
    */
    getMedia = async (filter?: APIDataObject) => this.getAgent().get<APIDataMedia>(`/Bots/media`, { params: { filter }})

    /**
    * POST /Bots/media/upload (Bot.uploadMedia)
    * Upload profile image to user.
    */
    postMediaUpload = async (data: FormData, meta?: APIDataObject) => this.getAgent().post<APIDataMedia>(`/Bots/media/upload`, data, { params: { meta }, headers: {'Content-Type': 'multipart/form-data'}})

    /**
    * POST /Bots/messages (Bot.sendBotMessageToUsers)
    * undefined
    */
    postMessages = async (body: { userIds: number[], message: APIDataThreadMessage, mediaIds: number[] }) => this.getAgent().post<APIDataObject>(`/Bots/messages`, body)

    /**
    * GET /Bots/media/download/:fileKey (Bot.getMediaDownload)
    * Download an image or video for Bot.
    */
    getMediaDownloadById = async (fileKey?: string) => this.getAgent().get<FormData>(`/Bots/media/download/${fileKey}`)

    /**
    * GET /Bots/media/count (Bot.countMedia)
    * Count instances of Media matched by where from the data source.
    */
    getMediaCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Bots/media/count`, { params: { where }})

    /**
    * POST /Bots/media/upload/start (Bot.startMultiPartUpload)
    * Upload profile image to user.
    */
    postMediaUploadStart = async (params: {  fileName: string,  mimeType: string,  meta: APIDataObject }) => this.getAgent().post<APIDataMediaMultiPartUpload>(`/Bots/media/upload/start`, params)

}
export class DepartmentUserThroughRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /DepartmentUserThrough (DepartmentUserThrough.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentUserThrough[]>(`/DepartmentUserThrough`, { params: { filter }})

    /**
    * GET /DepartmentUserThrough/count (DepartmentUserThrough.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DepartmentUserThrough/count`, { params: { where }})

}
export class LinkPreviewRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /LinkPreview (LinkPreview.getPreviews)
    * Get link previews
    */
    get = async (links: string[]) => this.getAgent().get<APIDataThread>(`/LinkPreview`, { params: { links }})

}
export class AppVersionRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /AppVersion/check (AppVersion.check)
    * Check if your current device is up to date
    */
    getCheck = async () => this.getAgent().get<APIDataObject>(`/AppVersion/check`)

}
export class EventLogRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /EventLogs/:id (EventLog.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (eventLogId: number, filter?: APIDataObject) => this.getAgent().get<APIDataEventLog>(`/EventLogs/${eventLogId}`, { params: { filter }})

    /**
    * GET /EventLogs (EventLog.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataEventLog[]>(`/EventLogs`, { params: { filter }})

    /**
    * GET /EventLogs/count (EventLog.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/EventLogs/count`, { params: { where }})

}
export class MailchimpRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * POST /Mailchimp/updateUsersList (Mailchimp.updateUsersListRemote)
    * Sync mailchimp list with our list of users
    */
    postUpdateUsersList = async (body: APIDataObject) => this.getAgent().post<APIDataObject>(`/Mailchimp/updateUsersList`, body)

}
export class DepartmentCRMRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /DepartmentCRM/:id/parent (DepartmentCRM.prototype.__get__parent)
    * Fetches belongsTo relation parent.
    */
    getParent = async (departmentCrmId: number, refresh?: boolean) => this.getAgent().get<APIDataDepartmentCRM>(`/DepartmentCRM/${departmentCrmId}/parent`, { params: { refresh }})

    /**
    * GET /DepartmentCRM/:id/history/:fk (DepartmentCRM.prototype.__findById__history)
    * Find a related item by id for history.
    */
    getHistoryById = async (departmentCrmId: number, fk: number) => this.getAgent().get<APIDataDepartmentCRMHistory>(`/DepartmentCRM/${departmentCrmId}/history/${fk}`)

    /**
    * GET /DepartmentCRM/:id/children (DepartmentCRM.prototype.__get__children)
    * Queries children of DepartmentCRM.
    */
    getChildren = async (departmentCrmId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentCRM[]>(`/DepartmentCRM/${departmentCrmId}/children`, { params: { filter }})

    /**
    * GET /DepartmentCRM/:id/children/count (DepartmentCRM.prototype.__count__children)
    * Counts children of DepartmentCRM.
    */
    getChildrenCount = async (departmentCrmId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DepartmentCRM/${departmentCrmId}/children/count`, { params: { where }})

    /**
    * GET /DepartmentCRM/:id/history (DepartmentCRM.prototype.__get__history)
    * Queries history of DepartmentCRM.
    */
    getHistory = async (departmentCrmId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentCRMHistory[]>(`/DepartmentCRM/${departmentCrmId}/history`, { params: { filter }})

    /**
    * GET /DepartmentCRM/:id/history/count (DepartmentCRM.prototype.__count__history)
    * Counts history of DepartmentCRM.
    */
    getHistoryCount = async (departmentCrmId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DepartmentCRM/${departmentCrmId}/history/count`, { params: { where }})

    /**
    * GET /DepartmentCRM/:id (DepartmentCRM.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (departmentCrmId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentCRM>(`/DepartmentCRM/${departmentCrmId}`, { params: { filter }})

    /**
    * GET /DepartmentCRM (DepartmentCRM.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentCRM[]>(`/DepartmentCRM`, { params: { filter }})

    /**
    * GET /DepartmentCRM/count (DepartmentCRM.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DepartmentCRM/count`, { params: { where }})

    /**
    * POST /DepartmentCRM (DepartmentCRM.createCRMData)
    * Create a new instance of the model and persist it into the data source.
    */
    post = async (data?: APIDataObject) => this.getAgent().post<APIDataDepartmentCRM>(`/DepartmentCRM`, data)

    /**
    * DEL /DepartmentCRM/:id (DepartmentCRM.destroyDepartmentCRM)
    * Delete a model instance by {{id}} from the data source.
    */
    delById = async (departmentCrmId: number) => this.getAgent().del<APIDataObject>(`/DepartmentCRM/${departmentCrmId}`)

    /**
    * PUT /DepartmentCRM/:id (DepartmentCRM.prototype.updateDepartmentCRM)
    * Patch attributes for a model instance and persist it into the data source.
    */
    putById = async (departmentCrmId: number, data?: APIDataObject) => this.getAgent().put<APIDataDepartmentCRM>(`/DepartmentCRM/${departmentCrmId}`, data)

    /**
    * POST /DepartmentCRM/:id/children (DepartmentCRM.prototype.createChild)
    * Creates a new instance in DepartmentCRM of this model.
    */
    postChildren = async (departmentCrmId: number, data?: APIDataObject) => this.getAgent().post<APIDataDepartmentCRM>(`/DepartmentCRM/${departmentCrmId}/children`, data)

    /**
    * DEL /DepartmentCRM/:id/children/:fk (DepartmentCRM.prototype.destroyByIdChildren)
    * Delete a related item by id for children.
    */
    delChildrenById = async (departmentCrmId: number, fk: number) => this.getAgent().del<never>(`/DepartmentCRM/${departmentCrmId}/children/${fk}`)

    /**
    * PUT /DepartmentCRM/:id/children/:fk (DepartmentCRM.prototype.updateByIdChildren)
    * Update a related item by id for children.
    */
    putChildrenById = async (departmentCrmId: number, fk: number, data?: APIDataObject) => this.getAgent().put<APIDataDepartmentCRM>(`/DepartmentCRM/${departmentCrmId}/children/${fk}`, data)

    /**
    * GET /DepartmentCRM/departments (DepartmentCRM.getDepartmentsForCRMRemote)
    * Returns Departments filtered by matching DepartmentCRM models.
    */
    getDepartments = async (departmentCRMWhere?: APIDataObject, departmentFilter?: APIDataObject) => this.getAgent().get<APIDataDepartment[]>(`/DepartmentCRM/departments`, { params: { departmentCRMWhere, departmentFilter }})

    /**
    * GET /DepartmentCRM/departments/count (DepartmentCRM.getDepartmentsForCRMCountRemote)
    * Count Departments filtered by matching DepartmentCRM models.
    */
    getDepartmentsCount = async (departmentCRMWhere?: APIDataObject, departmentWhere?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DepartmentCRM/departments/count`, { params: { departmentCRMWhere, departmentWhere }})

    /**
    * GET /DepartmentCRM/keys/unique (DepartmentCRM.getUniqueKeysRemote)
    * Get a list of unique DepartmentCRM keys.
    */
    getKeysUnique = async () => this.getAgent().get<APIDataObject[]>(`/DepartmentCRM/keys/unique`)

}
export class UserCRMRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /UserCRM/:id/parent (UserCRM.prototype.__get__parent)
    * Fetches belongsTo relation parent.
    */
    getParent = async (userCrmId: number, refresh?: boolean) => this.getAgent().get<APIDataUserCRM>(`/UserCRM/${userCrmId}/parent`, { params: { refresh }})

    /**
    * GET /UserCRM/:id/history/:fk (UserCRM.prototype.__findById__history)
    * Find a related item by id for history.
    */
    getHistoryById = async (userCrmId: number, fk: number) => this.getAgent().get<APIDataUserCRMHistory>(`/UserCRM/${userCrmId}/history/${fk}`)

    /**
    * GET /UserCRM/:id/children (UserCRM.prototype.__get__children)
    * Queries children of UserCRM.
    */
    getChildren = async (userCrmId: number, filter?: APIDataObject) => this.getAgent().get<APIDataUserCRM[]>(`/UserCRM/${userCrmId}/children`, { params: { filter }})

    /**
    * GET /UserCRM/:id/children/count (UserCRM.prototype.__count__children)
    * Counts children of UserCRM.
    */
    getChildrenCount = async (userCrmId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/UserCRM/${userCrmId}/children/count`, { params: { where }})

    /**
    * GET /UserCRM/:id/history (UserCRM.prototype.__get__history)
    * Queries history of UserCRM.
    */
    getHistory = async (userCrmId: number, filter?: APIDataObject) => this.getAgent().get<APIDataUserCRMHistory[]>(`/UserCRM/${userCrmId}/history`, { params: { filter }})

    /**
    * GET /UserCRM/:id/history/count (UserCRM.prototype.__count__history)
    * Counts history of UserCRM.
    */
    getHistoryCount = async (userCrmId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/UserCRM/${userCrmId}/history/count`, { params: { where }})

    /**
    * GET /UserCRM/:id (UserCRM.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (userCrmId: number, filter?: APIDataObject) => this.getAgent().get<APIDataUserCRM>(`/UserCRM/${userCrmId}`, { params: { filter }})

    /**
    * GET /UserCRM (UserCRM.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataUserCRM[]>(`/UserCRM`, { params: { filter }})

    /**
    * GET /UserCRM/count (UserCRM.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/UserCRM/count`, { params: { where }})

    /**
    * POST /UserCRM (UserCRM.createCRMData)
    * Create a new instance of the model and persist it into the data source.
    */
    post = async (data?: APIDataObject) => this.getAgent().post<APIDataUserCRM>(`/UserCRM`, data)

    /**
    * DEL /UserCRM/:id (UserCRM.destroyUserCRM)
    * Delete a model instance by {{id}} from the data source.
    */
    delById = async (userCrmId: number) => this.getAgent().del<APIDataObject>(`/UserCRM/${userCrmId}`)

    /**
    * PUT /UserCRM/:id (UserCRM.prototype.updateUserCRM)
    * Patch attributes for a model instance and persist it into the data source.
    */
    putById = async (userCrmId: number, data?: APIDataObject) => this.getAgent().put<APIDataUserCRM>(`/UserCRM/${userCrmId}`, data)

    /**
    * POST /UserCRM/:id/children (UserCRM.prototype.createChild)
    * Creates a new instance in UserCRM of this model.
    */
    postChildren = async (userCrmId: number, data?: APIDataObject) => this.getAgent().post<APIDataUserCRM>(`/UserCRM/${userCrmId}/children`, data)

    /**
    * DEL /UserCRM/:id/children/:fk (UserCRM.prototype.destroyByIdChildren)
    * Delete a related item by id for children.
    */
    delChildrenById = async (userCrmId: number, fk: number) => this.getAgent().del<never>(`/UserCRM/${userCrmId}/children/${fk}`)

    /**
    * PUT /UserCRM/:id/children/:fk (UserCRM.prototype.updateByIdChildren)
    * Update a related item by id for children.
    */
    putChildrenById = async (userCrmId: number, fk: number, data?: APIDataObject) => this.getAgent().put<APIDataUserCRM>(`/UserCRM/${userCrmId}/children/${fk}`, data)

    /**
    * GET /UserCRM/users (UserCRM.getUsersForCRMRemote)
    * Returns BlueUsers filtered by matching UserCRM models.
    */
    getUsers = async (userCRMWhere?: APIDataObject, userFilter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/UserCRM/users`, { params: { userCRMWhere, userFilter }})

    /**
    * GET /UserCRM/users/count (UserCRM.getUsersForCRMCountRemote)
    * Count BlueUsers filtered by matching UserCRM models.
    */
    getUsersCount = async (userCRMWhere?: APIDataObject, blueUserWhere?: APIDataObject) => this.getAgent().get<APIDataCount>(`/UserCRM/users/count`, { params: { userCRMWhere, blueUserWhere }})

    /**
    * GET /UserCRM/keys/unique (UserCRM.getUniqueKeysRemote)
    * Get a list of unique UserCRM keys.
    */
    getKeysUnique = async () => this.getAgent().get<APIDataObject[]>(`/UserCRM/keys/unique`)

}
export class AppRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * POST /App/logError (App.postError)
    * Log Error.
    */
    postLogError = async (msg?: string, data?: APIDataObject) => this.getAgent().post<APIDataObject>(`/App/logError`, undefined, { params: { msg, data }})

    /**
    * POST /App/log (App.postLog)
    * Log.
    */
    postLog = async (msg?: string, data?: APIDataObject) => this.getAgent().post<APIDataObject>(`/App/log`, undefined, { params: { msg, data }})

    /**
    * POST /App/feedback (App.postFeedback)
    * Send Feedback.
    */
    postFeedback = async (msg?: string, data?: APIDataObject) => this.getAgent().post<APIDataObject>(`/App/feedback`, undefined, { params: { msg, data }})

}
export class BotCampaignRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /BotCampaigns/:id/history/:fk (BotCampaign.prototype.__findById__history)
    * Find a related item by id for history.
    */
    getHistoryById = async (botCampaignId: number, fk: number) => this.getAgent().get<APIDataBotCampaignHistory>(`/BotCampaigns/${botCampaignId}/history/${fk}`)

    /**
    * GET /BotCampaigns/:id/history (BotCampaign.prototype.__get__history)
    * Queries history of BotCampaign.
    */
    getHistory = async (botCampaignId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBotCampaignHistory[]>(`/BotCampaigns/${botCampaignId}/history`, { params: { filter }})

    /**
    * GET /BotCampaigns/:id/history/count (BotCampaign.prototype.__count__history)
    * Counts history of BotCampaign.
    */
    getHistoryCount = async (botCampaignId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BotCampaigns/${botCampaignId}/history/count`, { params: { where }})

    /**
    * GET /BotCampaigns/:id/threadMessages (BotCampaign.prototype.__get__threadMessages)
    * Queries threadMessages of BotCampaign.
    */
    getThreadMessages = async (botCampaignId: number, filter?: APIDataObject) => this.getAgent().get<APIDataThreadMessage[]>(`/BotCampaigns/${botCampaignId}/threadMessages`, { params: { filter }})

    /**
    * GET /BotCampaigns/:id/threadMessages/count (BotCampaign.prototype.__count__threadMessages)
    * Counts threadMessages of BotCampaign.
    */
    getThreadMessagesCount = async (botCampaignId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BotCampaigns/${botCampaignId}/threadMessages/count`, { params: { where }})

    /**
    * GET /BotCampaigns/:id (BotCampaign.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (botCampaignId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBotCampaign>(`/BotCampaigns/${botCampaignId}`, { params: { filter }})

    /**
    * GET /BotCampaigns (BotCampaign.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataBotCampaign[]>(`/BotCampaigns`, { params: { filter }})

    /**
    * GET /BotCampaigns/count (BotCampaign.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/BotCampaigns/count`, { params: { where }})

    /**
    * GET /BotCampaigns/:id/preview (BotCampaign.prototype.getPreview)
    * returns a list of users that will be targeted by this camping.
    */
    getPreview = async (botCampaignId: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser[]>(`/BotCampaigns/${botCampaignId}/preview`, { params: { filter }})

    /**
    * GET /BotCampaigns/:id/preview/count (BotCampaign.prototype.countPreview)
    * Count BlueUser targeted by this campaign
    */
    getPreviewCount = async (botCampaignId: number) => this.getAgent().get<APIDataCount>(`/BotCampaigns/${botCampaignId}/preview/count`)

    /**
    * GET /BotCampaigns/:id/run (BotCampaign.prototype.run)
    * Run.
    */
    getRun = async (botCampaignId: number) => this.getAgent().get<APIDataBotCampaign>(`/BotCampaigns/${botCampaignId}/run`)

    /**
    * POST /BotCampaigns/run/scheduled (BotCampaign.runScheduledCampaigns)
    * Run scheduled campaigns.
    */
    postRunScheduled = async (body: APIDataObject) => this.getAgent().post<APIDataObject>(`/BotCampaigns/run/scheduled`, body)

    /**
    * POST /BotCampaigns (BotCampaign.createBotCampaign)
    * Create a new instance of the model and persist it into the data source.
    */
    post = async (data?: APIDataObject) => this.getAgent().post<APIDataBotCampaign>(`/BotCampaigns`, data)

    /**
    * DEL /BotCampaigns/:id (BotCampaign.destroyBotCampaign)
    * Delete a model instance by {{id}} from the data source.
    */
    delById = async (botCampaignId: number) => this.getAgent().del<APIDataObject>(`/BotCampaigns/${botCampaignId}`)

    /**
    * PUT /BotCampaigns/:id (BotCampaign.prototype.updateBotCampaign)
    * Patch attributes for a model instance and persist it into the data source.
    */
    putById = async (botCampaignId: number, data?: APIDataObject) => this.getAgent().put<APIDataBotCampaign>(`/BotCampaigns/${botCampaignId}`, data)

}
export class ReportsRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /Reports/verifiedUserPerMonth (Reports.getVerifiedUsersPerMonth)
    * undefined
    */
    getVerifiedUserPerMonth = async (startDate?: Date, endDate?: Date) => this.getAgent().get<APIDataAny[]>(`/Reports/verifiedUserPerMonth`, { params: { startDate, endDate }})

    /**
    * GET /Reports/platform/activity (Reports.getPlatformActivity)
    * undefined
    */
    getPlatformActivity = async (startDate?: Date, endDate?: Date) => this.getAgent().get<APIDataAny[]>(`/Reports/platform/activity`, { params: { startDate, endDate }})

    /**
    * GET /Reports/users/activity (Reports.getUserActivity)
    * Count number of user active in date range, defaults to last 15 min
    */
    getUsersActivity = async (startDate?: Date, endDate?: Date) => this.getAgent().get<APIDataAny[]>(`/Reports/users/activity`, { params: { startDate, endDate }})

    /**
    * GET /Reports/redis/info (Reports.redisInfo)
    * undefined
    */
    getRedisInfo = async () => this.getAgent().get<APIDataObject>(`/Reports/redis/info`)

}
export class DepartmentLinkedThroughRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /DepartmentLinkedThrough/:id/parentDepartment (DepartmentLinkedThrough.prototype.__get__parentDepartment)
    * Fetches belongsTo relation parentDepartment.
    */
    getParentDepartment = async (departmentLinkedThroughId: number, refresh?: boolean) => this.getAgent().get<APIDataDepartment>(`/DepartmentLinkedThrough/${departmentLinkedThroughId}/parentDepartment`, { params: { refresh }})

    /**
    * GET /DepartmentLinkedThrough/:id/childDepartment (DepartmentLinkedThrough.prototype.__get__childDepartment)
    * Fetches belongsTo relation childDepartment.
    */
    getChildDepartment = async (departmentLinkedThroughId: number, refresh?: boolean) => this.getAgent().get<APIDataDepartment>(`/DepartmentLinkedThrough/${departmentLinkedThroughId}/childDepartment`, { params: { refresh }})

    /**
    * POST /DepartmentLinkedThrough (DepartmentLinkedThrough.create)
    * Create a new instance of the model and persist it into the data source.
    */
    post = async (data?: APIDataObject) => this.getAgent().post<APIDataDepartmentLinkedThrough>(`/DepartmentLinkedThrough`, data)

    /**
    * GET /DepartmentLinkedThrough/:id (DepartmentLinkedThrough.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (departmentLinkedThroughId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentLinkedThrough>(`/DepartmentLinkedThrough/${departmentLinkedThroughId}`, { params: { filter }})

    /**
    * GET /DepartmentLinkedThrough (DepartmentLinkedThrough.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataDepartmentLinkedThrough[]>(`/DepartmentLinkedThrough`, { params: { filter }})

    /**
    * DEL /DepartmentLinkedThrough/:id (DepartmentLinkedThrough.deleteById)
    * Delete a model instance by {{id}} from the data source.
    */
    delById = async (departmentLinkedThroughId: number) => this.getAgent().del<APIDataObject>(`/DepartmentLinkedThrough/${departmentLinkedThroughId}`)

    /**
    * GET /DepartmentLinkedThrough/count (DepartmentLinkedThrough.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DepartmentLinkedThrough/count`, { params: { where }})

    /**
    * PUT /DepartmentLinkedThrough/:id (DepartmentLinkedThrough.prototype.updateAttributes)
    * Patch attributes for a model instance and persist it into the data source.
    */
    putById = async (departmentLinkedThroughId: number, data?: APIDataObject) => this.getAgent().put<APIDataDepartmentLinkedThrough>(`/DepartmentLinkedThrough/${departmentLinkedThroughId}`, data)

}
export class BanIpRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /BanIp/denied (BanIp.listDenied)
    * undefined
    */
    getDenied = async () => this.getAgent().get<APIDataObject>(`/BanIp/denied`)

    /**
    * DEL /BanIp/denied/:id (BanIp.deleteDenied)
    * undefined
    */
    delDeniedById = async (banIpId: number) => this.getAgent().del<APIDataObject>(`/BanIp/denied/${banIpId}`)

    /**
    * POST /BanIp/denied (BanIp.createDenied)
    * undefined
    */
    postDenied = async (ip?: string, data?: APIDataObject, banSeconds?: number) => this.getAgent().post<APIDataObject>(`/BanIp/denied`, undefined, { params: { ip, data, banSeconds }})

    /**
    * GET /BanIp/allowed (BanIp.listAllowed)
    * undefined
    */
    getAllowed = async () => this.getAgent().get<APIDataObject>(`/BanIp/allowed`)

    /**
    * DEL /BanIp/allowed/:id (BanIp.deleteAllowed)
    * undefined
    */
    delAllowedById = async (banIpId: number) => this.getAgent().del<APIDataObject>(`/BanIp/allowed/${banIpId}`)

    /**
    * POST /BanIp/allowed (BanIp.createAllowed)
    * undefined
    */
    postAllowed = async (ip?: string, reason?: string) => this.getAgent().post<APIDataObject>(`/BanIp/allowed`, undefined, { params: { ip, reason }})

}
export class VerifiedEmailDomainRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /VerifiedEmailDomains (VerifiedEmailDomain.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataVerifiedEmailDomain[]>(`/VerifiedEmailDomains`, { params: { filter }})

    /**
    * GET /VerifiedEmailDomains/count (VerifiedEmailDomain.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/VerifiedEmailDomains/count`, { params: { where }})

    /**
    * POST /VerifiedEmailDomains (VerifiedEmailDomain.createDomain)
    * Create a new verified email domain
    */
    post = async (data: {  id: string,  meta: APIDataObject }) => this.getAgent().post<APIDataObject>(`/VerifiedEmailDomains`, data)

    /**
    * GET /VerifiedEmailDomains/:domain (VerifiedEmailDomain.findByDomain)
    * Find One
    */
    getById = async (domain: string, filter?: APIDataObject) => this.getAgent().get<APIDataObject>(`/VerifiedEmailDomains/${domain}`, { params: { filter }})

    /**
    * DEL /VerifiedEmailDomains/:domain (VerifiedEmailDomain.destroy)
    * undefined
    */
    delById = async (domain: string) => this.getAgent().del<APIDataObject>(`/VerifiedEmailDomains/${domain}`)

    /**
    * POST /VerifiedEmailDomains/:domain/departments/:departmentId (VerifiedEmailDomain.linkDepartment)
    * Link a department
    */
    postDepartmentsById = async (domain: string, departmentId: string) => this.getAgent().post<APIDataVerifiedEmailDomain>(`/VerifiedEmailDomains/${domain}/departments/${departmentId}`, undefined)

    /**
    * DEL /VerifiedEmailDomains/:domain/departments/:departmentId (VerifiedEmailDomain.unlinkDepartment)
    * Un-link a department
    */
    delDepartmentsById = async (domain: string, departmentId: string) => this.getAgent().del<APIDataVerifiedEmailDomain>(`/VerifiedEmailDomains/${domain}/departments/${departmentId}`)

    /**
    * GET /VerifiedEmailDomains/:domain/departments (VerifiedEmailDomain.getDepartments)
    * Get departments by domain
    */
    getDepartments = async (domain: string, filter?: APIDataObject) => this.getAgent().get<APIDataDepartment[]>(`/VerifiedEmailDomains/${domain}/departments`, { params: { filter }})

    /**
    * GET /VerifiedEmailDomains/:domain/departments/count (VerifiedEmailDomain.getDepartmentsCount)
    * Count departments
    */
    getDepartmentsCount = async (domain: string, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/VerifiedEmailDomains/${domain}/departments/count`, { params: { where }})

    /**
    * PUT /VerifiedEmailDomains/:domain (VerifiedEmailDomain.updateAttributes)
    * Update attributes for a VerifiedEmailDomain instance
    */
    putById = async (domain: string, data: APIDataObject) => this.getAgent().put<APIDataObject>(`/VerifiedEmailDomains/${domain}`, data)

}
export class DocumentSchemaRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /DocumentSchemas/:id/history/:fk (DocumentSchema.prototype.__findById__history)
    * Find a related item by id for history.
    */
    getHistoryById = async (documentSchemaId: number, fk: number) => this.getAgent().get<APIDataDocumentSchemaHistory>(`/DocumentSchemas/${documentSchemaId}/history/${fk}`)

    /**
    * GET /DocumentSchemas/:id/documents/:fk (DocumentSchema.prototype.__findById__documents)
    * Find a related item by id for documents.
    */
    getDocumentsById = async (documentSchemaId: number, fk: number) => this.getAgent().get<APIDataDocument>(`/DocumentSchemas/${documentSchemaId}/documents/${fk}`)

    /**
    * GET /DocumentSchemas/:id/media/:fk (DocumentSchema.prototype.__findById__media)
    * Find a related item by id for media.
    */
    getMediaById = async (documentSchemaId: number, fk: number) => this.getAgent().get<APIDataMedia>(`/DocumentSchemas/${documentSchemaId}/media/${fk}`)

    /**
    * DEL /DocumentSchemas/:id/media/:fk (DocumentSchema.prototype.__destroyById__media)
    * Delete a related item by id for media.
    */
    delMediaById = async (documentSchemaId: number, fk: number) => this.getAgent().del<never>(`/DocumentSchemas/${documentSchemaId}/media/${fk}`)

    /**
    * GET /DocumentSchemas/:id/history (DocumentSchema.prototype.__get__history)
    * Queries history of DocumentSchema.
    */
    getHistory = async (documentSchemaId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchemaHistory[]>(`/DocumentSchemas/${documentSchemaId}/history`, { params: { filter }})

    /**
    * GET /DocumentSchemas/:id/history/count (DocumentSchema.prototype.__count__history)
    * Counts history of DocumentSchema.
    */
    getHistoryCount = async (documentSchemaId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DocumentSchemas/${documentSchemaId}/history/count`, { params: { where }})

    /**
    * GET /DocumentSchemas/:id/documents (DocumentSchema.prototype.__get__documents)
    * Queries documents of DocumentSchema.
    */
    getDocuments = async (documentSchemaId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocument[]>(`/DocumentSchemas/${documentSchemaId}/documents`, { params: { filter }})

    /**
    * GET /DocumentSchemas/:id/documents/count (DocumentSchema.prototype.__count__documents)
    * Counts documents of DocumentSchema.
    */
    getDocumentsCount = async (documentSchemaId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DocumentSchemas/${documentSchemaId}/documents/count`, { params: { where }})

    /**
    * GET /DocumentSchemas/:id/media (DocumentSchema.prototype.__get__media)
    * Queries media of DocumentSchema.
    */
    getMedia = async (documentSchemaId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMedia[]>(`/DocumentSchemas/${documentSchemaId}/media`, { params: { filter }})

    /**
    * GET /DocumentSchemas/:id/media/count (DocumentSchema.prototype.__count__media)
    * Counts media of DocumentSchema.
    */
    getMediaCount = async (documentSchemaId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DocumentSchemas/${documentSchemaId}/media/count`, { params: { where }})

    /**
    * GET /DocumentSchemas/:id/categories (DocumentSchema.prototype.__get__categories)
    * Queries categories of DocumentSchema.
    */
    getCategories = async (documentSchemaId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchemaCategory[]>(`/DocumentSchemas/${documentSchemaId}/categories`, { params: { filter }})

    /**
    * GET /DocumentSchemas/:id/categories/count (DocumentSchema.prototype.__count__categories)
    * Counts categories of DocumentSchema.
    */
    getCategoriesCount = async (documentSchemaId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DocumentSchemas/${documentSchemaId}/categories/count`, { params: { where }})

    /**
    * GET /DocumentSchemas/:id/revisions (DocumentSchema.prototype.__get__revisions)
    * Queries revisions of DocumentSchema.
    */
    getRevisions = async (documentSchemaId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchemaRevision[]>(`/DocumentSchemas/${documentSchemaId}/revisions`, { params: { filter }})

    /**
    * GET /DocumentSchemas/:id/revisions/count (DocumentSchema.prototype.__count__revisions)
    * Counts revisions of DocumentSchema.
    */
    getRevisionsCount = async (documentSchemaId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DocumentSchemas/${documentSchemaId}/revisions/count`, { params: { where }})

    /**
    * POST /DocumentSchemas (DocumentSchema.create)
    * Create a new instance of the model and persist it into the data source.
    */
    post = async (data?: APIDataObject) => this.getAgent().post<APIDataDocumentSchema>(`/DocumentSchemas`, data)

    /**
    * GET /DocumentSchemas/:id (DocumentSchema.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (documentSchemaId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchema>(`/DocumentSchemas/${documentSchemaId}`, { params: { filter }})

    /**
    * POST /DocumentSchemas/:id/media/upload (DocumentSchema.prototype.postMediaUpload)
    * Upload media.
    */
    postMediaUpload = async (documentSchemaId: number, data: FormData, meta?: APIDataObject) => this.getAgent().post<APIDataMedia>(`/DocumentSchemas/${documentSchemaId}/media/upload`, data, { params: { meta }, headers: {'Content-Type': 'multipart/form-data'}})

    /**
    * GET /DocumentSchemas/:id/media/download/:fileKey (DocumentSchema.prototype.getMediaDownload)
    * Download media.
    */
    getMediaDownloadById = async (documentSchemaId: number, fileKey?: string, ver?: string) => this.getAgent().get<FormData>(`/DocumentSchemas/${documentSchemaId}/media/download/${fileKey}`, { params: { ver }})

    /**
    * DEL /DocumentSchemas/:id (DocumentSchema.prototype.deleteDocumentSchema)
    * Delete DocumentSchema.
    */
    delById = async (documentSchemaId: number) => this.getAgent().del<APIDataObject>(`/DocumentSchemas/${documentSchemaId}`)

    /**
    * POST /DocumentSchemas/:id/clone (DocumentSchema.prototype.cloneRemote)
    * Clone schema.
    */
    postClone = async (documentSchemaId: number, departmentId: string, name?: string) => this.getAgent().post<APIDataDocumentSchema>(`/DocumentSchemas/${documentSchemaId}/clone`, undefined, { params: { departmentId, name }})

    /**
    * GET /DocumentSchemas/search (DocumentSchema.searchRemote)
    * Search DocumentSchemas, returns a list of DocumentSchemas that the context user has access to.
    */
    getSearch = async (filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchema[]>(`/DocumentSchemas/search`, { params: { filter }})

    /**
    * GET /DocumentSchemas/search/count (DocumentSchema.searchCountRemote)
    * Count Search DocumentSchemas, returns a count of DocumentSchemas that the context user has access to.
    */
    getSearchCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DocumentSchemas/search/count`, { params: { where }})

    /**
    * PUT /DocumentSchemas/:id/categories/:categoryId (DocumentSchema.prototype.addCategory)
    * Add Category to DocumentSchema
    */
    putCategoriesById = async (documentSchemaId: number, categoryId: number) => this.getAgent().put<APIDataObject>(`/DocumentSchemas/${documentSchemaId}/categories/${categoryId}`, undefined)

    /**
    * DEL /DocumentSchemas/:id/categories/:categoryId (DocumentSchema.prototype.removeCategory)
    * Remove Category to DocumentSchema
    */
    delCategoriesById = async (documentSchemaId: number, categoryId: number) => this.getAgent().del<APIDataObject>(`/DocumentSchemas/${documentSchemaId}/categories/${categoryId}`)

    /**
    * PUT /DocumentSchemas/:id (DocumentSchema.prototype.updateDocumentSchema)
    * Update DocumentSchema
    */
    putById = async (documentSchemaId: number, data: APIDataDocumentSchema) => this.getAgent().put<APIDataDocumentSchema>(`/DocumentSchemas/${documentSchemaId}`, data)

    /**
    * POST /DocumentSchemas/:id/media/upload/start (DocumentSchema.prototype.startMultiPartUpload)
    * Upload media.
    */
    postMediaUploadStart = async (documentSchemaId: number, params: {  fileName: string,  mimeType: string,  meta: APIDataObject }) => this.getAgent().post<APIDataMediaMultiPartUpload>(`/DocumentSchemas/${documentSchemaId}/media/upload/start`, params)

}
export class DocumentRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /Documents/:id/documentSchema (Document.prototype.__get__documentSchema)
    * Fetches belongsTo relation documentSchema.
    */
    getDocumentSchema = async (documentId: number, refresh?: boolean) => this.getAgent().get<APIDataDocumentSchema>(`/Documents/${documentId}/documentSchema`, { params: { refresh }})

    /**
    * GET /Documents/:id/history/:fk (Document.prototype.__findById__history)
    * Find a related item by id for history.
    */
    getHistoryById = async (documentId: number, fk: number) => this.getAgent().get<APIDataDocumentHistory>(`/Documents/${documentId}/history/${fk}`)

    /**
    * GET /Documents/:id/ACL/:fk (Document.prototype.__findById__ACL)
    * Find a related item by id for ACL.
    */
    getAclById = async (documentId: number, fk: number) => this.getAgent().get<APIDataDocumentACL>(`/Documents/${documentId}/ACL/${fk}`)

    /**
    * GET /Documents/:id/media/:fk (Document.prototype.__findById__media)
    * Find a related item by id for media.
    */
    getMediaById = async (documentId: number, fk: number) => this.getAgent().get<APIDataMedia>(`/Documents/${documentId}/media/${fk}`)

    /**
    * DEL /Documents/:id/media/:fk (Document.prototype.__destroyById__media)
    * Delete a related item by id for media.
    */
    delMediaById = async (documentId: number, fk: number) => this.getAgent().del<never>(`/Documents/${documentId}/media/${fk}`)

    /**
    * GET /Documents/:id/documentSchemaRevision (Document.prototype.__get__documentSchemaRevision)
    * Fetches belongsTo relation documentSchemaRevision.
    */
    getDocumentSchemaRevision = async (documentId: number, refresh?: boolean) => this.getAgent().get<APIDataDocumentSchemaRevision>(`/Documents/${documentId}/documentSchemaRevision`, { params: { refresh }})

    /**
    * GET /Documents/:id/history (Document.prototype.__get__history)
    * Queries history of Document.
    */
    getHistory = async (documentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentHistory[]>(`/Documents/${documentId}/history`, { params: { filter }})

    /**
    * GET /Documents/:id/history/count (Document.prototype.__count__history)
    * Counts history of Document.
    */
    getHistoryCount = async (documentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/${documentId}/history/count`, { params: { where }})

    /**
    * GET /Documents/:id/ACL (Document.prototype.__get__ACL)
    * Queries ACL of Document.
    */
    getAcl = async (documentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentACL[]>(`/Documents/${documentId}/ACL`, { params: { filter }})

    /**
    * GET /Documents/:id/ACL/count (Document.prototype.__count__ACL)
    * Counts ACL of Document.
    */
    getAclCount = async (documentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/${documentId}/ACL/count`, { params: { where }})

    /**
    * GET /Documents/:id/media (Document.prototype.__get__media)
    * Queries media of Document.
    */
    getMedia = async (documentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMedia[]>(`/Documents/${documentId}/media`, { params: { filter }})

    /**
    * GET /Documents/:id/media/count (Document.prototype.__count__media)
    * Counts media of Document.
    */
    getMediaCount = async (documentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/${documentId}/media/count`, { params: { where }})

    /**
    * GET /Documents/:id/ACLUsers (Document.prototype.__get__ACLUsers)
    * Queries ACLUsers of Document.
    */
    getAclUsers = async (documentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentACLUserThrough[]>(`/Documents/${documentId}/ACLUsers`, { params: { filter }})

    /**
    * GET /Documents/:id/ACLUsers/count (Document.prototype.__count__ACLUsers)
    * Counts ACLUsers of Document.
    */
    getAclUsersCount = async (documentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/${documentId}/ACLUsers/count`, { params: { where }})

    /**
    * GET /Documents/:id/revisions (Document.prototype.__get__revisions)
    * Queries revisions of Document.
    */
    getRevisions = async (documentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentRevision[]>(`/Documents/${documentId}/revisions`, { params: { filter }})

    /**
    * GET /Documents/:id/revisions/count (Document.prototype.__count__revisions)
    * Counts revisions of Document.
    */
    getRevisionsCount = async (documentId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/${documentId}/revisions/count`, { params: { where }})

    /**
    * GET /Documents/:id (Document.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (documentId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocument>(`/Documents/${documentId}`, { params: { filter }})

    /**
    * POST /Documents/:id/ACL (Document.prototype.createACLRemote)
    * Creates a new instance of Document in this Department.
    */
    postAcl = async (documentId: number, data?: APIDataDocumentACL) => this.getAgent().post<APIDataDocumentACL>(`/Documents/${documentId}/ACL`, data)

    /**
    * POST /Documents/:id/media/upload (Document.prototype.postMediaUpload)
    * Upload media.
    */
    postMediaUpload = async (documentId: number, data: FormData, meta?: APIDataObject) => this.getAgent().post<APIDataMedia>(`/Documents/${documentId}/media/upload`, data, { params: { meta }, headers: {'Content-Type': 'multipart/form-data'}})

    /**
    * GET /Documents/:id/media/download/:fileKey (Document.prototype.getMediaDownload)
    * Download media.
    */
    getMediaDownloadById = async (documentId: number, fileKey?: string, ver?: string) => this.getAgent().get<FormData>(`/Documents/${documentId}/media/download/${fileKey}`, { params: { ver }})

    /**
    * DEL /Documents/:id/ACL/:fk (Document.prototype.deleteACLRemote)
    * Remove ACL
    */
    delAclById = async (documentId: number, fk: number) => this.getAgent().del<APIDataObject>(`/Documents/${documentId}/ACL/${fk}`)

    /**
    * PUT /Documents/:id/ACL/:fk/accessType (Document.prototype.editACLAccessType)
    * Update ACL accessType
    */
    putAclAccessType = async (documentId: number, fk: number, value: string) => this.getAgent().put<APIDataDocumentACL>(`/Documents/${documentId}/ACL/${fk}/accessType`, undefined, { params: { value }})

    /**
    * GET /Documents/:id/myAccess (Document.prototype.getMyAccessRemote)
    * Get Dept Users.
    */
    getMyAccess = async (documentId: number) => this.getAgent().get<APIDataDocumentACLUserThrough>(`/Documents/${documentId}/myAccess`)

    /**
    * GET /Documents/search (Document.searchRemote)
    * Search Documents, returns a list of Documents that the context user has access to.
    */
    getSearch = async (filter?: APIDataObject) => this.getAgent().get<APIDataDocument[]>(`/Documents/search`, { params: { filter }})

    /**
    * GET /Documents/search/count (Document.searchCountRemote)
    * Count Search Documents, returns a count of Documents that the context user has access to.
    */
    getSearchCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/search/count`, { params: { where }})

    /**
    * DEL /Documents/:id (Document.prototype.deleteDocument)
    * Delete Document.
    */
    delById = async (documentId: number) => this.getAgent().del<APIDataObject>(`/Documents/${documentId}`)

    /**
    * POST /Documents/:id/notify (Document.prototype.notifyRemote)
    * Notify users of a Document update
    */
    postNotify = async (documentId: number, isUrgent?: boolean) => this.getAgent().post<{  status: string }>(`/Documents/${documentId}/notify`, undefined, { params: { isUrgent }})

    /**
    * POST /Documents/:id/ACL/:fk/notify (Document.prototype.ACLNotifyRemote)
    * Notify users of an update
    */
    postAclNotify = async (documentId: number, fk: number, isUrgent?: boolean) => this.getAgent().post<{  status: string }>(`/Documents/${documentId}/ACL/${fk}/notify`, undefined, { params: { isUrgent }})

    /**
    * GET /Documents/search/documentSchema (Document.searchDocumentSchemaRemote)
    * Search Documents DocumentSchema, returns a list of DocumentsSchema for the Documents that the context user has access to.
    */
    getSearchDocumentSchema = async (filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchema[]>(`/Documents/search/documentSchema`, { params: { filter }})

    /**
    * GET /Documents/search/documentSchema/count (Document.searchDocumentSchemaCountRemote)
    * Count Search Documents DocumentSchema, returns a count of DocumentsSchema for the Documents that the context user has access to.
    */
    getSearchDocumentSchemaCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/search/documentSchema/count`, { params: { where }})

    /**
    * GET /Documents/search/department (Document.searchDepartmentRemote)
    * Search Documents Department, returns a list of Departments for the Documents that the context user has access to.
    */
    getSearchDepartment = async (filter?: APIDataObject) => this.getAgent().get<APIDataDepartment[]>(`/Documents/search/department`, { params: { filter }})

    /**
    * GET /Documents/search/department/count (Document.searchDepartmentCountRemote)
    * Count Search Documents Department, returns a count of Departments for the Documents that the context user has access to.
    */
    getSearchDepartmentCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/search/department/count`, { params: { where }})

    /**
    * PUT /Documents/:id (Document.prototype.updateDocument)
    * Update Document
    */
    putById = async (documentId: number, data: APIDataDocument) => this.getAgent().put<APIDataDocument>(`/Documents/${documentId}`, data)

    /**
    * GET /Documents/:id/user/:fk (Document.prototype.getUserRemote)
    * Get Users.
    */
    getUserById = async (documentId: number, fk: number, filter?: APIDataObject) => this.getAgent().get<APIDataBlueUser>(`/Documents/${documentId}/user/${fk}`, { params: { filter }})

    /**
    * GET /Documents/search/acl (Document.searchAclRemote)
    * Search DocumentACLs, returns a list of DocumentACL for the Documents that the context user has access to.
    */
    getSearchAcl = async (filter?: APIDataObject) => this.getAgent().get<APIDataDocumentACL[]>(`/Documents/search/acl`, { params: { filter }})

    /**
    * GET /Documents/search/acl/count (Document.searchAclCountRemote)
    * Count Search DocumentACL, returns a count of DocumentACL for the Documents that the context user has access to.
    */
    getSearchAclCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/search/acl/count`, { params: { where }})

    /**
    * GET /Documents/search/acl/principals (Document.searchAclPrincipalsRemote)
    * Search DocumentACLs, returns a all Principals of DocumentACL for the Documents that the context user has access to.
    */
    getSearchAclPrincipals = async (filter?: APIDataObject, filterBlueUser?: APIDataObject, filterRoom?: APIDataObject, filterDepartment?: APIDataObject) => this.getAgent().get<{ users: APIDataBlueUser[], rooms: APIDataRoom[], departments: APIDataDepartment[] }>(`/Documents/search/acl/principals`, { params: { filter, filterBlueUser, filterRoom, filterDepartment }})

    /**
    * GET /Documents/search/room (Document.searchRoomRemote)
    * Search Documents Rooms, returns a list of Rooms for the Documents that the context user has access to.
    */
    getSearchRoom = async (filter?: APIDataObject) => this.getAgent().get<APIDataRoom[]>(`/Documents/search/room`, { params: { filter }})

    /**
    * GET /Documents/search/room/count (Document.searchRoomCountRemote)
    * Count Search Documents Room, returns a count of Rooms for the Documents that the context user has access to.
    */
    getSearchRoomCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/Documents/search/room/count`, { params: { where }})

    /**
    * GET /Documents/:id/ACL/principals (Document.prototype.getAclPrincipalRemote)
    * Get all ACL Principals in one request.
    */
    getAclPrincipals = async (documentId: number, filter?: APIDataObject, filterBlueUser?: APIDataObject, filterRoom?: APIDataObject, filterDepartment?: APIDataObject) => this.getAgent().get<{ users: APIDataBlueUser[], rooms: APIDataRoom[], departments: APIDataDepartment[] }>(`/Documents/${documentId}/ACL/principals`, { params: { filter, filterBlueUser, filterRoom, filterDepartment }})

    /**
    * PUT /Documents/:id/owner/:userId (Document.prototype.editOwnershipRemote)
    * Update OWNER ACL
    */
    putOwnerById = async (documentId: number, userId: number) => this.getAgent().put<APIDataDocumentACL>(`/Documents/${documentId}/owner/${userId}`, undefined)

    /**
    * GET /Documents/:id/download/csv (Document.prototype.getCSVDownload)
    * Download document as CSV
    */
    getDownloadCsv = async (documentId: number) => this.getAgent().get<FormData>(`/Documents/${documentId}/download/csv`)

    /**
    * POST /Documents/:id/media/upload/start (Document.prototype.startMultiPartUpload)
    * Upload media.
    */
    postMediaUploadStart = async (documentId: number, params: {  fileName: string,  mimeType: string,  meta: APIDataObject }) => this.getAgent().post<APIDataMediaMultiPartUpload>(`/Documents/${documentId}/media/upload/start`, params)

}
export class DocumentSearchDataRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /DocumentSearchData/:id/document (DocumentSearchData.prototype.__get__document)
    * Fetches belongsTo relation document.
    */
    getDocument = async (documentSearchDataId: number, refresh?: boolean) => this.getAgent().get<APIDataDocument>(`/DocumentSearchData/${documentSearchDataId}/document`, { params: { refresh }})

    /**
    * POST /DocumentSearchData (DocumentSearchData.create)
    * Create a new instance of the model and persist it into the data source.
    */
    post = async (data?: APIDataObject) => this.getAgent().post<APIDataDocumentSearchData>(`/DocumentSearchData`, data)

    /**
    * PUT /DocumentSearchData (DocumentSearchData.upsert)
    * Patch an existing model instance or insert a new one into the data source.
    */
    put = async (data?: APIDataObject) => this.getAgent().put<APIDataDocumentSearchData>(`/DocumentSearchData`, data)

    /**
    * POST /DocumentSearchData/replaceOrCreate (DocumentSearchData.replaceOrCreate)
    * Replace an existing model instance or insert a new one into the data source.
    */
    postReplaceOrCreate = async (data?: APIDataObject) => this.getAgent().post<APIDataDocumentSearchData>(`/DocumentSearchData/replaceOrCreate`, data)

    /**
    * POST /DocumentSearchData/upsertWithWhere (DocumentSearchData.upsertWithWhere)
    * Update an existing model instance or insert a new one into the data source based on the where criteria.
    */
    postUpsertWithWhere = async (where?: APIDataObject, data?: APIDataObject) => this.getAgent().post<APIDataDocumentSearchData>(`/DocumentSearchData/upsertWithWhere`, data, { params: { where }})

    /**
    * GET /DocumentSearchData/:id/exists (DocumentSearchData.exists)
    * Check whether a model instance exists in the data source.
    */
    getExists = async (documentSearchDataId: number) => this.getAgent().get<boolean>(`/DocumentSearchData/${documentSearchDataId}/exists`)

    /**
    * GET /DocumentSearchData/:id (DocumentSearchData.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (documentSearchDataId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSearchData>(`/DocumentSearchData/${documentSearchDataId}`, { params: { filter }})

    /**
    * POST /DocumentSearchData/:id/replace (DocumentSearchData.replaceById)
    * Replace attributes for a model instance and persist it into the data source.
    */
    postReplace = async (documentSearchDataId: number, data?: APIDataObject) => this.getAgent().post<APIDataDocumentSearchData>(`/DocumentSearchData/${documentSearchDataId}/replace`, data)

    /**
    * GET /DocumentSearchData (DocumentSearchData.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSearchData[]>(`/DocumentSearchData`, { params: { filter }})

    /**
    * GET /DocumentSearchData/findOne (DocumentSearchData.findOne)
    * Find first instance of the model matched by filter from the data source.
    */
    getFindOne = async (filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSearchData>(`/DocumentSearchData/findOne`, { params: { filter }})

    /**
    * POST /DocumentSearchData/update (DocumentSearchData.updateAll)
    * Update instances of the model matched by {{where}} from the data source.
    */
    postUpdate = async (where?: APIDataObject, data?: APIDataObject) => this.getAgent().post<{ count: number }>(`/DocumentSearchData/update`, data, { params: { where }})

    /**
    * DEL /DocumentSearchData/:id (DocumentSearchData.deleteById)
    * Delete a model instance by {{id}} from the data source.
    */
    delById = async (documentSearchDataId: number) => this.getAgent().del<APIDataObject>(`/DocumentSearchData/${documentSearchDataId}`)

    /**
    * GET /DocumentSearchData/count (DocumentSearchData.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DocumentSearchData/count`, { params: { where }})

    /**
    * PUT /DocumentSearchData/:id (DocumentSearchData.prototype.updateAttributes)
    * Patch attributes for a model instance and persist it into the data source.
    */
    putById = async (documentSearchDataId: number, data?: APIDataObject) => this.getAgent().put<APIDataDocumentSearchData>(`/DocumentSearchData/${documentSearchDataId}`, data)

}
export class Auth0Routes {
    constructor(private getAgent: () => Agent) {}
    /**
    * POST /Auth0/oauth/token (Auth0.getAccessTokenRemote)
    * It returns an accessToken from Auth0
    */
    postOauthToken = async (credentials?: {  username: string,  password: string,  deviceToken: string }) => this.getAgent().post<APIDataObject>(`/Auth0/oauth/token`, credentials)

    /**
    * POST /Auth0/lazyMigration (Auth0.lazyMigration)
    * It checks if the user exists in the db
    */
    postLazyMigration = async (credentials: {  email: string,  password: string }) => this.getAgent().post<APIDataObject>(`/Auth0/lazyMigration`, credentials)

    /**
    * POST /Auth0/signup (Auth0.registerInAuth0)
    * It registers a user in Auth0
    */
    postSignup = async (credentials: {  email: string,  password: string,  firstName: string,  lastName: string,  deviceToken: string }) => this.getAgent().post<APIDataObject>(`/Auth0/signup`, credentials)

    /**
    * POST /Auth0/emailAvailable (Auth0.emailAvailable)
    * It verifies the email is not already in use
    */
    postEmailAvailable = async (credential: {  email: string }) => this.getAgent().post<{  id: string,  nickname: string }>(`/Auth0/emailAvailable`, credential)

    /**
    * POST /Auth0/verifyEmail (Auth0.verifyEmail)
    * It changes the emailVerified flag to true
    */
    postVerifyEmail = async (credentials: APIDataObject) => this.getAgent().post<boolean>(`/Auth0/verifyEmail`, credentials)

    /**
    * GET /Auth0/isVerified (Auth0.isVerified)
    * Whether this email address is verified (true) or unverified (false) on Auth0
    */
    getIsVerified = async (email: string) => this.getAgent().get<APIDataObject>(`/Auth0/isVerified`, { params: { email }})

    /**
    * POST /Auth0/resendVerificationEmail (Auth0.resendVerificationEmail)
    * It re-sends the Auth0 verification email
    */
    postResendVerificationEmail = async (email: string) => this.getAgent().post<APIDataObject>(`/Auth0/resendVerificationEmail`, undefined, { params: { email }})

    /**
    * POST /Auth0/mfa/enroll (Auth0.enrollMFA)
    * It allows the user to enroll in a mfa method
    */
    postMfaEnroll = async (options: {  username: string,  password: string,  phoneNumber: string,  deviceToken: string }) => this.getAgent().post<APIDataObject>(`/Auth0/mfa/enroll`, options)

    /**
    * GET /Auth0/:userId/authenticators (Auth0.userAuthenticatorsRemote)
    * Returns the users authenticators
    */
    getAuthenticators = async (userId: number) => this.getAgent().get<{  confirmed: boolean,  created_at: string,  id: string,  last_auth_at: string,  name: string,  type: string }[]>(`/Auth0/${userId}/authenticators`)

    /**
    * GET /Auth0/authenticatorsByEmail/:email (Auth0.userAuthenticatorsByEmailRemote)
    * Returns the users authenticators
    */
    getAuthenticatorsByEmail = async (email: string) => this.getAgent().get<{  confirmed: boolean,  created_at: string,  last_auth_at: string,  name: string,  type: string }[]>(`/Auth0/authenticatorsByEmail/${email}`)

    /**
    * POST /Auth0/mfa/challenge (Auth0.challengeUser)
    * It sends an oob challenge to the users email
    */
    postMfaChallenge = async (mfaToken: string, options?: {  email: string,  useEmail: boolean,  usePhone: boolean }) => this.getAgent().post<APIDataObject>(`/Auth0/mfa/challenge`, options, { params: { mfaToken }})

    /**
    * POST /Auth0/verifyChallenge (Auth0.verifyChallenge)
    * It verifies the challenge and returns an accessToken if correct
    */
    postVerifyChallenge = async (mfaCredentials: {  mfaToken: string,  oobCode: string,  bindingCode: string,  deviceToken: string }) => this.getAgent().post<APIDataObject>(`/Auth0/verifyChallenge`, mfaCredentials)

    /**
    * POST /Auth0/enterpriseLogin (Auth0.enterpriseLogin)
    * If email is an enterprise email sends enterprise login request
    */
    postEnterpriseLogin = async (email: string) => this.getAgent().post<string>(`/Auth0/enterpriseLogin`, undefined, { params: { email }})

    /**
    * DEL /Auth0/authenticator/:authenticatorId (Auth0.deleteUserAuthenticatorsRemote)
    * Deletes a users authenticators
    */
    delAuthenticatorById = async (authenticatorId: string, options: {  username: string,  password: string,  deviceToken: string }) => this.getAgent().del<APIDataObject>(`/Auth0/authenticator/${authenticatorId}`, { data: options })

}
export class GoogleRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /Google/maps/api/staticmap (Google.staticmap)
    * Google maps static.
    */
    getMapsApiStaticmap = async (center?: string, zoom?: string, size?: string, markers?: string) => this.getAgent().get<FormData>(`/Google/maps/api/staticmap`, { params: { center, zoom, size, markers }})

}
export class DocumentSchemaCategoryRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /DocumentSchemaCategories/:id/department (DocumentSchemaCategory.prototype.__get__department)
    * Fetches belongsTo relation department.
    */
    getDepartment = async (documentSchemaCategoryId: number, refresh?: boolean) => this.getAgent().get<APIDataDepartment>(`/DocumentSchemaCategories/${documentSchemaCategoryId}/department`, { params: { refresh }})

    /**
    * GET /DocumentSchemaCategories/:id/documentSchemas/:fk (DocumentSchemaCategory.prototype.__findById__documentSchemas)
    * Find a related item by id for documentSchemas.
    */
    getDocumentSchemasById = async (documentSchemaCategoryId: number, fk: number) => this.getAgent().get<APIDataDocumentSchema>(`/DocumentSchemaCategories/${documentSchemaCategoryId}/documentSchemas/${fk}`)

    /**
    * GET /DocumentSchemaCategories/:id/documentSchemas (DocumentSchemaCategory.prototype.__get__documentSchemas)
    * Queries documentSchemas of DocumentSchemaCategory.
    */
    getDocumentSchemas = async (documentSchemaCategoryId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchema[]>(`/DocumentSchemaCategories/${documentSchemaCategoryId}/documentSchemas`, { params: { filter }})

    /**
    * GET /DocumentSchemaCategories/:id/documentSchemas/count (DocumentSchemaCategory.prototype.__count__documentSchemas)
    * Counts documentSchemas of DocumentSchemaCategory.
    */
    getDocumentSchemasCount = async (documentSchemaCategoryId: number, where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DocumentSchemaCategories/${documentSchemaCategoryId}/documentSchemas/count`, { params: { where }})

    /**
    * POST /DocumentSchemaCategories (DocumentSchemaCategory.create)
    * Create a new instance of the model and persist it into the data source.
    */
    post = async (data?: APIDataObject) => this.getAgent().post<APIDataDocumentSchemaCategory>(`/DocumentSchemaCategories`, data)

    /**
    * GET /DocumentSchemaCategories/:id (DocumentSchemaCategory.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (documentSchemaCategoryId: number, filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchemaCategory>(`/DocumentSchemaCategories/${documentSchemaCategoryId}`, { params: { filter }})

    /**
    * GET /DocumentSchemaCategories (DocumentSchemaCategory.find)
    * Find all instances of the model matched by filter from the data source.
    */
    get = async (filter?: APIDataObject) => this.getAgent().get<APIDataDocumentSchemaCategory[]>(`/DocumentSchemaCategories`, { params: { filter }})

    /**
    * GET /DocumentSchemaCategories/count (DocumentSchemaCategory.count)
    * Count instances of the model matched by where from the data source.
    */
    getCount = async (where?: APIDataObject) => this.getAgent().get<APIDataCount>(`/DocumentSchemaCategories/count`, { params: { where }})

    /**
    * PUT /DocumentSchemaCategories/:id (DocumentSchemaCategory.prototype.updateDocumentSchemaCategory)
    * Patch attributes for a model instance and persist it into the data source.
    */
    putById = async (documentSchemaCategoryId: number, data?: APIDataObject) => this.getAgent().put<APIDataDocumentSchemaCategory>(`/DocumentSchemaCategories/${documentSchemaCategoryId}`, data)

    /**
    * DEL /DocumentSchemaCategories/:id (DocumentSchemaCategory.destroyDocumentSchemaCategoryById)
    * Delete a model instance by {{id}} from the data source.
    */
    delById = async (documentSchemaCategoryId: number) => this.getAgent().del<APIDataObject>(`/DocumentSchemaCategories/${documentSchemaCategoryId}`)

}
export class MediaMultiPartUploadRoutes {
    constructor(private getAgent: () => Agent) {}
    /**
    * GET /MediaMultiPartUploads/:id/parts (MediaMultiPartUpload.prototype.__get__parts)
    * Queries parts of MediaMultiPartUpload.
    */
    getParts = async (mediaMultiPartUploadId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMediaMultiPartUploadPart[]>(`/MediaMultiPartUploads/${mediaMultiPartUploadId}/parts`, { params: { filter }})

    /**
    * GET /MediaMultiPartUploads/:id (MediaMultiPartUpload.findById)
    * Find a model instance by {{id}} from the data source.
    */
    getById = async (mediaMultiPartUploadId: number, filter?: APIDataObject) => this.getAgent().get<APIDataMediaMultiPartUpload>(`/MediaMultiPartUploads/${mediaMultiPartUploadId}`, { params: { filter }})

    /**
    * POST /MediaMultiPartUploads/:id/upload/part (MediaMultiPartUpload.prototype.uploadPart)
    * See here: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#uploadPart-property
    */
    postUploadPart = async (mediaMultiPartUploadId: number, partNumber: number, uploadId: string, contentLength: number, file?: FormData, onUploadProgress?: (event:any) => void) => this.getAgent().post<APIDataMediaMultiPartUploadPart>(`/MediaMultiPartUploads/${mediaMultiPartUploadId}/upload/part`, file, { params: { partNumber, uploadId, contentLength }, headers: {'Content-Type': 'multipart/form-data'}, onUploadProgress})

    /**
    * POST /MediaMultiPartUploads/:id/upload/complete (MediaMultiPartUpload.prototype.completeUpload)
    * See here: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#completeMultipartUpload-property
    */
    postUploadComplete = async (mediaMultiPartUploadId: number, uploadId?: string) => this.getAgent().post<APIDataMedia>(`/MediaMultiPartUploads/${mediaMultiPartUploadId}/upload/complete`, undefined, { params: { uploadId }})

}

export class InjectedApiRoutes {
    constructor(private getAgent: () => Agent) {}
    Routes = {
        BlueUser: new BlueUserRoutes(this.getAgent),
        Department: new DepartmentRoutes(this.getAgent),
        Invite: new InviteRoutes(this.getAgent),
        Push: new PushRoutes(this.getAgent),
        WebContent: new WebContentRoutes(this.getAgent),
        Bulletin: new BulletinRoutes(this.getAgent),
        Room: new RoomRoutes(this.getAgent),
        RoomMessage: new RoomMessageRoutes(this.getAgent),
        Thread: new ThreadRoutes(this.getAgent),
        ThreadMessage: new ThreadMessageRoutes(this.getAgent),
        Bot: new BotRoutes(this.getAgent),
        DepartmentUserThrough: new DepartmentUserThroughRoutes(this.getAgent),
        LinkPreview: new LinkPreviewRoutes(this.getAgent),
        AppVersion: new AppVersionRoutes(this.getAgent),
        EventLog: new EventLogRoutes(this.getAgent),
        Mailchimp: new MailchimpRoutes(this.getAgent),
        DepartmentCRM: new DepartmentCRMRoutes(this.getAgent),
        UserCRM: new UserCRMRoutes(this.getAgent),
        App: new AppRoutes(this.getAgent),
        BotCampaign: new BotCampaignRoutes(this.getAgent),
        Reports: new ReportsRoutes(this.getAgent),
        DepartmentLinkedThrough: new DepartmentLinkedThroughRoutes(this.getAgent),
        BanIp: new BanIpRoutes(this.getAgent),
        VerifiedEmailDomain: new VerifiedEmailDomainRoutes(this.getAgent),
        DocumentSchema: new DocumentSchemaRoutes(this.getAgent),
        Document: new DocumentRoutes(this.getAgent),
        DocumentSearchData: new DocumentSearchDataRoutes(this.getAgent),
        Auth0: new Auth0Routes(this.getAgent),
        Google: new GoogleRoutes(this.getAgent),
        DocumentSchemaCategory: new DocumentSchemaCategoryRoutes(this.getAgent),
        MediaMultiPartUpload: new MediaMultiPartUploadRoutes(this.getAgent)
    }
}

