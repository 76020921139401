import {
    observable,
    action,
    makeObservable
} from 'mobx'

const os = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
]

const browser = [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
]

const header = [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor,
    window.opera
]

// Keeps track of current user's device
class DeviceStore {
    device = {}
    leftNavState = { agencyWide: 'open', team: 'open', threads: 'open' }

    selectedTheme = localStorage.getItem('theme') || 'system' // stored in localStorage
    deviceTheme = 'light'
    screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    agent = header.join(' ')
    os = this.matchAgentItem(this.agent, os).name
    browserName = this.matchAgentItem(this.agent, browser).name
    browserVersion = this.matchAgentItem(this.agent, browser).version
    ipAddress = null
    appVersion = null
    appBuildNumber = '0'
    appBundleId = null
    osVersion = this.matchAgentItem(this.agent, os).version
    browserLanguage = navigator.language
    currentMediaFilePlaying = null // holds any playing media so all other media players can listen and stop

    constructor() {
        makeObservable(this, {
            device: observable,
            leftNavState: observable,
            selectedTheme: observable,
            deviceTheme: observable,
            screenWidth: observable,
            screenHeight: observable,
            agent: observable,
            os: observable,
            browserName: observable,
            browserVersion: observable,
            ipAddress: observable,
            appVersion: observable,
            appBuildNumber: observable,
            appBundleId: observable,
            osVersion: observable,
            browserLanguage: observable,
            currentMediaFilePlaying: observable,
            setCurrentMediaFilePlaying: action
        })

    }

    async init() {
        /** */
    }

    matchAgentItem(string, data) {
        var i = 0,
            j = 0,
            regex,
            regexv,
            match,
            matches,
            version

        for (i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, 'i')
            match = regex.test(string)
            if (match) {
                regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
                matches = string.match(regexv)
                version = ''
                if (matches) { if (matches[1]) { matches = matches[1] } }
                if (matches) {
                    matches = matches.split(/[._]+/)
                    for (j = 0; j < matches.length; j += 1) {
                        if (j === 0) {
                            version += matches[j] + '.'
                        } else {
                            version += matches[j]
                        }
                    }
                } else {
                    version = '0'
                }
                return {
                    name: data[i].name,
                    version: parseFloat(version)
                }
            }
        }
        return { name: 'unknown', version: 0 }
    }

    setCurrentMediaFilePlaying(media) {
        this.currentMediaFilePlaying = media
    }

}

// Creates a single instance of this store
export default new DeviceStore()
