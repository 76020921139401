import React, { useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// evertel
import { Button, Row, Text, useUI } from '@evertel/web/ui'
import { APIDataRoom } from '@evertel/types'
import { JoinRoomController } from '@evertel/room'
import { useService } from '@evertel/di'
import { CurrentUserController } from '@evertel/blue-user'


type Opts = {
    opts: {
        departmentMembersCanJoinAsMember: boolean,
        roomMembersCanLeave: boolean
    }
}
interface JoinRoomActionButtonProps {
    room: APIDataRoom & Opts,
    joinRoomController: JoinRoomController,
    closeModal: () => void,
    openExtraInfoModal: () => void
}


const JoinRoomActionButton: React.FC<JoinRoomActionButtonProps> = observer(({
    room,
    joinRoomController,
    closeModal,
    openExtraInfoModal
}) => {

    const { addToast } = useUI()
    const navigate = useNavigate()
    const currentUserController = useService(CurrentUserController, [])

    const [isLoading, setIsLoading] = useState(false)

    const joinedRooms = joinRoomController.joinedRoomIds
    const joined = !!joinedRooms?.find(r => r === room.id)
    const isPrivate = !room.opts?.departmentMembersCanJoinAsMember
    const isPublic = room.isSearchable

    const goToRoom = () => {
        navigate(`/room/${room.id}`)
        closeModal()
    }

    const joinRoom = async () => {
        setIsLoading(true)

        try {
            await joinRoomController.joinRoom(room.id)

        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }

        setIsLoading(false)
    }

    const requestToJoin = async () => {
        if (currentUserController.departmentsAccess?.length) {
            openExtraInfoModal()
            return
        }

        setIsLoading(true)

        try {
            await joinRoomController.requestToJoin(room.id)

        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }

        setIsLoading(false)
    }

    const cancelRequest = async (request: any) => {
        setIsLoading(true)

        try {
            await joinRoomController.cancelRequestToJoin(room.id, request.id)

        } catch (error) {
            console.error(error.message)
            addToast({
                color: 'danger',
                message: 'Sorry, could not cancel this request. Try again later.'
            })
        }

        setIsLoading(false)
    }

    if (!joined && isPublic) {
        // request to join rooms that are public and not a member of, and that are not private.
        // has this room been requested already by this user?
        const request = joinRoomController.joinRequests?.find(r => r.roomId === room.id)
        const requestStatus = request?.status

        return (
            <Row>
                {(request?.status === 'pending') &&
                    <Button
                        color="muted"
                        outline
                        size="sm"
                        className="mr-2"
                        onClick={() => cancelRequest(request)}>
                        Cancel
                    </Button>
                }
                <Button
                    color="secondary"
                    outline
                    size="sm"
                    block={(requestStatus === 'pending') ? false : true}
                    onClick={requestToJoin}
                    loading={isLoading}
                    disabled={isLoading || requestStatus === 'pending' || requestStatus === 'blocked'}>
                    {(requestStatus === 'pending') ? 'Pending...' : (requestStatus === 'blocked') ? 'Blocked' : 'Request Access'}
                </Button>
                {(requestStatus === 'rejected' || requestStatus === 'blocked') &&
                    <Text
                        color="danger"
                        size="small"
                        className="text-capitalize">
                        {requestStatus}: {moment(request.updatedDate).format('ll')}
                    </Text>
                }
            </Row>
        )
    } else if (joined) {
        // already joined rooms
        return (
            <Button
                color="muted"
                block
                outline
                size="sm"
                onClick={goToRoom}
                loading={isLoading}>
                Go to Room
            </Button>
        )
    } else if (!joined && !isPrivate && !isPublic) {
        // allow to join rooms not already joined that allow
        // joining and are not private or public
        return (
            <Button
                color="success"
                block
                size="sm"
                onClick={joinRoom}
                loading={isLoading}>
                Join
            </Button>
        )
    } else {
        return null
    }
})

export { JoinRoomActionButton }
