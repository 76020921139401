import { Suspense, useEffect, useRef, useState, useMemo, FC, Ref } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

// evertel
import { MessageList } from './MessageList'
import {
    CreateMessage,
    ForwardMessageModal,
    MessageListHeader,
    ReactedByModal,
    ReadByModal
} from './elements'
import { useService } from '@evertel/di'
import { MessageWallController } from '@evertel/message'
import { Col, Spinner, InfoBox } from '@evertel/web/ui'
import { EmojiPickerModal } from '@evertel/web/emojis'
import { CurrentUserEmojisController } from '@evertel/emojis'
import { APIDataRoom, APIDataThread } from '@evertel/types'
import { MessageWallContext, MessageWallContextProps } from './MessageWallContext'
import { Message } from './Message'
import { MessageHeaderDivider } from './elements/message/header/MessageHeaderDivider'
import { debounce } from 'lodash'
import { DisplayRoomMessage } from '@evertel/stores'
import { FetchUserService } from '@evertel/blue-user'

interface MessageWallProps {
    modelId: number,
    modelType: 'thread' | 'room',
    modelData: APIDataRoom | APIDataThread,
    allowedToPost: boolean,
    repliesToMessage?: DisplayRoomMessage
    ref?: Ref<unknown>,
}

const MessageWall: FC<MessageWallProps> = observer(({
    modelId,
    modelType,
    modelData,
    allowedToPost,
    repliesToMessage
}) => {

    const { repliesToId: repliesToIdString } = useParams<{repliesToId?: string}>()
    const repliesToId = repliesToIdString ? parseInt(repliesToIdString, 10) : null

    const messageWallController = useService(MessageWallController, [modelType, modelId])
    const fetchUserService = useService(FetchUserService, [])
    const currentUserEmojisController = useService(CurrentUserEmojisController, [])

    const [isLoading, setIsLoading] = useState(false)
    const [selectedMessageId, setSelectedMessageId] = useState(0)

    // modals
    const [emojiPickerState, setEmojiPickerState] = useState({ visible: false, params: {} })
    const [isReactedByModalOpen, setIsReactedByModalOpen] = useState(false)
    const [isReadByModalOpen, setIsReadByModalOpen] = useState(false)
    const [isForwardMessageModalOpen, setIsForwardMessageModalOpen] = useState(false)

    const contextValues: MessageWallContextProps = {
        modelId,
        modelType,
        modelData,
        repliesToId,
        allowedToPost,
        messageWallController,
        currentUserEmojisController,
        setSelectedMessageId,
        setIsReactedByModalOpen,
        setEmojiPickerState,
        setIsReadByModalOpen,
        setIsForwardMessageModalOpen,
        emojiPickerState,
        selectedMessageId
    }

    useEffect(() => {
        setIsLoading(true)
        if (modelId) {
            messageWallController.init(modelId, modelType, repliesToId)
            getMessages()
        }
        return () => {
            messageWallController.destroy()
        }
    }, [modelId, modelType, repliesToId])

    useEffect(() => {
        if (repliesToMessage?.ownerId)  {
            fetchUserService.fetchSpecificUsersFromRoom(messageWallController.modelId, [repliesToMessage?.ownerId])
            messageWallController.processForwardedMessage([repliesToMessage])
        }
    }, [repliesToMessage])

    const getMessages = debounce(async () => {
        await messageWallController.fetchMessages('new')
        setIsLoading(false)
    }, 100)

    if (messageWallController.errorFetching) {
        return (
            <Col className="no-messages pr-3">
                <InfoBox
                    color="warning"
                    className="m-3"
                >
                    Sorry, you don't have access to this room
                </InfoBox>
            </Col>
        )
    }


    return (
        <MessageWallContext.Provider value={contextValues}>
            <Col 
                className={classNames('message-list', { 'opacity-75': (modelData as APIDataRoom)?.isArchived })}
            >
                <MessageList
                    // key={`${modelType} - ${modelId} - ${repliesToId}`}
                    modelId={modelId}
                    modelType={modelType}
                    repliesToId={repliesToId}

                    data={messageWallController.messagesWithDates}

                    onStartReached={() => messageWallController.fetchMessages('old')}

                    listHeaderComponent={() =>
                        // <div style={{ height: 200 }}><h1>I AM THE HEADER</h1></div>
                        <>
                            {/* {`sorted:${messageWallController.messagesSorted?.length}  ---  Count ${messageWallController.messagesCount}`} */}
                            <MessageListHeader
                                isLoading={isLoading || messageWallController.messagesSorted?.length < messageWallController.messagesCount}
                                message={repliesToMessage}
                            />
                        </>
                    }
                />

                <CreateMessage />

                <Suspense fallback={<Spinner />}>
                    <ReactedByModal
                        visible={isReactedByModalOpen}
                        modelType={modelType}
                        onClose={() => setIsReactedByModalOpen(false)}
                        onCloseDone={() => setSelectedMessageId(0)}
                        messageId={selectedMessageId}
                    />
                    <ReadByModal
                        visible={isReadByModalOpen}
                        modelType={modelType}
                        onClose={() => setIsReadByModalOpen(false)}
                        onCloseDone={() => setSelectedMessageId(0)}
                        messageId={selectedMessageId}
                    />
                    <ForwardMessageModal
                        visible={isForwardMessageModalOpen}
                        onClose={() => setIsForwardMessageModalOpen(false)}
                        messageId={selectedMessageId}
                    />
                    <EmojiPickerModal
                        visible={emojiPickerState.visible}
                        onSelectEmoji={(emojiPickerState.params as any)?.onSelectEmoji}
                        {...(emojiPickerState.visible) && { onClickOutside: () => setEmojiPickerState({ visible: false, params: {} }) }}
                    />
                </Suspense>
            </Col>
        </MessageWallContext.Provider>
    )
})

MessageWall.displayName = 'MessageWall'

export { MessageWall }
