import React from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'
import { InfoBox } from '@evertel/web/ui'
import { AppStore, AnalyticsStore } from '../stores'

const ErrorBoundary: React.FC = () => {
    const error = useRouteError()

    React.useEffect(() => {
        const errorObj = {
            type: 'FATAL',
            URL: window.location.href,
            error: error instanceof Error ? error.toString() : 'Unknown error',
            errorInfo: error
        }

        // log to API
        AppStore.logError(errorObj)

        // log to GA
        AnalyticsStore.logException(errorObj.error, true)
    }, [error])

    let errorMessage: string
    let errorDetails: string | null = null

    if (isRouteErrorResponse(error)) {
    // Error is a response from a loader or action
        errorMessage = `${error.status} ${error.statusText}`
        errorDetails = error.data?.message || JSON.stringify(error.data)
    } else if (error instanceof Error) {
        errorMessage = error.message
        errorDetails = error.stack
    } else {
        errorMessage = 'Unknown error'
        errorDetails = JSON.stringify(error)
    }

    return (
        <div className="p-5 app fadeIn animated registration" style={{height: '100vh'}}>
            <InfoBox color="warning">
                <h1>Hmm. Something went wrong.</h1>
                <h5>Our engineers have been automatically notified. Try going back or refreshing your page. If you continue to experience this issue, please email us at <a href="mailto:connectsupport@genasys.com">connectSupport@genasys.com</a> or try our <a href="https://getevertel.com/help-center/" target="_blank" rel="noreferrer">Help Center</a>.
                </h5>
                <details className="pt-4" style={{ whiteSpace: 'pre-wrap' }}>
                    <summary>Error Details</summary>
                    <div>
                        {errorMessage}
                        {errorDetails && (
                            <>
                                <br />
                                {errorDetails}
                            </>
                        )}
                    </div>
                </details>
            </InfoBox>
        </div>
    )
}

export default ErrorBoundary