import { ContainerModule } from '@evertel/di'
import { MessageReadsController, MessageWallController, MessageController, MessageReactionsController, MessageReactedByController,
    CreateMessageController, MessageForwardController, MessageSearchController, DrawerController, 
    MessageActionController,
    RepliesViewController} from './controller'


const DI_MODULES = [
    new ContainerModule((bind) => {
        bind(MessageReadsController).toSelf()
        bind(MessageWallController).toSelf()
        bind(MessageController).toSelf()
        bind(MessageReactionsController).toSelf()
        bind(MessageReactedByController).toSelf()
        bind(CreateMessageController).toSelf()
        bind(MessageForwardController).toSelf()
        bind(MessageSearchController).toSelf()
        bind(DrawerController).toSelf()
        bind(MessageActionController).toSelf()
        bind(RepliesViewController).toSelf()
    })
]

export { DI_MODULES }
