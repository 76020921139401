
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { DisplayRoomMessage, DisplayThreadMessage, RoomStore } from '@evertel/stores'
import { SessionState } from '@evertel/session'
import { CurrentUserController } from '@evertel/blue-user'
import { Api } from '@evertel/api'
import { APIDataRoom } from '@evertel/types'
import { set, uniq } from 'lodash'
import { MESSAGE_DEFAULT_INCLUDE } from '@evertel/message'
import debugModule from 'debug'
const debug = debugModule('app:RoomController')


const POLL_INTERVAL = 10000
const GET_REPLIES_POLL_ENABLED = true

class RoomController {
    roomId = null
    private _repliesToId: number | null = null
    managersFetched = false
    isLoadingReplies = false
    managerIds: number[] = []
    memberIds: number[] = []
    repliesToMessages: Map<number, DisplayRoomMessage> = new Map([])
    private pollInterval: NodeJS.Timeout | undefined


    constructor(
        private api: Api,
        private roomStore: RoomStore,
        private session: SessionState,
        private currentUserController: CurrentUserController
    ) {
        makeAutoObservable(this)
    }

    init = async (roomId: number, repliesToId: number | null = null, filter?: any) => {
        if (!roomId) return
        
        this.roomId = roomId
        this.repliesToId = repliesToId

        await Promise.all([
            this.fetchRoom(filter),
            this.fetchAllUsersIds()
        ])
    }

    fetchRoom = async (filter?: any) => {
        const room = await this.api.Routes.Room.getById(this.roomId, filter)

        runInAction(() => {
            this.roomStore.update(room)
        })
    }

    fetchRepliesToMessage = async () => {
        // if (!this.repliesToMessages.has(repliesToId)) {
        if (this.repliesToId) {
            this.isLoadingReplies = true
            try {
	            const messages = await this.api.Routes.Room.getMessages(this.roomId, {
	                where: {
	                    id: this.repliesToId
	                },
                    include: [
                        ...MESSAGE_DEFAULT_INCLUDE,
                        {
                            relation: 'repliesSubscriptions',
                            scope: {
                                where: {
                                    blueUserId: this.session.currentUserId
                                },
                                fields: ['id', 'notify']
                            }
                        }
                    ]
                })
	            const message = messages.shift()
                message.meta = message.meta || {}
                if (message.repliesSubscriptions && message.repliesSubscriptions.length > 0) {
                    const subscription = message.repliesSubscriptions.find(sub => sub.notify !== undefined)
                    if (subscription) {
                        message.meta.repliesSubscription = { notify: subscription.notify }
                    } else {
                        // No subscription found, treat it as a true subscription
                        message.meta.repliesSubscription = { notify: true }
                    }
                } else {
                    // No repliesSubscriptions array, treat it as a true subscription
                    message.meta.repliesSubscription = { notify: true }
                }
                if (message.isRetracted) {
                    message.type = 'retracted'
                    message.ownerId = 1
                }

	            runInAction(() => {
	                this.repliesToMessages.set(this.repliesToId, message)
	            })

            } catch (e) {
                this.repliesToMessages.set(this.repliesToId, null)
            } finally {
                this.isLoadingReplies = false
            }
        // }
        }
        return this.repliesToMessage
    }

    get repliesToMessage() {
        return this.repliesToMessages.get(this.repliesToId)
    }

    startPoll = () => {
        this.stopPoll()

        if (!GET_REPLIES_POLL_ENABLED) return

        this.pollInterval = setInterval(async () => {
            try {
                debug('Replies poll running', this.repliesToId)
                await this.fetchRepliesToMessage()
            } catch (error: any) {
                if (error.status === 401) {
                    this.stopPoll()
                }
            }
        }, POLL_INTERVAL)
    }

    stopPoll = () => {
        if (this.pollInterval) {
            clearInterval(this.pollInterval)
            this.pollInterval = undefined
        }
    }

    async fetchAllUsersIds() {        
        return Promise.all([this.fetchManagerIds(), this.fetchMemberIds()])
    }

    fetchManagerIds = async () => {
        const managers = await this.api.Routes.Room.getManagers(this.roomId, {
            fields: ['id']
        })

        runInAction(() => {
            this.managerIds = managers.map(m => m.id)
            this.managersFetched = true
        })

        return this.managerIds
    }

    async fetchMemberIds() {
        const memberIds = await this.api.Routes.Room.getMembers(this.roomId, {
            fields: ['id']
        })

        runInAction(() => {
            this.memberIds = memberIds?.map(m => m.id)
        })

        return this.memberIds
    }

    async leaveRoom() {
        const isManager = this.managerIds.includes(this.session.currentUserId)

        if (isManager) {
            await this.api.Routes.Room.delManagersById(this.roomId, this.session.currentUserId)
        } else {
            await this.api.Routes.Room.delMembersById(this.roomId, this.session.currentUserId)
        }

        runInAction(() => {
            this.roomStore.deleteById(this.roomId)
        })
    }

    get canCurrentUserManageRoom(): boolean {
        return this.managerIds.includes(this.session.currentUserId)
            || this.currentUserController.canExecutive
    }

    get id(): number {
        return this.roomId
    }
    
    get room(): APIDataRoom {
        return this.roomStore.findById(this.roomId)
    }

    get isInteragnecy() {
        return this.room?.departmentId !== this.session.selectedDepartmentId
    }

    get allowedToPost() {
        // if archived, they can never post
        if (this.room?.isArchived) return false
    
        // executives can always post
        if (this.currentUserController.canExecutive) return true

        // grab room options and determine if they are a manager of this room
        const options = this.room?.options as any
        const isManager = this.managerIds.includes(this.session.currentUserId)

        if (isManager) {
            // if set, if a manager in this room, and if managers are allowed to post
            return options?.roomManagersCanMessage === undefined || options?.roomManagersCanMessage
        } else {
            // if set, if a member in this room, and if members are allowed to post
            return options?.roomMembersCanMessage === undefined || options?.roomMembersCanMessage
        }
    }

    get repliesToId() {
        return this._repliesToId
    }

    set repliesToId(repliesToId) {
        this._repliesToId = repliesToId
    }

    async addMember(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomController.addMember()')
            return
        }

        try {
            await this.api.Routes.Room.putMembersById(this.roomId, userId)

            runInAction(() => {
                this.memberIds.push(userId)
                this.managerIds = this.managerIds.filter(m => m !== userId)
            })
        } catch (error: any) {
            throw new Error(error.message) // rethrow to view
        }
    }

    async addManager(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomController.addManager()')
            return
        }

        try {
            await this.api.Routes.Room.putManagersById(this.roomId, userId)

            runInAction(() => {
                this.managerIds.push(userId)
                this.memberIds = this.memberIds.filter(m => m !== userId)
            })
        } catch (error: any) {
            throw new Error(error.message) // rethrow to view
        }
    }

    async removeUser(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomController.removeUser()')
            return
        }

        const isManager = this.managerIds?.find(m => m === userId)

        if (isManager) {
            await this.removeManager(userId)
        } else {
            await this.removeMember(userId)
        }
    }

    private async removeMember(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomDirectorController.removeMember()')
            return
        }

        try {
            await this.api.Routes.Room.delMembersById(this.roomId, userId)

            runInAction(() => {
                this.memberIds = this.memberIds.filter(m => m !== userId)
            })
        } catch (error: any) {
            throw new Error(error.message) // rethrow to view
        }
    }

    private async removeManager(userId: number) {
        if (!userId) {
            console.error('Missing userId in RoomDirectorController.removeManager()')
            return
        }

        try {
            await this.api.Routes.Room.delManagersById(this.roomId, userId)

            runInAction(() => {
                this.managerIds = this.managerIds.filter(m => m !== userId)
            })
        } catch (error: any) {
            throw new Error(error.message) // rethrow to view
        }
    }

    /**
     * Updates either the active selected replies notifications, or a selected one
     * @param value 
     * @param repliesToId - optional, to update other message repliesToId if viewing list
     */
    async updateRepliesNotification (value: boolean, repliesToId = null) {
        if (!repliesToId) {
            repliesToId = this.repliesToId
        }
        const response = await this.api.Routes.Room.putMessagesNotify(this.roomId, repliesToId, {notify: value})
        runInAction(() => {
            if (repliesToId === this.repliesToId) {
                set(this.repliesToMessage, 'meta.repliesSubscription.notify', response.notify)
            }
        })
    }

    get usersCount() {
        return uniq([...this.memberIds, ...this.managerIds])?.length || 0
    }


}

decorate(injectable(), RoomController)
decorate(inject(Api), RoomController, 0)
decorate(inject(RoomStore), RoomController, 1)
decorate(inject(SessionState), RoomController, 2)
decorate(inject(CurrentUserController), RoomController, 3)

export { RoomController }