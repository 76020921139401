import { Component } from 'react'
import 'spinkit/spinkit.min.css'

//========================================================================================================
export class SpinnerComponent extends Component {
//========================================================================================================

    constructor(props) {
        super(props)

        if (!this.props.name) {
            throw new Error('Loading indicators must have a name prop.')
        }

        if (!this.props.loadingImage && !this.props.children) {
            //throw new Error('Spinner components must have either a loadingImage prop or children to display.');
        }

        this.state = {
            show: !!this.props.show,
            type: this.props.type || 'default'
        }

        this.spinner = this.props.spinner || LoadingIndicator
        this.spinner._register(this)
    }

    componentWillUnmount() {
        this.spinner._unregister(this)
    }

    //==========================================
    // GETTERS & SETTERS
    //==========================================

    get name() {
        return this.props.name
    }

    get group() {
        return this.props.group
    }

    get show() {
        return this.state.show
    }

    set show(show) {
        this.setState({ show })
    }

    set type(type) {
        this.setState({ type })
    }


    //---------------------------------------------------------------------------------------
    render() {
    //---------------------------------------------------------------------------------------

        const { show, type } = this.state

        return (
            <div className={show ? 'fade-in' : 'fade-out'}>
                <div className="spinner">
                    <div className={type === 'full-overlay' ? 'full-overlay ': ''}>
                        {type === 'full-overlay' &&
                        <div className="pb-2">
                            <img src={'/assets/img/evertel-shield-110x110.png'} height="138" className="pb-3" alt="Evertel" />
                            <h1>Loading...</h1>
                            <h3>Just one moment, please</h3>
                        </div>
                        }
                        <div className="sk-three-bounce">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                        {this.props.children}
                    </div>
                </div>
                <div className={type === 'full-overlay' ? 'modal-backdrop opaque show' : 'modal-backdrop show'}></div>
            </div>
        )
    }
}



//==========================================================================================================
export class SpinnerService {
//==========================================================================================================

    constructor() {
        this.spinnerCache = new Set()
    }

    //==========================================
    // REGISTER/UNREGISTER SERVICE
    //==========================================

    _register(spinner) {
        this.spinnerCache.add(spinner)
    }

    _unregister(spinnerToRemove) {
        this.spinnerCache.forEach(spinner => {
            if (spinner === spinnerToRemove) {
                this.spinnerCache.delete(spinner)
            }
        })
    }

    _unregisterGroup(spinnerGroup) {
        this.spinnerCache.forEach(spinner => {
            if (spinner.group === spinnerGroup) {
                this.spinnerCache.delete(spinner)
            }
        })
    }

    _unregisterAll() {
        this.spinnerCache.clear()
    }

    //==========================================
    // SHOW/HIDE METHODS
    //==========================================

    show(spinnerName, type = 'default') {
        this.spinnerCache.forEach(spinner => {
            if (spinner.name === spinnerName) {
                spinner.show = true
                spinner.type = type
            }
        })
    }

    hide(spinnerName, type = 'default') {
        this.spinnerCache.forEach(spinner => {
            if (spinner.name === spinnerName) {
                spinner.show = false
                spinner.type = type
            }
        })
    }

    showGroup(spinnerGroup) {
        this.spinnerCache.forEach(spinner => {
            if (spinner.group === spinnerGroup) {
                spinner.show = true
            }
        })
    }

    hideGroup(spinnerGroup) {
        this.spinnerCache.forEach(spinner => {
            if (spinner.group === spinnerGroup) {
                spinner.show = false
            }
        })
    }

    showAll() {
        this.spinnerCache.forEach(spinner => spinner.show = true)
    }

    hideAll() {
        this.spinnerCache.forEach(spinner => spinner.show = false)
    }

    isShowing(spinnerName) {
        let showing
        this.spinnerCache.forEach(spinner => {
            if (spinner.name === spinnerName) {
                showing = spinner.show
            }
        })
        return showing
    }
}

const LoadingIndicator = new SpinnerService()
export default LoadingIndicator
