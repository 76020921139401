import React, { forwardRef, useState, useEffect, ChangeEventHandler, HTMLAttributes } from 'react'
import classNames from 'classnames'

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'disabled' | 'size'> {
    id?: string,
    name?: string
    className?: string
    /** Delay onChange event while typing. If set to true onChange event will be delayed 500ms, you can also provide the number of milliseconds you want to delay the onChange event.*/
    delay?: boolean | number
    disabled?: boolean
    onChange?: ChangeEventHandler<HTMLInputElement>
    size?: 'sm'|'lg'
    type?: 'color'|'file'|'text'|'email'|'hidden'|'password'|'tel'|'url'|'time'|'number'|string
    value?: string|string[]|number
    placeholder?: string
    maxLength?: number
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
    id,
    name,
    className,
    delay,
    disabled,
    onChange,
    size,
    type = 'text',
    value,
    placeholder,
    maxLength,
    children,
    ...otherProps
}, ref) => {

    const [text, setText] = useState<React.ChangeEvent<HTMLInputElement>>()

    useEffect(() => {
        const timeOutId = setTimeout(() =>
            (text && onChange) && onChange(text),
        (typeof delay === 'number') ? delay : 500
        )
        return () => clearTimeout(timeOutId)

    }, [text])

    const _className = classNames(
        'form-control',
        {
            [`form-control-${size}`]: size,
            'form-control-color': type === 'color'
        //   'is-invalid': invalid,
        //   'is-valid': valid,
        },
        className
    )

    return (
        <input
            ref={ref}
            id={id}
            name={name}
            value={value ?? ''}
            className={_className}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            onChange={(event) => ((delay) ? setText(event) : (onChange) && onChange(event))}
            {...otherProps}>
            {children}
        </input>
    )
})

export { Input }
