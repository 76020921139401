
import { observer } from 'mobx-react'

// evertel
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { DrawerNavItemLink } from '../elements'

interface DrawerProps {
    tag?: string
}


const AccountDrawer: React.FC<DrawerProps> = observer(() => {

    const session = useService(SessionState, [])
 
    return (
        <>
            <DrawerNavItemLink
                label="My Profile"
                icon={{name: 'user'}}
                to={`/account/profile/${session.currentUserId}`}
                selectedTo="/account/profile"
            />
            <DrawerNavItemLink
                label="Preferences"
                icon={{name: 'sliders-h'}}
                to="/account/preferences"
            />
            <DrawerNavItemLink
                label="Security"
                icon={{name: 'lock'}}
                to="/account/security"
            />
            <DrawerNavItemLink
                label="Notifications"
                icon={{name: 'bell'}}
                to="/account/notifications"
            />
            <DrawerNavItemLink
                label="My Agencies"
                icon={{name: 'list-alt'}}
                to={`/account/departments/${session.currentUserId}`}
                selectedTo="/account/departments"
            />
        </>
    )
})

export { AccountDrawer }

