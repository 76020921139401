import { useState, useEffect, useMemo, ComponentType, forwardRef, createElement } from 'react'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { observer } from 'mobx-react-lite'

// evertel
import { DisplayRoomMessage, DisplayThreadMessage } from '@evertel/stores'
import { useVirtuosoScrollSaveState } from '@evertel/web/ui'
import { Message } from './Message'
import { DateBlock } from './elements'

interface MessageListProps {
    modelId: number,
    modelType: 'room' | 'thread',
    repliesToId: number,
    listRef?: any, // ref for the windowed-list component
    listHeaderComponent?: ComponentType<{ context?: any; }>, // component to display at top of list
    isLoading?: boolean, // is data loading. useful for paging/lazy-loading
    onBottomStateChange?: () => void,

    // Windowed List Props
    data: (DisplayRoomMessage | DisplayThreadMessage)[], // loaded data
    groups?: string[],
    groupCounts?: number[],
    renderItem?: () => void, // callback for each item to be rendered. callback includes {index, isScrolling, style}
    onStartReached?: () => void,
    className?: string // applied to outer container of list
}

const INITIAL_MESSAGE_START = 1000000

const MessageList = observer(forwardRef<VirtuosoHandle, MessageListProps>(({
    modelId,
    modelType,
    repliesToId,
    data = [],
    onStartReached,
    listHeaderComponent
}, listRef) => {

    const stateKey = `${modelType}-${modelId}-${repliesToId}`
    const { virtuosoRef, scrollerRef, getRestoreState, saveState } = useVirtuosoScrollSaveState(stateKey)

    const [initialDate, setInitialDate] = useState<Date | null>(null)
    const [isScrolling, setIsScrolling] = useState(false)

    useEffect(() => {
        if (initialDate === null && data.length > 0) {
            //grab the newest date from the end of the data
            setInitialDate(data[data.length - 1].createdDate)
        }
        return () => {
            //saving the state here fails as virtuoso has already deconstructed
        }
    }, [data, initialDate])

    useEffect(() => {
        setInitialDate(null)
    }, [modelType, modelId, repliesToId])

    const [firstItemIndex, initialTopMostItemIndex, initialMessageIndex] = useMemo(() => {
        if (initialDate && data.length) {
            // Key off initialDate on all new message loads so virtuoso properly keeps track of new items
            // @ts-expect-error findLastIndex is too new for our ts config
            const indexedDate = data.findLastIndex(msg => msg.createdDate <= initialDate)
            return [INITIAL_MESSAGE_START - indexedDate, data.length - 1, indexedDate]
        }
        return [INITIAL_MESSAGE_START, 0]
    }, [data, initialDate])

    const localStartReached = () => {
        onStartReached()
    }

    if (!data.length || !initialDate) {
        return (
            <div style={{ height: '100%', overflowY: 'auto' }}>
                {createElement(listHeaderComponent)}
            </div>
        )
    }

    const restoreState = getRestoreState()

    return (
        <Virtuoso
            tabIndex={-1}
            id="virtuoso-scroller"
            //logLevel={LogLevel.DEBUG}

            key={stateKey}
            computeItemKey={(key, msg) => `item-${msg.id}`}
            ref={setRefs(virtuosoRef, listRef)}
            // Save/restore works and scrolls to last location:
            // Issues: 1) won't scroll for very bottom items
            //         2) offset broken when header exists
            restoreStateFrom={restoreState}
            scrollerRef={(ref) => { scrollerRef.current = ref }}

            
            //old method to save on scroll:
            // rangeChanged={(range) => saveState() }

            // context={{ isScrolling }}

            data={data}
            firstItemIndex={firstItemIndex}
            //can't have both restore and initialTopMostItemIndex at the same time
            {...(!restoreState && { initialTopMostItemIndex: initialTopMostItemIndex })}
            // alignToBottom={true}

            startReached={localStartReached}

            defaultItemHeight={200}
            increaseViewportBy={{ top: 600, bottom: 300 }} //number of px above current view to pre-render
            atBottomThreshold={350} //scroll to bottom if within this many px on new message
            followOutput={'smooth'}
            //isScrolling={setIsScrolling} //causes many re-loads

            itemContent={(index, msg) => {
                //NOTE: index is an arbitrary Virtuoso offset id, not any useful data array index
                return (<MessageMe msg={msg} modelType={modelType} isScrolling={isScrolling} index={index} />)
            }}
            components={{
                // Header: () => { return <h1 style={{ height: 100 }}> hello</ h1> }
                Header: listHeaderComponent
            }}
        />
    )
}))

// const mountCount = []
function MessageMe({ msg, modelType, isScrolling, index }: {
    msg: DisplayRoomMessage
    modelType: 'room' | 'thread'
    isScrolling: boolean
    index: number
}): JSX.Element {
    //for testing
    // useEffect(() => {
    //     mountCount[index] = (mountCount[index] ?? 0) + 1
    // }, [])
    // const renderCount = useRef(0)
    // renderCount.current += 1

    if (!msg) return null

    return (
        /* Must collapse border with wrapper or scrolling breaks */
        <div style={{ padding: '1px' }}>
            {msg.dateChanged &&
                <DateBlock
                    date={msg.createdDate}
                />
            }
            {/* <h3>Render#:{renderCount.current}</h3> */}
            {/* <h1>{msg.ownerId} --- {msg.id}</h1> */}
            {/* <h3>Mount#:{mountCount[index]}</h3> */}
            {/* <p>{JSON.stringify(msg)}</p> */}
            {/* <p>{msg.text}</p> */}
            <Message
                message={msg}
                modelType={modelType}
                isScrolling={isScrolling}
                showHoverBar={!msg.isRetracted}
            />
        </div>
    )
}

/*
 * helper method to set multiple refs when using forwardRef but
 * a local ref is still needed.  No way to tell if the passed ref
 * is a ref or a callback function
 */
function setRefs(...refs) {
    return (instance) => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(instance)
            } else if (ref) {
                ref.current = instance
            }
        })
    }
}

MessageList.displayName = 'MessageList'

export { MessageList }
