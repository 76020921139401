import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import LaddaButton from 'react-ladda-button'
import classNames from 'classnames'
import { Button as StandardButton, ButtonDropdown, ButtonDropdownToggle, ButtonDropdownMenu, ButtonDropdownItem } from '@evertel/web/ui'
import Colors from '../scss/_colors.module.scss'

// Ladda Button Docs -> https://www.npmjs.com/package/react-ladda

const propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'l', 'xl', 's', 'sm', 'lg']),
    spinnerStyle: PropTypes.oneOf(['contract', 'contract-overlay', 'expand-left', 'expand-right', 'expand-up', 'expand-down', 'slide-left', 'slide-right', 'slide-up', 'slide-down', 'zoom-in', 'zoom-out']),
    spinnerSize: PropTypes.number,
    spinnerColor: PropTypes.string,
    spinnerLines: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
    progress: PropTypes.number,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    block: PropTypes.bool,
    type: PropTypes.string, // 'submit' | 'dropdown' | 'standard'
    active: PropTypes.bool,
    dropdownItems: PropTypes.array, // [{label: 'string', onClick: 'callback function', header: bool, disabled: bool, divider: bool}]
    dropdownDirection: PropTypes.oneOf(['up', 'down', 'left', 'right']),
    style: PropTypes.object
}

const defaultProps = {
    spinnerStyle: 'expand-left',
    className: '',
    disabled: false,
    loading: false,
    dropdownDirection: 'down',
    dropdownItems: [],
    spinnerSize: 20
}


class Button extends PureComponent {

    state = {
        isDropdownOpen: false
    }

    toggleDropdown = () => {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        })
    }


    render() {
        const {
            children,
            type,
            spinnerStyle,
            color,
            size,
            disabled,
            spinnerSize,
            spinnerColor,
            spinnerLines,
            outline,
            block,
            active,
            dropdownItems,
            dropdownDirection,
            className,
            id,
            onClick,
            style,
            loading,
            ...otherProps
        } = this.props
        const { isDropdownOpen } = this.state

        if (type === 'submit') {
            return (
                <LaddaButton
                    data-style={spinnerStyle}
                    data-size={size}
                    data-color={color}
                    data-spinner-size={spinnerSize}
                    data-spinner-lines={spinnerLines}
                    data-spinner-color={(outline) ? Colors[color] : spinnerColor}
                    className={classNames('btn', 'btn-ladda', {['btn-'+color]: color && !outline, ['btn-outline-'+color]: color && outline, 'w-100': block, [className]: className})}
                    onClick={(disabled) ? null : onClick}
                    disabled={disabled}
                    loading={loading}
                    style={style}
                    {...otherProps}>
                    {children}
                </LaddaButton>
            )
        } else if (type === 'dropdown') {
            return (
                <ButtonDropdown
                    isOpen={isDropdownOpen}
                    toggle={this.toggleDropdown}
                    direction={dropdownDirection}
                    className={className}
                    style={style}
                    {...otherProps}>
                    <ButtonDropdownToggle
                        caret
                        color={color}
                        disabled={disabled}>
                        {children}
                    </ButtonDropdownToggle>
                    <ButtonDropdownMenu>
                        {dropdownItems.map((item, idx) =>
                            <ButtonDropdownItem
                                divider={item.divider || false}
                                disabled={item.disabled || false}
                                header={item.header || false}
                                onClick={item.onClick || null}
                                key={idx}>
                                {item.label}
                            </ButtonDropdownItem>
                        )}
                    </ButtonDropdownMenu>
                </ButtonDropdown>
            )
        } else {
            return (
                <StandardButton
                    id={id}
                    color={color}
                    outline={outline}
                    block={block}
                    //loading={this.props.loading?.toString()}
                    disabled={disabled}
                    className={className}
                    style={{fontSize: 'inherit', ...style}}
                    size={size}
                    active={active}
                    onClick={onClick}>
                    {children}
                </StandardButton>
            )
        }
    }
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps
export default Button
