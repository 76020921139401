import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
// evertel
import { InfoBox, Button, Input, Modal, ModalBody, Text, ModalHeader, ModalFooter, Label } from '@evertel/web/ui'
import { RoomSettingsForm } from '@evertel/web/room'
import { useService } from '@evertel/di'
import { CreateRoomController } from '@evertel/room'

interface CreateRoomModalProps {
    visible: boolean,
    onClose: () => void
}


const CreateRoomModal: React.FC<CreateRoomModalProps> = observer(({
    visible,
    onClose
}) => {

    const createRoomController = useService(CreateRoomController, [])

    const navigate = useNavigate()

    const defaultRoomConfig = {
        isSearchable: false,
        name: '',
        description: '',
        options: {
            autoAddDepartmentMembers: false,
            departmentMembersCanJoinAsMember: true,
            roomMembersCanMessage: true,
            detectScreenshots: true,
            roomMembersCanLeave: true
        }
    }

    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [roomConfig, setRoomConfig] = useState(defaultRoomConfig)

    const onChange = (e) => {
        setRoomConfig((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const toggleOptions = (option: string, value: boolean) => {
        const configCopy = {...roomConfig}

        if (option === 'isSearchable') {
            configCopy.isSearchable = value
        } else {
            configCopy.options[option] = value
        }

        setRoomConfig(configCopy)
    }

    const createRoom = async () => {
        // no name then ye shall not pass
        if (!roomConfig.name || !roomConfig.name.trim()) {
            setError('Please give your room a name')
            return
        }

        setIsLoading(true)

        try {
            const room = await createRoomController.createRoom(roomConfig)

            // close modal and go to new room
            navigate(`/room/${room.id}?new=true`)
            onClose()

        } catch (error) {
            setError(error.message)
        }

        setIsLoading(false)
    }

    const modalClose = () => {
        setRoomConfig(defaultRoomConfig)

        onClose()
    }

    return (
        <Modal
            visible={visible}
            onClose={modalClose}
            size="lg"
        >
            <ModalHeader
                title="Create a Room"
                subtitle="Name your room and set your room options"
            />
            <ModalBody className="mb-4">
                {(error) &&
                    <InfoBox
                        color="danger"
                        className="text-center">
                        {error}
                    </InfoBox>
                } 
                {/* ROOM NAME */}
                <Label
                    text="Name Your Room"
                    bold
                />
                <Input
                    name="name"
                    type="text"
                    value={roomConfig.name}
                    onChange={onChange}
                    placeholder="Add a room name..."
                    className="mb-3"
                    maxLength={150}
                />
                {/* ROOM DESCRIPTION */}
                <Label
                    text="Purpose/Description"
                    bold
                    optional
                />
                <Input
                    name="description"
                    type="text"
                    value={roomConfig.description}
                    onChange={onChange}
                    placeholder="Add a short description..."
                    maxLength={300}
                />
                <RoomSettingsForm
                    onChange={toggleOptions}
                    options={roomConfig?.options}
                    isSearchable={roomConfig?.isSearchable}
                    hideOptions={['isArchived', 'accessCriteria']}
                    className="mt-5"
                />                        
            </ModalBody>
            <ModalFooter
                align="right"
                color="light">
                <Button
                    color="success"
                    className="mr-2 my-2"
                    onClick={createRoom}
                    loading={isLoading}>
                    Create Room
                </Button>
            </ModalFooter>  
        </Modal>
    )
})

export { CreateRoomModal }
