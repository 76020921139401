import React from 'react'
import { Row, Text } from '@evertel/web/ui'
import classNames from 'classnames'
import moment from 'moment'


const DateBlock = ({ date }: { date: string | Date }) => {

    const todaysDate = moment().format('dddd, MMMM Do')
    const currentYear = moment().year()
    const displayDate = (moment(date).year() === currentYear) ? moment(date).format('dddd, MMMM Do') : moment(date).format('dddd, MMMM Do, YYYY')
    const isToday = displayDate === todaysDate

    return (
        <Row
            align="center"
            className="date-block">
            <Text
                tag="h3"
                className={classNames({ 'text-secondary': isToday })}
                heavy>
                {(isToday) ? 'Today' : displayDate}
            </Text>
        </Row>
    )
}

export { DateBlock }
