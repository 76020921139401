import { useState, useEffect } from 'react'
import ReactPasswordStrength from 'react-password-strength'
import EmailValidator from 'email-validator'
import { observer } from 'mobx-react'
// evertel
import { AnalyticsStore, NavigationStore, RegistrationStore, VerifiedEmailDomainsStore } from '../../../stores'
import { LoadingIndicator } from '../../../components'
import { InfoBox, Card, CardBody, Col, Container, Input, Icon, Button, InputGroup, InputGroupText, Row } from '@evertel/web/ui'
import { useUI } from '@evertel/web/ui'
import { useLocation } from 'react-router-dom'


const Register = observer(() => {

    const location = useLocation()

    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        confirmPassword: '',
        passwordMatchError: false,
        showDisclaimer: false,
        isEmailValid: false,
        isPasswordValid: false,
        isLoading: false,
        error: null,
        confirmConfig: {},
        showPassword: false,
        showConfirmPassword: false
    })

    const { isConfirmed } = useUI()

    useEffect(() => {
        AnalyticsStore.logPageView('/register/create-account')
    }, [])

    useEffect(() => {
        comparePasswords()
    }, [state.password, state.confirmPassword])


    //==============================================
    // FORM CHANGE HANDLERS
    //==============================================

    const onChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        // validate email address
        if (e.target.name === 'email') {
            setState((prevState) => ({
                ...prevState,
                isEmailValid: EmailValidator.validate(e.target.value)
            }))
        }
    }

    const validateEmail = (e) => {
        let isValid = state.isEmailValid
        if (!isValid) {
            setState((prevState) => ({
                ...prevState,
                emailFeedback: 'Email seems to be invalid!',
                isEmailValid: false
            }))
        } else {
            setState((prevState) => ({
                ...prevState,
                emailFeedback: '',
                isEmailValid: true
            }))
        }
    }

    const onPasswordChange = (e, result) => {
        // e = {isValid: bool, password: string, score: number 0-4}

        setState((prevState) => ({
            ...prevState,
            showMinPasswordLevel: true,
            password: e.password,
            isPasswordValid: e.isValid,
            passwordSuggestions: result?.feedback?.suggestions,
            passwordWarning: result?.feedback?.warning
        }))
    }

    const comparePasswords = () => {
        setState((prevState) => ({
            ...prevState,
            passwordsMatch: prevState.confirmPassword === prevState.password
        }))
    }


    //==============================================
    // CREATE ACCOUNT
    //==============================================

    const createAccount = async () => {
        const { firstName, lastName, email, password, confirmPassword, passwordsMatch, isPasswordValid } = state
        const firstNameTrim = firstName?.trim()
        const lastNameTrim = lastName?.trim()
        const emailAndPasswordCheck = email?.toLowerCase() === password?.toLowerCase()

        let error = null

        setState((prevState) => ({
            ...prevState,
            error
        }))

        // check for errors
        if (!firstName || !lastName || !email || !password || !confirmPassword || !firstNameTrim.length || !lastNameTrim.length) {
            // make sure all fields are filled out
            error = 'Please fill out all fields'
        } else if (!isPasswordValid) {
            // make sure password meets minimum strength score
            error = 'Your password does not meet our minimum strength requirements of "good". Please revise and try again'
        } else if (!passwordsMatch) {
            // make sure passwords match
            error = 'Your passwords do not match'
        } else if (!EmailValidator.validate(email)) {
            // make sure email is valid
            error = 'Please enter a valid email address'
        } else if (emailAndPasswordCheck) {
            error = 'Your password cannot match your work email. Please change it and try again'
        }

        // if there's an error, show InfoBox and stop
        if (error) {
            setState((prevState) => ({
                ...prevState,
                error
            }))
            return
        }

        // check that email domain is a government email
        const emailDomain = email.substring(email.lastIndexOf('@') + 1)
        const isGovernmentEmail = await VerifiedEmailDomainsStore.getDomain(emailDomain)

        if (!isGovernmentEmail) {
            const confirmed = await isConfirmed({
                title: 'Is this your work email?',
                message: `<h4 class="mb-4"><strong><span class="text-info">${email}</span> does not appear to be a government email.</strong></h4>It is highly recommended to use your work email with Evertel. If this is your work email. Click "Continue with this email".`,
                acceptButton: {
                    label: 'Continue with this Email',
                    color: 'muted'
                },
                cancelButton: {
                    label: 'Change Email',
                    color: 'success'
                },
                headerClassName: 'bg-info'
            })

            if (!confirmed) return
        }

        LoadingIndicator.show('page-loading')
        setState((prevState) => ({
            ...prevState,
            isLoading: true
        }))

        // everything looks ok, then create account
        await RegistrationStore.createAccount({
            email,
            password,
            firstName: firstNameTrim,
            lastName: lastNameTrim
        })

        LoadingIndicator.hide('page-loading')
        setState((prevState) => ({
            ...prevState,
            isLoading: false
        }))

        // check for API errors
        const err = RegistrationStore.error
        if (err) {
            const errorMsg = (err.message?.includes('Email unavailable')) ? <span>An account with this email already exists. Please go back and <a href="/login">login</a></span> : err.message

            setState((prevState) => ({
                ...prevState,
                error: errorMsg
            }))
            return
        }

        // grab query string
        const qs = new URLSearchParams(location?.search)
        let crisisResponseFields = {}
        // if crisis response, set extra registration fields
        if (qs.get('crisis')) {
            localStorage.setItem('crisis', true)
            crisisResponseFields = {
                isCrisisResponse: true,
                department: 'skipped',
                profile: 'skipped'
            }
        }

        // update registration steps and move on to next step
        RegistrationStore.updateRegistrationSteps({
            ...RegistrationStore.registrationSteps,
            ...crisisResponseFields,
            account: 'complete'
        })
        RegistrationStore.goToNextStep()
    }

    const handleShowPassword = (input) => {
        if (input === 'confirmation') {
            setState((prevState) => ({
                ...prevState,
                showConfirmPassword: !state.showConfirmPassword
            }))
        } else {
            setState((prevState) => ({
                ...prevState,
                showPassword: !state.showPassword
            }))
        }
    }


    return (
        <div className="app  fadeIn animated registration bg-img" style={{ height: '100vh', overflow: 'auto' }}>
            <Row className="d-flex justify-content-center align-items-top m-4">
                <Col className="text-center" style={{ maxWidth: 600 }}>
                    <Card className="">
                        <CardBody className="p-4">
                            <img
                                src={'/assets/img/evertel-shield-110x110.png'}
                                width="70"
                                alt="Evertel"
                            />
                            <h1>Create your Evertel account!</h1>
                            <p className="text-muted">
                                Stop using nonsecure SMS texting and public messaging apps that may be illegal in your state!
                                Keep your work related messaging safe and compliant with Evertel.
                            </p>

                            {(state.error) &&
                                <InfoBox color="danger">
                                    {state.error}
                                </InfoBox>
                            }

                            {/* FIRST NAME */}
                            <InputGroup className="mb-3 mt-4">
                                <InputGroupText>
                                    <Icon
                                        name="user"
                                    />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    value={state.firstName}
                                    disabled={state.isLoading}
                                    placeholder="First Name"
                                    name="firstName"
                                    required
                                    onChange={onChange}
                                />
                                <div className="invalid-feedback" />
                            </InputGroup>

                            {/* LAST NAME */}
                            <InputGroup className="mb-3">
                                <InputGroupText>
                                    <Icon
                                        name="user"
                                    />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    value={state.lastName}
                                    placeholder="Last Name"
                                    name="lastName"
                                    disabled={state.isLoading}
                                    required
                                    onChange={onChange}
                                />
                                <div className="invalid-feedback" />
                            </InputGroup>

                            {/* EMAIL */}
                            <InputGroup className="mb-3">
                                <InputGroupText className={(state.email && state.isEmailValid) ? 'bg-success border-success' : (state.email && !state.isEmailValid) ? 'bg-danger border-danger' : ''}>
                                    <Icon
                                        name="at"
                                    />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    value={state.email}
                                    disabled={state.isLoading}
                                    placeholder="Work Email (you will use this to login)"
                                    name="email"
                                    onChange={onChange}
                                    onBlur={validateEmail}
                                />
                                <div className="d-flex invalid-feedback d-block flex-row">
                                    {state.emailFeedback}
                                </div>
                            </InputGroup>

                            {/* PASSWORD */}
                            <InputGroup>
                                <InputGroupText className={state.isPasswordValid ? 'bg-success border-success' : ''}>
                                    <Icon
                                        name="lock"
                                    />
                                </InputGroupText>
                                <ReactPasswordStrength
                                    minLength={8}
                                    minScore={2}
                                    scoreWords={['too weak', 'too weak', 'good', 'strong', 'very strong']}
                                    changeCallback={onPasswordChange}
                                    inputProps={{
                                        type: state.showPassword ? 'text' : 'password',
                                        name: 'password',
                                        disabled: state.isLoading,
                                        autoComplete: 'off',
                                        placeholder: 'Create your password',
                                        className: 'form-control no-focus'
                                    }}
                                    userInputs={[state.firstName, state.lastName, state.email]}
                                />
                                <InputGroupText className='btn-ghost-muted' tag="button" onClick={() => handleShowPassword()}>
                                    <Icon
                                        name={state.showPassword ? 'eye-slash' : 'eye'}
                                    />
                                </InputGroupText>
                            </InputGroup>
                            <div className="w-100 d-flex text-start pb-2">
                                <small className="text-muted">
                                    {(state.passwordWarning) &&
                                        <div className="text-danger">{state.passwordWarning}.</div>
                                    }
                                    {(state.passwordSuggestions) ?
                                        state.passwordSuggestions.map((s, idx) =>
                                            <span key={idx} className='pr-1'>
                                                {(idx === 0) && ' Suggestions:'}{' ' + s}
                                            </span>
                                        )
                                        : 'Minimum 8 characters.'
                                    }
                                    &nbsp;
                                </small>
                            </div>

                            {/* CONFIRM PASSWORD */}
                            <InputGroup>
                                <InputGroupText className={(state.confirmPassword && state.passwordsMatch) ? 'bg-success border-success' : (state.confirmPassword && !state.passwordsMatch) ? 'bg-danger border-danger' : ''}>
                                    <Icon
                                        name="lock"
                                    />
                                </InputGroupText>
                                <Input
                                    type={state.showConfirmPassword ? 'text' : 'password'}
                                    value={state.confirmPassword}
                                    disabled={state.isLoading}
                                    name="confirmPassword"
                                    autoComplete="off"
                                    placeholder="Repeat password"
                                    onChange={onChange}
                                />
                                <InputGroupText className='btn-ghost-muted' tag="button" onClick={() => handleShowPassword('confirmation')}>
                                    <Icon
                                        name={state.showConfirmPassword ? 'eye-slash' : 'eye'}
                                    />
                                </InputGroupText>
                            </InputGroup>
                            {(state.confirmPassword && !state.passwordsMatch) &&
                                <div className="w-100 d-flex text-start pt-2 pb-2 text-danger">
                                    <small>Your passwords do not match.</small>
                                </div>
                            }

                            {/* TERMS OF USE & PRIVACY */}
                            <div className="py-4">
                                <small>
                                    <span>By creating an account with Evertel I agree to the </span>
                                    <a href="https://getevertel.com/terms/" className="btn btn-link" style={{ fontSize: 'revert' }} target="_blank" rel="noopener noreferrer"> Terms of Use </a>
                                    <span> and </span>
                                    <a href="https://getevertel.com/privacy/" className="btn btn-link" style={{ fontSize: 'revert' }} target="_blank" rel="noopener noreferrer"> Privacy Policy </a>
                                </small>
                            </div>

                            {/* SUBMIT BTTN */}
                            <Button
                                color="success"
                                size="xl"
                                type="submit"
                                disabled={state.isLoading}
                                loading={state.isLoading}
                                block
                                onClick={createAccount}
                                className="mb-4 w-100">
                                Create Account
                            </Button>
                            Already have an account?
                            <Button
                                color="link"
                                onClick={() => NavigationStore.navigate('/login')}
                                className="ml-2">
                                Login here
                            </Button>
                        </CardBody>
                    </Card>
                </Col>

                {/* BENEFITS BLOCK */}
                <Col className="ml-5 d-none d-lg-block" style={{ maxWidth: 300 }}>
                    <Card>
                        <CardBody className="p-4">
                            <h4 className="weight-900 text-center">
                                5 Reasons you'll love Evertel
                            </h4>
                            <ol className="pt-2 pl-3">
                                <li>Collaborate and share intel across your region</li>
                                <li>Share CJI securely and legally</li>
                                <li>True interoperability across borders</li>
                                <li>No more endless email threads</li>
                                <li>Built specifically for public safety!</li>
                            </ol>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
})

export default Register
