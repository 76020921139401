import React from 'react'

interface HiddenContentProps {
    visible?: boolean;
    children: React.ReactNode;
}

const HiddenContent: React.FC<HiddenContentProps> = ({ visible = true, children }) => {
    const style: React.CSSProperties = {
        display: visible ? 'contents' : 'none',
        visibility: visible ? 'visible' : 'hidden'
        // height: visible ? 'auto' : '0',
        // overflow: 'hidden'
    }

    return <div className="HiddenContent" style={style}>{children}</div>
}

export { HiddenContent }