import { useRef, Suspense, useEffect, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'

// evertel
import { MessageWall, MessageSearchModal } from '@evertel/web/message'
import { DocumentModalContextProvider } from '@evertel/web/document'
import { RoomHeader,
    RightGutter,
    RoomSettings,
    RoomMediaSearch,
    RoomDirectory,
    RoomReplies } from './elements'
import {
    PhotoGallery, Spinner, SlidePanel, SlidePanelHeader,
    Row, Text, CloseButton, SlidePanelBody, Badge, InfoBox, Col, HiddenContent,
    Button
} from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { RoomController } from '@evertel/room'
import { SessionState } from '@evertel/session'
import { formatNumber } from '@evertel/utils'
import { DepartmentEmojisController } from '@evertel/emojis'
import { CurrentUserController, UnreadCountsState, UserSettingsController } from '@evertel/blue-user'
import { DrawerController } from '@evertel/message'
import { RoomContext } from './RoomContext'
import { RoomJoinRequestsController } from '@evertel/room'

const Room = observer(() => {

    const { id: currentUrlIdString, repliesToId: repliesToIdString } = useParams<{id: string, repliesToId?: string}>()
    const currentRoomId = currentUrlIdString ? parseInt(currentUrlIdString, 10) : null
    const repliesToId = repliesToIdString ? parseInt(repliesToIdString, 10) : null

    const session = useService(SessionState, [])
    const unreadState = useService(UnreadCountsState, [])

    const userSettingsController = useService(UserSettingsController, [])
    const currentUserController = useService(CurrentUserController, [])

    const drawerController = useService(DrawerController, [session.selectedDepartmentId])
    const roomController = useService(RoomController, [currentRoomId])
    const departmentEmojisController = useService(DepartmentEmojisController, [session.selectedDepartmentId])
    const roomJoinRequestsController = useService(RoomJoinRequestsController, [currentRoomId])

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [showAsideTab, setShowAsideTab] = useState(null)
    const [openAside, setOpenAside] = useState(false)
    const [asideOpenComplete, setAsideOpenComplete] = useState(false)
    const [isMessageSearchModalOpen, setIsMessageSearchModalOpen] = useState(false)
    const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null)

    const initializationAttempted = useRef(false)
    useEffect(() => {
        if (!currentRoomId) {
            navigate('/landing')
        }
        initializationAttempted.current = false
    }, [currentRoomId])

    const asideTabs = useMemo(() => ({
        directory: {
            title: 'Room Members',
            component: RoomDirectory
        },
        replies: {
            title: 'Replies',
            component: RoomReplies,
            markAll: async () => unreadState.markAllRepliesAsRead(currentRoomId) 
        },
        settings: {
            title: 'Room Settings',
            component: RoomSettings
        },
        'media-search': {
            title: 'File Search',
            component: RoomMediaSearch
        },
        closed: {
            title: null,
            component: () => null
        }
    }), [])

    useEffect(() => {
        document.body.classList.add('aside-menu-hide')
        document.addEventListener('keydown', handleEscape)

        return () => {
            document.body.classList.remove('aside-menu-hide')
            document.removeEventListener('keydown', handleEscape)
        }
    }, [openAside])

    const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            toggleAside(showAsideTab, 'close')
        }
    }


    if (currentRoomId && !roomController.roomId && !initializationAttempted.current) {
        //Goal: initialize roomController as soon as possible before first render
        initializationAttempted.current = true
        setIsLoading(true)
        roomController.init(currentRoomId, repliesToId)
            .catch((error) => {
                console.error('Room init', error.message)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (roomController.room) {
            const departmentId = roomController.room.departmentId
            const isInterAgencyRoom = drawerController.interAgencyRooms.some(room => departmentId === room.departmentId)

            //update the selectedDepartmentId if a room is entered that doesn't match the current department
            if (departmentId && departmentId !== session.selectedDepartmentId && !isInterAgencyRoom) {
                session.setSelectedDeparmentId(departmentId)
                // useEffect will be triggered again after this, so no point in running init requests
                //     and we'd want to keep isLoading true for now
                return
            }

            departmentEmojisController.init(session.selectedDepartmentId)
            userSettingsController.init(session.currentUserId)

            setIsLoading(false)
        }
    }, [roomController.room, session.selectedDepartmentId])

    useEffect(() => {
        roomController.repliesToId = repliesToId

        if (repliesToId) {
            roomController.fetchRepliesToMessage()
            roomController.startPoll()
        }

        return () => {
            roomController.stopPoll()
        }
    }, [repliesToId])

    useEffect(()=>{
        const canManageRequests = roomController?.canCurrentUserManageRoom && currentUserController.getRoleInDepartment(roomController.room?.departmentId) !== 'guest'
        if (canManageRequests) {
            roomJoinRequestsController.init(currentRoomId)
        }
    }, [roomController.roomId, roomController.managerIds])

    useEffect(() => {
        const container = document.getElementById('slide-panel-root')
        if (container) setPortalContainer(container)

        return () => { setPortalContainer(null) }
    }, [])

    const toggleAside = (tab = 'directory', intent: 'open'|'close') => {
        if (intent === 'close') {
            setOpenAside(false)
            setShowAsideTab(null)
        } else if (intent === 'open') {
            setShowAsideTab(tab)
            setOpenAside(true)
        }
    }

    const contextValues = {
        canManageRoom: roomController?.canCurrentUserManageRoom,
        roomController,
        departmentEmojisController,
        userSettingsController,
        roomJoinRequestsController,
        setIsMessageSearchModalOpen,
        toggleAside,
        asideOpenComplete
    }

    const AsideComponent = asideTabs[showAsideTab]?.component || asideTabs.closed.component

    return (
        <RoomContext.Provider value={contextValues}>
            <PhotoGallery>
                <DocumentModalContextProvider>
                    <Row valign='stretch' className="room-container h-100">
                        <Col valign='start' className="w-100">
                            <RoomHeader />

                            { !roomController.room &&
                                <InfoBox
                                    color="warning"
                                    className="m-3"
                                >
                                    Sorry, this room was not found or you do not have access to it
                                </InfoBox>
                            }
                            <HiddenContent visible={!!roomController.room}>
                                <MessageWall
                                    modelId={roomController.id}
                                    modelType="room"
                                    modelData={roomController.room}
                                    allowedToPost={roomController.allowedToPost}
                                    repliesToMessage={roomController.repliesToMessage}
                                />
                            </HiddenContent>
                            
                        </Col>
                        <HiddenContent visible={!!roomController.room}>
                            <RightGutter
                                activeTab={(openAside) && showAsideTab}
                                toggleAside={toggleAside}
                            />
                        </HiddenContent>
                    </Row>

                    <Suspense fallback={<Spinner />}>
                        <MessageSearchModal
                            visible={isMessageSearchModalOpen}
                            onClose={() => setIsMessageSearchModalOpen(false)}
                            modelType="room"
                            modelId={roomController.room?.id}
                            modelName={roomController.room?.name}
                        />
                    </Suspense>

                    {portalContainer && createPortal(
                        <SlidePanel
                            //embed
                            backdrop={false}
                            placement="end"
                            visible={openAside}
                            onHide={() => setAsideOpenComplete(false)}
                            onShowComplete={() => setAsideOpenComplete(true)}
                            className="room-aside"
                        >
                            <SlidePanelHeader>
                                <Text
                                    heavy
                                    className="text-capitalize"
                                >
                                    {asideTabs[showAsideTab]?.title}
                                    {(showAsideTab === 'directory') &&
                                        <Badge color="muted" className="ml-2">
                                            {formatNumber(roomController.usersCount)}
                                        </Badge>
                                    }
                                </Text>
                                <div>
                                    { asideTabs[showAsideTab]?.markAll &&
                                        <Button
                                            className='mr-2'
                                            color='link'
                                            onClick={asideTabs[showAsideTab]?.markAll}
                                        >
                                            Mark all as read
                                        </Button>
                                    }
                                    <CloseButton onClick={() => toggleAside(showAsideTab, 'close')} />
                                </div>
                            </SlidePanelHeader>
                            <SlidePanelBody>
                                <AsideComponent/>
                            </SlidePanelBody>
                        </SlidePanel>,
                        portalContainer
                    )}

                </DocumentModalContextProvider>
            </PhotoGallery>
        </RoomContext.Provider>
    )
})

Room.displayName = 'Room'

export { Room }
