import { createContext } from 'react'
import { RoomController } from '@evertel/room'
import { DepartmentEmojisController } from '@evertel/emojis'
import { UserSettingsController } from '@evertel/blue-user'
import { RoomJoinRequestsController } from '@evertel/room'
import { RepliesViewController } from '@evertel/message'

export interface RoomContextProps {
    canManageRoom: boolean,
    roomController: RoomController,
    departmentEmojisController: DepartmentEmojisController,
    userSettingsController: UserSettingsController,
    roomJoinRequestsController: RoomJoinRequestsController,
    setIsMessageSearchModalOpen: (state: boolean) => void,
    toggleAside: (tab: string, intent: 'open'|'close') => void,
    asideOpenComplete: boolean
}

export const RoomContext = createContext({} as RoomContextProps)
