import React from 'react'
import { Text } from '@evertel/web/ui'
import { APIDataRoom, APIDataThread } from '@evertel/types'
import moment from 'moment'

interface Props {
    modelData: APIDataRoom | APIDataThread,
}

const MessageHeaderRoom = ({ modelData }: Props) => {
    const room = (modelData as APIDataRoom) || {}
    let details = 'This is an open Team room. Anyone in the agency can see and join this room.'


    if (room.options?.autoAddDepartmentMembers) {
        details = 'This is an Agency-Wide (broadcast) room. All agency users are auto-added to this room.'
    } else if (room.isSearchable) {
        details = 'This is an Incident Command room. Any Evertel user in any agency can see and request to join this room.'
    } else if (!room.options?.departmentMembersCanJoinAsMember) {
        details = 'This is a private room. Only those invited can see and join this room.'
    }

    return (
        <>
            <Text
                tag="h2"
                heavy>
            #{room.name}
            </Text>
            <Text
                italic
                color="muted">
                {`Created on ${moment(room.createdDate).format('L')}`}
            </Text>
            <Text>{details}</Text>
        </>
    )

}

export default MessageHeaderRoom 
