export const botMessageActions = [
    {
        label: 'Navigate to Screen',
        value: 'navigate'
    },
    {
        label: 'Open URL',
        value: 'url'
    },
    {
        label: 'Open Drawer',
        value: 'drawer'
    }
]

export const botMessageActionsNavigationOptions = [
    {
        label: 'Settings',
        value: 'settings',
        location: '/account/profile/'
    },
    {
        label: 'Settings -> Theme',
        value: 'theme',
        location: '/account/preferences/'
    },
    {
        label: 'Settings -> Profile',
        value: 'profile',
        location: '/account/profile/'
    },
    {
        label: 'Settings -> My Agencies',
        value: 'myAgencies',
        location: '/account/departments/'
    },
    {
        label: 'Settings -> Edit Email',
        value: 'editEmail',
        location: '/account/profile/'
    },
    {
        label: 'Settings -> Notifications',
        value: 'notifications',
        location: '/account/notifications/'
    },
    {
        label: 'Settings -> PIN',
        value: 'editPIN',
        location: 'mobile'
    },
    {
        label: 'Settings -> Reset Password',
        value: 'resetPassword',
        location: '/account/profile/'
    },
    {
        label: 'Join Room',
        value: 'joinRoom',
        location: 'modal'
    },
    {
        label: 'Create Room',
        value: 'createRoom',
        location: 'modal'
    },
    {
        label: 'Add Room --> Agency-Wide Room',
        value: 'createAgencyWideRoom',
        location: 'modal'
    },
    {
        label: 'Create Room --> Private Room',
        value: 'createPrivateRoom',
        location: 'modal'
    },
    {
        label: 'Create Room --> Crisis Room',
        value: 'createCrisisRoom',
        location: 'modal'
    },
    {
        label: 'Start DM',
        value: 'startThread',
        location: 'modal'
    },
    {
        label: 'Invite Employees to Agency',
        value: 'invite',
        location: 'modal',
        params: {type: 'department', role: 'employee'}
    },
    {
        label: 'Invite Managers to Agency',
        value: 'invite',
        location: 'modal',
        params: {type: 'department', role: 'management'}
    },
    {
        label: 'Invite Executives to Agency',
        value: 'invite',
        location: 'modal',
        params: {type: 'department', role: 'executive'}
    },
    {
        label: 'Invite People to DM',
        value: 'invite',
        location: 'modal',
        params: {type: 'user'}
    },
    {
        label: 'Invite Guests to Rooms',
        value: 'invite',
        location: 'modal',
        params: {type: 'room', role: 'guest'}
    },
    {
        label: 'Invite Employees to Rooms',
        value: 'invite',
        location: 'modal',
        params: {type: 'room', role: 'employee'}
    },
    {
        label: 'Room',
        value: 'room',
        location: '/room'
    },
    {
        label: 'Thread',
        value: 'thread',
        location: '/thread'
    }
]

export const ctaColorOptions = [
    {label: 'Green', value: 'success'},
    {label: 'Teal', value: 'secondary'},
    {label: 'Red', value: 'danger'},
    {label: 'Yellow', value: 'warning'},
    {label: 'Gunmetal', value: 'primary'},
    {label: 'Transparent', value: 'link'},
    {label: 'Border Only', value: 'border'}
]
