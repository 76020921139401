import { useRef, useEffect, useCallback } from 'react'
import { VirtuosoHandle, StateSnapshot } from 'react-virtuoso'
import { debounce } from 'lodash'

// Module-level variable to store state snapshots
const globalStateSnapshots: { [key: string]: StateSnapshot | undefined } = {}

export function useVirtuosoScrollSaveState(key: string, debounceMs = 200) {
    const virtuosoRef = useRef<VirtuosoHandle>(null)
    const scrollerRef = useRef<HTMLElement | Window | null>(null)

    const saveState = useCallback(() => {
        if (virtuosoRef.current) {
            virtuosoRef.current.getState((snapshot) => {
                globalStateSnapshots[key] = snapshot
                console.log('State saved for:', key)
            })
        }
    }, [key])

    const debouncedSaveState = useCallback(
        debounce(saveState, debounceMs),
        [saveState, debounceMs]
    )

    useEffect(() => {
        const currentScrollerRef = scrollerRef.current
        if (currentScrollerRef) {
            currentScrollerRef.addEventListener('scroll', debouncedSaveState)
        }

        return () => {
            if (currentScrollerRef) {
                currentScrollerRef.removeEventListener('scroll', debouncedSaveState)
            }
            debouncedSaveState.cancel()
        }
    }, [debouncedSaveState, scrollerRef.current])

    const getRestoreState = useCallback(() => {
        return globalStateSnapshots[key]
    }, [key])

    return { virtuosoRef, scrollerRef, getRestoreState, saveState }
}