import React, { ChangeEvent, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import {
    Row, Col, Card, CardBody, Input, Label, ToggleSwitch, InfoBox, Text, Badge, Icon, Button, useUI
} from '@evertel/web/ui'
import { DocumentIcon } from '../elements/DocumentIcon'
import { DocIconPickerModal } from './elements/DocIconPickerModal'
import { SchemaBuilderController, SchemaCategoriesController } from '../../controller'
import SchemaCategorySearchSelect from '../schema-categories/SchemaCategorySearchSelect'
import { useService } from '@evertel/di'
import { APIDataDocumentSchemaCategory } from '@evertel/types'
import { stringToHslColor } from '@evertel/utils'

interface SettingsBuilderSettingsProps {
    controller: SchemaBuilderController
    departmentId: number
}

const SchemaBuilderSettings: React.FC<SettingsBuilderSettingsProps> = observer(({
    controller,
    departmentId
}) => {

    const categoriesController = useService(SchemaCategoriesController, [])

    const { addToast, isConfirmed } = useUI()
    const navigate = useNavigate()

    const [showDocIconPickerModal, setShowDocIconPickerModal] = useState(false)

    useEffect(() => {
        categoriesController.init(controller.id)
    
    }, [controller.id])

    useEffect(() => {
        controller.setCategories(categoriesController.categoryArrays as any)

    }, [categoriesController.categoryArrays])

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        controller.setName(e.target.value)
    }

    const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
        controller.setDescription(e.target.value)
    }

    const onAddCategory = (category: APIDataDocumentSchemaCategory) => {
        categoriesController.add(category)
    }

    const onDeleteSchema = async () => {
        const deleteIt = () => {
            try {
                controller.deleteSchema()

                addToast({
                    color: 'success',
                    title: 'Template Deleted',
                    message: 'Your template has been successfully deleted.'
                })

                navigate('/everdocs')

            } catch (error: any) {
                addToast({
                    color: 'danger',
                    title: 'Oops!',
                    message: error.message
                })
            }
        }

        const confirmed = await isConfirmed({
            title: 'Delete Template?',
            message: 'Are you sure you want to delete this template?',
            acceptButton: {
                label: 'Delete',
                color: 'danger'
            }
        })
        if (confirmed) {
            deleteIt()
        }
    }

    return (
        <Row className="mt-4">
            <Col md={6}>
                <Card style={{minHeight: '75vh'}}>
                    <CardBody>
                        <Col>
                            <Row
                                valign="center"
                                className="mb-3">
                                <DocumentIcon
                                    schema={{
                                        icon: controller.icon,
                                        color: controller.color
                                    }}
                                    width={45}
                                    height={45}
                                    onClick={() => setShowDocIconPickerModal(true)}
                                    className="mx-3"
                                />
                                <Label
                                    text="Template Name"
                                    htmlFor="name"
                                    className="w-100">
                                    <Input
                                        id="name"
                                        name="name"
                                        value={controller.name}
                                        onChange={onChangeName}
                                    />
                                </Label>
                            </Row>
                            <Label
                                text="Description"
                                htmlFor="description">
                                <Input
                                    id="description"
                                    name="description"
                                    placeholder="Add a short description..."
                                    value={controller.description}
                                    onChange={onChangeDescription}
                                />
                            </Label>
                            <ToggleSwitch
                                id="isEnabled"
                                label="Enabled"
                                checked={controller.isEnabled}
                                className="mt-2"
                                onChange={() => controller.setIsEnabled(!controller.isEnabled)}
                            />
                            {(!controller.isEnabled) &&
                                <InfoBox
                                    color="info"
                                    className="mt-2">
                                    When an EverDoc template is disabled, it does not effect any existing documents created from this template.
                                    It will remove this EverDoc template as an option when creating new documents however.
                                </InfoBox>
                            }
                            {(!!controller.id) &&
                            <Row
                                align="between"
                                className="mt-5">
                                <Text>
                                    This template is used in <Text bold>{controller.documentsCount}</Text> documents
                                </Text>
                                {(!controller.documentsCount) &&
                                    <Button
                                        color="transparent"
                                        onClick={onDeleteSchema}>
                                        <Text color="danger">
                                            Delete this template
                                        </Text>
                                    </Button>
                                }
                            </Row>
                            }
                        </Col>
                    </CardBody>
                </Card>
            </Col>
            <Col valign="top">
                <Card>
                    <CardBody>
                        <Text bold>
                            Add this template to one or more categories
                        </Text>
                        <SchemaCategorySearchSelect
                            onSelect={onAddCategory}
                            departmentId={departmentId}
                        />
                        <Col className="mt-4">
                            <Text bold>
                                Categories
                            </Text>
                            {categoriesController.categoryArrays.added?.map((cat) =>
                                <div key={cat.id}>
                                    <CatBadge
                                        category={cat}
                                        onDelete={(categoryId: number) => categoriesController.deleteAdded(categoryId)}
                                    />
                                </div>
                            )}
                            {categoriesController.categoryArrays.addQueue?.map((cat) =>
                                <div key={cat.id}>
                                    <CatBadge
                                        category={cat}
                                        onDelete={(categoryId: number) => categoriesController.deleteFromAddQueue(categoryId)}
                                    />
                                </div>
                            )}
                        </Col>
                    </CardBody>
                </Card>
            </Col>

            <DocIconPickerModal
                visible={showDocIconPickerModal}
                onClose={() => setShowDocIconPickerModal(false)}
                controller={controller}
            />
        </Row>
    )
})

const CatBadge = ({
    category,
    onDelete
}: { category: APIDataDocumentSchemaCategory, onDelete: (categoryId: number) => void}) => {
    
    if (!category || !category.id) return null

    return (
        <Badge
            size={14}
            style={{backgroundColor: stringToHslColor(category.name as string)}}>
            {category.name}
            <Icon
                name="xmark"
                color="white"
                className="ml-3 cursor-pointer"
                onClick={() => onDelete(category.id as number)}
            />
        </Badge>
    )
}

export {
    SchemaBuilderSettings
}