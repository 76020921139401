import 'reflect-metadata'

import React, { useEffect, useState } from 'react'
import { createBrowserRouter, Navigate, Outlet, RouteObject, RouterProvider, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

// css
import './scss/style.scss'
import 'react-ladda-button/dist/ladda-themeless.min.css'

// Import routes
import routes from './routes'

// Import stores and services
import { Analytics } from '@evertel/analytics'
import { ThemeState } from '@evertel/web/theme'
// (in order of use)
import { SessionState } from '@evertel/session'
import { PushService } from '@evertel/push'
import { DeviceState } from '@evertel/device'
import { LogoutService } from '@evertel/logout'
import { AppStore, NavigationWrapper } from './stores'

// Dependency injection
// NOTE: if diContainer is imported before the services, things break
import { container as diContainer } from './di'
import { ContainerProvider, useService } from '@evertel/di'

// utilities
import LoadingIndicator from './components/LoadingIndicator'
import { ToasterComponent as Toaster } from './components/Toaster'
import { SpinnerComponent as Spinner } from './components/LoadingIndicator'

// Import components and contexts
import { Page404 } from './views/Pages'
import EvertelLayout from './containers/EvertelLayout'

import ErrorBoundary from './utilities/errorBoundary'
import ElectronBoundary from './utilities/ElectronBoundary'
import * as Electron from './utilities/electronInterface'

import { UIContextProvider } from '@evertel/web/ui'
import { JoinRoomContextProvider } from '@evertel/web/room'
import { InviteContextProvider } from '@evertel/web/invites'
import { CreateRoomContextProvider } from '@evertel/web/room'
import { UserDetailModalContextProvider } from '@evertel/web/user'
import { CreateThreadContextProvider } from '@evertel/web/thread'
import { CurrentMediaPlayingProvider } from '@evertel/hooks'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faPlusSquare, faUsers, faCheckSquare, faHandshake, faPlus, faPaperPlane, faBuilding, faEnvelope, faGauge,
    faCircleInfo, faCircleXmark, faEllipsisVertical, faLocationDot, faTableLayout, faTachometerAlt, faBell, faPaperPlaneTop,
    faCheckCircle, faDiamond, faCirclePlus, faShieldKeyhole, faCaretDown, faCaretRight, faStar, faFileAlt
} from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { faBold, faItalic, faList, faListOl, faStrikethrough, faLink, faBracketsCurly } from '@fortawesome/pro-regular-svg-icons'
import { faJira, faHubspot, faMailchimp, faApple, faAndroid } from '@fortawesome/free-brands-svg-icons'

import debugModule from 'debug'
if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEBUG_WEB) {
    debugModule.enable(process.env.REACT_APP_DEBUG_WEB)
}

//import { ipcRenderer } from 'electron'

library.add(fal, faPlusSquare, faUsers, faCheckSquare, faHandshake, faPlus, faPaperPlane, faBuilding, faEnvelope, faGauge, faCircleInfo,
    faCircleXmark, faEllipsisVertical, faLocationDot, faTableLayout, faTachometerAlt, faBell, faBold, faItalic, faList, faListOl, faStrikethrough,
    faLink, faBracketsCurly, faPaperPlaneTop, faCheckCircle, faDiamond, faCirclePlus, faJira, faHubspot, faMailchimp, faApple, faAndroid, faShieldKeyhole,
    faCaretDown, faCaretRight, faStar, faFileAlt)


const RootRedirect: React.FC = () => {
    const sessionState = useService(SessionState, [])

    //handle old links that might have the /#/ in the url
    const hashedUrl = window.location.hash.slice(1)
    if (hashedUrl[0] === '/') {
        return  <Navigate to={`${window.location.hash.slice(1)}`} replace />
    }

    return sessionState.currentUserId 
        ? ( <Navigate to="/landing" replace /> )
        : ( <Navigate to="/login" replace /> )
}

// ProtectedRoute component to handle auth check
const ProtectedRoute: React.FC = () => {
    const sessionState = useService(SessionState, [])
    return sessionState.currentUserId ? <EvertelLayout /> : <Navigate to="/login" replace />
}

const App: React.FC = observer(() => {
    const [isInitialized, setIsInitialized] = useState(false)

    const navigate = useNavigate()

    const analytics = useService(Analytics)
    const pushService = useService(PushService)
    const deviceState = useService(DeviceState, [])
    const sessionState = useService(SessionState, [])
    const themeState = useService(ThemeState, [])
    const logoutService = useService(LogoutService)


    useEffect(() => {
        const initializeApp = async () => {

            //need indicator showing before anything else
            //will be disabled inside AppStore.init()
            LoadingIndicator.show('app-loading', 'full-overlay')

            //want screen to be dark or light right away
            themeState.init()

            //session state restores userID and authToken from localStorage
            sessionState.init('web')

            //device state restores deviceToken from localStorage
            deviceState.init('web')

            //app store validates authToken and turns off LoadingIndicator
            await AppStore.init()

            //analytics meh
            analytics.init()

            logoutService.init()
            logoutService.setOnLogoutCallback(() => {
                // Force a full page reload and navigate to the login page
                navigate('/login')
                window.location.reload()
            })

            setIsInitialized(true)
        }

        initializeApp()
    }, [sessionState.currentUserId])

    useEffect(() => {
        pushService.init()
        // initialize AppStore   
        Electron.wireUp()
    }, [])

    useEffect(() => {
        if (sessionState.currentUserId && pushService.notificationPermission === 'granted') {
            pushService.postPushToken()
        }
    }, [sessionState.currentUserId, pushService.notificationPermission])

    if (!isInitialized || AppStore.appLoading) {
        return (
            <>
                <Spinner name="page-loading"></Spinner>
                <Spinner name="app-loading"></Spinner>
            </>
        )
    }

    return (
        <UIContextProvider>
            <>
                <NavigationWrapper />
                <UserDetailModalContextProvider>
                    <InviteContextProvider>
                        <CreateThreadContextProvider>
                            <CreateRoomContextProvider>
                                <JoinRoomContextProvider>
                                    <CurrentMediaPlayingProvider>
                                        <ElectronBoundary>
                                            <Spinner name="page-loading"></Spinner>
                                            <Spinner name="app-loading"></Spinner>
                                            <Toaster name="mainToaster" position="top-right" autoClose={5000} />
                                            <Outlet/>
                                        </ElectronBoundary>
                                    </CurrentMediaPlayingProvider>
                                </JoinRoomContextProvider>
                            </CreateRoomContextProvider>
                        </CreateThreadContextProvider>
                    </InviteContextProvider>
                </UserDetailModalContextProvider>
            </>
        </UIContextProvider>
    )
})

// Create the router
export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorBoundary />,
        children: [
            // Root redirect route
            {
                index: true,
                element: <RootRedirect />
            },
            // Public routes
            ...routes.filter(route => !route.requiresAuth) as RouteObject[],
            // Protected routes
            {
                element: <ProtectedRoute />,
                children: routes.filter(route => route.requiresAuth) as RouteObject[]
            },
            // Catch-all route
            {
                path: '*',
                element: <Page404 />
            }
        ]
    }
])

const WrappedApp: React.FC = observer((props) => {
    return (
        <ContainerProvider value={diContainer}>
            <RouterProvider router={router} />
        </ContainerProvider>
    )
})

export default WrappedApp