import { makeAutoObservable, runInAction } from 'mobx'
import { decorate, injectable, inject } from 'inversify'
import { debounce } from 'lodash'
import moment from 'moment'

// evertel
import { Api } from '@evertel/api'


class UsageByUserController {
    users: any[] = []
    usersLatestFetch: any[] = []
    userCount: number = 0
    departmentId: number | null = null
    searchText: string = ''
    page: number = 1
    limit: number = 30
    isLoading: boolean = false
    error: any = null
    sortBy: string = 'signupDate'
    sortOrder: 'asc' | 'desc' = 'asc'
    startDate: Date | undefined = undefined
    endDate: Date | undefined = undefined

    constructor(
        private api: Api
    ) {
        makeAutoObservable(this)
    }

    init(departmentId: number) {
        this.departmentId = departmentId
        this.resetState()
    }

    setSearchText(value: string) {
        this.searchText = value
        this.resetSearchMeta()
    }

    setPage(page: number) {
        this.page = page
    }

    setLimit(limit: number) {
        this.limit = limit
        this.resetSearchMeta()
    }

    setSorting(sortBy: string, sortOrder: 'asc' | 'desc') {
        this.sortBy = sortBy
        this.sortOrder = sortOrder
        this.resetSearchMeta()
    }

    setDateRange(startDate: Date | undefined, endDate: Date | undefined) {
        this.startDate = startDate
        this.endDate = endDate

        this.resetSearchMeta()
    }

    private debouncedSearchInternal = debounce(async () => {
        try {
            await this.search()
        } catch (e) {
            runInAction(() => {
                this.error = e
            })
        }
        runInAction(() => {
            this.isLoading = false
        })
    }, 500)

    debouncedSearch = () => {
        this.isLoading = true
        return this.debouncedSearchInternal()
    }

    search = async () => {
        if (!this.departmentId) return

        runInAction(() => {
            this.isLoading = true
            this.error = null
        })
        
        let count = this.userCount
        let results: any[] = []

        // grab the total count of users
        if (!this.userCount) {
            count = await this.fetchUsersCount()
        }

        this.usersLatestFetch = []

        // stop from fetching if we already got them all
        if (count > this.users.length) {

            const startDate = this.startDate && this.endDate ? moment(this.startDate).startOf('day').toDate() : undefined
            const endDate = this.startDate && this.endDate ? moment(this.endDate).endOf('day').toDate() : undefined
            
            results = await this.api.Routes.Department.getReportsUsersWithMessageCounts(
                this.departmentId, 
                this.filter, 
                startDate,
                endDate
            ) as any
        }

        runInAction(() => {
            this.usersLatestFetch = results
            this.users = [...this.users, ...results]
            this.isLoading = false
        })  
    }

    async fetchUsersCount() {
        if (!this.departmentId) return 0

        const res = await this.api.Routes.Department.getReportsUsersWithMessageCountsCount( this.departmentId, this.filter.where )

        runInAction(() => {
            this.userCount = res?.count ?? 0
        })

        return this.userCount
    }

    resetSearchMeta() {
        this.page = 1
        this.users = []
        this.userCount = 0
    }

    resetState() {
        this.resetSearchMeta()
        this.searchText = ''
        this.sortBy = 'signupDate'
        this.sortOrder = 'asc'
        this.startDate = undefined
        this.endDate = undefined
    }

    get filter() {
        return {
            where: {
                searchTerm: this.searchText,
                isVerified: true
            },
            limit: this.limit,
            skip: (this.page - 1) * this.limit,
            order: `${this.sortBy} ${this.sortOrder}`
        }
    }
}

decorate(injectable(), UsageByUserController)
decorate(inject(Api), UsageByUserController, 0)

export { UsageByUserController }