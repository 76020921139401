import { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
// evertel
import { Button, Modal, ModalBody, Row, Col, Text, ModalHeader, ModalFooter, PeopleSearchSelect, useUI } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { SearchScope } from './CreateThreadContext'
import { useNavigate } from 'react-router-dom'
import { APIDataBlueUser } from '@evertel/types'
import { CreateThreadController } from '@evertel/thread'
import { useInvite } from '@evertel/web/invites'
import { DrawerController } from '@evertel/message'

interface CreateThreadModalProps {
    visible: boolean,
    searchScope: SearchScope
    onClose: () => void
}

const USER_LIMIT = 25


const CreateThreadModal: React.FC<CreateThreadModalProps> = observer(({
    visible,
    searchScope = 'contacts',
    onClose
}) => {

    const windowSize = useRef({ width: window.innerWidth, height: window.innerHeight })
    const { openInvite } = useInvite()
    const { addToast } = useUI()
    const navigate = useNavigate()

    const session = useService(SessionState, [])
    const createThreadController = useService(CreateThreadController, [])
    const drawerController = useService(DrawerController, [])

    const [isLoading, setIsLoading] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState<APIDataBlueUser[]>([])
    const [limitMessage, setLimitMessage] = useState('')


    const onSelectChange = (users: APIDataBlueUser[]) => {
        if (users?.length >= (USER_LIMIT + 1)) return

        setSelectedUsers(users)

        const usersRemaining = USER_LIMIT - users?.length
        if (usersRemaining > 5) {
            setLimitMessage('')
            return
        }

        if (usersRemaining) {
            const userText = (usersRemaining === 1) ? 'person' : 'people'
            setLimitMessage(`You can add ${usersRemaining} more ${userText}`)
        } else {
            setLimitMessage(`You cannot add any more people (max: ${USER_LIMIT})`)
        }
    }

    const startThread = async () => {
        if (!selectedUsers?.length) return

        setIsLoading(true)

        try {
            const users = selectedUsers.map(u => u.id)

            const thread = await createThreadController.createThread(users as number[])

            // refresh the threads list in the drawer
            await drawerController.fetchActiveThreads()

            // navigate to the new thread and hide modal
            navigate(`/thread/${thread.id}`)
            onClose()
            setSelectedUsers([])

        } catch (error: any) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }

        setIsLoading(false)
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            style={{minHeight: 'calc(100vh - 4rem)'}}
            size="lg"
        >
            <ModalHeader
                title="Start a Direct Message Thread"
            />
            <ModalBody>
                {(limitMessage) &&
                    <Row>
                        <Col align="left">
                            <Text
                                className={classNames({
                                    'text-warning': (USER_LIMIT - selectedUsers?.length) < 5,
                                    'text-danger': (USER_LIMIT - selectedUsers?.length) === 0
                                })}>
                                {limitMessage}
                            </Text>
                        </Col>
                    </Row>
                }
                <Row className='mr-4'>
                    <Col xs={11} valign="top">
                        {(visible) &&
                            <PeopleSearchSelect
                                searchOnly={true}
                                searchScope="contact"
                                selected={selectedUsers || []}
                                whereFilter={{ emailVerified: true }}
                                departmentId={session.selectedDepartmentId}
                                onSelect={onSelectChange}
                                isMulti={true}
                                placeholder="Select one or more people..."
                                className="select-inline search no-bg"
                                menuIsOpen={true}
                                maxMenuHeight="calc(100vh - 370px)"
                            />
                        }
                    </Col>
                    <Col
                        valign="top"
                        align="right"
                        className='ml-2'>
                        <Button
                            color="success"
                            size="lg"
                            block
                            loading={isLoading}
                            disabled={!selectedUsers?.length}
                            onClick={startThread}>
                            <strong>Go</strong>
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter
                color="light"
                className="p-4">
                <Row>
                    <Col>
                        <Text
                            bold
                            tag="h5">
                            Invite others outside your agency to DM in Evertel.
                        </Text>
                        <Text>
                            Collaboration extends beyond the confines of a single agency. Invite personnel from other agencies,
                            vendors, or any external collaborators to join your secure discussions directly within Evertel.
                        </Text>
                    </Col>
                    <Col
                        xs={3}
                        align="right">
                        <Button
                            color="info"
                            block
                            onClick={() => {
                                onClose()
                                openInvite('contact')
                            }}>
                            Invite People
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    )
})

export { CreateThreadModal }
