import React, { forwardRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { testImage, stringToHslColor, gradientBackground } from '@evertel/utils'
import { Badge } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { UserController } from '@evertel/blue-user'

interface ProfilePicProps {
    userId: number, // if you only pass in userId, the userController will fetch the user. If you have the raw user data, pass in the rest of the props
    className?: string,

    /* you CAN pass in direct data IF you are using raw data i.e. in a search list.
       otherwise, just pass in the userId */
    imageUrl?: string,
    firstName?: string,
    lastName?: string,

    isGuest?: boolean,
    width?: number,
    height?: number,
    style?: React.CSSProperties,
    onClick?: (userId: number) => void,
    title?: boolean
}

const ProfilePic = observer(forwardRef<HTMLImageElement, ProfilePicProps>(({
    userId,
    imageUrl,
    firstName,
    lastName,
    isGuest,
    width = 40,
    height = 40,
    onClick,
    title = false,
    className,
    ...otherProps
}, ref) => {

    const userController = useService(UserController, [])

    const [isImageValid, setIsImageValid] = useState(true)

    const useRawData = firstName || lastName || imageUrl
    const profileImage = imageUrl || userController.user?.publicImage
    const _firstName = firstName || userController.user?.firstName || ''
    const _lastName = lastName || userController.user?.lastName || ''

    useEffect(() => {
        // only init the user controller if only the userId is passed in
        if (!useRawData && userId) {
            userController.init(userId)
        }
    }, [userId])

    useEffect(() => {
        // make sure image exists on server, if not, we render initials block
        if (profileImage) {
            testImage(profileImage).then(result => {
                if (result === 'error') {
                    setIsImageValid(false)
                }
            })
                .catch((error) => {
                    setIsImageValid(false)
                    //console.log('ProfilePic Error', {imageUrl})
                })
        }
    }, [profileImage])

    const _classNames = classNames(
        'profile-pic text-uppercase',
        {
            'cursor-pointer': onClick,
            'no-avatar': !profileImage || !isImageValid
        },
        className
    )

    const Img = () => {
        if (profileImage && isImageValid) {
            // valid image
            return (
                <img
                    ref={ref}
                    src={profileImage}
                    className={_classNames}
                    alt={`${_firstName} ${_lastName}`}
                    width={width}
                    height={height}
                    title={title ? `${_firstName} ${_lastName}` : undefined}
                    {...onClick && { onClick: () => onClick(userId) }}
                    {...otherProps}
                />
            )
        } else {
            // invalid image or no image
            const { style, ...rest } = otherProps

            const fontSize = Math.max(12, Math.floor(width / 3))

            return (
                <div
                    ref={ref}
                    className={classNames(_classNames, { 'bg-gray200': !_firstName && !_lastName })}
                    style={{
                        ...style,
                        background: (_firstName && _lastName) ? gradientBackground(`${_firstName} ${_lastName}`) : '',
                        // backgroundColor: (_firstName && _lastName) ? stringToHslColor(`${_firstName} ${_lastName}`) : '',
                        width,
                        height,
                        fontSize
                    }}
                    {...onClick && { onClick: () => onClick(userId) }}
                    {...rest}
                    title={title ? `${_firstName} ${_lastName}` : undefined}
                >
                    {_firstName?.charAt(0) + _lastName?.charAt(0)}
                </div>
            )
        }
    }

    return (
        <div style={{
            position: 'relative'
        }}>
            <Img />
            {(isGuest) &&
                <div className='profile-pic-badge'>
                    G
                </div>
            }
        </div>
    )
}))

export { ProfilePic }
