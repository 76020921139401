import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Col, Text, Spinner } from '@evertel/web/ui'
import { MessageWallContext } from '../../../MessageWallContext'
import MessageHeaderRoom from './MessageHeaderRoom'
import MessageHeaderThread from './MessageHeaderThread'
import { MessageHeaderDivider } from './MessageHeaderDivider'
import { DisplayRoomMessage, DisplayThreadMessage } from '@evertel/stores'
import { Message } from '../../../Message'
import { DateBlock } from '../DateBlock'

interface MessageListHeaderProps {
    isLoading: boolean,
    message: DisplayRoomMessage | DisplayThreadMessage
}

const MessageListHeader: React.FC<MessageListHeaderProps> = observer(({
    isLoading,
    message
}) => {

    const { modelType, modelData } = useContext(MessageWallContext)
    const isRoom = modelType === 'room'

    if (isLoading) {
        // return null
        return (
            <div
                style={{
                    padding: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                <Spinner />
                <Text
                    size="small"
                    color="muted">
                    Decrypting messages...
                </Text>
            </div>
        )
    }

    if (message) {
        return (
            <div style={{ padding: 1 }} className='mt-2'>
                {/* Always display DateBlock in Replies */}
                <DateBlock
                    date={(message.createdDate).toString()}
                />
                <Message
                    message={message}
                    modelType={('roomId' in message) ? 'room' : 'thread'}
                    forceHeader={true}
                    variant='reply'
                    showHoverBar={!message.isRetracted}
                />
                <MessageHeaderDivider />
            </div>
        )
    }

    return (
    //must collapse border or virtuoso "alignToBottom" has issues, must not have margin either
        <div style={{ padding: 1 }}>
            <Col align="center" className="mt-4">
                {isRoom ? <MessageHeaderRoom modelData={modelData} /> : <MessageHeaderThread modelData={modelData} />}
            </Col>
            <MessageHeaderDivider />
        </div>
    )

})

export { MessageListHeader }
