import React from 'react'

const MessageHeaderDivider: React.FC = () => {
    return (
        <div style={{
            height: 70,
            width: '100%',
            position: 'relative',
            marginTop: 20
        }}>
            <div
                style={{
                    position: 'absolute',
                    width: '200%',
                    height: 20,
                    transform: 'translate(-25%) scale(0.5)',
                    background: 'linear-gradient(45deg, transparent, transparent 49%, gray 49%, transparent 51%)',
                    backgroundSize: '40px 40px'
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    width: '200%',
                    height: 20,
                    transform: 'translate(-25%) scale(0.5)',
                    background: 'linear-gradient(-45deg, transparent, transparent 49%, gray 49%, transparent 51%)',
                    backgroundSize: '40px 40px'
                }}
            />
        </div>
    )
}

export { MessageHeaderDivider }