import { Component } from 'react'
import { Col, Container, Row } from '@evertel/web/ui'
import { AnalyticsStore } from '../../../stores'

class Page404 extends Component {

    componentDidMount = () => {
        AnalyticsStore.logPageView('/404')
    }

    render() {
        return (
            <Row className="login fadeIn animated" >
                <Col valign="center" align="center" style={{ minHeight: '100vh', margin: 'auto', overflow: 'auto', maxWidth: 900 }}>
                    <img src={'/assets/img/doughnut-guy.png'} className="pb-3" alt="Evertel Doughnut Guy" />
                    <h1 style={{fontSize: '4rem'}}>404</h1>
                    <h3 className="pt-3">Oops! Looks like you're lost.</h3>
                    <p className="text-muted">The page you are looking for was not found.</p>
                </Col>
            </Row>
        )
    }
}

export default Page404
