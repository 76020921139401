import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { Col, Icon, Media } from '@evertel/web/ui'
import { parsedContent } from '@evertel/utils'
import { ReplyTileBodyProps } from './ReplyTileBody'

interface ReplyTileBodyUserProps extends ReplyTileBodyProps {
    variant?: 'forward' | 'user'
}

const ReplyTileBodyUser: React.FC<ReplyTileBodyUserProps> = ({
    message,
    media,
    variant
}) => {
    const [truncatedMessage, setTruncatedMessage] = useState(message.text)

    useEffect(() => {
        trucatext()
    }, [message])

    const trucatext = () => {
        if (message?.text?.length > 250) {
            const truncatedMessage = message.text.slice(0, 250) + '...'
            setTruncatedMessage(truncatedMessage)
        }
    }

    const size = variant === 'forward' ? 30 : 50
    const padding = variant === 'forward' ? 5 : 15

    return (
        <Col className='mt-1'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {message.isUrgent && (
                    <span className="urgent-icon">
                        <Icon
                            name='triangle-exclamation'
                            color='white'
                        />
                    </span>
                )}

                <div
                    dangerouslySetInnerHTML={{ __html: parsedContent(truncatedMessage) }}
                    className={classNames('reply-body', {'urgent': message.isUrgent})}
                    style={{ display: 'inline-block' }}
                />

            </div>
            <div className='reply-body-media-container'>
                {!!media?.length && (
                    media.map(m => {
                        return (
                            <Media
                                key={m.id}
                                media={m}
                                width={size}
                                height={size}
                                rounded
                                allowDownload={false}
                                className="reply-body-media-element"
                                videoProps={{ showThumbnail: true }}
                                imageProps={{ flexibility: 'fixed' }}
                                fileProps={{width: size, height: size, padding}}
                                resizeMode="cover"
                                variant="preview"
                            />
                        )})
                )}
            </div>
        </Col>
    )
}

export { ReplyTileBodyUser }