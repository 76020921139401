import { memo, useState } from 'react'
import { Button, Col, Row, useUI } from '@evertel/web/ui'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { botMessageActionsNavigationOptions } from '@evertel/constants'
import { useService } from '@evertel/di'
import { MessageActionController } from '@evertel/message'
import { APIDataRoomMessage, APIDataThreadMessage } from '@evertel/types'

const MessageBodyAction: React.FC<{message: APIDataRoomMessage | APIDataThreadMessage}> = observer(({ message }) => {
    if (!message || !message.meta || !message.meta.cta || !message.meta.cta.length) return null

    const messageActionController = useService(MessageActionController, [])
    const navigate = useNavigate()
    const { addToast } = useUI()
    const [isBusy, setIsBusy] = useState(false)
    
    const cta = message.meta?.cta // array
    // if the CTA is mobile only or we have no handler for it (rare), don't show the button
    if (cta.action === 'navigate') {
        const option = (cta.params) ? botMessageActionsNavigationOptions.find(b => b.value === cta.params.screen) : null
        if (!option || option.location === 'mobile') return null
    }   

    const onClick = async (cta) => {
        try {
            messageActionController.onClickAction(cta, message, navigate)
        }
        catch (error) {
            addToast({
                color: 'danger',
                message: messageActionController.actionError
            })
        }
    }

    return (
        <Row className="message-action">
            <Col align={'left'} className='mt-3'>
                <Row className="bttn">
                    {cta.map((item, idx) =>
                        <Button
                            key={idx}
                            loading={isBusy}
                            color={(item.color === 'border') ? 'muted' : item.color || 'success'}
                            onClick={() => onClick(item)}
                            outline={item.color === 'border'}
                            className="mr-2">
                            <strong>{item.label}</strong>
                        </Button>
                    )}
                </Row>
            </Col>
            <Col xs={1} className="right-col"/>
        </Row>
    )
})

MessageBodyAction.displayName = 'MessageBodyAction'

export default memo(MessageBodyAction)
