import React from 'react'
import { observer } from 'mobx-react'
import { Row, Button} from '@evertel/web/ui'
import { EmojiButton } from '@evertel/web/emojis'
import { APIDataReaction } from '@evertel/types'
import { Reaction } from './Reaction'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'

interface ReactionProps {
    messageId: number,
    reactions: APIDataReaction[],
    onSelectReaction?: (reaction: APIDataReaction) => void,
    onShowEmojiPicker: (target: string) => void,
    onShowReactedBy?: () => void,
    showPickerButton?: boolean,
    showClickAnimation?: boolean,
    emojiButtonId: string,
    showCountLink?: boolean,
    canReact?: boolean
}

const ReactionBar: React.FC<ReactionProps> = observer(({
    messageId,
    reactions,
    onSelectReaction,
    onShowEmojiPicker,
    onShowReactedBy,
    emojiButtonId,
    showCountLink,
    showPickerButton,
    showClickAnimation,
    canReact = true,
    ...otherProps
}) => {

    const session = useService(SessionState, [])

    const count = reactions?.reduce((sum: number, r: any) => sum + r.count, 0) ?? 0

    //console.log('REACTIONS', reactions)

    return (
        <Row valign='end'
            {...otherProps}
            className="reaction-bar"
        >
            {reactions.slice().sort((a: any, b: any) => a.id - b.id).map((reaction: APIDataReaction) => {
                const isMyReaction = !!reaction?.reactedByThrough?.find(r => r.blueUserId === session.currentUserId)

                return (
                    <Reaction
                        key={reaction.id}
                        reaction={reaction}
                        emoji={reaction.text as string}
                        isMyReaction={isMyReaction}
                        {...(canReact) && { onSelect: onSelectReaction}}
                        showClickAnimation={showClickAnimation && canReact}
                    />
                )
            })}
            {(showPickerButton && canReact) &&
                <EmojiButton
                    id={emojiButtonId}
                    onClick={() => onShowEmojiPicker(`#emojiButton-${messageId}`)}
                    iconColor="muted"
                />
            }
            {(showCountLink) &&
                <Button
                    color="link"
                    size="sm"
                    onClick={onShowReactedBy}
                    className="ml-2 p-0 reaction-link">
                    <small>{count} {(count === 1) ? 'reaction' : 'reactions'}</small>
                </Button>
            }
        </Row>
    )
})

export { ReactionBar }
