import React, { useContext, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { uniqBy } from 'lodash'
import sanitizeHtml from 'sanitize-html'
import { useNavigate } from 'react-router-dom'
// evertel
import { Button, Col, Row, Text, useUI, Media } from '@evertel/web/ui'
import { CreateMessageToolbar } from './CreateMessageToolbar'
//import { CreateMessageFormatBar } from "./CreateMessageFormatBar"
import { MessageWallContext } from '../MessageWallContext'
import { convertToText, getFileTypeFromMimetype, positionElementRelativeToTarget, sanitizePastedContent } from '@evertel/utils'
import { useService } from '@evertel/di'
import { CreateMessageController } from '@evertel/message'
import { isExtensionBanned, getFileExt, fileToUploadableMedia } from '@evertel/utils'
import { MediaUploadWindowOverlay } from './MediaUploadWindowOverlay'
import { UploadableMedia } from '@evertel/media'
import { APIDataRoom } from '@evertel/types'


const CreateMessage: React.FC = observer(() => {

    const { setEmojiPickerState, modelType, modelId, allowedToPost, modelData, repliesToId } = useContext(MessageWallContext)
    const createMessageController = useService(CreateMessageController, [])

    const messageInput = useRef(null)
    const { addToast } = useUI()

    const [message, setMessage] = useState('')
    const [media, setMedia] = useState<UploadableMedia[]>([])
    const [caretOffset, setCaretOffset] = useState(1)
    const [isUrgent, setIsUrgent] = useState(false)
    const [submitBttnEnabled, setSubmitBttnEnabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const thumbWidth = 50

    useEffect(() => {
        createMessageController.init(modelId, modelType, repliesToId)
    }, [modelId, modelType, repliesToId])

    useEffect(() => {
        // enable/disable 'send' button
        if (message.length > 0 || media?.length) {
            setSubmitBttnEnabled(true)
        } else {
            setSubmitBttnEnabled(false)
        }
    }, [message, media])

    //=========================================
    // TOOLBAR CALLBACKS
    //=========================================

    const onClickToolbarOption = (option: string) => {
        switch (option) {
            case 'emoji-picker':
                openEmojiPicker()
                break
            case 'urgent':
                setIsUrgent(!isUrgent)
                break
            case 'attach':
                onClickAttachMedia()
                break
            default:
                break
        }
    }

    //=========================================
    // FILE DRAG OVERLAY DROP HANDLER
    //=========================================

    const onDrop = async (files: File[]) => {
        // convert file to uploadable media
        const uploadableMediaFile = await Promise.all(files.map(fileToUploadableMedia))
        setMedia(uniqBy([...media, ...uploadableMediaFile], 'fileName'))
    }

    //=========================================
    // EMOJIS
    //=========================================

    const onSelectEmoji = (emoji: any) => {
        setEmojiPickerState({
            visible: false,
            params: {}
        })

        const input = messageInput.current
        input.innerHTML = input.innerHTML.substr(0, caretOffset) + emoji.native + input.innerHTML.substr(caretOffset, input.innerHTML.length)

        setMessage(input.innerText)
        setCaretOffset(caretOffset + emoji.native.length)
    }

    const openEmojiPicker = () => {
        positionElementRelativeToTarget('#emoji-picker', '#messageInput')
        setEmojiPickerState({
            visible: true,
            params: {
                onSelectEmoji: onSelectEmoji
            }
        })
    }

    //=========================================
    // MESSAGE INPUT
    //=========================================

    const onMessageChange = (e) => {
        const content = e.target.innerText?.trim() || ''
        const _message = sanitizeHtml(content)

        getCaretPosition(e)

        setMessage(_message)
    }

    const onMessageInputKeyDown = (e) => {
        // enter key submits message (shift + enter adds line break)
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            onSubmit()
        }
    }

    const onPaste = (e) => {
        e.preventDefault()

        // grab items from clipboard
        const items = (e.clipboardData || e.originalEvent.clipboardData)?.items

        // determine if any are images
        let file = null
        for (let i = 0; i < items.length; i++) {
            const fileType = getFileTypeFromMimetype(items[i].type)
            if (fileType === 'image') {
                // grab file as blob
                file = items[i].getAsFile()
            }
        }

        if (file) {
            onDrop([file])
        } else {
            sanitizePastedContent(e)
        }
    }

    const getCaretPosition = (element) => {
        let _caretOffset = 0

        if (typeof window.getSelection !== 'undefined') {
            if (window.getSelection().rangeCount > 0) {
                const range = window.getSelection().getRangeAt(0)
                const preCaretRange = range.cloneRange()
                preCaretRange.selectNodeContents(element.target)
                preCaretRange.setEnd(range.endContainer, range.endOffset)
                _caretOffset = preCaretRange.toString().length
            }
        } else {
            // @ts-expect-error: document.selection is fine here since we
            // checked what the browser understands in the IF above
            const textRange = document.selection.createRange()
            // @ts-expect-error: ignore
            const preCaretTextRange = document.body.createTextRange()
            preCaretTextRange.moveToElementText(element.target)
            preCaretTextRange.setEndPoint('EndToEnd', textRange)
            _caretOffset = preCaretTextRange.text.length
        }

        setCaretOffset(_caretOffset)
    }

    //=========================================
    // MEDIA
    //=========================================

    const onClickAttachMedia = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.multiple = true
        input.onchange = async _ => {
            const files = [...input.files]
            // convert file to uploadable media

            const rejectedExtensions = new Set()
            const uploadableMediaFiles = (await Promise.all(
                files.map(async (file) => {
                    const ext = getFileExt(file)
                    if (isExtensionBanned(ext)) {
                        rejectedExtensions.add(ext)
                        return null
                    } else {
                        return await fileToUploadableMedia(file)
                    }
                })
            )).filter(Boolean) //remove null items

            if (rejectedExtensions.size) {
                addToast({
                    color: 'danger',
                    message: `Unsupported file format found: ${[...rejectedExtensions].join(', ')}`
                })
            }

            setMedia(uniqBy([...media, ...uploadableMediaFiles], 'fileName'))
        }
        input.click()
    }

    const onRemoveMedia = (remove: UploadableMedia) => {
        setMedia(media.filter(m => m.id !== remove.id))
    }

    //=========================================
    // SUBMIT HANDLER
    //=========================================

    const onSubmit = async () => {
        // there must be some text or at least one attachment to submit
        if (!media?.length && message?.trim() === '') {
            return
        }

        // If Room is archived, dont do anything
        if ((modelData as APIDataRoom)?.isArchived === true) return

        setIsLoading(true)

        try {
            await createMessageController.postMessage(convertToText(message), isUrgent, media)

            // reset everything
            setIsUrgent(false)
            setMessage('')
            setMedia([])
            messageInput.current.innerHTML = ''

        } catch (error) {
            console.error(error.message)

            switch (error.code) {
                case 'AUTHORIZATION_REQUIRED':
                    addToast({
                        color: 'danger',
                        message: `You don't belong to this ${createMessageController.modelType} anymore`
                    })
                    navigate('/landing')
                    break
                case 'ROOM_IS_ARCHIVED':
                    addToast({
                        color: 'warning',
                        message: `This rooms is archived`
                    })
                    break
                default:
                    addToast({
                        color: 'warning',
                        message: error?.message
                    })
                    break
            }

        } finally {
            messageInput.current?.focus()
        }

        setIsLoading(false)
    }

    if (!allowedToPost || (modelData as APIDataRoom)?.isArchived === true) {
        // not allowed to post
        return (
            <Col className="create-message-box">
                <Text
                    italic
                    color="muted"
                    className="p-4"
                >
                    {`Posting is disabled in this ${modelType === 'room' ? 'room' : 'conversation'}`}
                </Text>
            </Col>
        )
    }
    // allowed to post
    return (
        <Col className="create-message-box">
            <MediaUploadWindowOverlay
                onDrop={onDrop}
            />
            <Row className={classNames('message-input-wrapper', { urgent: isUrgent })}>
                <Col>
                    {/* <CreateMessageFormatBar
                        onClickOption={onClickToolbarOption}
                    /> */}
                    <div
                        ref={messageInput}
                        id="messageInput"
                        contentEditable="true"
                        role="textbox"
                        onKeyUp={onMessageChange}
                        onKeyDown={onMessageInputKeyDown}
                        onBlur={onMessageChange}
                        onPaste={onPaste}
                        className={classNames('message-input', { urgent: isUrgent })}
                        tabIndex={0}
                        data-ph={`Type your ${repliesToId ? 'reply' : 'message'} here...`}
                        aria-label={`Type your ${repliesToId ? 'reply' : 'message'} here...`}
                        aria-multiline="true"
                    />
                    {(!!media?.length) &&
                        <Row className="mt-3 pr-2 flex-wrap" style={{overflowY: 'auto', overflowX: 'clip', maxHeight: 200}}>
                            {media?.map(m =>
                                <Media
                                    key={m.id}
                                    media={m}
                                    variant="file-attachment"
                                    width={thumbWidth}
                                    height={thumbWidth}
                                    fileProps={{
                                        width: 200
                                    }}
                                    videoProps={{ showThumbnail: true }}
                                    imageProps={{ flexibility: 'fixed' }}
                                    resizeMode="cover"
                                    rounded
                                    onDelete={onRemoveMedia}
                                    className=""
                                />
                            )}
                        </Row>
                    }
                </Col>
            </Row>
            <Row align="between">
                <CreateMessageToolbar
                    isUrgent={isUrgent}
                    onClickOption={onClickToolbarOption}
                />
                <div>
                    <Button
                        outline={!submitBttnEnabled}
                        color={(submitBttnEnabled) ? 'success' : 'muted'}
                        onClick={onSubmit}
                        disabled={isLoading}
                        className="py-1 px-2 my-1">
                        Send
                    </Button>
                </div>
            </Row>
        </Col>
    )
})

CreateMessage.displayName = 'CreateMessage'

export { CreateMessage }