import { decorate, injectable } from '@evertel/di'
export interface PushNotification {
    notification?: {
        sound?: string,
        icon?: string,
        tag?: string,
        title?: string,
        body?: string
    }
    data: {
        type: 'RoomMessage' | 'ThreadMessage' | 'Room_updated',
        isUrgent?: boolean,
        departmentId?: number,
        id?: number,
        roomId?: number
        threadId?: number,
        ownerId?: number,
        blueUserId?: number,
        action?: 'room_join_request_accepted' | 'room_join_request_created' | 'member_added' | 'member_removed' | 'manager_added' | 'manager_removed',
        repliesToId?: number
    }
}

export type NotificationPermissionResult = Promise<NotificationPermission>

export type NotificationPermission = 'default' | 'denied' | 'granted' | undefined

export type NotificationClicked = { url: string, notificationId?: number }

class PushImplementation {
    constructor() {
        throw ('Do not use directly, push implementation must be platform specific! Create a platform specific implementation with the same shape of this class and inject it.')
    }

    async init() {
        throw ('Not Implemented')
    }

    async promptForNotificationPermission(): NotificationPermissionResult {
        throw ('Not Implemented')
    }

    async getCurrentNotificationPermission(): NotificationPermissionResult {
        throw ('Not Implemented')
    }

    async getPushToken(): Promise<string> {
        throw ('Not Implemented')
    }

    async deletePushToken() {
        throw ('Not Implemented')
    }

    async getInitialNotification(): Promise<PushNotification | undefined> {
        throw ('Not Implemented')
    }

    async clearInitialNotification() {
        throw ('Not Implemented')
    }

    async showLocalNotification() {
        throw ('Not Implemented')
    }

    setOnMessageCallback(callback: (message: PushNotification) => void) {
        throw ('Not Implemented')
        callback(undefined)
    }

    setOnPermissionChangeCallback(callback: (permission: NotificationPermission) => void) {
        throw ('Not Implemented')
        callback(undefined)
    }

    setOnNotificationClicked(callback: (notificationClicked: NotificationClicked) => void) {
        throw ('Not Implemented')
        callback(undefined)
    }
}

decorate(injectable(), PushImplementation)

export { PushImplementation }