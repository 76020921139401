import { ContentPlaceholder, Row } from '@evertel/web/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface RepliesCountProps {
    usersCount: number,
    repliesCount: number
}


const RepliesCount: React.FC<RepliesCountProps> = observer(({
    usersCount,
    repliesCount
}) => {

    return (
        <div className='replies-count'>
            <div>
                <strong className="text-secondary mr-2">
                    <span>{usersCount} {usersCount === 1 ? 'person' : 'people'}</span>
                    <span> | </span>
                    <span>{repliesCount} {repliesCount === 1 ? 'reply' : 'replies'}</span>
                </strong>
                <span>
                    Click to view Replies
                </span>
            </div>
        </div>
    )
})

export { RepliesCount }