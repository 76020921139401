import React, { Suspense, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

// evertel
import { useUI, Icon, Row, Spinner, IconLink } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { HeaderHelpDropdown, HeaderNotificationsDropdown, HeaderProfileDropdown, HelpModal, ResetPasswordModal } from './elements'
import { useJoinRoom } from '@evertel/web/room'
import { HeaderController, ResetPasswordController } from './controller'
import { NotificationClicked, PushService } from '@evertel/push'
import { CurrentUserController } from '@evertel/blue-user'
// dynamic imports for code splitting


const Header = observer(({
    ...props
}) => {

    const { leftNavOpen, toggleLeftNav, showAlertBar } = useUI()
    const navigate = useNavigate()
    const session = useService(SessionState, [])
    const pushService = useService(PushService, [])
    const currentUserController = useService(CurrentUserController, [session.currentUserId])

    const headerController = useService(HeaderController, [])
    const resetPasswordController = useService(ResetPasswordController, [])
    const { openJoinRoom } = useJoinRoom()
    
    const [isResetModalOpen, setIsResetModalOpen] = useState(false)
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
    const [helpAction, setHelpAction] = useState<string>('')

    useEffect(() => {
        (async () => {
            await Promise.all([
                headerController.init(),
                headerController.promptSystemNotifications()
            ])

            if (headerController.pushNotificationPermission !== 'granted' && headerController.allowedToPrompt()) {
                showAlertBar({ type: headerController.pushNotificationPermission, isOpen: true })
            }

            if (headerController.isCrisis) {
                headerController.clearCrisisQueryString()
                openJoinRoom('public')
            }

        })()

    }, [headerController, resetPasswordController, session.currentUserId])

    useEffect(() => {

        const onNotificationClickedEvent = (notification: NotificationClicked) => {
            navigate(notification.url)
        }

        if (currentUserController.user) {

            resetPasswordController.init(session.currentUserId)

            // popup reset password modal if password expires in 5 days or less
            const passwordTTLdays = resetPasswordController.passwordTTLdays
            if ((passwordTTLdays !== undefined) && passwordTTLdays < 5) {
                setIsResetModalOpen(true)
            }

            pushService.addNotificationClickedListener(onNotificationClickedEvent)
        }

        return (
            () => pushService.removeNotificationClickedListener(onNotificationClickedEvent)
        )
    }, [currentUserController.user])

    const openHelpModal = (action: string) => {
        setHelpAction(action)
        setIsHelpModalOpen(true)
    }

    return (
        <>
            <Row
                align="between"
                valign="center"
                className="app-header">

                <div>
                    <IconLink
                        className="ml-3 navbar-toggler"
                        label=""
                        icon={{
                            name: (leftNavOpen) ? 'arrow-alt-from-right' : 'sidebar',
                            color: 'muted',
                            size: 20
                        }}
                        onClick={() => toggleLeftNav('toggle')}
                        aria-expanded={leftNavOpen}
                        aria-label={leftNavOpen ? 'Collapse menu' : 'Expand menu'}
                    />
                </div>
                <Row valign='center'>
                    <HeaderHelpDropdown openHelpModal={openHelpModal} />
                    <HeaderNotificationsDropdown className="ml-2 mr-4" />
                    <HeaderProfileDropdown />
                </Row>
            </Row>

            {/* APP-WIDE MODALS */}
            <Suspense fallback={<Spinner />}>
                { currentUserController.user &&
                    <ResetPasswordModal
                        isOpen={isResetModalOpen}
                        onClose={() => setIsResetModalOpen(false)}
                    />
                }
                <HelpModal
                    isOpen={isHelpModalOpen}
                    action={helpAction}
                    onClose={() => setIsHelpModalOpen(false)}
                />
            </Suspense>
        </>
    )
})

export { Header }
