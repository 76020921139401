import { observable, action, runInAction, makeObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'

class DepartmentReportsStore {
    isBusy = false
    error = null

    constructor() {
        makeObservable(this, {
            isBusy: observable,
            error: observable,
            getUserMessageCountsByRole: action,
            getReportRoomMessageCountPerDay: action,
            getReportDirectMessageCountPerDay: action
        })

    }

    async getUserMessageCountsByRole(departmentId) {
        this.isBusy = true
        this.error = null

        try {
            const count = await api.Routes.Department.getReportsMessageCountsByRole(departmentId)

            runInAction(() => {
                this.isBusy = false
            })

            return count
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'DepartmentReportsStore.getUserMessageCountsByRole()', error: e.message || e})
            return
        }
    }

    async getReportRoomMessageCountPerDay(departmentId, startDate = null, endDate = null) {
        this.isBusy = true
        this.error = null

        try {
            const count = await api.Routes.Department.getReportsRoomMessagesCountPerDay(departmentId, startDate, endDate)

            runInAction(() => {
                this.isBusy = false
            })

            return count
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'DepartmentReportsStore.getReportRoomMessageCountPerDay()', error: e.message || e})
            return
        }
    }

    async getReportDirectMessageCountPerDay(departmentId, startDate = null, endDate = null) {
        this.isBusy = true
        this.error = null

        try {
            const count = await api.Routes.Department.getReportsDirectMessagesCountPerDay(departmentId, startDate, endDate)

            runInAction(() => {
                this.isBusy = false
            })

            return count
        } catch (e) {
            runInAction(() => {
                this.error = e
                this.isBusy = false
            })

            AppStore.logError({type: 'API get', message: 'DepartmentReportsStore.getReportDirectMessageCounPerDay()', error: e.message || e})
            return
        }
    }
}

const delay = (s) => {
    return new Promise(resolve => setTimeout(resolve, s * 1000))
}

export default new DepartmentReportsStore()
