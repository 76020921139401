import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import Select, { MultiValue, SingleValue } from 'react-select'
// evertel
import { APIDataDepartment } from '@evertel/types'
import classNames from 'classnames'
import { formatForSelect } from '@evertel/utils'

interface ChildDeptSelectProps {
    selected?: any[],
    isMulti?: boolean,
    loadOptions?: APIDataDepartment[],
    placeholder?: string,
    additionalOptions?: [],
    onSelect: (department: MultiValue<APIDataDepartment> | SingleValue<APIDataDepartment>) => void
}

const ChildDepartmentsSelect: React.FC<ChildDeptSelectProps> = observer(({
    selected = [],
    isMulti = false,
    loadOptions = [],
    onSelect,
    placeholder = 'Select...',
    additionalOptions = [],
    ...otherProps
}) => {

    const onSelectOption = (dept: MultiValue<APIDataDepartment> | SingleValue<APIDataDepartment>) => {
        if (onSelect) onSelect(dept)
    }

    let options = [...loadOptions]
    if (loadOptions[0] === null || loadOptions[0] === undefined) {
        options = null
    }

    const selectedOption = formatForSelect(selected, 'name', 'id') || []
    const departmentsFilter = formatForSelect(options, 'name', 'id') || []

    return (
        <Select
            name="children"
            options={departmentsFilter as any}
            value={selectedOption}
            onChange={onSelectOption as any}
            isMulti={isMulti}
            placeholder={placeholder}
            className={classNames('react-select')}
            classNamePrefix="select"
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            {...otherProps}
        />
    )
})

export { ChildDepartmentsSelect }