import { Component } from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
// evertel
import Steps from './components/Steps'
import { LoadingIndicator, SuccessCheckmark } from '../../../components'
import { InfoBox, Card, CardBody, Col, Input, InputGroup, InputGroupText, Row, Icon, Button } from '@evertel/web/ui'
import { RegistrationStore, AnalyticsStore, AppStore, NavigationStore, InviteStore, LoginStore } from '../../../stores'

//=================================================================================================
const RegisterDepartment = observer(class RegisterDepartment extends Component {
    //=================================================================================================
    
    state = {
        error: false,
        isLoading: false,
        showCodeRequestModal: false,
        tokenValid: false,
        token: ''
    }
    
    // create a broadcast channel to keep other tabs in sync
    bc = new BroadcastChannel('evertel-dept-bc')
    
    componentDidMount = () => {
        autorun((init) => {
            if (!AppStore.appLoading) {
                if (AppStore.hasValidAuthToken) {
                    AnalyticsStore.logPageView('/register/assign-department')
                } else {
                    console.log('no valid auth token from department page')
                    NavigationStore.navigate('/register')
                }
                
                init.dispose()
            }
            if (LoginStore.error) {
                this.setState({
                    error: LoginStore.error.message
                })
            }
        })
        
        // add listener for any broadcast channel messages coming from other tabs (only fires on tabs not the source of postMessage)
        this.bc.onmessage = (event) => {
            if (event.data?.departmentStep === 'complete' || event.data?.departmentStep === 'skipped') {
                RegistrationStore.updateRegistrationSteps({...RegistrationStore.registrationSteps, department: event.data?.departmentStep})
                RegistrationStore.goToNextStep()
            }
        }
    }
    
    componentWillUnmount = () => {
        // clsoe broadcast channel
        this.bc.close()
    }
    
    //==============================================
    // INVITE LINK
    //==============================================
    
    onInviteCodeChange = (e) => {
        this.setState({
            token: e.target.value
        })
    }
    
    consumeInviteToken = async () => {
        const { token } = this.state

        // no access code, can't continue
        if (!token) {
            this.setState({
                error: 'You must enter a valid agency access code to continue. Or you can simply click on the invite link in your invite email or text'
            })
            return
        }

        this.setState({ isLoading: true, error: null })
        LoadingIndicator.show('page-loading')

        // consume invite token
        await InviteStore.consumeInvite(token, true)

        LoadingIndicator.hide('page-loading')
        // we purposefully don't set isLoading here to false so the
        // user cannot click on the submit button again. will auto navigate below

        // check for errors
        const error = InviteStore.error
        if (error && !error.message.includes('Not enough user seats')) { // when there are not enough seats, we get an error but still add them to the dept
            this.setState({
                error: error.message || error,
                tokenValid: false,
                isLoading: false
            })
            return
        }

        RegistrationStore.updateRegistrationSteps({...RegistrationStore.registrationSteps, department: 'complete'})

        // let other tabs know this step is complete
        this.bc.postMessage({departmentStep: 'complete'})

        this.setState({ tokenValid: true })

        // give time for animation, then navigate to next step
        setTimeout(() => RegistrationStore.goToNextStep(), 1500)
    }

    toggleCodeRequestModal = () => {
        this.setState({
            showCodeRequestModal: !this.state.showCodeRequestModal
        })
    }

    skip = () => {
        RegistrationStore.updateRegistrationSteps({...RegistrationStore.registrationSteps, department: 'skipped'})

        // let other tabs know this step is skipped
        this.bc.postMessage({departmentStep: 'skipped'})

        RegistrationStore.goToNextStep()
    }


    //---------------------------------------------------------------------------------------------------------------------------------------
    render() {
        const { error, isLoading, tokenValid, token } = this.state

        return (
            <div className="app d-flex justify-content-center align-items-center fadeIn animated registration" style={{height: '100vh'}}>
                <Row className="justify-content-center">
                    <Col md="8">
                        <Steps
                            currentStep={2}
                            totalSteps={3}
                        />
                        <Card className="mx-4">
                            <CardBody className="p-4">
                                {/* HEADER */}
                                <div className="text-center mb-5">
                                    <h1>Now let's get you in the right agency</h1>
                                    <h3 className='text-muted'>Enter your agency's access code</h3>
                                </div>

                                {/* INVITE LINK BLOCK */}
                                <AccessCodeBlock
                                    token={token}
                                    tokenValid={tokenValid}
                                    error={error}
                                    onInviteCodeChange={this.onInviteCodeChange}
                                />

                                {/* SUBMIT BTTN */}
                                <Button
                                    color="success"
                                    type="submit"
                                    block
                                    size="xl"
                                    className="mt-4 weight-900 w-100"
                                    disabled={isLoading}
                                    loading={isLoading}
                                    onClick={this.consumeInviteToken}>
                                        Continue
                                </Button>

                                <Button
                                    color="link"
                                    block
                                    size="sm"
                                    className="mt-4 w-100"
                                    onClick={this.skip}>
                                        Skip
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
})

const AccessCodeBlock = observer(({
    token,
    tokenValid,
    error,
    onInviteCodeChange
}) => {

    return (
        <InfoBox color="info" className="text-center mt-5 pt-4 animated fadeIn">
            {/* <Button
                color="ghost-primary"
                onClick={() => setShowAccessCode(false)}
                className="float-right">
                <Icon
                    name="times"
                />
            </Button> */}
            <h3 className="weight-900">Agency Access Code</h3>
            <p className="text-muted">
                If you were invited to join Evertel, you were sent (email or text) an invite link and access code.
                Enter the access code here to join that agency.
            </p>
            <InputGroup className="pt-2 pb-2">
                <InputGroupText>
                    <Icon
                        name="key"
                    />
                </InputGroupText>
                {/* TODO: parse out the access code IF they paste the entire link. More user friendly. */}
                <Input
                    type="text"
                    placeholder="Enter your agency access code"
                    name="accessCode"
                    id="accessCode"
                    value={token}
                    onChange={onInviteCodeChange}
                />
            </InputGroup>
            <div className="text-center mb-4">
                {(error && !tokenValid) &&
                    <InfoBox color="danger">
                        {error}
                    </InfoBox>
                }
                {(tokenValid) &&
                    <h3 className="text-success mt-2 fadeIn animated">
                        <SuccessCheckmark
                            message="You have been verified"
                        />
                    </h3>
                }
            </div>
        </InfoBox>
    )
    //}
})

export default RegisterDepartment