import React from 'react'
import { Security } from '@evertel/web/security'
import { DepartmentUsersList, ChildDepartments } from '@evertel/web/department'

// Directly imported components (non-lazy)
import { Login, Page404, Page500, Register, RegisterProfile, RegisterDepartment, CrisisSplash,
    ForgotPassword, ConsumeInvite, ResetPassword, EnrollMfaInit, Version,
    CrisisNoAccess, EmailVerification, ConfirmAuth0TFA } from './views/Pages'

// Route configuration type
interface RouteConfig {
    path: string
    name?: string
    element?: React.ReactNode
    lazy?: () => Promise<{ Component: React.ComponentType<any> }>
    requiresAuth?: boolean,
    children?: RouteConfig[]
}

const routes: RouteConfig[] = [
    /**
     * Public routes (don't require authentication)
     */
    { path: '/login', name: 'Login', element: <Login /> },
    { path: '/login/forgotpassword', name: 'Forgot Password', element: <ForgotPassword /> },
    { path: '/login/crisissplash', name: 'Crisis Splash', element: <CrisisSplash /> },
    { path: '/register', name: 'Register', element: <Register /> },
    { path: '/register/profile', name: 'Register Profile', element: <RegisterProfile /> },
    { path: '/register/department', name: 'Register Department', element: <RegisterDepartment /> },
    { path: '/register/crisisnoaccess', name: 'Crisis No Access', element: <CrisisNoAccess /> },
    { path: '/404', name: 'Page Not Found', element: <Page404 /> },
    { path: '/500', name: 'Server Error', element: <Page500 /> },
    { path: '/invite/:token?', name: 'Consume Invite', element: <ConsumeInvite /> },
    { path: '/email-verification/:token?', name: 'Email Verification', element: <EmailVerification /> },
    { path: '/reset-password/:token?', name: 'Reset Password', element: <ResetPassword /> },
    { path: '/enroll-mfa/:token?', name: 'Enroll MFA', element: <EnrollMfaInit /> },
    { path: '/two-factor/', name: 'Confirm Two-Factor Auth', element: <ConfirmAuth0TFA /> },
    { path: '/ver', name: 'Version', element: <Version /> },

    /**
     * Protected routes (require authentication)
     **/
    { path: '/landing', name: 'Landing', lazy: () => import('./views/Landing/Landing').then(mod => ({ Component: mod.default })), requiresAuth: true },
    // { path: '/room/:id', name: 'Room', lazy: () => import('./views/Rooms/Room').then(mod => ({ Component: mod.default })), requiresAuth: true },

    { path: '/room/:id?', name: 'Room',
        lazy: () => import('./views/Rooms/Room').then(mod => ({ Component: mod.default })),
        requiresAuth: true,
        children: [
            { path: 'replies/:repliesToId', element: null },
            { path: '*', element: null }
        ]
    },
    { path: '/thread/:id', name: 'Thread', lazy: () => import('./views/Threads/Thread').then(mod => ({ Component: mod.default })), requiresAuth: true },

    // EVERDOCS
    { path: '/everdocs', name: 'EverDoc', lazy: () => import('./views/Documents/SearchPage').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/everdocs/add/:id', name: 'EverDoc - Create Doc', lazy: () => import('./views/Documents/CreatePage').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/everdocs/schema/add/:target?', name: 'EverDoc - Create Type', lazy: () => import('./views/Documents/SchemaBuilderPage').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/everdocs/schema/edit/:id/:target?', name: 'EverDoc - Edit Type', lazy: () => import('./views/Documents/SchemaBuilderPage').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/everdocs/schema/categories', name: 'Manage EverDoc - Template List', lazy: () => import('./views/Documents/ManageSchemaCategoriesPage').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/everdocs/schema/templates/:target?', name: 'EverDoc - Template', lazy: () => import('./views/Documents/SchemaTemplatesPage').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/everdocs/:id', name: 'EverDoc - Edit Doc', lazy: () => import('./views/Documents/EditPage').then(mod => ({ Component: mod.default })), requiresAuth: true },

    // ACCOUNT
    { path: '/account/profile/:id?', name: 'My Profile', lazy: () => import('./views/Account/Profile/Profile').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/account/preferences', name: 'Preferences', lazy: () => import('./views/Account/Profile/Preferences').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/account/security', name: 'Security', element: <Security />, requiresAuth: true },
    { path: '/account/notifications', name: 'Notifications', lazy: () => import('./views/Account/Profile/Notifications').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/account/departments/:id', name: 'My Agencies', lazy: () => import('./views/Account/Profile/MyDepartments').then(mod => ({ Component: mod.default })), requiresAuth: true },

    // MANAGEMENT
    { path: '/manage/users', name: 'Users', element: <DepartmentUsersList />, requiresAuth: true },
    { path: '/manage/user/:userId', name: 'User Details', lazy: () => import('./views/Users/User').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/manage/agency/:departmentId/user/:userId', name: 'User Details', lazy: () => import('./views/Users/User').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/manage/rooms', name: 'Rooms', lazy: () => import('./views/Rooms/ManageRooms').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/manage/audits', name: 'Audits', lazy: () => import('./views/Audits/Audits').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/manage/audits/messages', name: 'Audits - Create', lazy: () => import('./views/Audits/AuditForm').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/manage/history', name: 'Agency History', lazy: () => import('./views/History/DepartmentHistory').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/manage/reports/:id', name: 'Agency Reports', lazy: () => import('./views/Reports/Reports').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/manage/agency/settings/:id', name: 'Agency Settings', lazy: () => import('./views/Account/Department/Settings').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/manage/linked/', name: 'Linked Agencies', element: <ChildDepartments />, requiresAuth: true },

    // ADMIN (EVERTEL)
    { path: '/admin/dashboard', name: 'Admin Dashboard', lazy: () => import('./views/Admin/Dashboard/Dashboard').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/admin/agencies/', name: 'Agencies', lazy: () => import('./views/Admin/Departments/Departments').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/admin/agency/:id', name: 'Agency Details', lazy: () => import('./views/Admin/Departments/Department').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/admin/users/', name: 'Users', lazy: () => import('./views/Admin/Users/Users').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/admin/user/:id', name: 'User Details', lazy: () => import('./views/Admin/Users/User').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/admin/messaging', name: 'Bot Campaigns', lazy: () => import('./views/Admin/Messaging/Campaigns').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/admin/messaging/messageForm/:id?', name: 'Send Bot Messages', lazy: () => import('./views/Admin/Messaging/BotMessageForm').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/admin/messaging/campaignForm/:id?', name: 'Send Bot Campaign', lazy: () => import('./views/Admin/Messaging/BotCampaignForm').then(mod => ({ Component: mod.default })), requiresAuth: true },
    { path: '/admin/verified-domains', name: 'Verified Domains', lazy: () => import('./views/Admin/VerfiedDomains/VerifiedDomains').then(mod => ({ Component: mod.default })), requiresAuth: true }
]

export default routes