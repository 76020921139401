import { useParams, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react'

// evertel
import { InviteStore } from '../../../stores'

const ConsumeInvite = observer(() => {

    const {token} = useParams()
    if (token) {
        InviteStore.setPendingInviteToken(token)
    }

    return (
        <Navigate to="/" replace />
    )
})

export default ConsumeInvite