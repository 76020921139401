import { makeAutoObservable } from 'mobx'
import _ from 'lodash'

// evertel
import api from '../api'
import { container } from '../di'
import Department from './models/Department'
import AppStore from './AppStore'
import { DepartmentSSOController, DepartmentStore } from '@evertel/department'

interface DepartmentTypeOption {
    label: string;
    value: string;
}

// Keeps track of our login state and progress
class DepartmentsStore {
    private departmentSSOController: DepartmentSSOController
    private newDepartmentStore: DepartmentStore

    constructor() {
        this.departmentSSOController = container.get(DepartmentSSOController)
        this.newDepartmentStore = container.get(DepartmentStore)

        makeAutoObservable(this)
    }

    get departments(): Department[] {
        return this.newDepartmentStore?.objectsArray.map(o => new Department(o)) || []
    }

    updateFromData(data: any | any[]): void {
        if (!data) {
            throw new Error('data cannot be undefined')
        }

        // if data is an array then use it, otherwise its an object, put it in an array, the array objects should be unique by id
        const dataDepartments = _.uniqBy(Array.isArray(data) ? data : [data], 'id')
        this.newDepartmentStore?.update(dataDepartments)
    }

    addDepartmentToStore = (department: any): void => {
        this.updateFromData(department)
    }

    updateEnterpriseConnector = async (params: any): Promise<void> => {
        await this.departmentSSOController.updateEnterpriseConnector(params)
    }

    getDepartmentById(id: number): Department | undefined {
        if (!id) return undefined

        const deptId = +id

        const department = this.departments.find(d => d.id === deptId)

        //if we have a matching dept, return it, otherwise, fetch it from the server
        if (department) {
            return department
        } else {

            const tempDept = new Department({ id: deptId, name: 'Locating...' })

            // No Await, let this run on its own
            api.Routes.Department.getById(deptId).then(fetchedDept => {
                this.updateFromData(fetchedDept)
                tempDept.updateFromData(fetchedDept)
            }).catch(e => {
                if (!api.isProduction) console.log('api.Department.get Error', e)

                // Clean up on error
                tempDept.updateFromData({ name: 'Error' })
            })

            // Return the tempDept that will be updated by api.Department.get(id).then() at a later time
            return tempDept
        }
    }

    async fetchDepartmentById(id: number): Promise<Department | false> {
        if (!id) return false
        try {
            const dept = await api.Department.get(id, {
                include: ['publicMedia', 'enterprise']
            })
            this.updateFromData(dept)
            return this.departments.find(d => d.id === id)
        } catch (e: any) {
            AppStore.logError({
                type: 'API get',
                message: 'DepartmentsStore.fetchDepartmentById()',
                error: e.message || e,
                userId: AppStore.userId
            })
            return false
        }
    }

    get departmentTypeOptions(): DepartmentTypeOption[] {
        return [
            { label: 'Police', value: 'police' },
            { label: 'Fire', value: 'fire' },
            { label: 'Paramedics', value: 'paramedics' },
            { label: 'Government', value: 'government' },
            { label: 'Military', value: 'military' }
        ]
    }
}

// Creates a single instance of this store
export default new DepartmentsStore()