import { NormalizedMedia } from '@evertel/media'
import { DisplayRoomMessage } from '@evertel/stores'
import React, { useEffect, useState } from 'react'
import { ReplyTileBodyProps } from './ReplyTileBody'
import { Col, ContentPlaceholder, Icon, Row, Text } from '@evertel/web/ui'
import { parsedContent } from '@evertel/utils'
import { ProfilePic } from '@evertel/web/user'
import { useService } from '@evertel/di'
import { RoomForwardedMessageController } from '@evertel/room'
import { MessageHeader } from '../MessageHeader'
import { ReplyTileBodyUser } from './ReplyTileBodyUser'

const ReplyTileBodyForwarded: React.FC<ReplyTileBodyProps> = ({
    message,
    media
}) => {
    const [truncatedMessage, setTruncatedMessage] = useState(message.text)
    const roomForwardedMessageController = useService(RoomForwardedMessageController, [message])

    useState(() => {
        roomForwardedMessageController.init(message)
    })

    useEffect(() => {
        trucatext()
    }, [message])

    
    const trucatext = () => {
        if (message.text.length > 250) {
            const truncatedMessage = message.text.slice(0, 250) + '...'
            setTruncatedMessage(truncatedMessage)
        }
    }
    
    const forwardedPost = roomForwardedMessageController.message as DisplayRoomMessage
    const room = roomForwardedMessageController.room
    const user = roomForwardedMessageController.user || {}
    const formattedDate = roomForwardedMessageController.formattedPublished
    
    return (
        <Col className="mt-1">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {message.isUrgent && (
                    <span className="urgent-icon" >
                        <Icon
                            name='triangle-exclamation'
                            color='white'
                        />
                    </span>
                )}

                <div
                    dangerouslySetInnerHTML={{ __html: parsedContent(truncatedMessage) }}
                    className={`post-content reply-body ${message.isUrgent ? 'urgent' : ''}`}
                    style={{ display: 'inline-block' }}
                />
            </div>
            <div className='reply-forwarded-item'>
                <Row>
                    <Col valign="top" className='left-col ml-2'>
                        <ProfilePic
                            userId={user?.id}
                            firstName={user?.firstName}
                            lastName={user?.lastName}
                            imageUrl={user?.publicImage}
                            isGuest={false}
                            width={20}
                            height={20}
                        />
                    </Col>
                    <Col xs={11} valign="top" className='ml-1'>
                        <MessageHeader
                            message={forwardedPost}
                            user={user}
                            isGuest={false}
                            onClickUser={() => {/**/}}
                            className="reply-body"
                            variant='reply-forwarded'
                        />
                    </Col>
                </Row>
                <Row className='ml-2 mt-2'>
                    <ReplyTileBodyUser
                        message={forwardedPost}
                        media={media}
                        variant="forward"
                    />
                </Row>
                <div className='ml-2 mt-2'>
                    <Text size="smaller" bold className="mt-4">
                        {room && 
                            `Posted in #${room?.name} | `
                        }
                        {formattedDate}
                    </Text>
                </div>
            </div>
        </Col>
    )
}

export { ReplyTileBodyForwarded}