import React, { useRef, Suspense, useEffect, createContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
// evertel
import { MessageWall } from '@evertel/web/message'
import { PhotoGallery, Spinner, SlidePanel, SlidePanelHeader, Text, CloseButton, SlidePanelBody, InfoBox, Row, Col } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { ThreadController } from '@evertel/thread'
import { APIDataThread } from '@evertel/types'
import { SessionState } from '@evertel/session'
import { DepartmentEmojisController } from '@evertel/emojis'
import { ThreadHeader } from './elements/ThreadHeader'
import { ThreadMediaSearch } from './elements/aside/ThreadMediaSearch'
import { ThreadDirectory } from './elements/aside/ThreadDirectory'
import { RightGutter } from './elements/aside/RightGutter'
// dynamic imports for code splitting
const MessageSearchModal = React.lazy(() => import('@evertel/web/message').then(module => ({ default: module.MessageSearchModal })))

interface ThreadContextProps {
    threadController: ThreadController,
    departmentEmojisController: DepartmentEmojisController,
    setIsMessageSearchModalOpen: (state: boolean) => void,
    toggleAside: (tab: string, intent: 'open'|'close') => void
}

export const ThreadContext = createContext({} as ThreadContextProps)


const Thread = observer(() => {

    const session = useService(SessionState, [])
    const threadController = useService(ThreadController, [])
    const departmentEmojisController = useService(DepartmentEmojisController, [])

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [showAsideTab, setShowAsideTab] = useState<string>('directory')
    const [openAside, setOpenAside] = useState(false)
    const [isMessageSearchModalOpen, setIsMessageSearchModalOpen] = useState(false)
    const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null)

    const asideTabs = {
        directory: {
            title: 'People in This Thread',
            component: ThreadDirectory
        },
        'media-search': {
            title: 'File Search',
            component: ThreadMediaSearch
        },
        closed: {
            title: null,
            component: () => null
        }
    }

    useEffect(() => {
        document.body.classList.add('aside-menu-hide')
        document.addEventListener('keydown', handleEscape)

        return () => {
            document.body.classList.remove('aside-menu-hide')
            document.removeEventListener('keydown', handleEscape)
        }
    }, [])

    const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            toggleAside(showAsideTab, 'close')
        }
    }

    const { id: currentUrlIdString } = useParams<{id: string}>()
    const currentThreadId = currentUrlIdString ? parseInt(currentUrlIdString, 10) : undefined

    useEffect(() => {

        if (!currentThreadId) {
            navigate('/landing')
            return
        }

        setIsLoading(true)
            
        // initialize controllers
        threadController.init(currentThreadId)
        departmentEmojisController.init(session.selectedDepartmentId)

        setIsLoading(false)

    }, [currentThreadId, session.selectedDepartmentId])

    useEffect(() => {
        const container = document.getElementById('slide-panel-root')
        if (container) setPortalContainer(container)

        return () => { setPortalContainer(null) }
    }, [])

    const toggleAside = (tab = 'directory', intent: 'open'|'close') => {
        if (intent === 'close') {
            setOpenAside(false)
            setShowAsideTab(null)
        } else if (intent === 'open') {
            setShowAsideTab(tab)
            setOpenAside(true)
        }
    }

    const contextValues = {
        threadController,
        departmentEmojisController,
        setIsMessageSearchModalOpen,
        toggleAside
    }

    const thread = threadController.thread
    const AsideComponent = asideTabs[showAsideTab]?.component || asideTabs.closed.component

    if (!thread) {
        return (
            <InfoBox
                color="warning"
                className="m-3">
                Sorry, this thread was not found or you do not have access to it
            </InfoBox>
        )
    }

    return (
        <ThreadContext.Provider value={contextValues}>
            <PhotoGallery>
                <Row valign='stretch' className="thread-container h-100">
                    <Col className="w-100">
                        <ThreadHeader/>
                        <MessageWall
                            modelId={threadController.id}
                            modelType="thread"
                            modelData={threadController.thread}
                            allowedToPost={!threadController.isBotThread}
                        />
                    </Col>

                    <RightGutter
                        activeTab={(openAside) && showAsideTab}
                        toggleAside={toggleAside}
                    />
                    {portalContainer && createPortal(
                        <SlidePanel
                        // embed
                            backdrop={false}
                            placement="end"
                            visible={openAside}
                            className="room-aside">
                            <SlidePanelHeader>
                                <Text
                                    heavy
                                    className="text-capitalize">
                                    {asideTabs[showAsideTab]?.title}
                                </Text>
                                <CloseButton
                                    onClick={() => toggleAside(showAsideTab, 'close')}
                                />
                            </SlidePanelHeader>
                            <SlidePanelBody>
                                <AsideComponent/>
                            </SlidePanelBody>
                        </SlidePanel>,
                        portalContainer
                    )}
                </Row>

                <Suspense fallback={<Spinner />}>
                    <MessageSearchModal
                        visible={isMessageSearchModalOpen}
                        onClose={() => setIsMessageSearchModalOpen(false)}
                        modelType="thread"
                        modelId={threadController.id}
                        modelName={threadController.name}
                    />
                </Suspense>

            </PhotoGallery>
        </ThreadContext.Provider>
    )
})

Thread.displayName = 'Thread'

export { Thread }
