import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { RoomStore } from '@evertel/stores'
import { BlueUserStore } from '@evertel/blue-user'
import { Api } from '@evertel/api'
import { APIDataRoom, APIDataRoomMessage, APIDataRoomMessageForwarded } from '@evertel/types'
import moment from 'moment'

class RoomForwardedMessageController {
    originalMessage: APIDataRoomMessage = {}
    roomId = 0
    ownerId = 0

    constructor(
        private api: Api,
        private roomStore: RoomStore,
        private userStore: BlueUserStore
    ) {
        makeAutoObservable(this)
    }

    init = (message: APIDataRoomMessage) => {
        this.originalMessage = message
        this.roomId = this.originalMessage?.meta?.forwardedMessage?.roomId
        this.ownerId = this.originalMessage?.meta?.forwardedMessage?.ownerId
    }

    get id(): number {
        return this.roomId
    }
    
    get room(): APIDataRoom {
        return this.roomStore.findById(this.roomId)
    }

    get user() {
        return this.userStore.findById(this.ownerId)
    }

    get fullName() {
        if (!this.user) return undefined
        return this.user.firstName + ' ' + this.user.lastName
    }

    get formattedPublished() {
        return moment(this.message.publishedDate).format('lll')
    }

    get message() {
        const message = {
            media: this.originalMessage.media || [],
            isUrgent: false,
            videoUrls: [],
            ...this.originalMessage?.meta?.forwardedMessage
        }
        if (!message.meta) message.meta = {}
        message.meta.isForwarded = true

        return message
    }

}

decorate(injectable(), RoomForwardedMessageController)
decorate(inject(Api), RoomForwardedMessageController, 0)
decorate(inject(RoomStore), RoomForwardedMessageController, 1)
decorate(inject(BlueUserStore), RoomForwardedMessageController, 2)

export { RoomForwardedMessageController }