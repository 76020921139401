import { APIDataRoomMessage, APIMetaDataRoomMessagePropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

export interface DisplayRoomMessage extends APIDataRoomMessage {
    prevMessageDate?: Date,
    prevOwnerId?: number,
    dateChanged?: boolean,
    meta?: {
        repliesSubscription?: {
            notify: boolean
        },
        [key: string]: any
    }
}

class RoomMessagesStore extends Store<APIDataRoomMessage> {
    constructor() {
        super([...APIMetaDataRoomMessagePropertyNames, 'media', 'reactions', 'prevMessageDate', 'prevOwnerId', 'dateChanged'])
    }
}

decorate(injectable(), RoomMessagesStore)
const roomMessagesStoreInstance = new RoomMessagesStore()


export { RoomMessagesStore, roomMessagesStoreInstance  }
