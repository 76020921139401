import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { RoomContext } from '../../RoomContext'
import { Button, Col, Icon, Row, Text } from '@evertel/web/ui'
import { Link } from 'react-router-dom'
import { RoomRepliesSearchSelect } from '../RoomRepliesSearchSelect'


const RoomReplies: React.FC = observer(() => {

    const { roomController: { room }, toggleAside } = useContext(RoomContext)

    return (
        <Col>
            <Link
                to={`/room/${room.id}`}
                onClick={() => toggleAside('replies', 'close')}
                className='btn btn-outline-primary reply-button text-center p-3 mx-3 mb-0'
            >
                <Icon
                    name='envelope'
                    type='solid'
                    className='icon'
                />
                <Text 
                    className='text ml-2'
                    size={15}
                >
                    All messages in  #<i><strong>{room.name}</strong></i>
                </Text>
            </Link>
            <Col className='mt-3 w-100'>
                <RoomRepliesSearchSelect 
                    onSelect={() => toggleAside('replies', 'close')}
                    menuIsOpen
                    maxMenuHeight="calc(100vh - 220px)"
                    className="no-bg search space-below"
                />
            </Col>
        </Col>
    )
})

export { RoomReplies }