import React, { useMemo, useContext } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
// evertel
import { useUI, Icon, Row } from '@evertel/web/ui'
import { MessageWallContext } from '../MessageWallContext'
import { EmojiList } from './EmojiList'
import { DisplayRoomMessage, DisplayThreadMessage } from '@evertel/stores'

type OptionProps = {
    option: string,
    messageId?: number,
    params?: any
}

interface HoverToolbarProps {
    message: DisplayRoomMessage | DisplayThreadMessage
    visible: boolean
    canRetract: boolean
    canReact?: boolean
    canForward?: boolean
    canReply?: boolean
    onClickOption: (option: OptionProps) => void
    onOpenEmojiPicker: (target: string) => void
    ownerId?: number
    variant?: 'list-item' | 'search' | 'reply'
}

// Type predicate function
function isDisplayRoomMessage(message: DisplayRoomMessage | DisplayThreadMessage): message is DisplayRoomMessage {
    return 'roomId' in message
}

const HoverToolbar: React.FC<HoverToolbarProps> = observer(({
    message,
    visible,
    canRetract = false,
    canReact = true,
    canForward = false,
    canReply = true,
    onOpenEmojiPicker,
    onClickOption,
    ownerId,
    variant = 'list-item'
}) => {
    const { currentUserEmojisController, setIsReadByModalOpen, setSelectedMessageId } = useContext(MessageWallContext)

    const { isConfirmed } = useUI()

    const onClickReads = () => {
        setSelectedMessageId(message.id)
        setIsReadByModalOpen(true)
    }

    const onClickForward = () => {
        onClickOption({
            option: 'forward-message',
            messageId: message.id
        })
    }

    const onClickRetract = async () => {
        const confirmed = await isConfirmed({
            title: 'Retract message?',
            message: 'Are you sure you want to retract this message. This will remove it from the conversation?',
            acceptButton: {
                label: 'Retract',
                color: 'danger'
            }
        })
        if (confirmed) {
            onClickOption({
                option: 'retract',
                messageId: message.id
            })
        }
    }
    const emojis = useMemo(() => currentUserEmojisController.getFrequent(5, true), [currentUserEmojisController])

    // important to minimize what is rendered with each message
    if (!visible) return null

    return (
        <Row className="hover-toolbar mt-2">
            {/* REACTIONS */}
            {(canReact) &&
                <div
                    className={classNames('option', { 'reactions': emojis.length > 0 })}
                    id={`msg-reactions-${message.id}`}
                    onClick={() => onOpenEmojiPicker(`#msg-reactions-${message.id}`)}>
                    <EmojiList
                        emojis={emojis}
                        onClickOption={onClickOption}
                        messageId={message.id}
                        skinTone={currentUserEmojisController.skinTone}
                    />
                    <Icon
                        name="grin"
                        className="emoji-btn"
                    />
                </div>
            }

            {/* READS */}
            <div
                className="option"
                onClick={onClickReads}
            >
                <Icon
                    name="eye"
                />
            </div>

            {/* REPLIES */}
            {canReply && isDisplayRoomMessage(message) && message.repliesToId == null && (ownerId !== 1) &&
                <div className="option">
                    <Link to={`/room/${message.roomId}/replies/${message.id}`}>
                        <Icon
                            name="message-lines"
                            className="replies-btn"
                        />
                    </Link>
                </div>
            }

            {/* FORWARD */}
            {(canForward) &&
                <div
                    className="option"
                    onClick={onClickForward}
                >
                    <Icon
                        name="share"
                    />
                </div>
            }

            {/* RETRACT */}
            {(canRetract) &&
                <div
                    className="option"
                    onClick={onClickRetract}
                >
                    <Icon
                        name="trash-alt"
                    />
                </div>
            }
        </Row>
    )
})

export { HoverToolbar }