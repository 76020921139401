
import { makeAutoObservable, runInAction } from 'mobx'
import { injectable, inject, decorate } from 'inversify'
import { SessionState } from '@evertel/session'
import { Api } from '@evertel/api'
import { APIDataBlueUser, APIDataRoom, APIDataThread } from '@evertel/types'
import { DepartmentsAccessStore } from '@evertel/departments-access'
import { getRole } from '@evertel/utils'
import { BlueUserStore, UnreadCountsState } from '@evertel/blue-user'
import { RoomStore, ThreadStore, DisplayThread } from '@evertel/stores'
import { uniqBy } from 'lodash'

const GET_ROOMS_AND_THREADS_POLL_ENABLED = true
const GET_ROOMS_AND_THREADS_POLL_INTERVAL_MS = 1000 * 15 // 15 seconds
class DrawerController {
    private pollInterval = undefined // holds the setInterval timer

    constructor(
        private api: Api,
        private session: SessionState,
        private userStore: BlueUserStore,
        private departmentsAccessStore: DepartmentsAccessStore,
        private unreadState: UnreadCountsState,
        private roomStore: RoomStore,
        private threadStore: ThreadStore
    ) {
        makeAutoObservable(this)
    }

    startPoll = () => {
        this.stopPoll()
        if (!GET_ROOMS_AND_THREADS_POLL_ENABLED) return

        this.pollInterval = setInterval(() => {
            Promise.all([
                this.fetchActiveRooms(),
                this.fetchActiveThreads()
            ]).then()
        }, GET_ROOMS_AND_THREADS_POLL_INTERVAL_MS)
    }

    stopPoll = () => {
        if (this.pollInterval) {
            clearInterval(this.pollInterval)
            this.pollInterval = undefined
        }
    }

    // Extend the interval timer if its set
    resetPollInterval = () => {
        if (this.pollInterval) {
            this.startPoll()
        }
    }

    fetchActiveRooms = async () => {
        this.resetPollInterval()
        const roomsCount = (await this.api.Routes.BlueUser.getRoomsCount(this.session.currentUserId, {
            where: {
                isArchived: false
            }
        }))?.count || 0

        const pages = Math.ceil(roomsCount / 100)
        const promises = []

        for (let i = 0; i < pages; i++) {
            promises.push(this.api.Routes.BlueUser.getRooms(this.session.currentUserId, {
                where: { isArchived: false },
                skip: 100 * i,
                limit: 100
            }))
        }

        try {
            const fetchedRooms = await Promise.all(promises)
            
            const rooms = fetchedRooms?.reduce((acc, val) => acc.concat(val), [])
            const roomIds = rooms.map((r: APIDataRoom) => r.id)
            
            runInAction(() => {
                this.roomStore.update(rooms || [])
                this.session.roomIdsIBelongTo = roomIds
            })

        } catch (error: any) {
            console.error(error.message)
        }
    }

    fetchActiveThreads = async () => {
        this.resetPollInterval()
        const threadsCount = (await this.api.Routes.BlueUser.getThreadsCount(this.session.currentUserId))?.count || 0

        const pages = Math.ceil(threadsCount / 100)
        const promises = []

        for (let i = 0; i < pages; i++) {
            promises.push(this.api.Routes.BlueUser.getThreads(this.session.currentUserId, {
                include: {
                    relation: 'users',
                    scope: {
                        fields: ['id', 'firstName', 'lastName', 'publicImage', 'publicMedia', 'isBot', 'isAdmin']
                    }
                },
                skip: 100 * i,
                limit: 100
            }))
        }

        try {
            const fetchedThreads = await Promise.all<DisplayThread[]>(promises)

            const _threads = fetchedThreads?.reduce((acc, val) => acc.concat(val), [] as DisplayThread[])

            // grab unique users and flatten into single array
            const users = uniqBy(_threads.map(t => t.users)?.flat(), 'id')

            // add a userIds field to thread object
            const threads = _threads.map(t => ({...t, userIds: t.users?.map(u => u.id)}))

            threads.forEach(t => {
                t.name = this.getThreadName(t.users)
                t.isBotThread = t.meta.isBotThread
            })

            runInAction(() => {
                this.threadStore.update(threads)
                this.userStore.update(users as APIDataBlueUser[])
            })

        } catch (error: any) {
            console.error(error.message)
        }
    }

    getThreadName = (users: APIDataBlueUser[]) => {
        if (!users?.length) return null

        const otherUsers = users?.filter(u => u.id !== this.session.currentUserId)
        const isJustMe = users?.length === 1 && users[0].id === this.session.currentUserId

        if (isJustMe) {
            return users[0].firstName + users[0].lastName
        } else if (otherUsers?.length === 1) {
            return otherUsers[0].firstName + ' ' + otherUsers[0].lastName
        } else {
            const names = otherUsers?.map(u => u.firstName?.charAt(0) + '. ' + u.lastName)
            return names?.join(', ')
        }
    }

    closeThread = async (threadId: number) => {
        try {
            await this.api.Routes.Thread.delById(threadId)

            runInAction(() => {
                this.threadStore.deleteById(threadId)
            })

        } catch (error: any) {
            console.error(error.message)
        }
    }

    sortRooms = (roomArray: APIDataRoom[]) => {
        const user = this.userStore.findById(this.session.currentUserId)
        const userMeta = user?.meta as any
        const sortType = userMeta?.appearance?.drawerRoomSort

        switch (sortType) {
            case 'newest':
                return roomArray.sort((a: any, b: any) => Date.parse(b.createdDate) - Date.parse(a.createdDate))
            case 'oldest':
                return roomArray.sort((a: any, b: any) => Date.parse(a.createdDate) - Date.parse(b.createdDate))
            default:
            case 'unread': {
                const unread = roomArray.filter(r => this.unreadState.getRoomUnread(r.id as number)?.count > 0)
                const read = roomArray.filter(r => !this.unreadState.getRoomUnread(r.id as number) ||
                    this.unreadState.getRoomUnread(r.id as number)?.count <= 0)

                // unread first stored as alpha, then read sorted as alpha
                return this.sortRoomsAlpha(unread).concat(this.sortRoomsAlpha(read))
            }
        }
    }

    sortRoomsAlpha = (arr: APIDataRoom[]) => {
        return arr.sort((a: any, b: any) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
    }

    get sortedThreads() {
        const threadArray = [...this.threadStore.objectsArray]

        let sortedThreads: DisplayThread[]

        const user = this.userStore.findById(this.session.currentUserId)
        const userMeta = user?.meta as any
        const sortType = userMeta?.appearance?.drawerThreadSort || 'newest'

        const botThread = threadArray.find(t => t?.meta?.isBotThread === true)
        
        if (botThread) {
            const index = threadArray.findIndex(item => item?.id === botThread?.id)
            if (index > -1 ) {
                threadArray.splice(index, 1)
            }
        }

        switch (sortType) {
            case 'alpha':
                sortedThreads = this.sortThreadsAlpha(threadArray)
                break
            case 'newest':
                sortedThreads = threadArray.sort((a: any, b: any) => Date.parse(b.createdDate) - Date.parse(a.createdDate))
                break
            case 'oldest':
                sortedThreads = threadArray.sort((a: any, b: any) => Date.parse(a.createdDate) - Date.parse(b.createdDate))
                break
            default: {
                const unread = threadArray.filter(r => this.unreadState.getThreadUnread(r.id as number)?.count > 0)
                const read = threadArray.filter(r => !this.unreadState.getThreadUnread(r.id as number) ||
                    this.unreadState.getThreadUnread(r.id as number)?.count <= 0)

                // unread first stored as alpha, then read sorted as alpha
                sortedThreads = this.sortThreadsAlpha(unread).concat(this.sortThreadsAlpha(read))
                break
            }
        }

        if (botThread) {
            sortedThreads.unshift(botThread)
        }

        return sortedThreads
    }

    sortThreadsAlpha = (arr: DisplayThread[]) => {
        return arr.sort((a: DisplayThread, b: DisplayThread) => {
            return a.name?.toLocaleLowerCase().localeCompare(b.name?.toLocaleLowerCase())
        })
    }

    threadUsers = (threadId: number) => {
        const userIds = this.threadStore.findById(threadId).userIds
        if (!userIds) return null

        return this.userStore.findByIds(userIds)
    }

    get interAgencyRooms() {
        const guestDAs = this.departmentsAccessStore.find(da => (da.blueUserId === this.session.currentUserId && getRole(da.roles) === 'guest'))
        const guestDepartmentIds = guestDAs?.map(d => d.departmentId) || []
        const rooms = this.roomStore?.find((r: APIDataRoom) => (r.departmentId !== this.session.selectedDepartmentId && this.session.roomIdsIBelongTo?.includes(r.id) && guestDepartmentIds?.includes(r.departmentId)))
        return this.sortRooms(rooms)
    }

    get agencyWideRooms() {
        const rooms = this.roomStore?.find((r: APIDataRoom) => (r.departmentId === this.session.selectedDepartmentId && this.session.roomIdsIBelongTo?.includes(r.id) && (r.options as any)?.autoAddDepartmentMembers))
        return this.sortRooms(rooms)
    }

    get teamRooms() {
        const rooms = this.roomStore?.find((r: APIDataRoom) => (r.departmentId === this.session.selectedDepartmentId && this.session.roomIdsIBelongTo?.includes(r.id) && !(r.options as any)?.autoAddDepartmentMembers))
        return this.sortRooms(rooms)
    }

    get teamRoomsUnreadCountTotal() {
        //console.log(this.session.currentUser.user)
        const roomIds = this.teamRooms.map(r => r.id)
        const unreads = this.unreadState.roomUnreads.filter((u: any) => roomIds.includes(u.roomId))
        return {
            count: unreads.reduce((acc: number, obj: any) => acc + obj.count, 0),
            urgent: unreads.find((u: any) => u.urgent)
        }
    }

    get agencyWideRoomsUnreadCountTotal() {
        const roomIds = this.agencyWideRooms.map(r => r.id)
        const unreads = this.unreadState.roomUnreads.filter((u: any) => roomIds.includes(u.roomId))
        return {
            count: unreads.reduce((acc: number, obj: any) => acc + obj.count, 0),
            urgent: unreads.find((u: any) => u.urgent)
        }
    }

    get interAgencyRoomsUnreadCountTotal() {
        const roomIds = this.interAgencyRooms.map(r => r.id)
        const unreads = this.unreadState.roomUnreads.filter((u: any) => roomIds.includes(u.roomId))
        return {
            count: unreads.reduce((acc: number, obj: any) => acc + obj.count, 0),
            urgent: unreads.find((u: any) => u.urgent)
        }
    }

    get threadsUnreadCountTotal() {
        const threadIds = this.threadStore.objectsArray?.map(t => t.id)
        const unreads = this.unreadState.threadUnreads.filter((u: any) => threadIds.includes(u.threadId))
        return {
            count: unreads.reduce((acc: number, obj: any) => acc + obj.count, 0),
            urgent: unreads.find((u: any) => u.urgent)
        }
    }
}

decorate(injectable(), DrawerController)
decorate(inject(Api), DrawerController, 0)
decorate(inject(SessionState), DrawerController, 1)
decorate(inject(BlueUserStore), DrawerController, 2)
decorate(inject(DepartmentsAccessStore), DrawerController, 3)
decorate(inject(UnreadCountsState), DrawerController, 4)
decorate(inject(RoomStore), DrawerController, 5)
decorate(inject(ThreadStore), DrawerController, 6)

export { DrawerController }
