import { useContext } from 'react'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UIContext, ConfirmProps } from '@evertel/web/ui'

type ToggleIntent = 'open' | 'toggle' | 'close'
interface EToasterProps {
    visible?: boolean
    title?: string
    message: string
    autohide?: boolean
    animation?: boolean
    className?: string
    color?: string
    delay?: number
    closeButton?: boolean
    onShow?: () => void
    onClose?: () => void
}
interface UIContextProps {
    children?: JSX.Element
    confirm?: ConfirmProps
    setConfirm: (config: ConfirmProps) => void
    toasts?: EToasterProps
    addToast: (config: EToasterProps) => void
    toggleLeftNav: (intent: ToggleIntent) => void
    leftNavOpen?: boolean
}

type IsConfirmedProps = Omit<ConfirmProps, 'isOpen, proceed, cancel'>

const useConfirm = () => {
    const { confirm, setConfirm } = useContext(UIContext)

    const isConfirmed = ({
        title,
        message,
        cancelButton,
        acceptButton,
        headerClassName
    }: IsConfirmedProps) => {

        const promise = new Promise((resolve, reject) => {
            setConfirm({
                title, // string
                message, // string
                cancelButton, // {label: '', color: '', ...buttonProps}
                acceptButton, // {label: '', color: '', ...buttonProps}
                headerClassName, // string
                isOpen: true, // bool
                proceed: resolve, // func
                cancel: reject // func
            })
        })

        return promise.then(
            () => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setConfirm((prevState: ConfirmProps) => ({
                    ...prevState,
                    isOpen: false
                }))
                return true
            },
            () => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setConfirm((prevState: ConfirmProps) => ({
                    ...prevState,
                    isOpen: false
                }))
                return false
            }
        )
    }

    return {
        ...confirm,
        isConfirmed
    }
}

export { useConfirm }
