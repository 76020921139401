import { makeAutoObservable, runInAction } from 'mobx'

import _ from 'lodash'
// evertel
import { injectable, inject, decorate } from '@evertel/di'
import { Api } from '@evertel/api'
import { APIDataRoomMessage, APIDataThreadMessage } from '@evertel/types'
import moment from 'moment'
import { RoomStore, RoomMessagesStore, ThreadStore, ThreadMessagesStore, DisplayRoomMessage, DisplayThreadMessage } from '@evertel/stores'
import { FetchUserService, UnreadCountsState } from '@evertel/blue-user'
import { MediaUploadService } from '@evertel/media'
import { PushService, PushNotification } from '@evertel/push'
import debugModule from 'debug'
const debug = debugModule('app:MessageWallController')

const GET_NEW_MESSAGE_POLL_ENABLED = true
const GET_ROOM_MESSAGE_POLL_INTERVAL_MS = 10000
const GET_THREAD_MESSAGE_POLL_INTERVAL_MS = 5000

export const MESSAGE_DEFAULT_INCLUDE = [{
    relation: 'media',
    scope: {
        fields: ['id', 'mimetype', 'url', 'ownerId', 'requiresAuth', 'description', 'previewUrl', 'fileName', 'contentLength', 'meta']
    }
}, {
    relation: 'reactions',
    scope: {
        include: [{
            relation: 'reactedByThrough',
            scope: {
                fields: ['blueUserId']
            }
        }]
    }
}]

class MessageWallController {
    modelId = null // roomId or threadId
    modelType: 'room' | 'thread' = 'room'
    messagesCount = null
    limit = 44
    errorFetching = null

    private _repliesToId: number | null = null
    private pollInterval: any | undefined = undefined
    private messagesStore: RoomMessagesStore | ThreadMessagesStore

    constructor(
        private api: Api,
        private roomStore: RoomStore,
        private roomMessagesStore: RoomMessagesStore,
        private threadStore: ThreadStore,
        private threadMessagesStore: ThreadMessagesStore,
        private unreadState: UnreadCountsState,
        private mediaUploadService: MediaUploadService,
        private fetchUserService: FetchUserService,
        private pushService: PushService
    ) {
        makeAutoObservable(this)
    }

    init = (modelId: number, modelType: 'room' | 'thread', repliesToId: number | null = null) => {
        debug('init', modelId)
        if (!modelId) {
            console.error('Missing a modelId! in MessageWallController.init')
            return
        }

        this.modelId = modelId
        this.modelType = modelType
        this.repliesToId = repliesToId
        this.messagesStore = (modelType === 'room') ? this.roomMessagesStore : this.threadMessagesStore

        this.pushService.addPushNotificationListener(this.onPushNotification)
        this.startPoll()
    }

    destroy = () => {
        debug('Destroy start', this.modelId, 'interval', this.pollInterval)
        this.pushService.removePushNotificationListener(this.onPushNotification)
        this.stopPoll()
        debug('Destroy end', this.modelId)
    }

    onPushNotification = (notification: PushNotification) => {
        debug('onPushNotification', this.modelId)
        if (!this.api.isProduction) debug('MessageWallController.onPushNotification', notification)
        // If we get a push for the model and id we are looking at, update
        // TODO: make this smarter to only fetch what we know has changed.
        if (notification.data.type === 'RoomMessage'
                && this.modelType === 'room'
                && notification.data.roomId === this.modelId
                && notification.data.repliesToId === this.repliesToId
        ) {
            this.fetchMessages('new')
        } else if (notification.data.type === 'ThreadMessage' && this.modelType === 'thread' && notification.data.threadId === this.modelId) {
            this.fetchMessages('new')
        } else {
            this.unreadState.fetchUnreadCounts()
        }
    }

    setLimit(limit: number) {
        this.limit = limit
    }

    get repliesToId() {
        return this._repliesToId
    }

    set repliesToId(repliesToId) {
        this._repliesToId = repliesToId
        this.messagesCount = null
    }

    /**
     * @experimental don't use this probably, it's fetched with notification permissions in RoomController
     */
    get repliesToMsg() {
        if (this.messagesStore) {
            return this.messagesStore.findById(this.repliesToId)
        }
        return null
    }

    /**
     * Retrieve messages based on the specified order.
     * For 'old': Retrieve a number of messages prior to oldest message id that is not retracted.
     * For 'new': Retrieve all messages with updatedDates greater than the newestUpdatedDate including retracted.
     * @param order 'old' | 'new' | 'first'
     */
    fetchMessages = async (order: 'first' | 'old' | 'new' = 'new') => {
        debug('fetchMessages:', this.modelId)
        if (!this.modelId) {
            console.error('Missing a modelId! in MessageWallController.fetchMessages')
            return
        }

        this.errorFetching = null
        let count = this.messagesCount

        if (['first', 'new'].includes(order)) this.resetPoll()

        try {
            const sharedWhere = {
                repliesToId: this.repliesToId,
                publishedDate: { neq: null },
                and: [{or: [
                    // {isRetracted: false}
                    // {and: [ {isRetracted: true}, {hasReplies: true} ] }
                ] }]
            }

            // Fetch total count of messages if not available
            if (!this.messagesCount) {
                count = await this.fetchMessagesCount(sharedWhere)
            }

            // stop from fetching if we already got all messages
            // if (count <= this.messagesSorted?.length) return

            // we only want messages with positive ID
            // Question: why/how would they ever be negative?
            const remoteMessages = this.messagesSorted.filter(m => m.id > 0)
            // const remoteMessagesUnfiltered = this.messagesSortedRaw(true).filter(m => m.id > 0)
            let whereClause, orderBy, limit

            if (!remoteMessages?.length) { order = 'first' }

            const lowestId = remoteMessages.reduce((a, m) => Math.min(a, m.id), 999999999999)

            // get messages based on the last id we have, or if we have no messages, get the latest
            if (order === 'old') {
                whereClause = { id: { lt: lowestId } }
                orderBy = 'id DESC'
                limit = this.limit
            } else if (order === 'new') {
                const newestUpdatedDate = remoteMessages.reduce((newest, current) => {
                    return (newest > current.updatedDate) ? newest : current.updatedDate 
                }, remoteMessages[0]?.updatedDate)
                whereClause = { id: { gte: lowestId }, updatedDate: { gt: newestUpdatedDate} }
                orderBy = 'id ASC'
                limit = undefined // No limit to get all updated cases
            } else if (order === 'first') {
                // First request, when no messages are available locally
                orderBy = 'id DESC'
                whereClause = { id: { gt: 0 } }
                limit = this.limit
            }

            const filter = {
                include: MESSAGE_DEFAULT_INCLUDE,
                unpublished: true, // we want to get all messages to keep synchronized with the backend
                where: {
                    ...whereClause,
                    ...sharedWhere
                },
                order: orderBy, // the order matters when im looking to receive for the next set of (limited to GET_MESSAGE_LIMIT) messages and not just the oldest or newest in the DB
                limit: limit
            }

            const newMessages: (APIDataRoomMessage | APIDataThreadMessage)[] = 
                await (this.modelType === 'room' 
                    ? this.api.Routes.Room.getMessages(this.modelId, filter)
                    : this.api.Routes.Thread.getMessages(this.modelId, filter))
                
            runInAction(() => {
                // Update all messages, including redacted ones
                newMessages.forEach(message => {
                    if (message.isRetracted) {
                        // message.text = '' // Clear text for redacted messages
                        message.type = 'retracted'
                        message.ownerId = 1
                    }
                })
                //existing redacted messages should be updated here
                this.messagesStore?.update(newMessages)
            })

            // Fetch users from messages
            const ownerIds = newMessages.map(m => m.ownerId)
            if (this.modelType === 'room') {
                this.fetchUserService.fetchSpecificUsersFromRoom(this.modelId, ownerIds)
                this.processForwardedMessage(newMessages)
            } else {
                this.fetchUserService.fetchSpecificUsersFromThread(this.modelId, ownerIds)
            }

            if (['new', 'first'].includes(order) && newMessages.length) {
                // only call for newMessages to reduce read table writes, existing messages should already be marked
                this.markAllMessagesAsRead()
            }

        } catch (e) {
            this.errorFetching = e
            throw e
        }
    }

    fetchMessagesCount = async (where?) => {
        const count = (await (this.modelType === 'room'
            ? this.api.Routes.Room.getMessagesCount(this.modelId, where)
            : this.api.Routes.Thread.getMessagesCount(this.modelId, where))).count

        runInAction(() => {
            this.messagesCount = count || 0
        })

        return this.messagesCount
    }

    /**
     * This will fetch a list of rooms that haven't been fetched before.
     * Note: while this is ok for agency forwarded messages, it is not robust enough
     *       for forwarding between agencies
     * @param forwardedRoomIds 
     * @returns 
     */
    fetchDepartmentRooms = async (forwardedRoomIds: number[]) => {

        const existingRooms = this.roomStore.find(room => forwardedRoomIds.includes(room.id))
        const existingRoomIds = existingRooms.map(room => room.id)
        const unknownRoomIds = forwardedRoomIds.filter(id => !existingRoomIds.includes(id))

        if (!unknownRoomIds.length) return

        const filter = {
            where: {
                id: {
                    inq: unknownRoomIds
                }
            }
        }
        const rooms = await this.api.Routes.Department.getRooms(this.room.departmentId, filter)

        this.roomStore.update(rooms)
    }

    processForwardedMessage = (newMessages: APIDataRoomMessage[]) => {

        const forwardedOwnerIds = newMessages
            .filter(m => m.type === 'forwarded' && _.get(m, 'meta.forwardedMessage.ownerId'))
            .map(m => m.meta.forwardedMessage.ownerId)
        this.fetchUserService.fetchUsersForForwardedOwnerIds(this.room.departmentId, forwardedOwnerIds)

        const forwardedRoomIds = newMessages
            .filter(m => m.type === 'forwarded' && _.get(m, 'meta.forwardedMessage.roomId'))
            .map(m => m.meta.forwardedMessage.roomId)
        this.fetchDepartmentRooms(forwardedRoomIds)
    }

    markAllMessagesAsRead = async () => {
        try {
            let where: any | undefined

            if (this.modelType === 'room') {
                where = {
                    //null, undefined, or 0 will fail this and set eq:null
                    repliesToId: /^[1-9]\d*$/.test(String(this.repliesToId)) ? Number(this.repliesToId) : { eq: null }
                }
            }

            if (this.modelType === 'room') {
                await this.api.Routes.Room.postMessagesReads(this.modelId, where)
            } else {
                await this.api.Routes.Thread.postMessagesReads(this.modelId, where)
            }

            runInAction(() => {
                if (this.modelType === 'room') {
                    this.unreadState.markReadByRoomId(this.modelId, this.repliesToId)
                } else {
                    this.unreadState.markReadByThreadId(this.modelId)
                }
            })

        } catch (error) {
            debug('markAllMessagesAsRead', error.message)
        }
    }

    startPoll = () => {
        this.stopPoll()

        if (!GET_NEW_MESSAGE_POLL_ENABLED) return

        this.pollInterval = setInterval(async () => {
            try {
                debug('Poll running', this.modelId)
                await this.fetchMessages('new')
            } catch (error: any) {
                if (error.status === 401) {
                    this.stopPoll()
                }
            }

        }, this.getPollIntervalTime())
    }

    stopPoll() {
        if (this.pollInterval) {
            clearInterval(this.pollInterval)
            this.pollInterval = undefined
        }
    }

    getPollIntervalTime() {
        switch (this.modelType) {
            case 'room':
                return GET_ROOM_MESSAGE_POLL_INTERVAL_MS
            case 'thread':
            default:
                return GET_THREAD_MESSAGE_POLL_INTERVAL_MS
        }
    }

    resetPoll() {
        if (this.pollInterval) {
            this.startPoll()
        }
    }

    get messagesWithDates() {
        // constructs an array of messages with date objects inserted
        const messages = this.messagesVisible // excludes retracted messages
        let newMessagesWithDates = null

        runInAction(() => {
            newMessagesWithDates = messages.map((msg, idx) => {
                // add a date object every time the message date changes

                const newMsg = msg //{ ...msg }
    
                // if no publishedDate (new messages not yet posted to server) use createdDate
                const currentDateField = newMsg.publishedDate ? 'publishedDate' : 'createdDate'
    
                // Convert current message date to moment format for comparison
                const currentPostDate = moment(newMsg[currentDateField]).format('LL')
    
                if (idx === 0) {
                // First message
                    newMsg.dateChanged = true
                } else {
                    const prevMsg = messages[idx - 1]
                    const prevDateField = prevMsg.publishedDate ? 'publishedDate' : 'createdDate'
    
                    // Convert previous message date to moment format
                    const prevPostDate = moment(prevMsg[prevDateField]).format('LL')
    
                    // Check if the date has changed
                    newMsg.dateChanged = prevPostDate !== currentPostDate
    
                    // Set previous message information
                    newMsg.prevMessageDate = prevMsg[prevDateField]
                    newMsg.prevOwnerId = prevMsg.ownerId
                }
    
                return newMsg
            })
        })

        return newMessagesWithDates
    }

    get messagesSorted() {
        return this.messagesSortedRaw(false)
    }

    messagesSortedRaw(unfiltered = false) {
        if (!this.messagesStore) return []
        // returns messages sorted by publishedDate or createdDate in DESC order (newest date on top).
        // **does NOT filter out retracted or empty messages

        const messages = this.messagesStore.find(m => {
            if (m.threadId === this.modelId) return true

            if (m.roomId === this.modelId) {
                if (unfiltered) return true
                
                if (this.repliesToId === null) {
                    return m.repliesToId === undefined || m.repliesToId === null
                } else {
                    return this.repliesToId === m.repliesToId
                }
            }
            
            return false
        })

        const sorted = messages.slice().sort((a: any, b: any) => {
            // sort by publish date, fall back to created date
            if (a.publishedDate && b.publishedDate) {
                return Date.parse(a.publishedDate) - Date.parse(b.publishedDate)
            } else {
                return Date.parse(a.createdDate) - Date.parse(b.createdDate)
            }
        })
        return sorted as Array<DisplayRoomMessage | DisplayThreadMessage>
    }

    get messagesVisible() {
        const sorted = this.messagesSorted?.filter((m) => {
            //the order of these if statements is significant

            //if there is a current upload nothing else matters, it should show
            const uploadableMedia = this.mediaUploadService.getMediaUploadsForModel(m.id, this.modelType)
            if (uploadableMedia.length) return true

            // don't return unpublished messages 
            if (!m.publishedDate) return false

            // don't return retracted messages
            // if (m.isRetracted && !m.hasReplies) return false

            // always display forwarded messages
            if (m.type === 'forwarded') return true

            // if empty, eg no text and no media, don't show
            if (!m.text && !m.media?.length) return false

            return true
        })

        return sorted
    }

    get room() {
        if (this.modelType === 'thread') return undefined
        return this.roomStore.findById(this.modelId)
    }

    get thread() {
        if (this.modelType === 'room') return undefined
        return this.threadStore.findById(this.modelId)
    }

}

decorate(injectable(), MessageWallController)
decorate(inject(Api), MessageWallController, 0)
decorate(inject(RoomStore), MessageWallController, 1)
decorate(inject(RoomMessagesStore), MessageWallController, 2)
decorate(inject(ThreadStore), MessageWallController, 3)
decorate(inject(ThreadMessagesStore), MessageWallController, 4)
decorate(inject(UnreadCountsState), MessageWallController, 5)
decorate(inject(MediaUploadService), MessageWallController, 6)
decorate(inject(FetchUserService), MessageWallController, 7)
decorate(inject(PushService), MessageWallController, 8)

export { MessageWallController }
