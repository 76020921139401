import { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
// evertel
import { Col, InfoBox, Button, useUI } from '@evertel/web/ui'
import { ThreadContext } from '../../Thread'
import { UserTile, useUserDetail } from '@evertel/web/user'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'


const ThreadDirectory = observer(() => {

    const { threadController } = useContext(ThreadContext)
    const { addToast } = useUI()
    const { openUserDetail } = useUserDetail()
    const navigate = useNavigate()
    const session = useService(SessionState, [])

    const leave = async () => {
        try {
            await threadController.closeThread()

            session.deleteThreadFromNavigationHistory(threadController.id)

            navigate('/landing')

        } catch (error: any) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
    }

    return (
        <Col>
            {threadController.users?.map((user, idx) =>
                <button
                    key={idx}
                    className='p-3 btn btn-link border-light hover-light'
                    style={{borderRadius: 0}}
                    onClick={() => openUserDetail(user.id as number)}
                >
                    <UserTile
                        userId={user.id as number}
                        showPosition
                    />
                </button>
            )}
            {(threadController.isJustMe) &&
                <InfoBox color="light">
                    This is your private space. It's a great place to draft messages, take notes, 
                    and keep links and files handy so you can reference them when you need them.
                </InfoBox>
            }
            {(threadController.canLeave) &&
                <Button
                    color="link"
                    onClick={leave}
                    className="mt-5">
                    Leave Conversation
                </Button>
            }
        </Col>
    )
})

export { ThreadDirectory }
