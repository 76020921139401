import React, { useState, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

// evertel
import { useService } from '@evertel/di'
import { SessionState, IdleTimerService } from '@evertel/session'
import { LogoutService } from '@evertel/logout'
import { Drawer, LeftGutter } from '@evertel/web/drawer'
import { Header } from '@evertel/web/header'
import { Col, useUI } from '@evertel/web/ui'
import { CurrentUserPollService, UnreadCountsState } from '@evertel/blue-user'
import { InviteStore } from '../../stores'
import { PushService } from '@evertel/push'

const EvertelLayout: React.FC = observer(() => {
    const { addToast } = useUI()

    const navigate = useNavigate()
    const location = useLocation()
    const pushService = useService(PushService)
    const sessionState = useService(SessionState, [])
    const logoutService = useService(LogoutService, [])
    const idleTimer = useService(IdleTimerService, [])
    const currentUserPollService = useService(CurrentUserPollService, [])
    const unreadCounts = useService(UnreadCountsState, [])

    useState(() => {
        if (!sessionState.currentUserId) {
            logoutService.logout()
        }
    })

    useEffect(() => {
        if (location.pathname === '/' && sessionState.currentUserId) {
            navigate('/landing', { replace: true })
        }
    }, [location, sessionState.currentUserId])

    useEffect(() => {
        if (!sessionState.currentUserId) return

        idleTimer.init()
        idleTimer.setOnIdleCallback(() => logoutService.logout())
        currentUserPollService.startPoll()
        unreadCounts.startPoll()
        currentUserPollService.fetchUserDetails().then(() => {
            InviteStore.consumePendingInvite().finally(() => {
                //the invite could have been consumed during NavigationStore.restoreNavigation
                //in which case the error wouldn't have been displayed
                if (InviteStore.error) {
                    addToast({
                        message: InviteStore.error?.message || InviteStore.error,
                        color: 'danger'
                    })
                    InviteStore.error = null
                }
            })
        })

        const notificationClicked = (url) => {
            navigate(url)
        }
        pushService.addNotificationClickedListener(notificationClicked)

        return () => {
            idleTimer.destroyTimers()
            currentUserPollService.stopPoll()
            unreadCounts.stopPoll()
            pushService.removeNotificationClickedListener(notificationClicked)
        }
    }, [sessionState.currentUserId])

    // This effect runs on mount and whenever location changes
    useEffect(() => {
        sessionState.updateNavigationHistory(location)
    }, [location, sessionState])

    if (!sessionState.currentUserId) return null

    return (
        <div className="app-wrap">
            <LeftGutter />
            <Drawer />
            <Col valign="top" className="canvas">
                <Header />
                <div className="page">
                    <Outlet />
                </div>
            </Col>
            <div id='slide-panel-root'></div>
        </div>
    )
})

export default EvertelLayout