import React, { forwardRef, HTMLAttributes, MouseEventHandler } from 'react'
import classNames from 'classnames'
import { Colors } from '../prop-types'

interface BadgeProps extends HTMLAttributes<HTMLDivElement|HTMLSpanElement> {
    color?: Colors,
    textColor?: Colors,
    size?: number
    position?: 'top-start'|'top-end'|'bottom-start'|'bottom-end'|''
    shape?: 'rounded'|'rounded-top'|'rounded-end'|'rounded-bottom'|'rounded-start'|'rounded-circle'|'rounded-pill'|'rounded-0'|'rounded-1'|'rounded-2'|'rounded-3'|string
    className?: string
    style?: any
    onClick?: MouseEventHandler<HTMLElement>|undefined
}

const Badge = forwardRef<HTMLDivElement, BadgeProps>(({
    color,
    textColor = 'white',
    size = 12,
    position = 'top-end',
    shape = 'rounded',
    className,
    style,
    onClick,
    children,
    ...otherProps
}, ref) => {

    const _className = classNames(
        'badge',
        {
            [`bg-${color}`]: color,
            // this should be used but all uses of it need to be wrapped in a "position:relative" item
            // 'position-absolute translate-middle': position,
            'top-0': position?.includes('top'),
            'top-100': position?.includes('bottom'),
            'start-100': position?.includes('end'),
            'start-0': position?.includes('start'),
            [`badge-${size}`]: size,
            [`text-bg-${color}`]: color,
            'cursor-pointer': onClick
        },
        shape,
        className
    )

    return (
        <span
            ref={ref}    
            className={_className}
            style={{
                ...style,
                fontSize: size
            }}
            onClick={(onClick) ? onClick : undefined}
            {...otherProps}>
            {children}
        </span>
    )
})

export { Badge }
