import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
// evertel
import { InfoBox, Button, Input, Modal, ModalBody, Text, useUI, ModalHeader, ModalFooter } from '@evertel/web/ui'
import { RoomContext } from '../../RoomContext'
import { formatNumber } from '@evertel/utils'
import { useService } from '@evertel/di'
import { Analytics } from '@evertel/analytics'
import { CloneRoomController } from '@evertel/room'

interface CloneRoomModalProps {
    visible: boolean,
    onClose: () => void
}


const CloneRoomModal: React.FC<CloneRoomModalProps> = observer(({
    visible,
    onClose
}) => {

    const { roomController } = useContext(RoomContext)
    const cloneRoomController = useService(CloneRoomController, [])
    const analytics = useService(Analytics, [])
    const navigate = useNavigate()
    const { addToast } = useUI()

    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [roomName, setRoomName] = useState('')
    const [roomDescription, setRoomDescription] = useState('')

    const toggleModal = () => {
        analytics.logView('clone-room', true)
        setRoomName('')
        setRoomDescription('')

        onClose()
    }

    const cloneRoom = async () => {
        if (!roomName) {
            setError('Please give your room a name')
            return
        }

        setIsLoading(true)

        try {
            const clone = await cloneRoomController.cloneRoom(roomController.id, {
                name: roomName,
                description: roomDescription
            })

            addToast({
                color: 'success',
                message: 'This room has been cloned.'
            })

            setTimeout(() => navigate(`/room/${clone.id}`), 500)

            toggleModal()

        } catch (error) {
            setError(error.message)
        }

        setIsLoading(false)
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            size="lg"
            className="room-ad">
            <ModalHeader
                title={`Clone "${roomController.room?.name}"`}
            />
            <ModalBody className="mb-4">
                <Text
                    tag="h4"
                    color="muted"
                    className="pb-4">
                    Cloning this room will copy all room settings and members to a new room
                </Text>
                {(error) &&
                    <InfoBox
                        color="danger"
                        className="text-center">
                        {error}
                    </InfoBox>
                } 
                {/* ROOM NAME */}
                <Text heavy>
                    Name Your Room
                </Text>
                <Input
                    name="roomName"
                    type="text"
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                    placeholder="Add a room name..."
                    className="mb-3"
                />
                {/* ROOM DESCRIPTION */}
                <Text heavy>
                    Purpose/Description <small className="text-muted">(optional)</small>   
                </Text>
                <Input
                    name="roomDescrption"
                    type="text"
                    value={roomDescription}
                    onChange={(e) => setRoomDescription(e.target.value)}
                    placeholder="Add a short description..."
                />
                <InfoBox
                    color="info"
                    className="my-4 text-center">
                    <Text tag="h4">
                        <Text bold>{formatNumber(roomController.usersCount)}</Text><br/>
                        {`${(roomController.usersCount > 1) ? `people` : `person`}  will be added to this room`}
                    </Text>                       
                    <Text size="small">
                        (You can add more or remove people once the room is created)
                    </Text> 
                </InfoBox>                           
            </ModalBody>
            <ModalFooter align="right">
                <Button 
                    color="muted"
                    outline
                    className="mr-2"
                    onClick={onClose}>
                    Cancel
                </Button>
                <Button 
                    color="success"
                    onClick={cloneRoom}
                    loading={isLoading}>
                    Clone Room
                </Button>
            </ModalFooter>
        </Modal>
    )
})

export { CloneRoomModal }
