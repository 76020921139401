import { HTMLAttributes } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
// evertel
import { Badge, Text, Row, Button, Icon } from '@evertel/web/ui'
import { APIDataBlueUser } from '@evertel/types'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { ProfilePic } from '@evertel/web/user'
import { DisplayThread } from '@evertel/stores'
import { Link, LinkProps } from 'react-router-dom'


interface DrawerNavThreadLinkProps extends LinkProps {
    thread: DisplayThread
    selected?: boolean
    avatarWidth?: number
    unreadCount?: number
    hasUrgentUnreads?: string
    muted?: boolean
    onClick?: () => void
    onCloseThread: (e: any) => Promise<void>
}


const DrawerNavThreadLink: React.FC<DrawerNavThreadLinkProps> = observer(({
    thread,
    to,
    onCloseThread,
    avatarWidth = 20,
    selected,
    unreadCount,
    muted,
    hasUrgentUnreads,
    ...otherProps
}) => {

    const session = useService(SessionState, [])

    const otherUsers = thread.users?.filter(u => u.id !== session.currentUserId)
    const isJustMe = thread.users?.length === 1 && thread.users[0].id === session.currentUserId

    if (!thread.users?.length) return null

    const singleUser = (isJustMe) ? thread.users[0] : (otherUsers?.length === 1) ? otherUsers[0] : undefined

    return (
        <div className="d-flex position-relative drawer-item-link-wrapper">
            <Link
                to={to}
                className={classNames('d-flex justify-content-between drawer-item-link', {
                    'hover-action': to,
                    'active': selected,
                    'muted': muted,
                    'unread': unreadCount
                })}
                {...otherProps}
            >
                <Row
                    valign="center"
                    style={{
                        width: 220
                    }}>
                    {(singleUser) ?
                        <ProfilePic
                            userId={singleUser.id as number}
                            imageUrl={singleUser.publicImage}
                            firstName={singleUser.firstName as string}
                            lastName={singleUser.lastName as string}
                            width={avatarWidth}
                            height={avatarWidth}
                        />
                        :
                        <Badge
                            color="gray"
                            position=""
                            className="position-relative flex-shrink-0"
                            style={{
                                width: avatarWidth,
                                height: avatarWidth
                            }}>
                            <span className="top-50 start-50 position-absolute translate-middle">{otherUsers?.length}</span>
                        </Badge>
                    }
                    <Text
                        className="text-truncate ml-3"
                        color={(hasUrgentUnreads) ? 'danger' : undefined}>
                        {thread.name} {isJustMe && <Text color="muted" className="ml-2">(me)</Text>}
                    </Text>
                </Row>
                {(!!unreadCount) &&
                <Row
                    align="right"
                    valign="center"
                    className="unread-badge">
                    <div>
                        <Badge
                            color={(hasUrgentUnreads) ? 'danger' : 'warning'}
                            aria-label={`Unread count ${hasUrgentUnreads? 'with some urgent messages':''}`}
                        >
                            {unreadCount}
                        </Badge>
                    </div>
                </Row>
                }
            </Link>
            {(!isJustMe && !thread.isBotThread) &&
                <div
                    className="close-thread d-flex position-absolute end-0 align-items-center mx-2 h-100"
                >
                    <Button
                        className="p-2"
                        size="sm"
                        onClick={onCloseThread}
                        aria-label="Close thread"
                    >
                        <Icon
                            name="xmark"
                            color="white"
                            style={{fontSize: '1.3em'}}
                        />
                    </Button>
                </div>
            }
        </div>
    )
})

export { DrawerNavThreadLink }
