import PropTypes from 'prop-types'

const propTypes = {
    width: PropTypes.number,
    invert: PropTypes.bool
}

const defaultProps = {
    invert: false,
    width: 50
}

const Spinner = ({ width, invert, ...otherProps }) => {
    const style = invert ? {filter: 'invert(100%)'} : {}
    return (
        <img src="/assets/img/spinners/Spinner-1s-40px.svg" alt="Loading..." width={width} style={style} {...otherProps}/>
    )
}

Spinner.propTypes = propTypes
Spinner.defaultProps = defaultProps
export default Spinner
