import React, { createContext, useContext, useMemo, useState, ReactNode } from 'react'

type CurrentMediaPlaying = string | undefined

interface CurrentMediaPlayingState {
    currentMediaPlaying: CurrentMediaPlaying
    setCurrentMediaPlaying: (value: CurrentMediaPlaying) => void
}

const CurrentMediaPlayingContext = createContext<CurrentMediaPlayingState>({
    currentMediaPlaying: undefined,
    setCurrentMediaPlaying: () => {
        // This is intentionally empty
    }
})

export const CurrentMediaPlayingProvider: React.FC<{children?: JSX.Element}> = ({
    children
}) => {
    const [currentMediaPlaying, setCurrentMediaPlaying] = useState<CurrentMediaPlaying>(undefined)
    
    const value = useMemo(
        () => ({ currentMediaPlaying, setCurrentMediaPlaying }),
        [currentMediaPlaying]
    )

    return (
        <CurrentMediaPlayingContext.Provider value={value}>
            {children}
        </CurrentMediaPlayingContext.Provider>
    )
}

export const useCurrentMediaPlaying = (): CurrentMediaPlayingState => {
    return useContext(CurrentMediaPlayingContext)
}