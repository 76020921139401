import { Component } from 'react'
import { AnalyticsStore, LoginStore } from '../../../stores'
import { LoadingIndicator } from '../../../components'
import { InfoBox, Card, CardBody, CardGroup, Button, Icon, Col, Container, Input, InputGroup, InputGroupText, Row } from '@evertel/web/ui'
import { isValidEmail } from '../../../utilities/emailHelpers'

//===============================================================================================================
class ForgotPassword extends Component {
//===============================================================================================================

    state = {
        email: '',
        alertText: null,
        alertColor: 'success',
        isLoading: false
    }

    componentDidMount = () => {
        AnalyticsStore.logPageView('/login/forgot-password')
    }

    //===========================================
    // RESET PASSWORD
    //===========================================

    resetPassword = async () => {
        LoginStore.error = ''
        const { email } = this.state

        // validate
        if (!email) {
            this.setState({
                alertText: 'Please enter an email address',
                alertColor: 'danger'
            })
            return
        }

        if (!isValidEmail(email)) {
            this.setState({
                alertText: 'Please enter a valid email address',
                alertColor: 'danger'
            })
            return
        }

        LoadingIndicator.show('page-loading')
        this.setState({
            isLoading: true,
            alertText: null
        })

        // reset (sends reset email to email-address provided)
        await LoginStore.forgotPassword(email)

        // error check
        if (LoginStore.error) {
            LoadingIndicator.hide('page-loading')
            this.setState({
                isLoading: false,
                alertText: LoginStore.error.message || LoginStore.error,
                alertColor: 'danger'
            })
            return
        }

        LoadingIndicator.hide('page-loading')
        this.setState({
            isLoading: false,
            alertText: `An email has been sent to ${email} with password reset instructions`,
            alertColor: 'success'
        })
    }

    //=========================================
    // FORM CHANGE HANDLER
    //=========================================

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target?.value?.trim()
        })
    }


    //-------------------------------------------------------------------------------------------------
    render() {
    //-------------------------------------------------------------------------------------------------

        return (
            <div style={{ height: '100vh'}} className="app d-flex justify-content-center align-items-center animated fadeIn">
                <Row>
                    <Col md="8">
                        <CardGroup>
                            <Card className="p-4">
                                <CardBody>
                                    <h1><img src={'/assets/img/evertel-shield-110x110.png'} width="40" align="left" alt="EV" /> &nbsp;Forgot your password?</h1>
                                    <p className="text-muted">Enter your email and we'll send you intructions to reset your password</p>
                                    {this.state.alertText && <InfoBox color={this.state.alertColor}>{this.state.alertText}</InfoBox>}
                                    <InputGroup className="mb-3">
                                        <InputGroupText>
                                            <Icon name="user"/>
                                        </InputGroupText>
                                        <Input type="text" name="email" placeholder="Email" onChange={this.onChange} value={this.state.email} />
                                    </InputGroup>
                                    <Row className="flex-wrap">
                                        <Col xs="12" className="mt-3">
                                            <Button
                                                color="primary"
                                                className="px-4"
                                                loading={this.state.isLoading}
                                                onClick={this.resetPassword}>
                                                Submit
                                            </Button>
                                        </Col>
                                        <Col xs="12" className="mt-3" align="center">
                                            <a href="/login">Back</a>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ForgotPassword
