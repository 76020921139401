import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ServiceProvider } from '@evertel/service'
import { useService } from '@evertel/di'
import { Document, WidgetsProvider } from '@evertel/schema-parser'
import { DocumentCreateController, EverdocMediaUplaodError } from '@evertel/document'
import { WriteWidgets } from './schema-widgets'
import { DocumentIcon, DocumentPlaceholder } from './elements'
import {
    Button,
    Text,
    InfoBox,
    PageBody,
    Breadcrumb,
    Input,
    Card,
    CardHeader,
    CardBody,
    CardHeaderActions,
    useUI
} from '@evertel/web/ui'
import { SessionState } from '@evertel/session'
import { APIDataDocumentSchema } from '@evertel/types'


const DocumentCreate = observer(() => {

    const docName = useRef<HTMLInputElement>(null)

    const { id } = useParams()
    const { addToast } = useUI()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [hasChanged, setHasChanged] = useState(false)
    const [name, setName] = useState('')

    const controller = useService(DocumentCreateController, [id])
    const session = useService(SessionState, [])

    useEffect(() => {
        (async () => {
            if (!id || !session) return

            setIsLoading(true)
            await controller.init(
                session.selectedDepartmentId,
                Number(id),
                'web'
            )
            setName(controller.name)
            setIsLoading(false)
        })()
    }, [id, session])

    const onNameFocus = () => {
        docName.current.select()
    }

    const onChangeName = (e: any) => {
        setName(e?.target?.value)
        controller.setName(e?.target?.value)
    }

    const onSave = async () => {
        setIsSaving(true)
        try {
            const { id } = await controller.create()

            setIsSaving(false)
            // take them to the edit screen
            navigate(`/everdocs/${id}`)

        } catch (error: any) {
            if (error instanceof EverdocMediaUplaodError) {
                addToast({
                    color: 'warning',
                    title: 'Be aware',
                    message: `Document created with some media issues`
                })

                setIsSaving(false)
                // take them to the edit screen
                navigate(`/everdocs/${error?.document?.id}`)

            } else {
                addToast({
                    color: 'danger',
                    title: 'Something went wrong',
                    message: error.message
                })
            }

            setIsSaving(false)
            return
        }
    }

    const onChange = (value: any) => {
        controller.updateData(
            value
        )
        setHasChanged(true)
    }

    if (isLoading) {
        return (
            <DocumentPlaceholder />
        )
    }

    return (
        <PageBody>
            <Breadcrumb
                items={[
                    { label: 'EverDocs', onClick: () => navigate('/everdocs') },
                    { label: `Create "${controller?.documentSchema?.name}"`, active: true }
                ]}
            />
            <Card>
                <CardHeader>
                    <DocumentIcon
                        schema={controller?.documentSchema as APIDataDocumentSchema}
                        width={35}
                        height={35}
                    />
                    <Input
                        ref={docName}
                        value={name}
                        onChange={onChangeName}
                        onFocus={onNameFocus}
                        className="doc-name"
                    />
                    <CardHeaderActions>
                        <Button
                            color='success'
                            loading={isSaving}
                            onClick={onSave}>
                            <Text
                                bold
                                color="white">
                                Save
                            </Text>
                        </Button>
                    </CardHeaderActions>
                </CardHeader>
                <CardBody>
                    {(!!controller.errors) &&
                        <InfoBox color="danger">
                            <Text>
                                {`Please review the document, there ${(controller.errorsCount === 1) ?
                                    'is 1 error' : `are ${controller.errorsCount} errors`}`}
                            </Text>
                        </InfoBox>
                    }
                    <ServiceProvider services={[{
                        id: 'meta', value: {
                            user: session?.currentUserId,
                            department: session?.selectedDepartmentId,
                            documentSchema: id
                        }
                    }]}>
                        <WidgetsProvider value={WriteWidgets}>
                            <Document
                                layout="full"
                                schema={controller.documentSchema?.schema as { layouts: Record<string, any>; id?: number; }}
                                schemaId={controller.documentSchemaId}
                                value={controller.data}
                                onChange={onChange}
                                errors={controller.errors}
                            />
                        </WidgetsProvider>
                    </ServiceProvider>
                </CardBody>
            </Card>
        </PageBody>
    )
})

export {
    DocumentCreate
}
