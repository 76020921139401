import React, { useCallback, useEffect } from 'react'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { observer } from 'mobx-react-lite'
import ReactDatePicker from 'react-datepicker'

// evertel
import { Card, Input, Col, Row, Modal, ModalBody, useUI, Checkbox, ModalHeader, Text, Button, Spinner } from '@evertel/web/ui'
import { Message } from '../../Message'
import { useService } from '@evertel/di'
import { MessageSearchController } from '@evertel/message'
import { Analytics } from '@evertel/analytics'
import { FetchUserService } from '@evertel/blue-user'

interface MessageSearchProps {
    visible: boolean
    modelType: 'room' | 'thread',
    modelId: number, // roomId or threadId
    modelName: string, // room/thread name
    onClose?: () => void
}

const MessageSearchModal: React.FC<MessageSearchProps> = observer(({
    modelType = 'room',
    modelId,
    modelName,
    visible,
    onClose
}) => {
    const messageSearchController = useService(MessageSearchController, [modelId])
    const fetchUserService = useService(FetchUserService, [])
    const analytics = useService(Analytics, [])

    const { addToast } = useUI()

    const isRoom = (modelType === 'room')

    useEffect(() => {
        if (visible) {
            messageSearchController.reset()
            messageSearchController.init(modelId, modelType)

            if (isRoom) {
                analytics.logView('/rooms/search', true)
            } else {
                analytics.logView('/threads/search', true)
            }
        }
    }, [visible, modelId, modelType, messageSearchController, isRoom, analytics])

    const search = useCallback(() => {
        const isOnlyRetractedSelected = messageSearchController.isRetracted && 
            !messageSearchController.onlyMe && 
            !messageSearchController.notMe && 
            !messageSearchController.isUrgent

        // must be at least two characters
        if (messageSearchController.searchTerm.length < 2 && (!messageSearchController.isRetracted && !messageSearchController.onlyMe)) {
            return
        }

        try {
            messageSearchController.debouncedSearch()
        } catch (error) {
            addToast({
                color: 'danger',
                message: error.message
            })
        }
    }, [messageSearchController])

    useEffect(() => {
        search()
    }, [messageSearchController.searchTerm,
        messageSearchController.isRetracted,
        messageSearchController.isUrgent,
        messageSearchController.onlyMe,
        messageSearchController.notMe,
        search])

    /**
     * There's a chance the owners of the messages won't exist in the store so must be retrieved
     */
    const retrieveMessageUsers = useCallback(() => {
        const ownerIds = messageSearchController.messages.map(m => m.ownerId)
        
        if (modelType === 'room') {
            fetchUserService.fetchSpecificUsersFromRoom(modelId, ownerIds)
        } else {
            fetchUserService.fetchSpecificUsersFromThread(modelId, ownerIds)
        }
    }, [messageSearchController, modelId, modelType, fetchUserService])

    useEffect(() => {
        if (messageSearchController.messages.length)
            retrieveMessageUsers()
    }, [messageSearchController.messages, retrieveMessageUsers])

    const handleDateRangeChange = (update: [Date | null, Date | null]) => {
        const [startDate, endDate] = update
    
        // Only proceed if both dates are set or both are null
        if ((startDate && endDate) || (!startDate && !endDate)) {
            const adjustedStartDate = startDate 
                ? moment(startDate).startOf('day').toDate()
                : null
            const adjustedEndDate = endDate 
                ? moment(endDate).endOf('day').toDate()
                : null
    
            messageSearchController.setStartDate(adjustedStartDate)
            messageSearchController.setEndDate(adjustedEndDate)
            search()
        }
    }

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            size="xl">
            <ModalHeader
                title={`Search for Messages in ${modelName}`}
            />
            <ModalBody>
                <Row className="flex-wrap">
                    <Col md={7} className="order-2 order-lg-1 bg-light rounded p-2 position-relative">
                        <div className="mx-3 pb-2">
                            {(!!messageSearchController.messagesCount) &&
                                <Text
                                    color="muted"
                                    italic
                                    className="mb-2">
                                    {messageSearchController.messagesCount} {messageSearchController.messagesCount !== 1 ? 'results' : 'result'}
                                </Text>
                            }
                            {(messageSearchController.isLoading) &&
                                <div className="text-center position-relative">
                                    <div className="position-absolute mt-5 start-50 translate-middle"
                                        style={{top: 10}}>
                                        <Spinner width={100} />
                                    </div>
                                    <i>Searching {modelType}...</i>
                                </div>
                            }
                            {(!messageSearchController.messages?.length && !messageSearchController.isLoading) &&
                                <div className="text-center">
                                    <i className="text-muted text-nowrap">No results. Try modifying your search.</i>
                                </div>
                            }
                        </div>
                        <PerfectScrollbar
                            options={{ suppressScrollX: true }}
                            style={{
                                height: '73vh'
                            }}>

                            {messageSearchController.messages?.map(msg => {
                                if (msg.type !== 'event') {
                                    return (
                                        <Card
                                            className="mb-4 pb-2 mx-3 page"
                                            key={msg.id}>
                                            <Text
                                                italic
                                                size="smaller"
                                                className="pl-2 pt-2">
                                                {msg.repliesToId ? (
                                                    <><Text bold>Replied</Text> to a message in <Text bold>{modelName}</Text> </>
                                                ) : (
                                                    <>Posted {isRoom && <>to <Text bold>{modelName}</Text> </>} </>
                                                )} 
                                                     on <Text bold>{moment(msg.publishedDate).format('MMM DD, YYYY')}</Text>
                                            </Text>
                                            <Col>
                                                <Message
                                                    message={msg}
                                                    modelType={modelType}
                                                    showHoverBar={false}
                                                    forceHeader={true}
                                                    highlightString={messageSearchController.searchTerm}
                                                    allowRetracted={true}
                                                    variant="search"
                                                />
                                            </Col>
                                        </Card>
                                    )
                                } else {
                                    return null
                                }
                            })}

                        </PerfectScrollbar>
                    </Col>
                    <Col className="order-1 order-lg-2 p-3">
                        <div className="mb-3">
                            <Input
                                type="text"
                                name="searchText"
                                value={messageSearchController.searchTerm}
                                className="p-3"
                                autoFocus
                                style={{
                                    border: '2px solid #8f9ba6'
                                }}
                                placeholder="Search for keywords..."
                                onChange={(e) => messageSearchController.setSearchTerm(e.target.value)}
                            />
                        </div>
                        <Col className="mb-3">
                            <Text
                                tag="h4"
                                className="pb-2">
                                    Filter by
                            </Text>
                            <div className="border-bottom mb-3">
                                <strong>People</strong>
                            </div>
                            <Checkbox
                                label="Only search messages from me"
                                id="onlyMe"
                                name="onlyMe"
                                checked={messageSearchController.onlyMe}
                                onChange={(e) => { messageSearchController.setOnlyMe(e.checked) }}
                            />
                            <Checkbox
                                id="notMe"
                                name="notMe"
                                label="Don't search messages from me"
                                checked={messageSearchController.notMe}
                                onChange={(e) => { messageSearchController.setNotMe(e.checked) }}
                            />
                        </Col>
                        <Col className="mb-3">
                            <div className="border-bottom mb-3">
                                <strong>Attributes</strong>
                            </div>
                            <Checkbox
                                id="urgent"
                                name="urgent"
                                label="Urgent Messages Only"
                                checked={messageSearchController.isUrgent}
                                onChange={(e) => { messageSearchController.setIsUrgent(e.checked) }}
                            />
                            <Checkbox
                                id="retracted"
                                name="retracted"
                                label="Retracted Messages Only (by me)"
                                checked={messageSearchController.isRetracted}
                                onChange={(e) => { messageSearchController.setIsRetracted(e.checked) }}
                            />
                        </Col>
                        <Col className="mb-3">
                            <div className="border-bottom mb-3">
                                <strong>Date Range</strong>
                            </div>
                            <ReactDatePicker
                                selectsRange={true}
                                startDate={messageSearchController.startDate}
                                endDate={messageSearchController.endDate}
                                onChange={handleDateRangeChange}
                                isClearable={true}
                                className="form-control"
                                placeholderText="Select range..."
                                monthsShown={2}
                                popperPlacement='auto'
                            />
                        </Col>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
})

export { MessageSearchModal }