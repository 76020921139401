import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import * as qs from 'qs'
// evertel, 
import { autorun, runInAction } from 'mobx'
import { LoadingIndicator } from '../../../components'
import { LoginStore, AnalyticsStore, NavigationStore, DeviceStore, AppStore, CurrentUserStore } from '../../../stores'
import api from '../../../api'
import { Links } from '@evertel/constants'
import { InfoBox, Card, CardBody, Col, Input, Row, InputGroup, InputGroupText, CardGroup, Button, Icon, useUI } from '@evertel/web/ui'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useService } from '@evertel/di'
import { DeviceState } from '@evertel/device'
import { SessionState } from '@evertel/session'
import classNames from 'classnames'


const Login = observer(() => {

    const [state, setState] = useState({
        isLoading: false,
        email: '',
        password: '',
        error: null,
        enterpriseView: true,
        message: {
            color: 'success',
            text: ''
        },
        showPassword: false
    })

    const { addToast } = useUI()

    const sessionState = useService(SessionState, [])
    const deviceState = useService(DeviceState, [])
    const cookiesEnabled = deviceState.cookiesEnabled

    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const accessToken = searchParams.get('access_token')

    const parseHashQuery = () => {
        // Remove the leading '#' if present
        const hash = location.hash

        const hashWithoutPrefix = hash.startsWith('#') ? hash.slice(1) : hash
        
        // Use URLSearchParams to parse the string
        const params = new URLSearchParams(hashWithoutPrefix)
        
        // Convert the params to an object
        return Object.fromEntries(params.entries())
    }

    useEffect(() => {
        if (sessionState.currentUserId) {
            //user is already logged in?  go to landing?
            navigate('/landing', { replace: true })
        }
    }, [sessionState.currentUserId])

    useEffect(() => {
        document.title = 'Evertel'
        AnalyticsStore.logPageView('login')
    }, [])

    useEffect(() => {
        (async () => {
            const accessToken = parseHashQuery().access_token

            if (accessToken) {
                LoadingIndicator.show('page-loading')

                // clear the access token parameter
                navigate('/login', {replace: true})

                // try the access token.
                const result = await LoginStore.login(accessToken)

                if (LoginStore.error) {
                    addToast({
                        color: 'warning',
                        message: LoginStore.error
                    })
                }

                LoadingIndicator.hide('page-loading')
            }
        })()
    }, [])

    useEffect(() => {
        // grab any message from the query string and set it to state.
        // used primarily for Auth0 redirects.
        const queryString = qs.parse(window?.location?.search?.replace('?', ''))

        if (queryString?.message) {
            setState((prevState) => ({
                ...prevState,
                message: {
                    color: (queryString.success === 'true') ? 'success' : 'warning',
                    text: queryString.message + ' Please login to continue.'
                }
            }))
        }
    }, [])

    const onChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target?.value?.trim()
        }))
    }

    const login = async (e) => {
        e.preventDefault()

        setState((prevState) => ({
            ...prevState,
            message: {
                color: 'danger',
                text: ''
            }
        }))

        // validate
        if (!state.email || (!state.enterpriseView && !state.password)) {

            setState((prevState) => ({
                ...prevState,
                message: {
                    text: 'Please complete all required fields',
                    color: 'danger'
                }
            }))
            return
        }

        const { email, password } = state

        LoadingIndicator.show('page-loading')
        setState((prevState) => ({
            ...prevState,
            isLoading: true
        }))

        if (state.enterpriseView) {
            const result = await LoginStore.enterpriseLogin(email)

            if (!result) {
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    enterpriseView: false
                }))
            }

            LoadingIndicator.hide('page-loading')
            return

        } else {
            await LoginStore.loginToAuth0(email, password)
        }

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            enterpriseView: false
        }))

        // error check
        if (LoginStore.error) {
            setState((prevState) => ({
                ...prevState,
                error: LoginStore.error,
                isLoading: false,
                message: {
                    text: LoginStore.error,
                    color: 'danger'
                }
            }))
        }

        runInAction(() => {
            CurrentUserStore.currentEmail = email.toLowerCase()
        })

        LoadingIndicator.hide('page-loading')
    }

    const handleShowPassword = () => {
        setState((prevState) => ({
            ...prevState,
            showPassword: !state.showPassword
        }))
    }


    return (
        <Row
            key="evertelLoginScreen"
            className="login fadeIn animated"
        >
            <Col valign="center" align="center"
                className="p-md-5 p-3"
                style={{ minHeight: '100vh', margin: 'auto', overflow: 'auto', maxWidth: 900 }}
            >
                <CardGroup>
                    {/* LOGIN BLOCK */}
                    <Card className="p-4 login-first-card">
                        <CardBody>
                            <h1>
                                Login to Evertel
                            </h1>
                            <label className="text-muted mb-2" htmlFor='email'>
                                Sign In to your account
                            </label>
                            {(!!state.message?.text) &&
                                <InfoBox color={state.message.color}>
                                    {state.message.text}
                                </InfoBox>
                            }
                            {(cookiesEnabled) &&
                                <form onSubmit={login}>
                                    <InputGroup className="mb-3">
                                        <InputGroupText>
                                            <Icon
                                                name="at"
                                                aria-label="Email address"
                                            />
                                        </InputGroupText>

                                        <Input
                                            id="email"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={state.email}
                                            onChange={onChange}
                                        />
                                    </InputGroup>
                                    <div className={classNames('mb-4', {'d-none invisible': state.enterpriseView})}>
                                        <label className="text-muted mb-2" htmlFor="password">
                                                Please enter your password
                                        </label>
                                        <InputGroup className="mb-4">
                                            <InputGroupText>
                                                <Icon
                                                    name="lock"
                                                    aria-label="Password"
                                                />
                                            </InputGroupText>

                                            <Input
                                                type={state.showPassword ? 'text' : 'password'}
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                                value={state.password}
                                                onChange={onChange}
                                            />
                                            <InputGroupText className='btn-ghost-muted' tag="button" type="button" onClick={() => handleShowPassword()}>
                                                <Icon
                                                    name={state.showPassword ? 'eye-slash' : 'eye'}
                                                />
                                            </InputGroupText>
                                        </InputGroup>
                                    </div>
                                    <Row>
                                        <Col>
                                            <Button
                                                type="submit"
                                                style={{ minWidth: 100 }}
                                                color="secondary"
                                                spinnerStyle="expand-right"
                                                loading={state.isLoading}
                                            // onClick={() => login()}
                                            >
                                                {(state.enterpriseView) ? 'Continue' : 'Login'}
                                            </Button>
                                        </Col>
                                        {(state.enterpriseView) &&
                                            <Col xs={6} />
                                        }
                                        {(!state.enterpriseView) &&
                                            <Col
                                                className="pl-3 text-right"
                                                align="right"
                                                valign="center">
                                                <Link
                                                    className="btn btn-link text-nowrap"
                                                    to="/login/forgotpassword"
                                                >Forgot password?</Link>
                                            </Col>
                                        }
                                    </Row>
                                    <Row className="mt-5">
                                        <strong>
                                            Don't have an account?
                                        </strong>
                                        <Link
                                            className="btn btn-link mx-2 text-nowrap"
                                            to="/register"
                                        >
                                            Create Account
                                        </Link>
                                    </Row>
                                </form>
                            }
                            {(!cookiesEnabled) &&
                                <InfoBox color="danger">
                                    <strong>Cookies are disabled: </strong><br />
                                    In order to use Evertel, you will need to enable cookies in your browser settings.<br />
                                    <Button
                                        color="link"
                                        onClick={() => NavigationStore.navigateToExternal(Links.cookiesHelp)}>
                                        Learn how to enable cookies
                                    </Button>
                                </InfoBox>
                            }
                        </CardBody>
                    </Card>

                    {/* REGISTER BLOCK */}
                    <Card className="login-second-card">
                        <CardBody className="d-flex">
                            <Col align='center' valign='center'>
                                <img
                                    src={'/assets/img/evertel-shield-110x110.png'}
                                    width="100"
                                    alt="Evertel"
                                    className="mb-2"
                                />
                                {/* <h2 className="weight-900">Responding to a Crisis Event?</h2>
                                        <p>Hundreds of leading government agencies rely on Evertel for compliant, secure, instant collaboration and communication.</p> */}
                                <p className='px-2 text-center'>
                                    If you're responding to a crisis event and your agency is not registered on Evertel
                                    you can still collaborate with the agencies responding.
                                </p>
                                <Button
                                    color="white"
                                    className="mt-3 font-weight-bold"
                                    onClick={() => NavigationStore.navigate('/login/crisissplash')}>
                                    <Icon
                                        name="fire"
                                        color="danger"
                                        className="mr-2"
                                    />
                                    Responding to a Crisis Event?
                                </Button>
                            </Col>
                        </CardBody>
                    </Card>
                </CardGroup>

                <div>
                    <div className="mt-2 text-muted">
                        <small>
                            You are accessing a restricted information system. Do not login to Evertel on public computers or on unsecured networks.
                            System usage may be monitored, recorded, and subject to audit. Unauthorized use of the system is prohibited and may be subject to criminal and/or civil
                            penalties. Use of the system indicates consent to monitoring and recording.
                            |
                        </small>
                        <a href={Links.helpCenter} className="btn btn-link" target="_blank" rel="noopener noreferrer">
                            <small> Help Center </small>
                        </a>
                    </div>

                    {(!api.isProduction) && // for debugging
                        <div className="mt-4 text-muted">
                            <small>
                                Version: <strong>{process.env.VERSION}</strong>,
                                Build Number: <strong>{process.env.BUILD_NUMBER}</strong>,
                                Commit: <strong>{process.env.COMMIT}</strong>,
                                Branch: <strong>{process.env.BRANCH}</strong>,
                                BuildDate: <strong>{process.env.BUILD_DATE}</strong>
                                Env: <strong>{process.env.NODE_ENV}</strong>
                            </small>
                        </div>
                    }
                </div>
            </Col>
        </Row>
    )
})

export default Login
