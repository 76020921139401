
import { APIDataMedia } from '@evertel/types'
import { makeObservable, observable, runInAction } from 'mobx'
import { debounce, uniqBy } from 'lodash'
import { injectable, inject, decorate } from 'inversify'
import { Api } from '@evertel/api'
import { getFileTypeFromMimetype } from '@evertel/utils'
import { NormalizedMedia } from '../NormalizedMediaType'
import { APIDataMediaToNormalizedMedia } from '../APIDataMediaToNormalizedMedia'

class ThreadMediaSearchController {
    media: NormalizedMedia[] = []
    mediaLatestFetch: NormalizedMedia[] = []
    threadId = 0
    searchTerm = ''
    mimeType = ''
    page = 1
    skip = 0
    limit = 10
    mediaCount = 0
    isInfiniteScroll = true
    isLoading = false

    constructor(
        private api: Api
    ) {
        makeObservable(this, {
            media: observable
        })
    }

    init(threadId: number, isInfiniteScroll = true) {
        this.threadId = threadId

        this.isInfiniteScroll = isInfiniteScroll

        this.resetState()
    }

    setSearchTerm(value: string) {
        this.resetSearchMeta()
        this.searchTerm = value
    }

    setMimetype(value: string) {
        this.resetSearchMeta()
        this.mimeType = value
    }

    setPage(page: number) {
        this.page = page
    }

    debouncedSearch = async () => {
        return debounce( async () => this.search(), 300)
    }

    search = async () => {
        if (!this.threadId) return

        runInAction(() => {
            this.isLoading = true
        })
        
        let count = this.mediaCount
        let results: APIDataMedia[] = []

        // grab the total count of media in this thread
        if (!this.mediaCount) {
            count = await this.fetchMediaCount()
        }

        this.mediaLatestFetch = []

        // stop from fetching if we already got them all
        if (count > this.media?.length) {
            results = await this.api.Routes.Thread.getMedia(this.threadId, this.filter)
        }

        const normalizedResults = results.map(m => APIDataMediaToNormalizedMedia(m))
        const apiDataResults = (this.isInfiniteScroll) ? uniqBy([...this.media, ...normalizedResults], 'id') : normalizedResults

        runInAction(() => {
            this.mediaLatestFetch = normalizedResults
            this.media = apiDataResults
            this.isLoading = false
        })
    }

    fetchNextMedia() {
        this.page = this.page + 1
        this.search()
    }

    async fetchMediaCount() {
        const res = await this.api.Routes.Thread.getMediaCount(this.threadId, this.filter.where)

        runInAction(() => {
            this.mediaCount = res?.count ?? 0
        })

        return res?.count ?? 0
    }

    resetSearchMeta() {
        // This runInAction should NOT be necessary, but RN complains
        runInAction(() => {
            this.page = 1
            this.media = []
            this.mediaCount = 0    
        })
    }

    resetState() {
        this.resetSearchMeta()
        this.searchTerm = ''
        this.mimeType = ''
    }

    get filter() {
        const f: any = {
            where: {},
            order: 'updatedDate DESC',
            limit: this.limit,
            skip: (this.page - 1) * this.limit
        }

        if (this.searchTerm != '') {
            f.where['fileName'] = { like: `%${this.searchTerm}%` }
        }

        return f
    }

    get images() {
        return this.media?.filter(m => getFileTypeFromMimetype(m.mimeType as string) === 'image')
    }
}

decorate(injectable(), ThreadMediaSearchController)
decorate(inject(Api), ThreadMediaSearchController, 0)

export { ThreadMediaSearchController }