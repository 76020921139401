import { createContext } from 'react'
import { MessageWallController } from '@evertel/message'
import { CurrentUserEmojisController } from '@evertel/emojis'
import { APIDataRoom, APIDataThread } from '@evertel/types'

export interface MessageWallContextProps {
    repliesToId: number | null,
    modelId: number,
    modelType: 'room'|'thread',
    modelData: APIDataRoom|APIDataThread,
    allowedToPost: boolean,
    messageWallController: MessageWallController,
    currentUserEmojisController: CurrentUserEmojisController,
    setSelectedMessageId: (messageId: number) => void,
    setIsReactedByModalOpen: (state: boolean) => void,
    setIsReadByModalOpen: (state: boolean) => void,
    setIsForwardMessageModalOpen: (state: boolean) => void,
    setEmojiPickerState: ({visible, params}: {visible: boolean, params: Record<string, any>}) => void,
    emojiPickerState: {visible: boolean, params?: Record<string, any>},
    selectedMessageId: number
}

export const MessageWallContext = createContext({} as MessageWallContextProps)