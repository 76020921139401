import React from 'react'
import { Row, Text } from '@evertel/web/ui'
import { ProfilePic } from '@evertel/web/user'
import { APIDataRoom, APIDataThread } from '@evertel/types'

interface Props {
    modelData: APIDataRoom | APIDataThread,
}

const MessageHeaderThread = ({ modelData }: Props) => {
    const room = (modelData as any)

    return (
        <>
            <Row
                align="center"
                valign="center"
                className="mb-3 flex-wrap"
                style={{maxWidth: 620}}
            >
                {(modelData as any).userIds?.map((id, idx) =>
                    <div key={idx} className="m-2">
                        <ProfilePic
                            key={id}
                            userId={id}
                            width={60}
                            height={60}
                            title={true}
                        />
                    </div>
                )}
            </Row>
            <Text className="mb-3">
                This is the beginning of your conversation.
            </Text>
            <strong>Description:</strong> {room.description}
        </>
    )

}

export default MessageHeaderThread 
