import { APIDataThreadMessage, APIMetaDataThreadMessagePropertyNames } from '@evertel/types'
import { Store } from '@evertel/types'
import { decorate, injectable } from '@evertel/di'

export interface DisplayThreadMessage extends APIDataThreadMessage {
    prevMessageDate: Date,
    prevOwnerId: number,
    dateChanged: boolean
}

class ThreadMessagesStore extends Store<APIDataThreadMessage> {
    constructor() {
        super([...APIMetaDataThreadMessagePropertyNames, 'media', 'reactions', 'prevMessageDate', 'prevOwnerId', 'dateChanged'])
    }
}

decorate(injectable(), ThreadMessagesStore)

export { ThreadMessagesStore }
