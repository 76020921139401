import { FC, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { set } from 'lodash'
import moment from 'moment'

import { Icon, Col, Badge, Text, Row, Button } from '@evertel/web/ui'
import { RoomContext } from '../RoomContext'
import { useService } from '@evertel/di'
import { DepartmentController } from '@evertel/department'
import { BlueUserStore } from '@evertel/blue-user'
import { APIDataBlueUser } from '@evertel/types'
import { DisplayRoomMessage } from '@evertel/stores'

const RoomHeader: FC = observer(() => {

    const [user, setUser] = useState<APIDataBlueUser>()
    const [loadingNotification, setLoadingNotification] = useState(false)
    const { roomController, userSettingsController } = useContext(RoomContext)
    const room = roomController.room
    const repliesToMessage = roomController.repliesToMessage

    const departmentController = useService(DepartmentController, [])
    const userStore = useService(BlueUserStore, [])

    const [ replyNotification, setReplyNotification ] = useState(false)

    const toggleNotify = async () => {
        try {
            setLoadingNotification(true)
            const notify = repliesToMessage.meta?.repliesSubscription?.notify as boolean
            await roomController.updateRepliesNotification(!notify)
            setReplyNotification(!notify)
        } catch (e) {
            /** nothing */
        } finally {
            setLoadingNotification(false)
        }
    }

    useEffect(() => {
        if (!room?.id) return
        setUser(userStore.findById(repliesToMessage?.ownerId))

    }, [room?.id, repliesToMessage])

    useEffect(() => {
        setReplyNotification(repliesToMessage?.meta?.repliesSubscription?.notify)
    }, [repliesToMessage?.meta?.repliesSubscription?.notify])

    useEffect(() => {
        if (!room?.id || room?.departmentId) return
        departmentController.init(room?.departmentId)
    }, [room?.id, room?.departmentId])

    const date = moment(repliesToMessage?.publishedDate ?? repliesToMessage?.createdDate).format('LLLL')

    if (!room) return null

    const roomNotificationSettings = userSettingsController.roomNotificationSettings[room.id]
    const roomNotificationsEnabled = (roomNotificationSettings) ? roomNotificationSettings.roomMessage?.enabled : true
    const isPrivate = !(room.options as any)?.departmentMembersCanJoinAsMember

    return (
        <Row valign="center" className="page-toolbar flex-shrink-0">
            { user && roomController.repliesToId && roomController.repliesToMessage &&
            <>
                
                <Link to={`/room/${room.id}`}>
                    <Icon name="arrow-left" size={20} className="mr-2 d-inline-block" style={{width: 18}}/>
                </Link>
                <h5 className="replies-header-title weight-900 m-0">
                    <span>{room.name}</span>
                    <span>·</span>
                    <span>Reply to {user?.firstName} {user?.lastName}</span>
                </h5>
                
                <Button
                    loading={loadingNotification}
                    color="link"
                    className="btn-link text-nowrap"
                    style={{marginLeft: 'auto', marginRight: '-1rem'}}
                    onClick={toggleNotify}
                    aria-label={replyNotification ? 'Disable notifications' : 'Enable notifications'}
                    title={replyNotification ? 'Click to disable notifications' : 'Click to enable notifications'}
                >
                    {replyNotification ? (
                        <span className="unsubscribe-button">
                            <Icon className="mr-2" name="circle-minus" title="Notifications" />
                            Unsubscribe<span className="invisible">S</span>
                        </span>
                    ) : (
                        <span>
                            <Icon className="mr-2" name="circle-plus" color="secondary" title="Notifications" />
                            {/* this is to keep the spacing the same as unsubscribe */}
                            Subscribe<span className="invisible">Uns</span>
                        </span>
                    )}
                </Button>
            </>
            }
            {roomController.repliesToId && !roomController.repliesToMessage &&
                <>
                    <Link to={`/room/${room.id}`}>
                        <Icon name="arrow-left" size={20} className="mr-2 d-inline-block" style={{width: 18}}/>
                    </Link>
                    <h5 className="weight-900 m-0">
                        { roomController.isLoadingReplies ?
                            <span>Loading...</span>
                            :
                            <span>Invalid Replies Message</span>
                        }
                    </h5>
                </>
            }
            {!roomController.repliesToId &&
                <>
                    <div>
                        <Icon name="hashtag" size={18} color="muted" className="mr-2 d-inline-block" style={{width: 18}}/>
                    </div>
                    <h5 className={classNames('m-0 mr-2 weight-900', 'text-truncate', {'text-decoration-line-through': !!room.isArchived})} >
                        {room.name || 'No Name'}
                    </h5>
                    {(roomController.isInteragnecy) &&
                        <Badge color="secondary">
                            {departmentController.shortName}
                        </Badge>
                    }
                    {(!!isPrivate) &&
                        <Icon
                            name="lock"
                            size={13}
                            className="px-2"
                        />
                    }
                    {(room.isSearchable) &&
                        <Badge
                            color="blue"
                            className="mr-2">
                            <Icon
                                name="bolt"
                                color="white"
                                size={13}
                                className="pr-2"
                            />
                            Incident Command
                        </Badge>
                    }
                    {(roomController.isInteragnecy) &&
                        <Icon
                            name="handshake"
                            color="success"
                            size={13}
                            className="px-2"
                        />
                    }
                    {(!roomNotificationsEnabled) &&
                        <Icon
                            name="bell-slash"
                            color="muted"
                            size={13}
                            className="px-2 hidden-mobile"
                        />
                    }
                    {(!!room.isArchived) &&
                        <Badge
                            color="danger"
                            className="mr-2">
                            {/* <Icon
                                name="archive"
                                size={13}
                                className="px-2"
                            /> */}
                            Archived
                        </Badge>
                    }
                    {(!!room.description && !roomController?.repliesToId) &&
                        <Text
                            size="small"
                            color="muted"
                            className="text-truncate">
                            {room.description}
                        </Text>
                    }
                </>
            }
        </Row>
    )
})

export { RoomHeader }
