
import { observer } from 'mobx-react'

// evertel
import { DrawerNavItemLink } from '../elements'
import { Icon } from '@evertel/web/ui'

interface DrawerProps {
    tag?: string
}


const AdminDrawer: React.FC<DrawerProps> = observer(() => {

    return (
        <>
            <h3 className="ml-0 mb-3" style={{color: '#DDD'}}>
                <Icon name="crown" className='mr-2'/>
                &nbsp;Admin
            </h3>
            <DrawerNavItemLink
                label="Dashboard"
                icon={{ name: 'tachometer-alt' }}
                to="/admin/dashboard"
            />
            <DrawerNavItemLink
                label="Agencies"
                icon={{ name: 'building' }}
                to="/admin/agencies"
                selectedTo="/admin/agenc" //include agency pages
            />
            <DrawerNavItemLink
                label="Users"
                icon={{ name: 'users' }}
                to="/admin/users"
                selectedTo="/admin/user" //include user pages
            />
            <DrawerNavItemLink
                label="Bot Campaigns"
                icon={{ name: 'paper-plane' }}
                to="/admin/messaging"
            />
            <DrawerNavItemLink
                label="Verified Domains"
                icon={{ name: 'cloud-check' }}
                to="/admin/verified-domains"
            />
            <DrawerNavItemLink
                label="Global EverDocs Templates"
                icon={{ name: 'table-layout' }}
                to="/everdocs/schema/templates/global"
            />
            <DrawerNavItemLink
                label="JIRA"
                icon={{ name: 'jira', type: 'brands', color: 'blue' }}
                className="mt-5"
                href="https://evertel.atlassian.net/"
            />
            <DrawerNavItemLink
                label="Auth0/Okta"
                icon={{ name: 'shield-keyhole', type: 'solid', color: 'info' }}
                href='https://manage.auth0.com/dashboard/us/evertel-production/'
            />
            <DrawerNavItemLink
                label="Mandrill"
                icon={{ name: 'mailchimp', type: 'brands', color: 'warning' }}
                href='https://mandrillapp.com/'
            />
            <DrawerNavItemLink
                label="Hubspot"
                icon={{ name: 'hubspot', type: 'brands', color: 'orange' }}
                href='https://app.hubspot.com/'
            />
        </>
    )
})

export { AdminDrawer }

