import React, { forwardRef, HTMLAttributes } from 'react'
import { observer } from 'mobx-react-lite'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
// evertel
import { Icon } from './Icon'
import { Row } from '../layout/Row'
import { Col } from '../layout/Col'
import { Text } from './Text'
import { formatBytes, getFileAttributes, getFileExt } from '@evertel/utils'
import { downloadFile } from '@evertel/web/utils'

export interface FileProps extends HTMLAttributes<HTMLDivElement> {
    url: string
    fileName: string
    fileSize?: number
    className?: string
    width?: number
    height?: number
    maxWidth?: number
    allowDownload?: boolean
    variant?: string
    padding?: number
}

const FileTile = observer(forwardRef<HTMLImageElement, FileProps>(({
    url,
    fileName,
    fileSize,
    className,
    width,
    height,
    maxWidth,
    allowDownload = true,
    ...otherProps
}, ref) => {

    const fileTypeAttributes = getFileAttributes(fileName)
    const fileExt = getFileExt(fileName)
    const iconWidth = (width && width < 200) ? width * .1 : 20

    if (otherProps.variant === 'preview') {
        const iconSize = (width && width < 50) ? width * 0.6 : width * 0.5
        
        return (
            <div className='d-flex h-100'>
                <Icon
                    name={fileTypeAttributes.iconName as IconName}
                    color="white"
                    size={iconSize || 25}
                    className="rounded"
                    style={{
                        backgroundColor: fileTypeAttributes.iconColor,
                        padding: otherProps.padding || 15,                        
                        width: width ?? 50,
                        height: height ?? 50
                    }}
                    data-tooltip={fileName || 'No file name'}
                    data-tooltip-pos="top"
                />
            </div>
        )
    }

    return (
        <Row
            ref={ref}
            className={classNames(
                'file p-1',
                className
            )}
            style={{
                width,
                maxWidth,
                height,
                borderColor: fileTypeAttributes.iconColor
            }}
            valign="center"
            onClick={() => allowDownload && downloadFile(url, fileName)}
            {...otherProps}
        >
            <Icon
                name={fileTypeAttributes.iconName as IconName}
                color="white"
                size={iconWidth}
                className="rounded"
                style={{
                    backgroundColor: fileTypeAttributes.iconColor,
                    padding: 10
                }}
            />
            <Col className="ml-2">
                <Text
                    className="file-name text-truncate"
                    bold
                    {...(maxWidth || width) && { style: { maxWidth: ((maxWidth || width) * .9) - iconWidth } }}
                    size={(width && width < 200) ? width * .08 : undefined}
                >
                    {fileName}
                </Text>
                <Row>
                    <Text
                        color="muted"
                        size={11}
                        className="mr-2 text-uppercase">
                        {fileExt}
                    </Text>
                    {(fileSize) &&
                            <Text
                                color="muted"
                                size={11}
                                className="mr-2"
                            >
                                ({formatBytes(fileSize, 1)})
                            </Text>
                    }
                    <Text
                        tag="small"
                        color="muted"
                        size={11}>
                            Download
                    </Text>
                </Row>
            </Col>
        </Row>
    )
}
))

export { FileTile }
