import { Component } from 'react'
import { Col, Container, Row } from '@evertel/web/ui'
import { AnalyticsStore } from '../../../stores'

class Page500 extends Component {

    componentDidMount = () => {
        AnalyticsStore.logPageView('/500')
    }

    render() {
        return (
            <Row className="login fadeIn animated" >
                <Col valign="center" align="center" style={{ minHeight: '100vh', margin: 'auto', overflow: 'auto', maxWidth: 900 }}>
                    <img src={'/assets/img/doughnut-guy.png'} className="pb-3" alt="Evertel Doughnut Guy" />
                    <h1 style={{fontSize: '4rem'}}>500</h1>
                    <h4 className="pt-3">Houston, we have a problem!</h4>
                    <p className="text-muted">The page you are looking for is temporarily unavailable.</p>
                </Col>
            </Row>
        )
    }
}

export default Page500
