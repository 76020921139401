import React, { useRef, useState } from 'react'
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import {
    Row, Text, Icon, Button,
    FloatingMenu, MenuItem, useUI
} from '@evertel/web/ui'
import { DocumentIcon } from '../../elements/DocumentIcon'
import { DocIconPickerModal } from './DocIconPickerModal'
import { SchemaBuilderController } from '../../../controller'

const DESC_PLACEHOLDER = 'Add a short description...'
interface SettingsBarProps {
    controller: SchemaBuilderController
}
interface LocationState {
    from?: string;
    isAdmin?: boolean;
}

const SettingsBar: React.FC<SettingsBarProps> = observer(({
    controller
}) => {

    const { target } = useParams()
    const navigate = useNavigate()
    const location = useLocation<LocationState>()
    const docName = useRef<HTMLSpanElement>(null)
    const docDesc = useRef<HTMLSpanElement>(null)
    const { addToast, isConfirmed } = useUI()

    const [showDocIconPickerModal, setShowDocIconPickerModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const onFocus = (field: string) => {
        if (field === 'name') {
            if (docName.current?.innerText === controller.defaultName) {
                docName.current.innerText = ''
            }
        } else if (field === 'description') {
            if (docDesc.current?.innerText === DESC_PLACEHOLDER) {
                docDesc.current.innerText = ''
            }
        }
    }

    const onBlur = (field: string) => {
        if (field === 'name' && docName.current) {
            const name = docName.current?.innerText?.trim()
            controller.setName(name || controller.defaultName)

        } else if (field === 'description' && docDesc.current) {
            const description = docDesc.current?.innerText?.trim()
            controller.setDescription(description || DESC_PLACEHOLDER)
        }
    }

    const onSave = async () => {
        const isNew = !controller.id

        if (controller.name === controller.defaultName || !controller.name) {
            await isConfirmed({
                title: 'Please name your template',
                message: 'Please name your template before saving',
                acceptButton: {
                    label: 'Ok',
                    color: 'success'
                }
            })
        } else {
            setIsSaving(true)
            try {
                const schema = await controller.save()

                addToast({
                    color: 'success',
                    message: 'Your template has been saved.'
                })

                if (schema?.id && isNew) navigate(`/everdocs/schema/edit/${schema?.id}`)

            } catch (error: any) {
                addToast({
                    color: 'danger',
                    message: error.message
                })
            }
            setIsSaving(false)
        }
    }

    const getBackLinkTo = () => {
        if (location.state?.from) {
            return {
                pathname: location.state.from,
                state: { isAdmin: location.state?.isAdmin }
            }
        } else if (target === 'global') {
            return '/everdocs/schema/templates/global'
        } else {
            return '/everdocs'
        }
    }

    return (
        <Row
            align="between"
            valign="center"
            className="schema-builder-settings-bar">
            <Link to={getBackLinkTo()} style={{ textDecoration: 'none' }}>
                <Button color="primary">
                    <Icon
                        name="chevron-left"
                        color="white"
                        className="mr-2"
                    />
                    Back
                </Button>
            </Link>

            <Row valign="center">
                <Icon
                    name="pen"
                    color="muted"
                />
                <div className="d-flex flex-column align-items-center">
                    <span
                        ref={docName}
                        id="name"
                        role="textbox"
                        contentEditable
                        suppressContentEditableWarning={true}
                        onBlur={() => onBlur('name')}
                        onFocus={() => onFocus('name')}
                        className="doc-name">
                        {controller.name}
                    </span>
                    <span
                        ref={docDesc}
                        id="description"
                        role="textbox"
                        contentEditable
                        suppressContentEditableWarning={true}
                        onFocus={() => onFocus('description')}
                        onBlur={() => onBlur('description')}
                        className="doc-description">
                        {controller.description || DESC_PLACEHOLDER}
                    </span>
                </div>
                <button
                    onClick={() => setShowDocIconPickerModal(true)}
                    className="btn btn-link mx-3"
                >
                    <DocumentIcon
                        schema={{
                            icon: controller.icon,
                            color: controller.color
                        }}
                        width={30}
                        height={30}
                    />
                </button>
            </Row>
            <Row>
                <FloatingMenu
                    label="Toggle"
                    className="mr-4"
                    renderLabel={() => (
                        <>
                            <Icon
                                name={controller.isEnabled ? 'toggle-on' : 'toggle-off'}
                                color={controller.isEnabled ? 'success' : 'danger'}
                                className="mr-2"
                            />
                            <span className="text-white">
                                {controller.isEnabled ? 'Enabled' : 'Disabled'}
                            </span>
                        </>
                    )}
                >
                    <MenuItem
                        label={controller.isEnabled ? 'Disable' : 'Enable'}
                        onClick={() => controller.setIsEnabled(!controller.isEnabled)}
                    />
                </FloatingMenu>
                <Button
                    color="success"
                    loading={isSaving}
                    onClick={onSave}>
                    <Text
                        color="white"
                        bold>
                        Save
                    </Text>
                </Button>
            </Row>

            <DocIconPickerModal
                visible={showDocIconPickerModal}
                onClose={() => setShowDocIconPickerModal(false)}
                controller={controller}
            />
        </Row>
    )
})

export {
    SettingsBar
}