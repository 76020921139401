
import { observer } from 'mobx-react'

// evertel
import { DrawerNavItemLink } from '../elements'
import { SessionState } from '@evertel/session'
import { useService } from '@evertel/di'
import { CurrentUserController } from '@evertel/blue-user'
import { Icon } from '@evertel/web/ui'


const ManageDrawer: React.FC = observer(() => {
    const session = useService(SessionState, [])
    const currentUserController = useService(CurrentUserController, [])


    return (
        <>
            <h3 className="ml-0 mb-3" style={{color: '#DDD'}}>
                <Icon name="people-roof" size={20} className='mr-3'/>
                Manage Agency
            </h3>
            <DrawerNavItemLink
                label="Users"
                icon={{name: 'users'}}
                to='/manage/users'
                selectedTo='/manage/user'
            />
            <DrawerNavItemLink
                label="Rooms"
                icon={{name: 'sitemap'}}
                to='/manage/rooms'
            />
            {currentUserController.canExecutive && (
                <>
                    <DrawerNavItemLink
                        label="Audits"
                        icon={{name: 'search'}}
                        to='/manage/audits'
                    />
                    <DrawerNavItemLink
                        label="History Logs"
                        icon={{name: 'history'}}
                        to='/manage/history'
                    />
                    <DrawerNavItemLink
                        label="Usage Reports"
                        icon={{name: 'chart-pie'}}
                        to={`/manage/reports/${session.selectedDepartmentId}`}
                        selectedTo='/manage/reports'
                    />
                    <DrawerNavItemLink
                        label="Agency Settings"
                        icon={{name: 'cogs'}}
                        to={`/manage/agency/settings/${session.selectedDepartmentId}`}
                        selectedTo='/manage/agency/settings'
                    />
                </>
            )}
        </>
    )
})

export { ManageDrawer }
