import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { UnreadCountsState, UserController } from '@evertel/blue-user'
import { useService } from '@evertel/di'
import { Badge, Button, Card, CardBody, Col, ContentPlaceholder, Icon, Row } from '@evertel/web/ui'
import { ProfilePic } from '@evertel/web/user'
import { DepartmentsAccessController } from '@evertel/departments-access'
import { MessageHeader } from '../MessageHeader'
import ReplyTileBody from './ReplyTileBody'
import { NormalizedMedia } from '@evertel/media'
import { Link } from 'react-router-dom'
import { DisplayRoomMessage } from '@evertel/stores'


interface ReplyTileProps {
    message: DisplayRoomMessage,
    media?: NormalizedMedia[],
    onSelect?: (message: DisplayRoomMessage) => void
    onToggleNotify?: (message: DisplayRoomMessage) => void
    loadingNotification: boolean
}

const ReplyTile: React.FC<ReplyTileProps> = observer(({
    message,
    media,
    onSelect = () => { /** */ },
    onToggleNotify = () => { /** */ },
    loadingNotification
}) => {

    const userController = useService(UserController, [message.ownerId])
    const daController = useService(DepartmentsAccessController, [message.ownerId])
    const unreadState = useService(UnreadCountsState, [])

    useEffect(() => {
        setIsLoading(true)
        userController.init(message.ownerId)
        daController.init(message.ownerId)
        setIsLoading(false)
    }, [message.ownerId])

    const handleToggleNotify = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
        onToggleNotify(message)
    }

    const [isLoading, setIsLoading] = useState(false)
    const isGuest = daController.selectedDepartmentRole === 'guest' || false
    const replyNotification = message.meta?.repliesSubscription?.notify
    const replyUnreadObj = unreadState?.getReplyUnread(message?.roomId, message?.id)

    if (isLoading) {
        return (
            <Card
                className='reply-tile ml-4 mb-4'
            >
                <CardBody>
                    <Row className="mt-2">
                        <ContentPlaceholder
                            width={40}
                            height={40}
                            color="gray200"
                            className="mr-2"
                        />
                        <ContentPlaceholder
                            width='40%'
                            height={20}
                            color="gray200"
                            className="mt-1"
                        />
                    </Row>
                    <div className='ml-5'>
                        <ContentPlaceholder
                            width='70%'
                            color="gray200"
                            className="mt-1"
                        />
                        <ContentPlaceholder
                            width='80%'
                            color="gray200"
                            className="mt-1"
                        />
                        <ContentPlaceholder
                            width='90%'
                            color="gray200"
                            className="mt-1"
                        />
                    </div>
                </CardBody>
            </Card>
        )

    }


    return (
        <Link className="reply-tile-link" to={`/room/${message.roomId}/replies/${message.id}`}
            onClick={() => onSelect(message)}
            style={{textDecoration: 'none', color: 'inherit'}}
        >
            <Card className="reply-tile mb-2">
                <CardBody>
                    <Row>
                        <Col
                            valign="top"
                            className="left-col pt-1 mr-2"
                        >
                            <ProfilePic
                                userId={userController?.user?.id}
                                firstName={userController?.user?.firstName}
                                lastName={userController?.user?.lastName}
                                imageUrl={userController?.user?.publicImage}
                                isGuest={isGuest}
                            />
                        </Col>
                        <Col 
                            valign="top"
                            xs={10}
                        >
                            <MessageHeader
                                message={message}
                                user={userController?.user}
                                isGuest={isGuest}
                                onClickUser={() => { /** */ }}
                                variant='reply'

                            />
                            <ReplyTileBody
                                message={message}
                                media={media}
                            />
                        </Col>
                    </Row>
                    <div className="reply-footer mt-4">
                        <div>
                            <span>{message.meta.repliesUsersCount} {message.meta.repliesUsersCount > 1 ? 'people' : 'person'}</span>
                            <span>  | {message.meta.repliesCount} {message.meta.repliesCount > 1 ? 'replies' : 'reply'}</span>
                            <span className='ml-2'>
                                {replyUnreadObj?.unreadCount && 
                                <Badge
                                    color={`${replyUnreadObj.urgentCount ? 'danger' : 'warning'}`}
                                >
                                    {<strong>{replyUnreadObj?.unreadCount}</strong>}
                                </Badge>
                                }
                            </span>
                        </div>
                        <Button
                            loading={loadingNotification}
                            color="link"
                            className="btn-link text-nowrap"
                            style={{marginRight: '-1rem'}}
                            onClick={handleToggleNotify}
                            aria-label={replyNotification ? 'Disable notifications' : 'Enable notifications'}
                            title={replyNotification ? 'Click to disable notifications' : 'Click to enable notifications'}        
                        >
                            {replyNotification ? (
                                <span className="unsubscribe-button">
                                    <Icon className="mr-2" name="circle-minus" title="Notifications" />
                                    Unsubscribe<span className="invisible">S</span>
                                </span>
                            ) : (
                                <span>
                                    <Icon className="mr-2" name="circle-plus" color="secondary" title="Notifications" />
                                    {/* this is to keep the spacing the same as unsubscribe */}
                                    Subscribe<span className="invisible">Uns</span>
                                </span>
                            )}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </Link>
    )
})

export { ReplyTile }