import { computed, action, observable, makeObservable } from 'mobx'
import AppStore from './AppStore'
import CurrentUserStore from './CurrentUserStore'
import RegistrationStore from './RegistrationStore'
import AnalyticsStore from './AnalyticsStore'
import InviteStore from './InviteStore'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

/**
 * @deprecated
 */
class NavigationStore {
    _navigate: NavigateFunction

    constructor() {

        makeObservable(this, {
            restoreNavigation: action,
            navigateToRoom: action('navigateToRoom'),
            navigateToThread: action('navigateToThread'),
            navigateToLanding: action('navigateToLanding'),
            navigate: action,
            navigateToExternal: action
        })
    }

    setTopLevelNavigator(navigate) {
        this._navigate = navigate

        //console.log('NAV', _navigator)
    }


    /**
     * @deprecated
     */
    async restoreNavigation() {
        // if not logged in take them to login
        if (!AppStore.hasValidAuthToken) {
            this.navigate('/login')
        }

        // if registration is incomplete, take them to where they left off
        if (!RegistrationStore.isRegistrationComplete) {
            RegistrationStore.goToNextStep()
            return
        }

        // if email is not verified, take them to email verification
        if (!CurrentUserStore.emailVerified) {
            this.navigate('/email-verification')
            return
        }

        // if we have a pending invite token, consume token (consume function handles navigating them to the right place)
        if (InviteStore.pendingInviteToken) {
            console.log('NAV STORE CONSUME INVITE TOKEN:', InviteStore.pendingInviteToken)
            InviteStore.consumePendingInvite().then((hasNavigated) => {
                if (!hasNavigated) {
                    this.navigate('/landing')
                }
            })
            return
        }

        this.navigate('/landing')

    }

    /**
     * @deprecated
     */
    navigateToRoom(id) {

        // Log to analytics
        AnalyticsStore.logPageView('room')

        // navigate to room
        this.navigate(`/room/${id}`)
    }

    /**
     * @deprecated
     */
    navigateToThread(id) {

        // Log to analytics
        AnalyticsStore.logPageView('thread')

        // navigate to thread
        this.navigate(`/thread/${id}`)
    }

    /**
     * @deprecated
     */
    navigateToLanding = () => {
        this.navigate('/landing')
    }


    /**
     * @deprecated
     */
    navigate(route) {
        let path = route

        // if route does not start with forward slash, add it
        if (route.charAt(0) !== '/') path = '/' + path

        this._navigate(path)
    }

    /**
     * @deprecated
     */
    navigateToExternal(url, target = '_blank') {
        if (!url) return

        // if no http or https add it
        if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url
        }

        window.open(url, target)

        AnalyticsStore.logOutboundLink(url)
    }

}

const defaultExport = new NavigationStore()

export const NavigationWrapper: React.FC = () => {
    //TODO: I dislike the existence of this, will need to eliminate NavigationStore soon

    const navigate = useNavigate()
  
    useEffect(() => {
        defaultExport.setTopLevelNavigator(navigate)
    }, [navigate])
  
    return null
}


// Creates a single instance of this store
export default defaultExport
