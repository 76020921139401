import { useEffect, useState, useMemo, useRef } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { Card, CardBody, Col, Container, Row, Modal, ModalBody } from '@evertel/web/ui'
import * as qs from 'qs'
// evertel
import { AnalyticsStore, AppStore, LoginStore, NavigationStore, RegistrationStore } from '../../../stores'
import { LoadingIndicator, Toaster, Button, Icon } from '../../../components'
import { EmailVerificationController } from '@evertel/shared/feature-authentication-auth0'
import { container } from '../../../di'


const EmailVerification = observer(() => {

    const emailVerificationController = useMemo(() => container.get(EmailVerificationController), [])

    const [isResending, setIsResending] = useState(false)
    const [firstTab, setFirstTab] = useState(true) // assume I am the first tab until I know otherwise
    const [verificationComplete, setVerificationComplete] = useState(false)

    const bc = useRef(null)
    const email = RegistrationStore.email || LoginStore.email || localStorage.getItem('email')
    const password = RegistrationStore.password || LoginStore.password

    useEffect(() => {   
        // for app loading on refresh 
        autorun((init) => {
            if (!AppStore.appLoading) {
                AnalyticsStore.logPageView('/email-verification')

                LoadingIndicator.hide('page-loading')

                init.dispose()
            }               
        })
    }, [])

    useEffect(() => {
        /* FOR COMMUNICATION BETWEEN TABS */

        // store the email in localStorage so it can be picked up by other tabs
        if (email && !localStorage.getItem('email')) {
            localStorage.setItem('email', email)
        }

        // create a broadcast channel to communicate to other open tabs
        bc.current = new BroadcastChannel('evertel-bc')

        // add listener for any broadcast channel messages (only fires on tabs not the source of postMessage)
        bc.current.onmessage = async (event) => {
            console.log('bc', event.data)

            if (event.data === 'am I the first tab') {
                // if I receive this message, respond to other tabs
                // to let them know I'm here
                bc.current.postMessage('no')
            }
            if (event.data === 'no') {
                // if I get a response back I'm not the first tab
                setFirstTab(false)
            }
        }

        bc.current.postMessage('am I the first tab')

        return () => {
            // close broadcast channel
            //bc.current.close()
        }
    }, [email])

    useEffect(() => {
        // grab the querystring params (Auth0 will include qs params on redirect)
        const queryString = qs.parse(window?.location?.search?.replace('?', ''))

        // show any error messages in the querystring
        if (queryString.success === 'false' && queryString.code !== 'already-verified') {
            Toaster.show('error', queryString.message)
        }

        // initialize controller
        if (email) emailVerificationController.init(email)

        autorun((verificationListener) => {
            // once email is verified (or was already verified), show success modal
            if (emailVerificationController.isVerified || queryString.code === 'already-verified' || queryString.code === 'success') {
                // open verification success modal
                setVerificationComplete(true)

                verificationListener.dispose()

                // remove email from localStorage
                if (localStorage.getItem('email')) {
                    localStorage.removeItem('email') 
                }
            }
            if (emailVerificationController.error) {
                Toaster.show('error', emailVerificationController.error)
            }
        })

        return (() => {
            emailVerificationController.stopGetIsVerifiedPoll()
        })

    }, [email, emailVerificationController])

    const login = async () => {
        try {
            await LoginStore.loginToAuth0(email, password)
            return true

        } catch (e) {
            Toaster.show('error', e.message)
            goToLogin()
            return false
        }
    }

    const resendVerificationEmail = async () => {
        setIsResending(true)

        await emailVerificationController.resendVerificationEmail()

        setIsResending(false)

        if (emailVerificationController.error) {
            Toaster.show('error', emailVerificationController.error)
            return
        }

        Toaster.show('success', 'A new verification email has been sent')
    }

    const goToLogin = () => {
        NavigationStore.navigate('/login')
    }
    
    return (            
        <div className="app d-flex justify-content-center flex-row align-items-center fadeIn animated registration" style={{height: '100vh'}}>
            <Row className="justify-content-center">
                <Col md="8">
                    <Card className="mx-4">
                        <CardBody className="p-4">
                            {(email) ?
                                <div className="text-center pb-3">
                                    <h1 className="mb-4">Please verify your email address</h1>
                                    <h3>Check your email and click on the verification link to continue.</h3>
                                    <h4 className="mt-5">
                                               We sent a verification email to:<br/>
                                        <span
                                            className="text-secondary"
                                            style={{fontWeight: 'bold'}}>
                                            {email}
                                        </span>
                                    </h4>
                                    <Button
                                        color='success'
                                        type="submit"
                                        loading={isResending}
                                        disabled={isResending || verificationComplete}
                                        className='mt-2'
                                        onClick={resendVerificationEmail}>
                                               Resend verification email
                                    </Button>
                                    <NoEmailRecieved />
                                    <div className="d-flex justify-content-center">
                                        <Button
                                            color="link"
                                            block
                                            size="sm"
                                            className="mt-4"
                                            onClick={goToLogin}>
                                   Login
                                        </Button>
                                    </div>
                                </div>
                                :
                                <EmailNotProvided goToLogin={goToLogin}/>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <VerificationCompleteModal
                isOpen={verificationComplete}
                firstTab={firstTab}
                login={login}
                goToLogin={goToLogin}
                requireLogin={!email || !password}
            />
        </div>
    )  
})

const EmailNotProvided = (goToLogin) => (
    <div className="text-center pb-3">
        <h1 className="mb-4">This email may have already been verified</h1>
        <h3>Please try to log in</h3>
        <Button
            color="success"
            size="l"
            className="mt-4"
            onClick={goToLogin}>
                Login
        </Button>
    </div>
)

const NoEmailRecieved = () => {
    return (
        <div className="mt-4 text-center">
            <small className="text-muted">
                <strong>Didn't receive an email?</strong>
                <br/>
                Make sure your email is correct above (no typos). If it is, check your SPAM folder.
                <br/>
                You can also try adding no-reply@getevertel.com to your contacts or whitelist.
            </small>
        </div>
    )
}

const VerifiedSubText = ({
    requireLogin,
    firstTab
}) => {
    if (!requireLogin) {
        return <h3>Please continue to complete your account setup</h3>
    } else if (requireLogin && firstTab) {
        return <h3>Please login to continue setting up your account</h3>
    } else {
        // not the first tab
        return <h3>You can now close this tab and continue on your other open tab or login and continue here</h3>
    }
}

const VerificationCompleteModal = ({
    isOpen = false,
    requireLogin = false,
    goToLogin,
    login,
    firstTab = true
}) => {

    const [isLoggingIn, setIsLoggingIn] = useState(false)

    const doLogin = async () => {
        setIsLoggingIn(true)
        await login()
        setIsLoggingIn(false)
    }

    return (
        <Modal
            visible={isOpen}
            centered
            className="modal-lg">
            <ModalBody className="py-4 px-5 mx-5">
                <Row className="mb-5">
                    <Col className="text-center">
                        <Icon
                            name="check-circle"
                            color="success"
                            style={{fontSize: 60, marginBottom: 25}}
                        />
                        <h1 className="mb-4">Your email has been verified!</h1>
                        <VerifiedSubText
                            requireLogin={requireLogin}
                            firstTab={firstTab}
                        />
                        <Button
                            color="success"
                            type="submit"
                            loading={isLoggingIn}
                            disabled={isLoggingIn}
                            size="l"
                            onClick={(requireLogin) ? goToLogin : doLogin}
                            className="mt-5">
                            {(requireLogin) ? 'Login to Continue' : 'Continue'}
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default EmailVerification