import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import ReactPasswordStrength from 'react-password-strength'

// evertel
import { AnalyticsStore, LoginStore } from '../../../stores'
import { InfoBox, Button, Icon, Card, CardBody, Col, Input, InputGroup, InputGroupText, Row, useUI } from '@evertel/web/ui'

interface PasswordStrengthResult {
    score: number;
    feedback: {
        warning: string;
        suggestions: string[];
    };
}

const ResetPassword: React.FC = () => {
    // Authentication related state
    const [token, setToken] = useState<string | null>(null)
    const [passwordHasBeenReset, setPasswordHasBeenReset] = useState(false)

    const { addToast } = useUI()

    // Password input state
    const [passwordState, setPasswordState] = useState({
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        passwordsMatch: false
    })

    // Password validation state
    const [passwordValidation, setPasswordValidation] = useState({
        isPasswordValid: false,
        showMinPasswordLevel: false,
        passwordSuggestions: null as string[] | null,
        passwordWarning: null as string | null
    })

    // UI feedback state
    const [alert, setAlert] = useState({
        text: '',
        color: 'danger' as 'danger' | 'success'
    })

    // Loading state
    const [isLoading, setIsLoading] = useState(false)

    const { token: urlToken } = useParams<{ token: string }>()

    useEffect(() => {
        if (!urlToken) {
            setAlert({
                text: 'Unable to retrieve password reset token. Please click on your reset link to try again.',
                color: 'danger'
            })
        } else {
            setToken(urlToken)
        }

        AnalyticsStore.logPageView('/reset-password')
    }, [urlToken])

    useEffect(() => {
        const passwordsMatch = passwordState.confirmPassword === passwordState.password
        setPasswordState(prev => ({ ...prev, passwordsMatch }))
    }, [passwordState.password, passwordState.confirmPassword])

    const onPasswordChange = (e: { password: string; isValid: boolean }, result: PasswordStrengthResult) => {
        setPasswordValidation(prev => ({
            ...prev,
            showMinPasswordLevel: true,
            isPasswordValid: e.isValid,
            passwordSuggestions: result?.feedback.suggestions || null,
            passwordWarning: result?.feedback.warning || null
        }))
        setPasswordState(prev => ({ ...prev, password: e.password }))
    }

    const resetPassword = async (e) => {
        e.preventDefault()
        setAlert({ text: '', color: 'danger' })

        if (!passwordState.password) {
            addToast({
                color: 'danger',
                title: 'Error',
                message: 'Please fill out both password fields.'
            })
            return
        }

        if (!passwordValidation.isPasswordValid) {
            setAlert({
                text: 'Your password does not meet our minimum strength requirements of "good". Please revise and try again',
                color: 'danger'
            })
            addToast({
                color: 'danger',
                title: 'Oops',
                message: 'Password is not valid'
            })
            return
        }

        if (!passwordState.passwordsMatch) {
            if (!passwordState.confirmPassword) {
                addToast({
                    color: 'danger',
                    title: 'Error',
                    message: 'Passwords do not match.'
                })
            }
            return
        }

        if (!token) {
            addToast({
                color: 'danger',
                title: 'Error',
                message: 'Invalid reset token.'
            })
            return
        }

        setIsLoading(true)

        const forgotPasswordBody = {
            password: passwordState.password.trim(),
            confirmation: passwordState.confirmPassword.trim()
        }
    
        await LoginStore.resetForgotPassword(forgotPasswordBody, token)

        setIsLoading(false)

        const error = LoginStore.error
        if (error) {
            setAlert({
                text: error.message || error,
                color: 'danger'
            })
            addToast({
                color: 'danger',
                title: 'Oops',
                message: error.message || error
            })
            return
        }

        setAlert({
            text: 'Your password has been reset. You can now login',
            color: 'success'
        })
        setPasswordHasBeenReset(true)
        setPasswordState(prev => ({ ...prev, showPassword: false, showConfirmPassword: false }))

    }

    const handleShowPassword = (input: 'password' | 'confirmation') => {
        setPasswordState(prev => ({
            ...prev,
            showPassword: input === 'password' ? !prev.showPassword : prev.showPassword,
            showConfirmPassword: input === 'confirmation' ? !prev.showConfirmPassword : prev.showConfirmPassword
        }))
    }

    return (
        <div className="app  fadeIn animated bg-img" style={{ height: '100vh', overflow: 'auto' }}>
            <Row align="center" valign="center" className="d-flex p-4 h-100">
                <Card className="" style={{minHeight: 370, maxWidth: 600}}>
                    <form onSubmit={resetPassword}>
                        <fieldset disabled={passwordHasBeenReset} >
                            <CardBody className="p-4">
                                <h1><img src={'/assets/img/evertel-shield-110x110.png'} width="40" alt="EV" /> &nbsp;Reset your password</h1>
                                <p className="text-muted">
                                Create a new password for your account.
                                Learn how to 
                                    <a className="link" target="_blank" rel="noopener noreferrer"
                                        href="https://www.getevertel.com/help-center/choosing-a-secure-password-for-blue/"
                                    > create a secure password. </a>
                                </p>
                                {alert.text && <InfoBox color={alert.color}>{alert.text}</InfoBox>}
                                <InputGroup>
                                    <InputGroupText>
                                        <Icon name="lock"/>
                                    </InputGroupText>
                                    <ReactPasswordStrength
                                        minLength={8}
                                        minScore={2}
                                        scoreWords={['too weak', 'too weak', 'good', 'strong', 'very strong']}
                                        changeCallback={onPasswordChange}
                                        inputProps={{
                                            type: passwordState.showPassword ? 'text' : 'password',
                                            name: 'password', 
                                            autoComplete: 'off', 
                                            placeholder: 'Create your new password', 
                                            className: 'form-control no-focus'
                                        }}
                                    />
                                    <InputGroupText>
                                        <Icon
                                            onClick={() => handleShowPassword('password')}
                                            name={passwordState.showPassword ? 'eye-slash' : 'eye'}
                                        />
                                    </InputGroupText>
                                </InputGroup>
                                <div className="text-left pb-2">
                                    <small className="text-muted">
                                        {passwordValidation.passwordWarning &&
                                    <div className="text-danger">{passwordValidation.passwordWarning}.</div>
                                        }
                                        {passwordValidation.passwordSuggestions ?
                                            passwordValidation.passwordSuggestions.map((s, idx) =>
                                                <div key={idx}>{(idx === 0) && ' Suggestions:'}{' ' + s}</div>
                                            )
                                            : 'Minimum 8 characters.'
                                        }
                                &nbsp;
                                    </small>
                                </div>

                                {/* CONFIRM PASSWORD */}
                                <InputGroup>
                                    <InputGroupText className={(passwordState.confirmPassword && passwordState.passwordsMatch) ? 'bg-success border-success' : (passwordState.confirmPassword && !passwordState.passwordsMatch) ? 'bg-danger border-danger' : ''}>
                                        <Icon name="lock"/>
                                    </InputGroupText>
                                    <Input
                                        type={passwordState.showConfirmPassword ? 'text' : 'password'} 
                                        value={passwordState.confirmPassword} 
                                        name="confirmPassword" 
                                        autoComplete="off" 
                                        placeholder="Repeat password" 
                                        onChange={(e) => setPasswordState(prev => ({ ...prev, confirmPassword: e.target.value }))} 
                                        disabled={passwordHasBeenReset}
                                    />
                                    <InputGroupText>
                                        <Icon
                                            onClick={() => handleShowPassword('confirmation')}
                                            name={passwordState.showConfirmPassword ? 'eye-slash' : 'eye'}
                                        />
                                    </InputGroupText>
                                </InputGroup>
                                {(passwordState.confirmPassword && !passwordState.passwordsMatch) &&
                            <div className="text-left text-danger"><small>Your passwords do not match</small></div>
                                }

                                {!passwordHasBeenReset &&
                                <Col className="mt-4">
                                    <Button
                                        color="secondary"
                                        loading={isLoading}
                                        disabled={!passwordState.passwordsMatch || !passwordValidation.isPasswordValid}
                                        type="submit">
                                        Reset Password
                                    </Button>
                                </Col>
                                }
                                {passwordHasBeenReset &&
                            <Col className="mt-4">
                                <Link
                                    className="btn btn-success px-4 py-2 text-center"
                                    to="/login"
                                    color="success"
                                >
                                    Login
                                </Link>
                            </Col>
                                }
                            </CardBody>
                        </fieldset>
                    </form>
                </Card>
            </Row>
        </div>

    )
}

export default ResetPassword