
import { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
// evertel
import { useService } from '@evertel/di'
import { DrawerController } from '@evertel/drawer'
import { DrawerHeader } from './elements'
import { Col, Row, ContentPlaceholder } from '@evertel/web/ui'
import { SessionState } from '@evertel/session'
import { CurrentUserController, UserSettingsController } from '@evertel/blue-user'
import { AccountDrawer, AdminDrawer, DocumentsDrawer, MessagesDrawer, ManageDrawer } from './drawers'


const Drawer: React.FC = observer(() => {

    const session = useService(SessionState, [])
    const drawerController = useService(DrawerController, [])
    const userSettingsController = useService(UserSettingsController, [])
    const currentUserController = useService(CurrentUserController, [])

    useEffect(() => {
        if (!session.currentUserId) return

        //this does nothing:
        drawerController.init()
        //this fetches user but not with async:
        userSettingsController.init(session.currentUserId)

    }, [drawerController, userSettingsController, session.currentUserId])

    const userMeta = currentUserController.user?.meta as any
    const display = userMeta?.appearance?.drawerDisplay || 'medium'

    return (
        <Col
            valign="top"
            className={classNames(
                'drawer animated fadeIn',
                {
                    [display]: display
                })}
        >
            <DrawerHeader/>
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Col
                    valign="top"
                    className="inner-drawer">
                    {(drawerController.activeSection === 'messages') &&
                        <MessagesDrawer/>
                    }
                    {(drawerController.activeSection === 'everdocs') &&
                        <DocumentsDrawer/>
                    }
                    {(drawerController.activeSection === 'account') &&
                        <AccountDrawer/>
                    }
                    {(drawerController.activeSection === 'manage') &&
                        <ManageDrawer/>
                    }
                    {(drawerController.activeSection === 'admin') &&
                        <AdminDrawer/>
                    }
                </Col>
            </PerfectScrollbar>
        </Col>
    )
})

export { Drawer }

