import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { Badge, ContentPlaceholder, Row, Text } from '@evertel/web/ui'
import { useService } from '@evertel/di'
import { DepartmentController } from '@evertel/department'
import { APIDataBlueUser, APIDataRoom, APIDataDepartmentUserThrough } from '@evertel/types'
import { SessionState } from '@evertel/session'
import { DepartmentsAccessController } from '@evertel/departments-access'
import { getNonGuestPrimaryDepartmentsAccess } from '@evertel/utils'
import { MessageWallContext } from '../../MessageWallContext'
import { DisplayRoomMessage, DisplayThreadMessage } from '@evertel/stores'

interface MessageHeaderProps {
    message: DisplayRoomMessage | DisplayThreadMessage,
    user?: APIDataBlueUser,
    isGuest?: boolean,
    onClickUser: (userId: number) => void,
    className?: string
    variant?: 'list-item' | 'search' | 'reply' | 'reply-forwarded'
}

const MessageHeader: React.FC<MessageHeaderProps> = observer(({
    message,
    user,
    isGuest,
    onClickUser,
    className,
    variant = 'list-item'
}) => {
    const { modelData } = useContext(MessageWallContext)

    const session = useService(SessionState, [])
    const departmentController = useService(DepartmentController, [])
    const daController = useService(DepartmentsAccessController, [message.ownerId])

    const isRoom = Boolean((message as DisplayRoomMessage).roomId)
    const departmentId = isRoom ? ((modelData as APIDataRoom)?.departmentId ?? session.selectedDepartmentId) : undefined

    const [subtext, setSubtext] = useState<string>('')

    useEffect(() => {
        (async () => {
            if (!user?.id) {
                setSubtext('')
                return
            }

            if (user.isBot) {
                setSubtext('Evertel Bot')
                return
            }

            await daController.init(message.ownerId)

            let relevantDepartmentAccess: APIDataDepartmentUserThrough | undefined
            let departmentName: string | undefined

            if (isGuest || !isRoom) {
                relevantDepartmentAccess = getNonGuestPrimaryDepartmentsAccess(daController.departmentsAccess)
            } else if (isRoom && departmentId) {
                relevantDepartmentAccess = daController.departmentsAccess.find(da => da.departmentId === departmentId)
            }

            if (relevantDepartmentAccess) {
                await departmentController.init(relevantDepartmentAccess.departmentId)
                departmentName = departmentController.shortName || departmentController.department?.name
            }

            const position = relevantDepartmentAccess?.positionString || ''

            if (!position) {
                setSubtext('No position')
            } else if (isGuest || (!isRoom && user.id !== session.currentUserId)) {
                setSubtext(departmentName ? `${position} @ ${departmentName}` : position)
            } else {
                setSubtext(position)
            }

        })()
    }, [
        message, user?.id, isGuest, 
        daController.departmentsAccess, 
        departmentId, 
        departmentController.department.name
    ])

    if (!message) return null

    if (!user?.firstName) return (
        <Row valign="center" className="pt-1">
            <ContentPlaceholder width={300} height={18} />
        </Row>
    )

    return (
        <Row valign="center" className="flex-wrap">
            <div>
                <Text
                    bold
                    onClick={() => onClickUser(user?.id)}
                    className={classNames(className, {'cursor-pointer': onClickUser})}>
                    {`${user?.firstName || ''} ${user?.lastName || ''}`}
                </Text>
            </div>
            {variant !== 'reply-forwarded' &&
            <div>
                <Text
                    color="muted"
                    size="smaller"
                    className="pl-2"
                    italic={subtext === 'No position'}>
                    {subtext}
                </Text>
                {variant !== 'reply' &&
                <Text
                    color="muted"
                    size="smaller"
                    className="pl-1">
                    &#183; {moment(message.publishedDate || message.createdDate).format('H:mm')}
                </Text>
                }
            </div>
            }
        </Row>
    )
})

export { MessageHeader }