import { observable, action, runInAction, makeObservable } from 'mobx'
import api from '../api'
import AppStore from './AppStore'
import CurrentUserStore from './CurrentUserStore'
import RegistrationStore from './RegistrationStore'
import EmojiStore from './EmojiStore'
import AnalyticsStore from './AnalyticsStore'
import * as Electron from '../utilities/electronInterface'
import NavigationStore from './NavigationStore'
import { container } from '../di'
import { AuthenticationController } from '@evertel/shared/feature-authentication-auth0'
import { DepartmentSSOController } from '@evertel/department'
import { SessionState } from '@evertel/session'
import { DeviceState } from '@evertel/device'
import { CurrentUserPollService } from '@evertel/blue-user'


// Keeps track of our login state and progress
class LoginStore {
    isBusy = false
    error: any
    isLoggedIn = false
    authenticationController: AuthenticationController
    departmentSSOController: DepartmentSSOController
    sessionState: SessionState
    deviceState: DeviceState
    mfaToken: any
    oobCode: any
    email = ''
    password = '' // used to log user in after email verification (only set if email verification needed)
    currentUserPollService: CurrentUserPollService

    constructor() {
        this.authenticationController = container.get(AuthenticationController)
        this.departmentSSOController = container.get(DepartmentSSOController)
        this.sessionState = container.get(SessionState)
        this.deviceState = container.get(DeviceState)
        this.currentUserPollService = container.get(CurrentUserPollService)

        makeObservable(this, {
            isBusy: observable,
            error: observable,
            isLoggedIn: observable,
            email: observable,
            password: observable,
            login: action,
            resetForgotPassword: action,
            enterpriseLogin: action
        })
    }

    async loginToAuth0(email: string, password: string) {
        // reset out current values
        this.isBusy = true
        this.isLoggedIn = false
        this.error = false
        this.email = email

        let response: any
        const body = {
            email: email,
            password: password,
            deviceToken: this.deviceState.deviceToken
        }

        // Login to Auth0
        try {
            response = await this.authenticationController.loginToAuth0(body)

        } catch (e: any) {
            runInAction(() => {
                this.isBusy = false
                this.error = e.message
            })

            if (this.error.includes('Please verify your email')) {

                runInAction(() => {
                    this.email = email
                    this.password = password
                })

                NavigationStore.navigate('/email-verification')
            }
            return false
        }

        if (typeof response === 'string' && response.includes('https')) {
            runInAction(() => {
                this.isBusy = false
            })
            NavigationStore.navigateToExternal(response)
            return
        }

        if (response.mfaToken) {
            this.mfaToken = response.mfaToken
            this.oobCode = response.oobCode

            NavigationStore.navigate('/two-factor')
        } else {
            const loginResponse = await this.login(response.access_token)
            return loginResponse
        }

        return true
    }

    async getTFAToken(code: string) {

        let response: any
        const bodyParams = {
            mfaToken: this.mfaToken,
            oobCode: this.oobCode,
            bindingCode: code,
            deviceToken: this.deviceState.deviceToken
        }

        // Try to get access token
        try {
            response = await this.authenticationController.verifyChallenge(bodyParams)
        } catch (e: any) {
            runInAction(() => {
                this.isBusy = false
                this.error = e.message
            })
            return
        }

        if (response.error) {
            runInAction(() => {
                this.isBusy = false
                this.error = response.error_description
            })
            return
        }

        await this.login(response.access_token)
    }

    async login(accessToken: string) {
        // reset out current values
        this.isBusy = true
        this.isLoggedIn = false
        this.error = false

        const bodyAPIRequest = {
            auth0Token: accessToken,
            deviceToken: this.deviceState.deviceToken
        }

        let loginResponse: any

        // Try to login
        try {
            loginResponse = await this.authenticationController.postLogin(bodyAPIRequest)
        } catch (e: any) {
            runInAction(() => {
                this.isBusy = false
                this.error = e.message
            })
            return
        }

        if (!api.isProduction) console.log('loginResponse', loginResponse)

        const { userId, deviceId, user, id } = loginResponse

        // set values in state
        this.sessionState.setCurrentUserId(userId)
        this.deviceState.setCurrentDeviceId(deviceId)

        // Extract api response fields
        localStorage.setItem('userId', userId)

        // Update app values
        runInAction(() => {
            AppStore.hasValidAuthToken = true
            api.setAuthToken(id)
            localStorage.setItem('authorization', id)
        })

        // Get current user details, includes managed rooms and departments access
        await this.currentUserPollService.fetchUserDetails()


        // TODO: we should store and resolve the last selected department for this user, else do the following:
        // Resolve primary department
        const firstPrimary = CurrentUserStore.departmentsAccess.find((d: any) => d.isPrimary && d.isVerified) || false
        const firstVerified = CurrentUserStore.departmentsAccess?.find((d: any) => d.isVerified) || false
        const firstDepartmentId = CurrentUserStore.departmentsAccess.length &&  CurrentUserStore.departmentsAccess[0].departmentId
        const primaryDepartmentId = firstPrimary.departmentId || firstVerified.departmentId || firstDepartmentId || 0
        this.sessionState.setSelectedDeparmentId(primaryDepartmentId)

        RegistrationStore.init()
        EmojiStore.init()

        // fetch department emojis and childDepartments
        if (CurrentUserStore.selectedDepartment) {
            //TODO: selectedDepartment uses OLD department controller, should NOT use
            CurrentUserStore.selectedDepartment.fetchEmojis()

            if (CurrentUserStore.hasExecutiveRole) {
            //TODO: selectedDepartment uses OLD department controller, should NOT use
                CurrentUserStore.selectedDepartment.fetchChildDepartments()
            }

        }

        // get device info/settings
        this.deviceState.fetchCurrentDevice()

        // Complete
        runInAction(() => {
            this.isBusy = false
            this.isLoggedIn = true
        })

        // log to analytics
        AnalyticsStore.logEvent({
            category: 'User',
            action: 'login',
            label: undefined,
            value: undefined,
            nonInteraction: undefined,
            transport: undefined
        })

        const dept = CurrentUserStore.selectedDepartment
        if (dept) {
            // log user data to GA
            AnalyticsStore.setDimension({
                dimension1: dept.type, // department type
                dimension2: dept.name, // department name
                dimension3: dept.id.toString(),  // department id
                dimension4: CurrentUserStore.selectedDepartmentRole, // role in dept.
                dimension5: CurrentUserStore.isAdmin
                //dimension6: deptSize, //size of dept. (small, medium, large)
            })
        } else if (user.auth0.enterprise) {
            // If user has no department and the user is enterprise, try joining to a department
            try {
                await this.departmentSSOController.joinEnterpriseAccount(user.id)
                await this.currentUserPollService.fetchUserDetails()
            } catch (e: any) {
                runInAction(() => {
                    this.isBusy = false
                    this.error = e.message
                })
                if (e.code === 'AUTO_JOIN_FAILED') {
                    NavigationStore.navigate('/register/department')
                }
                return
            }
        }

        Electron.loggedIn({
            canManage: CurrentUserStore.canManage,
            userDepartments: CurrentUserStore.departments.map(d => { return { id: d.id, name: d.shortName } }),
            selectedDepartment: { id: dept?.id, name: dept?.shortName }
        })

        NavigationStore.restoreNavigation()

        return { ...loginResponse, selectedDepartmentId: CurrentUserStore.selectedDepartment?.id }
    }

    forgotPassword = async (email: string) => {
        this.isBusy = true

        try {
            await this.authenticationController.forgotPassword(email)

            runInAction(() => {
                this.isBusy = false
            })
            return true

        } catch (e: any) {
            runInAction(() => {
                this.isBusy = false
                this.error = e.message
            })

            AppStore.logError({ type: 'API post', message: 'LoginStore.forgotPassword()', error: e.message || e })
            return false
        }
    }


    resetForgotPassword = async (body: {password:string, confirmation: string}, token: string) => {
        this.isBusy = true
        this.error = null
        try {
            await this.authenticationController.resetForgotPassword(body, token)

            runInAction(() => {
                this.isBusy = false
            })
            return true
        } catch (e: any) {
            runInAction(() => {
                this.isBusy = false
                this.error = e.message
            })

            AppStore.logError({ type: 'API put', message: 'LoginStore.resetForgotPassword()', error: e.message || e })
            return false
        }

    }

    enterpriseLogin = async (email: string) => {
        this.isBusy = true
        this.isLoggedIn = false

        try {
            const redirectUrl = await this.authenticationController.enterpriseLogin(email)
            window.location.href = redirectUrl
            return redirectUrl
        } catch (e: any) {
            this.isBusy = false
            this.error = e.message
        }
    }

    challengeUser = async (mfaToken: string, options: any) => {
        let response: any
        runInAction(() => {
            this.isBusy = false
            this.error = null
        })

        try {
            response = await this.authenticationController.challengeUser(mfaToken, options)

            runInAction(() => {
                this.isBusy = false
                this.oobCode = response.oob_code
            })

        } catch (e: any) {
            runInAction(() => {
                this.isBusy = false
                this.error = e
            })
        }
    }

}

export default new LoginStore()