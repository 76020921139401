
import { observer } from 'mobx-react'

// evertel
import { Icon, FloatingMenu, MenuItem, MenuHeader } from '@evertel/web/ui'
import { HTMLAttributes, useMemo } from 'react'
import { useService } from '@evertel/di'
import { SessionState } from '@evertel/session'
import { CurrentUserController } from '@evertel/blue-user'


interface HeaderButtonSettingsProps extends HTMLAttributes<HTMLButtonElement> {
    onClick?: () => void
    userRole?: string
}


const HeaderButtonSettings: React.FC<HeaderButtonSettingsProps> = observer(({
    userRole,
    ...otherProps
}) => {

    const session = useService(SessionState, [])
    const currentUserController = useService(CurrentUserController, [])

    const canViewSettings = userRole === 'executive' || userRole === 'management'

    const showSettings = currentUserController.user?.isAdmin || canViewSettings

    const renderEvertelAdministrationMenu = () => (
        <FloatingMenu label="Connect Admin">
            <MenuHeader style={{ color: 'dodgerblue' }} label="Connect Admin:" />
            <MenuItem
                label="Dashboard"
                icon={{ name: 'tachometer-alt' }}
                to='/admin/dashboard'
            />
            <MenuItem
                label="Agencies"
                icon={{ name: 'building' }}
                to='/admin/agencies'
            />
            <MenuItem
                label="Users"
                icon={{ name: 'users' }}
                to='/admin/users'
            />
            <MenuItem
                label="Bot Campaigns"
                icon={{ name: 'paper-plane' }}
                to='/admin/messaging'
            />
            <MenuItem
                label="Verified Domains"
                icon={{ name: 'cloud-check' }}
                to='/admin/verified-domains'
            />
            <MenuItem
                label="Global EverDoc Templates"
                icon={{ name: 'table-layout' }}
                to='/everdocs/schema/templates/global'
            />
        </FloatingMenu>
    )

    const renderAdminToolsMenu = () => (
        <FloatingMenu label="Admin Links">
            <MenuHeader style={{ color: 'green' }} label="Admin Links:" />
            <MenuItem
                label="JIRA"
                icon={{ name: 'jira', type: 'brands' }}
                href="https://evertel.atlassian.net/"
            />
            <MenuItem
                label="Auth0/Okta"
                icon={{ name: 'shield-keyhole', type: 'solid' }}
                href="https://manage.auth0.com/dashboard/us/evertel-production/"
            />
            <MenuItem
                label="Mandrill"
                icon={{ name: 'mailchimp', type: 'brands' }}
                href="https://mandrillapp.com/"
            />
            <MenuItem
                label="Hubspot"
                icon={{ name: 'hubspot', type: 'brands' }}
                href="https://app.hubspot.com/"
            />
        </FloatingMenu>
    )

    const renderManageAgencyMenu = () => (
        <>
            <MenuHeader label={<span style={{ fontWeight: 'bold' }}>Manage Agency:</span>} />
            { false && //REMOVED UNTIL THIS PAGE IS FIXED
                <MenuItem
                    label="Agencies"
                    icon={{ name: 'building' }}
                    to='/manage/linked'
                />
            }
            <MenuItem
                label="Users"
                icon={{ name: 'users' }}
                to='/manage/users'
            />
            <MenuItem
                label="Rooms"
                icon={{ name: 'sitemap' }}
                to='/manage/rooms'
            />
            {currentUserController.canExecutive && (
                <>
                    <MenuItem
                        label="Audits"
                        icon={{ name: 'search' }}
                        to='/manage/audits'
                    />
                    <MenuItem
                        label="History Logs"
                        icon={{ name: 'history' }}
                        to='/manage/history'
                    />
                    <MenuItem
                        label="Usage Reports"
                        icon={{ name: 'chart-pie' }}
                        to={`/manage/reports/${session.selectedDepartmentId}`}
                    />
                    <MenuItem
                        label="Agency Settings"
                        icon={{ name: 'cogs' }}
                        to={`/manage/agency/settings/${session.selectedDepartmentId}`}
                    />
                </>
            )}
        </>
    )


    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {showSettings && (
                <FloatingMenu
                    label="Settings"
                    renderLabel={() => (
                        <Icon
                            name="cog"
                            color="muted"
                            size={16}
                        />
                    )}
                    {...otherProps}
                >
                    {currentUserController.user?.isAdmin && renderEvertelAdministrationMenu()}
                    {currentUserController.user?.isAdmin && renderAdminToolsMenu()}
                    {canViewSettings && renderManageAgencyMenu()}
                </FloatingMenu>
            )}
        </>
    )

})

export { HeaderButtonSettings }
